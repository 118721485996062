import React, { Component, Fragment } from 'react';
import { INSTRUCTION_SEARCH, REFERENCE_TYPE, INSTRUCTION_DELETE, INSTRUCTION_FAV } from '../../utils/constant';
import LS_SERVICE from '../../utils/localStorage';
import { Modal, Button, Form } from 'antd';
import axios from 'axios';
import icsearch from '../../assets/images/ic-search.svg'
import { jQueryLabels, AntSelectLabels, FixedCta } from '../../utils/jQueryLabels';
class InstructionsForm extends Component {
    constructor(props) {
        super(props)
        this.state = ({
            instructions: [],
            instructionSelected: this.props.addedInstructionsData ?? [],
            newInstructions: [],
            addedInstructions: [],
            searchBtnEnabled: true,
            searchInstruction: '',
            addBtnEnabled: false,
        })
    }

    async removeFromSelected(obj){
        let selected =this.state.instructionSelected
        let newSelected = selected.filter(item =>item.id !== obj.id)
        await this.setState({
            instructionSelected : newSelected
        })
    }

    updatedeleteInstruction = (obj) => {
        const PARAMS = {
            reference_type: REFERENCE_TYPE,
            reference_id: LS_SERVICE.get('call_patient_detail').qms_token_id,
            instruction_id: obj.id
        }
        axios.get(INSTRUCTION_DELETE(PARAMS))
            .then(success => {
                if (success.data) {
                    this.SearchInstrunction();
                    this.removeFromSelected(obj)
                }
            });
    }

    updateAddedInstructions = () => {
        let { instructionSelected, newInstructions } = this.state;
        instructionSelected.map((v, k) => {
            if (v.id == -1)
                newInstructions.push({ can_delete: true, id: -1, instruction: v.instruction, selected: true });
        });
        this.setState({ newInstructions });
    }

    SearchInstrunction =(search = '') => {
        axios.defaults.headers.common["Authorization"] = LS_SERVICE.get('token');

        const doctor_id = LS_SERVICE.get('staff_id');
        axios.get(`${INSTRUCTION_SEARCH}?&doctor_id=${doctor_id}&query=${search}`
        ).then(success => {
            var instructionData = success.data.data.details;
            var addedInstructionsData = {};
            let newInstructions = this.state.newInstructions;
            this.state.instructionSelected.map((v, k) => {
                if (v.id != -1)
                    addedInstructionsData[v.id] = v.instruction;
            });
            let matches = newInstructions.filter(str => str.instruction.toLowerCase().includes((search).toLowerCase()));
            instructionData = [...instructionData, ...matches];
            instructionData.map((v, k) => {
                if (v.id != -1 && typeof addedInstructionsData[v.id] != 'undefined') {
                    instructionData[k].instruction = addedInstructionsData[v.id];
                    instructionData[k].selected = true;
                }
            });
           let arr1=[]
           let arr2=[]
            instructionData.map(ele=>{
                if(ele.is_fav == "Y"){
                    arr1.push(ele)
                }
                else{
                    arr2.push(ele)
                }
            })

            arr2.map(inst=>{
                arr1.push(inst)
            })

            if (search == '') {
                let instructData = []
                let data = arr1;
                data.map(obj => {
                    if (obj.is_fav == "Y" || obj.selected == true) {
                        instructData.push(obj)
                    }
                })
                this.setState({
                    instructions: instructData
                });
            }
            else {
                this.setState({
                    instructions: arr1
                });
            }
        }).catch(err => console.log(err))
    }
    componentDidMount() {
        this.updateAddedInstructions();
        this.SearchInstrunction();
        jQueryLabels();
        FixedCta();
    }
    componentDidUpdate(prevProps, prevState) {

    }
    static getDerivedStateFromProps(nextProps, prevState) {

        return null;
    }
    onInstructionSelect = (id, newInstruction, oldInstruction) => {
        let { instructionSelected } = this.state;
        /*instructions.map((v,k)=>{
            if(v.id==id){
                instructions[k].instruction=instructions[k].selected?newInstruction:oldInstruction;
                instructions[k].selected=!instructions[k].selected;
            }
        })*/
        var instructionExist = -1;
        instructionSelected.map((v, k) => {
            if (id != -1 && v.id == id)
                instructionExist = k;
            else if (v.instruction == newInstruction)
                instructionExist = k;
        })
        if (instructionExist != -1)
            instructionSelected.splice(instructionExist, 1);
        else
            instructionSelected.push({ id: id, instruction: newInstruction });
        this.setState({ instructionSelected })

        this.SearchInstrunction();
    }

    saveInstructions = () => {
        /*const {instructionSelected} = this.state;
        let instructionsData =[];
        instructionSelected.map((v,k)=>{
            instructionsData.push({id:k, instruction:v});
        })*/
        this.props.updateData(this.state.instructionSelected);
    }

    /* onInstructionselectedList() {
         if (!this.state.instructionSelected || this.state.instructionSelected.length === 0) {
             return (null)
         }
         return (
             <ul className="listed-success">
                 {this.state.instructions.map((obj, i) => {
                     return (
                         <li key={i}>{obj.instruction}</li>
                     )
                 })
                 }
             </ul>
         )
     }*/
    instructionAdd = (e) => {
        let { newInstructions, searchInstruction, instructionSelected } = this.state;
        let instructionObj = {};
        instructionObj = { can_delete: true, id: -1, instruction: searchInstruction, selected: true }
        newInstructions.push(instructionObj);
        instructionSelected.push({ id: -1, instruction: searchInstruction });
        // this.onInstructionselect(-1, searchInstruction, true, 0)
        this.setState({
            newInstructions,
            instructionSelected,
            searchInstruction: ''
        }, () => { this.SearchInstrunction(); this.instruction_textarea('instruction_textarea', true); AntSelectLabels(); })
        document.getElementById('search-instruction').focus();
    }
    instructionChange = (e) => {
        // this.SearchInstrunction(e.target.value);
        // const { instructionListAdded} = this.state;
        // let matches =  instructionListAdded.filter(str => str.instruction.toLowerCase().includes((e.target.value).toLowerCase()));
        this.setState({
            searchInstruction: e.target.value,
        }, () => this.SearchInstrunction(this.state.searchInstruction))


        FixedCta();
    }

    autoHeight = (e) => {
        if (e)
            e.target.style.height = e.target.scrollHeight + "px";
    }

    instruction_textarea = (textarea, clear = false) => {
        let Elem = document.querySelectorAll('.' + textarea);
        if (!clear) {
            Elem.forEach((el) => { el.style.height = el.scrollHeight + "px"; });
        } else {
            Elem.forEach((el) => { el.style.height = "40px"; });
        }
    }

    makeFavInstruction(obj) {
        const PARAMS = {
            id: obj.id,
        }
        axios.post(INSTRUCTION_FAV, PARAMS)
            .then(success => {
                if (success.data) {
                    this.SearchInstrunction();
                }
            });
    }

    render() {
        let showdeletebutton = this.props.showdeletebutton ? this.props.showdeletebutton : false;
        return (
            <Fragment>
                <div className="row add_instruc">
                    <div className="col-12 emrfrm ">
                        <div className="form-group adv-search">
                            <textarea
                                id="search-instruction"
                                name="search_instruction"
                                style={{ minHeight: "calc(1.75em + 0.75rem + 2px)" }}
                                onInput={(e) => this.autoHeight(e)}
                                className="form-control textarea-field instruction_textarea" placeholder="Search Instructions" autoComplete="off" autoFocus value={this.state.searchInstruction}
                                onChange={this.instructionChange} />
                            <label htmlFor="search-instruction">Search Instructions</label>

                            {this.state.instructions && this.state.instructions.length === 0 && this.state.searchInstruction!=''?
                                <button type="button" className="btn btn-outline-primary"
                              onClick={this.instructionAdd}>ADD</button>
                                :
                                <button type="button" className="btn find_instruction"><img src={icsearch} alt="Search" className="icon" /></button>
                            }
                        </div>
                    </div>
                    <ul className="col-12 instructions_search_list instruction-wrap mb-2 emrfrm">
                        {
                            (this.state.instructions !== undefined && this.state.instructions.length !== 0) ?
                                this.state.instructions.map((obj, i) => {

                                    return (
                                        <li key={i} className="instructions_list3 mt-2">
                                            <div className="custom-control custom-checkbox">

                                                <input type="checkbox" className="custom-control-input" id={'instructions_item_' + i}
                                                    checked={obj.selected}
                                                    onChange={(e) => this.onInstructionSelect(obj.id, obj.instruction, obj.instruction)} datainstruction={obj.id} name="instructions_item" />
                                                <label className="custom-control-label" style={{ width: "80%" }} htmlFor={'instructions_item_' + i}>{obj.instruction}</label>

                                                {showdeletebutton == true && obj.id != -1 ?
                                                    <a className='icon_delete float-right' onClick={() => this.updatedeleteInstruction(obj)}></a>
                                                    : null}
                                                {
                                                   showdeletebutton == true &&  obj.id != -1 && obj.is_fav == "N" ?
                                                        <a className='icon_star float-right mr-2' onClick={() => this.makeFavInstruction(obj)}></a>
                                                        : null
                                                }
                                                {
                                                   showdeletebutton == true &&  obj.id != -1 && obj.is_fav == "Y" ?
                                                        <a className='icon_star favourite float-right mr-2' onClick={() => this.makeFavInstruction(obj)}></a>
                                                        : null
                                                }

                                            </div>
                                        </li>
                                    )
                                }) : null
                        }
                    </ul>
                </div>

                <div className="modal-footer pb-0 pt-1">
                    <div className="col-12 text-center instructions_cta">
                        <button type="button" className="btn btn-outline-secondary px-4 mr-2" onClick={(e) => this.props.handleModalPopup(e, 'showInstructionModal')}>CANCEL</button>
                        <button type="submit" className="btn btn-primary px-4" onClick={this.saveInstructions}>SAVE</button>
                    </div>
                </div>
                {/*<div className="row instructions-container">
                    <div className="col-12 mt-3">
                        <div className="float-left">
                            <h2 className="text-heading mb-3">Instructions</h2>
                        </div>
                        <div className="float-right">
                            <button className="btn btn-outline-primary small" onClick={() => this.showInstModal()}>+ INSTRUCTIONS</button>
                        </div>

                    </div>
                    <div className="col-12" id="instructions_selected_list">
                        {this.onInstructionselectedList()}
                    </div>
                </div>
                <Modal id="modal_instructions"
                    title="Instructions"
                    visible={this.state.isInstModalVisible}
                    onOk={() => this.handleInstOk()}
                    onCancel={() => this.handleInstCancel()}
                    footer={[
                        <Button
                            type="button"
                            className="btn btn-outline-secondary px-4 small  mr-2 text-uppercase"
                            onClick={() => this.handleInstCancel()}>CANCEL</Button>,
                        <Button className="btn btn-primary px-4 small text-uppercase" id="instructions_save" onClick={() => this.handleInstOk()}>SAVE</Button>
                    ]}
                >*/}

                {/*<div className="row" >
                        <ul className="col-12 instructions_search_list instruction-wrap mb-5 emrfrm">
                            {
                                (this.state.addList !== undefined || this.state.addList.length !== 0) ?
                                    this.state.addList.map((obj, i) => {
                                        let checked1 = this.state.instructionSelected.filter((data, i) => data.instructionId === obj.id || data.id === obj.id)
                                        return (
                                            <li key={i} className="instructions_list3">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id={'instructions_item_' + i}
                                                        value={obj.selected} 
                                                        // defaultChecked={obj.selected===true}
                                                        defaultChecked={this.state.instructionSelected.find(x => x.instructionId === obj.id) ? "checked" : ""}
                                                        onChange={(e) => this.onInstructionselect(obj.id, obj.instruction, obj.selected, checked1.length)} datainstruction={obj.id} name="instructions_item" />
                                                    <label className="custom-control-label" htmlFor={'instructions_item_' + i}>{obj.instruction}</label>
                                                </div>
                                            </li>
                                        )
                                    }) : null
                            }
                        </ul>
                    </div>
                </Modal>*/}
            </Fragment>
        );
    }
}

export default InstructionsForm;