import React, { Component } from 'react';
import Axios from 'axios';
import qs from 'qs';
// import LS_SERVICE from '../../utils/localStorage';
import { ALL_PRESCRIPTIONS, LOADER_RED, PATIENT_DATA } from '../../utils/constant';

import AsideLeft from '../../components/aside/asideleft';
// import copyImg from '../../assets/images/copy.png';
import { Pagination } from 'antd';
import { Modal } from 'antd';
import Moment from 'react-moment';
import LS_SERVICE from '../../utils/localStorage';
import { headerService$ } from '../../utils/rxjs-sharing';

class AllPastVisit extends Component {

    state = {
        patient: null,
        pagination: null,
        currentPage: 1,
        visits: [],
        isLoading: false,
        showRxModal: false,
        rxPdfPath: null,
        records_per_page: 10
    };

    componentDidMount() {

        const { match: { params } } = this.props;
        const { hospital_id, patient_id, reference_id } = params;
        const patient = {
            hospital_id,
            patient_id,
            qms_token_id: reference_id
        }
        this.setState({
            hospital_id,
            patient_id,
            reference_id,
            patient
        }, () => {
            if (LS_SERVICE.has('hospitalConfigData')) {
                const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
                let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
                headerService$.soapConfig({ soapConfig: hospitalConfigObj.opd_layout }); // IMPORTANT TO HIT AS PER THE VIEW
                this.primaryLoadData({ loader: true });
            }
        });

        // if (this.props.location.state !== undefined) {
        //     this.setState({
        //         patient: this.props?.location?.state?.patient
        //     }, () => {
        //         this.primaryLoadData({ loader: true });
        //     });
        // } else {
        //     this.getPatientData();
        // }
        this.navigateToPage = this.navigateToPage.bind(this);
    }

    // getPatientData = _ => {
    //     const { match: { params } } = this.props;
    //     const { patient_id } = params;
    //     const { call_qms_token_id } = this.state;
    //     Axios.get(PATIENT_DATA({ patient_id }))
    //         .then(success => {
    //             const patient = {
    //                 ...success.data.data.results,
    //                 qms_token_id: success.data.data.results.qmsToken ? success.data.data.results.qmsToken.qms_token_id : call_qms_token_id
    //             }
    //             this.setState({
    //                 patient
    //             }, () => {
    //                 this.primaryLoadData({ loader: true });
    //             });
    //         })
    //         .catch(err => console.log(err))
    // }

    goBack = (event) => {
        event.preventDefault();
        this.props.history.goBack();
    }

    primaryLoadData = (info) => {
        const { patient } = this.state;

        if (info.loader) {
            this.setState({ isLoading: true });
        }

        const PARAMS = {
            patient_id: patient.patient_id,
            page: this.state.currentPage
        }
        Axios.post(ALL_PRESCRIPTIONS, qs.stringify(PARAMS))
            .then(response => {
                this.setState({
                    isLoading: false,
                    visits: response.data.past_visit,
                    pagination: response.data.pagination
                });
            });
    }

    navigateToPage(page) {
        this.setState({
            currentPage: page
        }, () => this.primaryLoadData({ loader: false }));
    }

    allvisits() {
        return (
            <>
                {this.state.visits.map((visit, index) => (
                    <div className={"card card1 border-0 mb-4 " + (index > 0 ? 'mt-4' : '')} key={index}>
                        <div className="row">
                            <div className="col-md-6">
                                <button disabled = {visit.confidential_prescription} className="btn text-left page-title btn-bg-accordion-red past-visit-container-button w-100 collapsed" data-referencetype="0" data-referenceid="undefined" data-toggle="collapse" data-target="#collapseBA517H2L49" role="button" aria-expanded="false" aria-controls="collapseBA517H2L49">
                                    <div className="float-left">
                                        <span className="d-block font-weight-normal">{visit.doctor_name}</span>
                                        <small className="mt-1">
                                            {visit.date != null ? <Moment parse="YYYY-MM-DD HH:mm" date={visit.date} format="D MMM, YYYY h:mm A" /> : null}
                                        </small>
                                    </div>
                                    <div className="float-right mt-2">
                                        {
                                          visit.confidential_prescription == false
                                        ?
                                          <button onClick={() => this.setState({ rxPdfPath: visit.upload_prescription, showRxModal: true })} className="btn btn-sm float-right btn-primary text-uppercase show-rx-modal">VIEW RX</button>
                                        :
                                          <p class="text-uppercase text-secondary">Confidential</p>
                                        }
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </>
        )
    }

    render() {
        const { patient, visits, isLoading } = this.state;

        return (
            <>

                {(patient != undefined && patient.patient_id != undefined) ? <AsideLeft patient_id={patient.patient_id} reference_id={patient.qms_token_id} /> : null}

                <div className="content-wrapper">
                    <div className="container-fluid emrfrm">

                        <div className="row">
                            <div className="col-12 mb-3">
                                <div className="float-left">
                                    <h1 className="page-title mb-0">All Prescriptions</h1>
                                </div>
                                <div className="float-right">
                                    <button className="btn btn-outline-secondary px-4 small text-uppercase" onClick={this.goBack}>BACK</button>
                                </div>
                            </div>

                            {visits && visits.length > 0 ? <div className="col-lg-12" id="PastVisitContainerFluid">
                                <div className="accordion" id="accordionPastVisit">
                                    {this.allvisits()}
                                </div>
                            </div> : null}

                            {this.state.pagination != null && this.state.pagination.total_records > 0 ? <div className="mb-5">
                                <Pagination showSizeChanger={false} defaultCurrent={this.state.currentPage} defaultPageSize={this.state.records_per_page} total={this.state.pagination.total_records} onChange={this.navigateToPage} />
                            </div> : null}
                        </div>

                        {isLoading ? <div className="row">
                            <div className="col-md-12 text-center">
                                {LOADER_RED}
                            </div>
                        </div> : null}

                        <Modal
                            title="View Prescription"
                            visible={this.state.showRxModal}
                            footer={false}
                            width={850}
                            onCancel={() => { this.setState({ showRxModal: false, rxPdfPath: null }) }}
                            style={{ top: 20 }}
                        >
                            <div className="row">
                                <div className="col-12 my-3 text-center">
                                    {this.state.rxPdfPath != null ? <iframe src={this.state.rxPdfPath + "#toolbar=0"} frameBorder="0" scrolling="no" style={{ overflow: 'hidden' }} width="100%" height="700">
                                        {LOADER_RED}
                                    </iframe> : null}
                                </div>
                            </div>
                        </Modal>

                    </div>
                </div>
            </>
        );
    }

}

export default AllPastVisit;