import React from 'react';
import Axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import { PATIENT_INTERNAL_NOTES, BASIC_STORE, SOAP_DETAILS, COPY_PRESCRIPTION, GET_BASIC_DATA, UPDATE_QMS_STATUS, PATIENT_DATA, REFERENCE_TYPE, SEPARATEUPLOADEDDOCSNEW, LOADER_RED, TOGGLE_PRACTICE, ISALLOWEDITSOAP, EDIT_TEMPLATE } from '../../utils/constant';
import LS_SERVICE from '../../utils/localStorage';
import API_SERVICE from '../../utils/apiService';
import { Modal, notification, Carousel, Tooltip, Button } from 'antd';
import AsideLeft from '../../components/aside/asideleft';
import { Link } from 'react-router-dom';
import { headerService$, practiceService, speechService, speechTextService } from '../../utils/rxjs-sharing';
import MyHcSpeechRecognition from "../../pages/speech-to-text/speech-recognition";
import icJpg from '../../assets/images/ic-jpg.png';
import icPdf from '../../assets/images/ic-pdf.png';
import { jQueryLabels, FixedCta } from '../../utils/jQueryLabels';
import Bowser from "bowser";
import Moment from 'react-moment';

class BasicComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            patient: null,
            upbtn: false,
            patient_note_doctor: '',
            diagnosis_notes: '',
            medicines_tests: '',
            tests_adviced: '',
            instructions_for_patient: '',
            internal_notes: '',
            patientDataLoaded: false,
            copyPrescriptionModal: false,
            copyPrescriptionList: [],
            copyPrescriptionDataLoaded: false,
            copyPrescriptionPage: 1,
            copyPrescriptionNoMoreData: false,
            copyPrescriptionIsProcessing: false,
            copyPrescriptionChecked: null,
            copyPrescriptionOld: null,
            isDiagnosisConfirmed: false,
            isMedicineConfirmed: false,
            isTestConfirmed: false,
            isDiagnosisChecked: null,
            isMedicineChecked: null,
            isTestChecked: null,
            speech: {
                identifier: null,
                state: false
            },
            viewDocumentModal: false,
            docSrc: null,
            docType: '',
            isCpReqProcessing: false,
            isThisCompletedAppointment: null,
            hospital_id: null,
            isPracticing: false,
            isPracticingModal: false,
            qms_details: {},
            internalNotesInfo: [],
            currentHospitalConfigData: {},
            junior_doctor_complete_appointment:false
        }

        this.subscription = null;
        this.cpInfinitePagination = null;
        this.carouselRef = React.createRef();
    }

    async componentDidMount() {
        Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");

        const { match: { params } } = this.props;
        const { hospital_id, patient_id, reference_id } = params;

        const isPracticingHospitalId = LS_SERVICE.get('practicing')?.hospital_id;

        if (+isPracticingHospitalId === +hospital_id) {
            this.setState({
                isPracticing: true
            })
        }

        // if (LS_SERVICE.has('soapConfigData')) {
        //     const soapConfigData = LS_SERVICE.get('soapConfigData');
        //     let isSoapOrBasic = soapConfigData.find(scd => scd.reference_id === +reference_id);
        //     if (isSoapOrBasic !== undefined) {
        //         this.setState({
        //             hospital_id: isSoapOrBasic.hospital_id
        //         })
        //         isSoapOrBasic = isSoapOrBasic.soapConfig;
        //         headerService$.soapConfig({ soapConfig: isSoapOrBasic });
        //         LS_SERVICE.set('soapConfig', isSoapOrBasic);
        //     }
        // }

        this.checkJuniorDoctorCompleteAppoitment();
        this.setState({
            hospital_id,
            patient_id,
            reference_id,
        }, async () => {
            this.getInternalNotes();

            if (LS_SERVICE.has('hospitalConfigData')) {
                const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
                let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
                headerService$.soapConfig({ soapConfig: hospitalConfigObj.opd_layout }); // IMPORTANT TO HIT AS PER THE VIEW
                this.getPatientData();
            }
            const PARAMS = {
                reference_type: REFERENCE_TYPE,
                patient_id: this.state.patient_id,
                reference_id: this.state.reference_id,
                hospital_id: this.state.hospital_id,
            };
            var SOAP_DETAIL_REQ = await Axios.post(SOAP_DETAILS, qs.stringify(PARAMS))
            // .then((SOAP_DETAIL_REQ) => {
            await this.setState({
                qms_details: SOAP_DETAIL_REQ?.data?.details?.qms_details
            })
            // });
        });

        // if (this.props.location.state !== undefined) {
        //     this.setState({
        //         patient: this.props?.location?.state?.patient
        //     }, () => {
        //         this.getBasicData();
        //         this.handleCopyPresData();
        //     })
        // } else {
        //     this.getPatientData();
        // }

        this.subscription = speechTextService.status().subscribe(data => {
            if (this.state.speech.identifier === "diagnosis-notes") {
                this.setState({ diagnosis_notes: data.text });
            }
            if (this.state.speech.identifier === 'medicine-test') {
                this.setState({ medicines_tests: data.text });
            }
            if (this.state.speech.identifier === 'tests-adviced') {
                this.setState({ tests_adviced: data.text });
            }
            if (this.state.speech.identifier === 'instructions-to-patients') {
                this.setState({ instructions_for_patient: data.text });
            }
            if (this.state.speech.identifier === 'update-prescription') {
                this.setState({ update_prescription: data.text });
            }
            if (this.state.speech.identifier === 'internal-notes') {
                this.setState({ internal_notes: data.text });
            }
        });

        jQueryLabels();
        FixedCta();
        this.gethospitalConfigData();
    }

    checkJuniorDoctorCompleteAppoitment(){
        let  junior_doctor_complete_appointment = LS_SERVICE.get('junior_doctor_complete_appointment');
        let  is_junior_doctor = LS_SERVICE.get('is_junior_doctor');
        let call_patient_detail = LS_SERVICE.get('call_patient_detail');
        let current_login_doctor =LS_SERVICE.get('staff_id');
        if(is_junior_doctor == true && current_login_doctor != call_patient_detail.doctor_id){
            this.setState({
              junior_doctor_complete_appointment:!junior_doctor_complete_appointment
            })
        }
        }

    componentWillUnmount() {
        this.subscription.unsubscribe();
        jQueryLabels();
        FixedCta();
    }

    gethospitalConfigData = () => {
        let currentHospitalConfigData = '';
        for (let element of LS_SERVICE.get("hospitalConfigData")){
            if(element.hospital_id == LS_SERVICE.get("slot_hospital_id")){
                currentHospitalConfigData = element;
                break;
            }
        }
        
        this.setState({currentHospitalConfigData});
    }

    isShowData() {
        const { reference_id } = this.state;
        if (this.state.visitData?.visit?.status == 1) {
            let data = this.state.currentHospitalConfigData;

            return ISALLOWEDITSOAP(data.pres_modification, data.allow_hours, LS_SERVICE.get('appointmentCompletedTime')[reference_id], 'edit-prep') ? false : true
        }
        else{
            return false
        }
    }

    isShowbutton() {
        const { reference_id } = this.state;
        if (this.state.visitData?.visit?.status == 1) {
            let data = this.state.currentHospitalConfigData;

            return ISALLOWEDITSOAP(data.pres_modification, data.allow_hours, LS_SERVICE.get('appointmentCompletedTime')[reference_id], 'patient-queue') ? true : false
        }
        else
            return true
    }

    async getInternalNotes() {
        const { reference_id } = this.state;
        const notes = await Axios.get(PATIENT_INTERNAL_NOTES({ reference_type: REFERENCE_TYPE, reference_id: reference_id, type: 'basic' }));
        if (typeof notes.data != 'undefined' && typeof notes.data.data != 'undefined') {
            this.setState({
                internalNotesInfo: notes.data.data
            });
        }
    }

    cpScrollEvent = () => {
        var cpInfinitePagination = document.querySelector(".cp_scroll_loader");
        if (cpInfinitePagination !== null) {
            cpInfinitePagination.addEventListener("scroll", this.handleCpScroll, true)
        }
    }

    handleCpScroll = () => {
        const lastLi = document.querySelector(
            ".cp_scroll_loader > div > div:last-child"
        );
        const scrollWin = document.querySelector(".cp_scroll_loader");
        if (lastLi === null) return;
        let lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
        let pageOffset = scrollWin.scrollTop + scrollWin.clientHeight + lastLi.clientHeight;
        if (pageOffset > lastLiOffset) {
            this.cpLoadMore();
        }
    };

    cpLoadMore = () => {
        const { copyPrescriptionNoMoreData, isCpReqProcessing } = this.state

        if (isCpReqProcessing === true) return;
        if (copyPrescriptionNoMoreData) return;

        this.setState({
            isCpReqProcessing: true,
        });

        this.setState(
            (prevState) => ({
                copyPresc_pagination: prevState.copyPresc_pagination + 1
            }),
            () => {
                this.handleCopyPresData()
            }
        );
    }

    getBasicData = async _ => {

        const { patient, hospital_id, reference_id } = this.state;
        const { patient_id, qms_token_id } = patient;

        const PARAMS = {
            reference_type: REFERENCE_TYPE,
            reference_id: qms_token_id || reference_id,
            patient_id,
            hospital_id: hospital_id !== null ? hospital_id : LS_SERVICE.get('slot_hospital_id')
        }

        try {
            const basicPromise = await Axios.post(GET_BASIC_DATA, qs.stringify(PARAMS))

            if (basicPromise.status) {

                const { data } = basicPromise;
                const { patient_note_doctor, patient_docments, booking_status_code } = data.appointment;
                const { symptomDiagnosis, instructionsToPatients, medicineTest, testsAdviced, updatePrescription, uploadPrescription, prescription_bool, prescriptionPath, internalNotes } = data.visit;
                const docs = SEPARATEUPLOADEDDOCSNEW(patient_docments);
                let formData = [];
                if (LS_SERVICE.has('formData')) {
                    formData = LS_SERVICE.get('formData');
                }
                let diagnosis_notes = '';
                let medicines_tests = '';
                let update_prescription = '';
                let tests_adviced = '';
                let instructions_for_patient = '';
                let internal_notes = '';
                if (formData.find(e => e.id === data.appointment.id) !== undefined) {
                    formData.forEach(e => {
                        if (e.id === data.appointment.id) {
                            diagnosis_notes = e['diagnosis_notes'];
                            medicines_tests = e['medicines_tests'];
                            instructions_for_patient = e['instructions_for_patient'];
                            internal_notes = e['internal_notes'];
                            tests_adviced = e['tests_adviced'];
                            update_prescription = e['update_prescription'] !== undefined ? e['update_prescription'] : "";
                        }
                    })
                }

                this.setState({
                    booking_status: booking_status_code,
                    prescription_bool: prescription_bool || 0,
                    upload_prescription: uploadPrescription || null,
                    patient_note_doctor: patient_note_doctor || '',
                    internal_notes: internal_notes !== "" ? internal_notes : (internalNotes || ''),
                    diagnosis_notes: diagnosis_notes !== "" ? diagnosis_notes : (symptomDiagnosis || ''),
                    instructions_for_patient: instructions_for_patient !== "" ? instructions_for_patient : (instructionsToPatients || ''),
                    tests_adviced: tests_adviced !== "" ? tests_adviced : (testsAdviced || ''),
                    medicines_tests: medicines_tests !== "" ? medicines_tests : (medicineTest || ''),
                    update_prescription: update_prescription !== "" ? update_prescription : (updatePrescription || ''),
                    patient_docments: docs,
                    patientDataLoaded: true,
                    isThisCompletedAppointment: prescriptionPath,
                    visitData: data.visit
                })
            }
        } catch (err) {
            console.log(err)
        }

    }

    getPatientData = _ => {
        const { reference_id } = this.state;
        Axios.get(PATIENT_DATA({ reference_id }))
            .then(success => {
                const patient = {
                    ...success.data.data.results,
                    qms_token_id: success.data.data.results?.qmsToken?.qms_token_id || reference_id
                }
                this.setState({
                    patient
                }, () => {
                    this.getBasicData();
                    this.handleCopyPresData();
                })
            })
            .catch(err => console.log(err))
    }

    handleCopyPresData = async _ => {

        const { patient, copyPrescriptionPage } = this.state;

        const PARAMS = {
            patient_id: patient.patient_id,
            doctor_id: LS_SERVICE.get('staff_id'),
            page: copyPrescriptionPage
        }

        try {
            const prescriptionPromise = await Axios.post(COPY_PRESCRIPTION, qs.stringify(PARAMS))

            if (prescriptionPromise.status) {
                let { copyPrescriptionNoMoreData, copyPrescriptionList, copyPrescriptionPage } = this.state;
                const { data } = prescriptionPromise.data;
                const { pagination } = data;
                copyPrescriptionList = [...copyPrescriptionList, ...data?.visits];
                copyPrescriptionNoMoreData = pagination.total_pages === copyPrescriptionPage;
                copyPrescriptionPage += 1;

                this.setState({
                    copyPrescriptionList,
                    copyPrescriptionPage,
                    copyPrescriptionNoMoreData,
                    isCpReqProcessing: false // belongs to (cpLoadMore)
                }, () => this.handleCopyPrescriptionListClick(0))
            }
        } catch (error) {
            console.log(error);
        }
    }

    handleCopyPrescriptionListClick = (index) => {
        let { copyPrescriptionList, copyPrescriptionDetail } = this.state;
        copyPrescriptionDetail = copyPrescriptionList[index];
        this.setState({
            copyPrescriptionDetail,
            copyPrescriptionIndex: index,
            copyPrescriptionDataLoaded: true
        })
    }

    handleOnChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        }, this.formDataEntry)
    }

    formDataEntry = _ => {
        const { diagnosis_notes, medicines_tests, update_prescription, tests_adviced, instructions_for_patient, internal_notes } = this.state;
        /** Booking Id is Refernece Id which can be found in url */
        const { match: { params } } = this.props;

        let formData = [];
        if (LS_SERVICE.has('formData')) {
            formData = LS_SERVICE.get('formData');
        } else {
            formData = [{
                id: this.state.patient.booking_id,
                diagnosis_notes,
                medicines_tests,
                tests_adviced,
                instructions_for_patient,
                internal_notes,
                update_prescription,
            }];
            LS_SERVICE.set('formData', formData)
        }

        if (formData.find((e) => e.id === +params.reference_id) !== undefined) {
            formData.forEach(e => {
                if (e.id === +params.reference_id) {
                    e['diagnosis_notes'] = diagnosis_notes;
                    e['medicines_tests'] = medicines_tests;
                    e['tests_adviced'] = tests_adviced;
                    e['instructions_for_patient'] = instructions_for_patient;
                    e['internal_notes'] = internal_notes;
                    e['update_prescription'] = update_prescription;
                }
            })
        } else {
            formData = [...formData, { id: +params.reference_id, diagnosis_notes, medicines_tests, tests_adviced, instructions_for_patient, update_prescription, internal_notes }]
        }
        LS_SERVICE.set('formData', formData);
    }

    isEnabled = () => {
        var btnDisable = false;
        const { visitData, update_prescription, diagnosis_notes, medicines_tests, tests_adviced, instructions_for_patient, isDiagnosisConfirmed, isMedicineConfirmed, isTestConfirmed } = this.state;
        
        if (typeof visitData != 'undefined' && typeof visitData.visit != 'undefined' && visitData?.visit?.status == 1 && typeof update_prescription != 'undefined' && update_prescription == '') {
            btnDisable = true;
        } else if (typeof visitData != 'undefined' && typeof visitData.visit != 'undefined' && visitData?.visit?.status == 1 && typeof update_prescription != 'undefined' && update_prescription != '') {
            btnDisable = false;
        } else {
            if ((isDiagnosisConfirmed || isMedicineConfirmed || isTestConfirmed) === true) {
                btnDisable = true;
            }
            else if ((diagnosis_notes.length || medicines_tests.length || tests_adviced.length || instructions_for_patient.length) == 0) {
                btnDisable = true;
            }
            else if (this.state?.qms_details?.token_date > moment().format('YYYY-MM-DD')) {
                btnDisable = true;
            }
            else {
                btnDisable = false;
            }
        }

        if(this.state.currentHospitalConfigData && this.state.currentHospitalConfigData.pres_modification == 'allow'){
            btnDisable = false
        }

        return btnDisable;
    }

    handleBasicFormSubmit = async e => {
        e.preventDefault();
        const { diagnosis_notes, medicines_tests, tests_adviced, instructions_for_patient, patient, prescription_bool, update_prescription, hospital_id, isPracticing, internal_notes } = this.state;
        const { patient_id, qms_token_id } = patient;
        const hospitalId = hospital_id !== null ? hospital_id : LS_SERVICE.get('slot_hospital_id');

        if (!isPracticing) {
            this.handleModalPopup(null, 'isPracticingModal');
            return;
        }

        const PARAMS = new FormData();
        PARAMS.append('reference_type', REFERENCE_TYPE);
        PARAMS.append('reference_id', qms_token_id);
        PARAMS.append('patient_id', patient_id);
        PARAMS.append('hospital_id', hospitalId);
        PARAMS.append('symptom_diagnosis', diagnosis_notes);
        PARAMS.append('medicine_test', medicines_tests);
        PARAMS.append('tests_adviced', tests_adviced);
        PARAMS.append('internal_notes', internal_notes);
        PARAMS.append('instructions_to_patients', instructions_for_patient);
        PARAMS.append('print_pdf', 1);

        if (prescription_bool) {
            PARAMS.append('update_prescription', update_prescription);
        }

        try {
            const basicSubmitPromise = await Axios.post(BASIC_STORE, PARAMS);

            if (this.state?.qms_details?.source == "qms") {
                let params = { status: 'complete', token_id: this.state?.qms_details?.token_id }
                Axios.post(UPDATE_QMS_STATUS, qs.stringify(params)).then(response => {
                    console.log(response)
                })
            }

            if (basicSubmitPromise.status) {
                notification.success({
                    message: 'Appointment completed successfully!',
                    placement: 'topRight'
                });

                const browser = Bowser.parse(window.navigator.userAgent).browser.name;
                if (browser == "Safari") {
                    this.props.history.push({
                        pathname: `/patient-queue/${LS_SERVICE.get('slot_hospital_id')}`
                    })
                } else {
                    this.props.history.push({
                        pathname: `/patient-queue/${LS_SERVICE.get('slot_hospital_id')}?reference=${qms_token_id}`
                    })
                }
            }

        } catch (error) {
            console.log(error);
        }
    }

    handleModalPopup = (e, popup, data, type) => {
        if (e !== null)
            e.preventDefault();

        const { copyPrescriptionList } = this.state

        this.setState({
            [popup]: !this.state[popup],
            isMedicineChecked: null,
            isDiagnosisChecked: null,
            isTestChecked: null,
            copyPrescriptionIndex: 0,
            copyPrescriptionDetail: copyPrescriptionList[0]
        }, () => {
            const { viewDocumentModal } = this.state;
            if (viewDocumentModal) {
                if (data !== null) {
                    /*this.setState({
                        docSrc: data,
                        docType: type === 'img' ? 'image/jpeg' : 'application/pdf'
                    })*/
                    this.carouselRef.current.goTo(data);
                }
            } else {
                this.setState({
                    docSrc: null,
                    docType: ''
                })
            }
        })
        if (popup == 'copyPrescriptionModal') {
            setTimeout(() => {
                this.cpScrollEvent()
            }, 500)
        }
    }

    handleTextClick = (e, copy, check, id) => {
        const { checked } = e.target;
        let { copyPrescriptionDetail, copyPrescriptionChecked } = this.state;

        let copyPrescriptionCheckedData = { ...copyPrescriptionChecked }

        if (checked) {
            copyPrescriptionCheckedData = {
                ...copyPrescriptionChecked,
                [copy]: copyPrescriptionDetail[copy]
            }
        } else {
            delete copyPrescriptionCheckedData[copy];
        }


        this.setState({
            copyPrescriptionChecked: { ...copyPrescriptionCheckedData },
            [check]: checked ? id : null
        })


    }

    handleCopyClick = _ => {
        let { copyPrescriptionChecked, copyPrescriptionOld, diagnosis_notes, medicines_tests, tests_adviced } = this.state;

        copyPrescriptionOld = {
            diagnosis_notes,
            medicines_tests,
            tests_adviced
        }

        this.setState({
            copyPrescriptionOld,
            diagnosis_notes: copyPrescriptionChecked['symptomDiagnosis'] || diagnosis_notes,
            medicines_tests: copyPrescriptionChecked['medicineTest'] || medicines_tests,
            tests_adviced: copyPrescriptionChecked['testsAdviced'] || tests_adviced,
        }, () => {

            const { diagnosis_notes, medicines_tests, tests_adviced } = this.state;

            if (copyPrescriptionChecked['symptomDiagnosis']) {
                this.setState({
                    isDiagnosisConfirmed: true,
                })
            }

            if (copyPrescriptionChecked['medicineTest']) {

                this.setState({
                    isMedicineConfirmed: true,
                })
            }

            if (copyPrescriptionChecked['testsAdviced']) {
                this.setState({
                    isTestConfirmed: true,
                })
            }
        })

        this.handleModalPopup(null, 'copyPrescriptionModal');
    }

    handleConfirmClick = (e, confirm, confirmed) => {
        e.preventDefault();


        const { copyPrescriptionChecked } = this.state;
        const confirmData = { ...copyPrescriptionChecked };
        delete copyPrescriptionChecked[confirm];

        this.setState({
            [confirm]: confirmData[confirm],
            copyPrescriptionChecked,
            [confirmed]: false
        }, this.formDataEntry)
    }

    initSpeech(info) {
        let initialText = '';
        if (info.identifier === 'diagnosis-notes') {
            initialText = this.state.diagnosis_notes != undefined ? this.state.diagnosis_notes : '';
        }
        if (info.identifier === 'medicine-test') {
            initialText = this.state.medicines_tests != undefined ? this.state.medicines_tests : '';
        }
        if (info.identifier === 'tests-adviced') {
            initialText = this.state.tests_adviced != undefined ? this.state.tests_adviced : '';
        }
        if (info.identifier === 'instructions-to-patients') {
            initialText = this.state.instructions_for_patient != undefined ? this.state.instructions_for_patient : '';
        }
        if (info.identifier === 'update-prescription') {
            initialText = this.state.update_prescription != undefined ? this.state.update_prescription : '';
        }
        if (info.identifier === 'internal-notes') {
            initialText = this.state.internal_notes != undefined ? this.state.internal_notes : '';
        }

        if (info.identifier === this.state.speech.identifier) {
            this.setState({
                speech: {
                    identifier: info.identifier,
                    state: !this.state.speech.state,
                    initialText: initialText
                }
            }, () => {
                speechService.init(this.state.speech);
            });
        } else {
            this.setState({
                speech: {
                    identifier: this.state.speech.identifier,
                    state: false,
                    initialText: ''
                }
            }, () => {
                speechService.init(this.state.speech);
                // setTimeout(() => {
                this.setState({
                    speech: {
                        identifier: info.identifier,
                        state: true,
                        initialText: initialText
                    }
                }, () => {
                    speechService.init(this.state.speech);
                });
                // }, 1000);
            });
        }
    }

    disableInternalNotes() {
        const { prescription_bool, update_prescription, upload_prescription, booking_status, visitData } = this.state;
        if (visitData?.visit?.status == 1) {
            if (visitData?.updatePrescription !== null && visitData?.updatePrescription !== '') {
                return true;
            }
            if (visitData?.updatePrescription === null || visitData?.updatePrescription === '') {
                return false;
            }
        }
        return false;
    }

    enablePractice = async e => {
        e.preventDefault();
        practiceService.init({ initiater: 'SOAP Pages', status: 'start' });
        const practice = await API_SERVICE.post(TOGGLE_PRACTICE, {
            hospital_id: this.state.hospital_id,
            practice_session: 'start'
        });

        let is_practicing;
        if (practice.data && practice.data.is_practicing === 'Y') {
          is_practicing = true;
          LS_SERVICE.set('practicing', {
            status: true,
            hospital_id: this.state.hospital_id
          });
        } else {
            is_practicing = false;
        }

        this.setState({
            isPracticing: is_practicing,
        });
        this.handleModalPopup(null, 'isPracticingModal');
    }

    disableInputs() {
        return true;
    }

    render() {
        const { patient, copyPrescriptionDataLoaded, copyPrescriptionList, copyPrescriptionDetail, copyPrescriptionIndex, patient_note_doctor, patient_docments,
            medicines_tests, diagnosis_notes, instructions_for_patient, internal_notes, tests_adviced, isDiagnosisConfirmed, isMedicineConfirmed, isTestConfirmed, visitData,
            isDiagnosisChecked, isMedicineChecked, isTestChecked, patientDataLoaded, update_prescription, prescription_bool, booking_status, isThisCompletedAppointment, internalNotesInfo } = this.state;

        return (

            <>
                {patient != undefined && patient.patient_id != undefined && patient.patient_id != null ? <AsideLeft patient_id={patient.patient_id} reference_id={patient.qms_token_id} /> : null}

                <div className="content-wrapper">
                    <div className="container-fluid">
                        <form autoComplete="off" onSubmit={this.handleBasicFormSubmit}>
                            <div className="row pb-5">
                                {patientDataLoaded && (
                                    patient_note_doctor.length > 0 ||
                                    patient_docments?.length > 0
                                ) ? (
                                    <div className="col-12 mb-3" id="patient_notes_docs">
                                        <div className="card bg-grey rounded-0">
                                            <div className="card-body">

                                                {patient_note_doctor.length > 0 ? (
                                                    <>
                                                        <h6 className="text-uppercase text-dark" id="notes_for_doctor_head">Notes For Doctor</h6>
                                                        <p className="text-dark" id="notes_for_doctor_note">{patient_note_doctor}</p>
                                                    </>) : null}

                                                {
                                                    patient_docments?.length > 0 ? (
                                                        <>
                                                            <h6 className="text-uppercase text-dark" id="docs_for_doctor_head">Documents uploaded by the patient</h6>
                                                            <figure className="mb-0" id="docs_for_doctor_docs">
                                                                {
                                                                    patient_docments?.map((file, index) => (
                                                                        <a href="#" className="show-rx-modal" key={index} onClick={e => this.handleModalPopup(e, 'viewDocumentModal', index, 'img')}>
                                                                            {/* <img className="mr-3" alt='Doc IImg' src={file.type === 'img' ? icJpg : icPdf} /> */}
                                                                            <i className={`mr-2 icon-${file.type === 'img' ? 'jpg' : 'pdf'}`}></i>
                                                                        </a>
                                                                    ))
                                                                }
                                                            </figure>
                                                        </>
                                                    ) : null
                                                }
                                            </div>
                                        </div>
                                    </div>

                                ) : null}
                                <div className="col-6 mb-3">
                                    <div className="card rounded-0 ">
                                        <div className="card-body vc_dash_mic">
                                            {/* <a href="javascript:void(0);" className="mic symptoms-mic" id="mic_1" /> */}
                                            {/* {!isThisCompletedAppointment ? ( */}
                                            {!this.isShowData() ? (
                                                <Link
                                                    onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: 'diagnosis-notes' }) }}
                                                    className={(this.state.speech.identifier === 'diagnosis-notes' && this.state.speech.state) ? 'mic symptoms-mic on mute' : 'mic symptoms-mic'} ></Link>

                                            ) : null}
                                            <h6 className="text-uppercase text-dark">SYMPTOMS AND DIAGNOSIS</h6>
                                            <textarea className="form-control p-2 border-0 subjective-field mandatory-field mic_1" rows={5} tabIndex="1"
                                                value={diagnosis_notes} onChange={this.handleOnChange} name="diagnosis_notes"
                                                readOnly={this.isShowData()}
                                            />
                                            {copyPrescriptionDetail !== undefined ? (
                                                !isDiagnosisConfirmed ? (
                                                    !this.isShowData() ? (
                                                        <a href="#" className={`copy_prescription`} onClick={e => this.handleModalPopup(e, 'copyPrescriptionModal')}
                                                            style={{ position: 'absolute', bottom: '12px', right: '15px' }}><u>Copy Prescription</u></a>
                                                    ) : null
                                                ) : (
                                                    <a href="#" className="symptoms_prescription_confirm" onClick={e => this.handleConfirmClick(e, 'symptomDiagnosis', 'isDiagnosisConfirmed')}
                                                        style={{ position: 'absolute', bottom: '12px', right: '15px' }}><u>Confirm</u></a>
                                                )
                                            ) : (
                                                <Tooltip placement="topLeft" title="No Prescription" arrowPointAtCenter>
                                                    <a href="#" className="copy_prescription" onClick={e => e.preventDefault()}
                                                        style={{ position: 'absolute', bottom: '12px', right: '15px' }}><u>Copy Prescription</u></a>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 mb-3">
                                    <div className="card rounded-0 ">
                                        <div className="card-body vc_dash_mic">
                                            {/* <a href="javascript:void(0);" className="mic symptoms-mic" id="mic_2" /> */}
                                            {/* {!isThisCompletedAppointment ? ( */}
                                            {!this.isShowData() ? (
                                                <Link
                                                    onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: 'medicine-test' }) }}
                                                    className={(this.state.speech.identifier === 'medicine-test' && this.state.speech.state) ? 'mic symptoms-mic on mute' : 'mic symptoms-mic'} ></Link>
                                            ) : null}

                                            <h6 className="text-uppercase text-dark">MEDICINES PRESCRIBED</h6>
                                            <textarea className="form-control p-2 border-0 subjective-field mandatory-field mic_2" rows={5} tabIndex="2"
                                                value={medicines_tests} onChange={this.handleOnChange} name="medicines_tests"
                                                //readOnly={isMedicineConfirmed || (Datvisita?.visit?.status == 1)}
                                                readOnly={this.isShowData()}
                                            />
                                            {/* {!isMedicineConfirmed ? (
                                            <a href="#" className="copy_prescription" onClick={e => this.handleModalPopup(e, 'copyPrescriptionModal')}
                                                style={{ position: 'absolute', bottom: '12px', right: '15px' }}><u>Copy Prescription</u></a>
                                        ) : (
                                                <a href="#" className="symptoms_prescription_confirm" onClick={e => this.handleConfirmClick(e, 'medicines_tests', 'isMedicineConfirmed')}
                                                    style={{ position: 'absolute', bottom: '12px', right: '15px' }}><u>Confirm</u></a>
                                            )} */}

                                            {copyPrescriptionDetail !== undefined ? (
                                                !isMedicineConfirmed ? (
                                                    !this.isShowData() ? (
                                                        <a href="#" className={`copy_prescription`} onClick={e => this.handleModalPopup(e, 'copyPrescriptionModal')}
                                                            style={{ position: 'absolute', bottom: '12px', right: '15px' }}><u>Copy Prescription</u></a>
                                                    ) : null
                                                ) : (
                                                    <a href="#" className="symptoms_prescription_confirm" onClick={e => this.handleConfirmClick(e, 'medicineTest', 'isMedicineConfirmed')}
                                                        style={{ position: 'absolute', bottom: '12px', right: '15px' }}><u>Confirm</u></a>
                                                )
                                            ) : (
                                                <Tooltip placement="topLeft" title="No Prescription" arrowPointAtCenter>
                                                    <a href="#" className="copy_prescription" onClick={e => e.preventDefault()}
                                                        style={{ position: 'absolute', bottom: '12px', right: '15px' }}><u>Copy Prescription</u></a>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 mb-3">
                                    <div className="card rounded-0 ">
                                        <div className="card-body vc_dash_mic">
                                            {/* {!isThisCompletedAppointment ? ( */}
                                            {!this.isShowData() ? (
                                                <Link
                                                    onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: 'tests-adviced' }) }}
                                                    className={(this.state.speech.identifier === 'tests-adviced' && this.state.speech.state) ? 'mic symptoms-mic on mute' : 'mic symptoms-mic'} ></Link>
                                            ) : null}
                                            <h6 className="text-uppercase text-dark">TESTS ADVISED</h6>
                                            <textarea className="form-control p-2 border-0 subjective-field mandatory-field mic_3" rows={5} tabIndex="3"
                                                value={tests_adviced} onChange={this.handleOnChange} name="tests_adviced" //readOnly={isTestConfirmed || (visitData?.visit?.status == 1)} 
                                                readOnly={this.isShowData()}
                                            />
                                            {copyPrescriptionDetail !== undefined ? (
                                                !isTestConfirmed ? (
                                                    !this.isShowData() ? (
                                                        <a href="#" className={`copy_prescription`} onClick={e => this.handleModalPopup(e, 'copyPrescriptionModal')}
                                                            style={{ position: 'absolute', bottom: '12px', right: '15px' }}><u>Copy Prescription</u></a>
                                                    ) : null
                                                ) : (
                                                    <a href="#" className="symptoms_prescription_confirm" onClick={e => this.handleConfirmClick(e, 'testsAdviced', 'isTestConfirmed')}
                                                        style={{ position: 'absolute', bottom: '12px', right: '15px' }}><u>Confirm</u></a>
                                                )
                                            ) : (
                                                <Tooltip placement="topLeft" title="No Prescription" arrowPointAtCenter>
                                                    <a href="#" className="copy_prescription" onClick={e => e.preventDefault()}
                                                        style={{ position: 'absolute', bottom: '12px', right: '15px' }}><u>Copy Prescription</u></a>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 mb-3">
                                    <div className="card rounded-0 ">
                                        <div className="card-body vc_dash_mic">
                                            {/* {!isThisCompletedAppointment ? ( */}
                                            {!this.isShowData() ? (
                                                <Link
                                                    onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: 'instructions-to-patients' }) }}
                                                    className={(this.state.speech.identifier === 'instructions-to-patients' && this.state.speech.state) ? 'mic symptoms-mic on mute' : 'mic symptoms-mic'} ></Link>
                                            ) : null}
                                            <h6 className="text-uppercase text-dark">INSTRUCTIONS FOR PATIENTS</h6>
                                            <textarea className="form-control p-2 border-0 subjective-field mandatory-field" rows={5} tabIndex="4"
                                                value={instructions_for_patient} onChange={this.handleOnChange} name="instructions_for_patient"
                                                //readOnly={(visitData?.visit?.status == 1)}
                                                readOnly={this.isShowData()}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6 mb-3">
                                    <div className="card rounded-0 ">
                                        <div className="card-body vc_dash_mic">
                                            {/* {!this.disableInternalNotes() ? ( */}
                                            {!this.isShowData() ? (
                                                <Link
                                                    onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: 'internal-notes' }) }}
                                                    className={(this.state.speech.identifier === 'internal-notes' && this.state.speech.state) ? 'mic symptoms-mic on mute' : 'mic symptoms-mic'} ></Link>
                                            ) : null}
                                            <h6 className="text-uppercase text-dark">INTERNAL NOTES (Not to be printed)</h6>
                                            <textarea className="form-control p-2 border-0 subjective-field mandatory-field" rows={5} tabIndex="4"
                                                value={internal_notes} onChange={this.handleOnChange} name="internal_notes"
                                                //readOnly={(this.disableInternalNotes())}
                                                readOnly={this.isShowData()}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {this.isShowData() ? (
                                    <div className="col-6 mb-3">
                                        <div className="card rounded-0">
                                            <div className="card-body vc_dash_mic">
                                            <Link
                                                onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: 'update-prescription' }) }}
                                                className={(this.state.speech.identifier === 'update-prescription' && this.state.speech.state) ? 'mic symptoms-mic on mute' : 'mic symptoms-mic'} ></Link>
                                                <h6 className="text-uppercase text-dark">UPDATE PRESCRIPTION</h6>
                                                <textarea className="form-control p-2 border-0 subjective-field mandatory-field" rows={5} tabIndex="4"
                                                    value={update_prescription}
                                                    onChange={this.handleOnChange}
                                                    name="update_prescription"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>

                            <div className="previous-internal-notes">
                                {internalNotesInfo.map((element, index) => <div key={index} className="text-dark mb-2">
                                    <span className="font-weight-light text-muted mr-2">
                                        <Moment date={element.created_at} format="D MMM, YYYY" />
                                    </span>
                                    {element?.description}
                                </div>)}
                            </div>

                            { this.isShowbutton ? (
                                <div className="fixed-cta">
                                    <button type="submit" className="btn btn-primary text-uppercase px-3 py-2 mandatory-save"
                                        disabled={this.isEnabled() || this.state.junior_doctor_complete_appointment}
                                        id="confirm-prescription">PRINT &amp; COMPLETE CONSULT</button>
                                </div>
                            ) : null}
                        </form>
                    </div>
                </div>

                <Modal
                    title="Copy Prescription"
                    visible={this.state.copyPrescriptionModal}
                    onCancel={(e) => this.handleModalPopup(e, 'copyPrescriptionModal')}
                    style={{ top: '10vh' }}
                    width="1500px"
                    wrapClassName="copy-prescription"
                    footer={
                        <div className="row">
                            <button className="btn btn-primary mx-auto  font-weight-bold"
                                disabled={!isMedicineChecked && !isTestChecked && !isDiagnosisChecked}
                                onClick={this.handleCopyClick}>
                                Copy
                            </button>
                        </div>
                    }
                >
                    <div className="row border-top m-0">
                        <div className="col-12 p-0">
                            <section className="d-flex copy-prescription-section">
                                <aside className="aside-copy cp_scroll_loader">
                                    <div>
                                        {copyPrescriptionDataLoaded && copyPrescriptionList.length > 0 ? (
                                            copyPrescriptionList.map((cp, i) => (
                                                <div className={`border-bottom p-3 aside-data ${copyPrescriptionIndex === i ? 'active' : ''}`} key={i}
                                                    onClick={e => this.handleCopyPrescriptionListClick(i)}>
                                                    <h6 className="m-0">{cp.doctorDetails?.first_name + ' ' + cp.doctorDetails?.last_name}</h6>
                                                    <p className="mb-2 text-capitalize">{cp.patientDetails?.first_name && cp.patientDetails?.first_name != null ? cp.patientDetails?.first_name : ""} {cp.patientDetails?.middle_name && cp.patientDetails?.middle_name != null ? cp.patientDetails?.middle_name : ""} {cp.patientDetails?.last_name && cp.patientDetails?.last_name != null ? cp.patientDetails?.last_name : ""}</p>
                                                    <p>
                                                        {moment(cp.createdAt).format("H:MM A, D MMM YYYY")}
                                                    </p>
                                                </div>
                                            ))
                                        ) : null}
                                    </div>
                                    {/* <div> */}
                                    <div className={`my-3 justify-content-center  ${this.state.isCpReqProcessing ? 'd-flex ' : 'd-none'}`}>
                                        {/* {this.state.docSrc != null ? <iframe src={this.state.docSrc} frameBorder="0" scrolling="no" */}
                                        {/* style={{ overflow: 'hidden' }} width="100%" height="700"> */}
                                        {LOADER_RED}
                                        {/* </iframe> : null} */}
                                    </div>

                                    {/* </div> */}
                                </aside>
                                <div className="px-3 pt-3 pb-5 bg-grey notes-main overflow-auto">
                                    {copyPrescriptionDataLoaded && copyPrescriptionDetail !== undefined ? (
                                        <div className="row">
                                            <div className="col-6 d-none mb-3">
                                                <div className="card rounded-0 text-boxes">
                                                    <div className="card-body vc_dash_mic">
                                                        <h6 className="text-uppercase text-dark">NOTES FOR DOCTOR</h6>
                                                        <pre className="cb-innerwrapper">
                                                            {copyPrescriptionDetail.patientNoteDoctor || 'No Notes'}
                                                        </pre>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 d-none mb-3">
                                                <div className="card rounded-0 text-boxes">
                                                    <div className="card-body vc_dash_mic">
                                                        <h6 className="text-uppercase text-dark">DOCUMENTS UPLOADED</h6>
                                                        <pre className="cb-innerwrapper">
                                                            {copyPrescriptionDetail.patient_documents.length > 0 ? (
                                                                copyPrescriptionDetail.patient_docments.map((item, idx) => {
                                                                    <button key={idx}></button>
                                                                })
                                                            ) : 'NO UPLOADED DOCUMENTS'}
                                                        </pre>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 mb-3">
                                                <div className="card rounded-0 text-boxes">
                                                    <div className="card-body vc_dash_mic">
                                                        <h6 className="text-uppercase text-dark">SYMPTOMS AND DIAGNOSIS</h6>
                                                        <pre className="cb-innerwrapper">
                                                            {copyPrescriptionDetail.symptomDiagnosis || 'No Notes'}
                                                        </pre>
                                                        <div className="d-flex justify-content-end mr-2 cursor-pointer copy-check">
                                                            <div className="check_no_know">
                                                                {copyPrescriptionDetail.symptomDiagnosis !== undefined && copyPrescriptionDetail.symptomDiagnosis !== null && copyPrescriptionDetail.symptomDiagnosis.length > 0 ? (
                                                                    <div className="custom-control custom-checkbox-md">
                                                                        <input type="checkbox" id={`diagnosis_check_${copyPrescriptionDetail.id}`} checked={+isDiagnosisChecked === +copyPrescriptionDetail.id}
                                                                            name="diagnosis_check" onChange={e => this.handleTextClick(e, 'symptomDiagnosis', 'isDiagnosisChecked', copyPrescriptionDetail.id)}
                                                                            className={`custom-control-input ${copyPrescriptionDetail.symptomDiagnosis.length !== 0 ? ' cursor-pointer' : null}`} disabled={copyPrescriptionDetail.symptomDiagnosis.length === 0} />
                                                                        <label className="custom-control-label text-secondary text-basic" htmlFor={`diagnosis_check_${copyPrescriptionDetail.id}`}>Copy</label>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 mb-3">
                                                <div className="card rounded-0 text-boxes">
                                                    <div className="card-body vc_dash_mic">
                                                        <h6 className="text-uppercase text-dark">MEDICINES PRESCRIBED</h6>
                                                        <pre className="cb-innerwrapper">
                                                            {copyPrescriptionDetail.medicineTest || 'No Notes'}
                                                        </pre>
                                                        <div className="d-flex justify-content-end mr-2 cursor-pointer copy-check">
                                                            <div className="check_no_know">
                                                                {copyPrescriptionDetail.medicineTest !== undefined && copyPrescriptionDetail.medicineTest !== null && copyPrescriptionDetail.medicineTest.length > 0 ? (
                                                                    <div className="custom-control custom-checkbox-md">
                                                                        <input type="checkbox" onChange={e => this.handleTextClick(e, 'medicineTest', 'isMedicineChecked', copyPrescriptionDetail.id)} checked={+isMedicineChecked === +copyPrescriptionDetail.id}
                                                                            id={`medicine_check_${copyPrescriptionDetail.id}`} className={`custom-control-input ${copyPrescriptionDetail.medicineTest.length !== 0 ? ' cursor-pointer' : null} copy_diagnosis_notes`} name="medicine_check" disabled={copyPrescriptionDetail.medicineTest.length === 0} />
                                                                        <label className="custom-control-label text-secondary text-basic" htmlFor={`medicine_check_${copyPrescriptionDetail.id}`}>Copy</label>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 mb-3">
                                                <div className="card rounded-0 text-boxes">
                                                    <div className="card-body vc_dash_mic">
                                                        <h6 className="text-uppercase text-dark">TESTS ADVISED</h6>
                                                        <pre className="cb-innerwrapper">
                                                            {copyPrescriptionDetail.testsAdviced || 'No Notes'}
                                                        </pre>
                                                        <div className="d-flex justify-content-end mr-2 cursor-pointer copy-check">
                                                            <div className="check_no_know">
                                                                {copyPrescriptionDetail.testsAdviced !== undefined && copyPrescriptionDetail.testsAdviced !== null && copyPrescriptionDetail.testsAdviced.length > 0 ? (
                                                                    <div className={`custom-control cursor-pointer custom-checkbox-md `}>
                                                                        <input type="checkbox" onChange={e => this.handleTextClick(e, 'testsAdviced', 'isTestChecked', copyPrescriptionDetail.id)} checked={+isTestChecked === +copyPrescriptionDetail.id}
                                                                            id={`test_adviced_${copyPrescriptionDetail.id}`} className={`custom-control-input ${copyPrescriptionDetail.testsAdviced.length !== 0 ? ' cursor-pointer' : null} copy_diagnosis_notes`} name="test_adviced" disabled={copyPrescriptionDetail.testsAdviced.length === 0} />
                                                                        <label className="custom-control-label text-secondary text-basic" htmlFor={`test_adviced_${copyPrescriptionDetail.id}`}>Copy</label>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 mb-3">
                                                <div className="card rounded-0 text-boxes">
                                                    <div className="card-body vc_dash_mic">
                                                        <h6 className="text-uppercase text-dark">UPDATED PRESCRIPTION</h6>
                                                        <pre className="cb-innerwrapper">
                                                            {copyPrescriptionDetail.updatePrescription || 'No Notes'}
                                                        </pre>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </section>
                        </div>
                    </div>
                </Modal>


                {/** Document Viewer */}
                <Modal
                    title={patient_docments?.length === 1 ? "Document" : "Documents"}
                    visible={this.state.viewDocumentModal}
                    footer={false}
                    width={850}
                    onCancel={e => this.handleModalPopup(e, 'viewDocumentModal')}
                    style={{ top: 20 }}
                >
                    {/* <div className="row">
                        <div className="col-12 my-3 text-center">
                            {this.state.docSrc != null ? <iframe src={this.state.docSrc} frameBorder="0" scrolling="no"
                                style={{ overflow: 'hidden' }} width="100%" height="700">
                                {LOADER_RED}
                            </iframe> : null}
                        </div>
                    </div> */}

                    <div className="pdf_slider">
                        {
                            patient_docments?.length > 0 ? (
                                <Carousel arrows dots={false} ref={this.carouselRef} >
                                    {
                                        patient_docments?.map((file, index) => (
                                            file.type === 'img' ?
                                                <figure key={index}><img className="mr-3" src={file.src} alt='uploaded Doc' /></figure>
                                                :
                                                <embed key={index} className="mr-3" src={file.src + "#toolbar=0"} />
                                        ))
                                    }

                                </Carousel>
                            ) : null
                        }
                    </div>
                </Modal>

                {/** || isPracticing Modal */}
                <Modal
                    title={false}
                    closable={false}
                    visible={this.state.isPracticingModal}
                    footer={false}
                >
                    <div className="row">
                        <div className="col-12 my-3 text-center">
                            <h6>Do you want to Start Practice?</h6>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <div className="btn btn-outline-secondary px-5" onClick={e => this.setState({ isPracticingModal: false })}>No</div>
                        <div className="btn btn-primary px-5" onClick={this.enablePractice}>Yes</div>
                    </div>
                </Modal>

                {/* <MyHcSpeechRecognition /> */}

            </>
        )
    }
}

export default BasicComponent;