import React, { Component } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import qs from "qs";
import LS_SERVICE from "../../utils/localStorage";
import Chart from "../../components/chart/chart";
import Vitals from "../../components/vitals/vitals";
import KeyFindingsChart from "../../components/chart/key-findings-chart";
import KeyFindingsFilters from "../../components/vitals/key-findings-filters";
import KeyFindings from "../../components/vitals/key-findings";
import SoapFlow from "../../utils/soap";
import {
  ALLERGIES_DROPDOWN,
  CONDITION_SEARCH,
  INFORMED_BY,
  OBJECTIVE_GETALL,
  LOADER,
  HANDLEVALIDANTMESSAGES,
  HANDLEVALIDANTMESSAGESPATIENTAGE,
  LOADER_BLACK,
  OBJECTIVE_STORE,
  OBJECTIVE_VITAL_STORE,
  REFERENCE_TYPE,
  GETVITAL_RANGE_CLASS,
  HISTORY_DELETE,
  DURATION_TYPE,
  MEDICATION_ON,
  VITALS_GRAPH,
  PDF_CONFIG_VITALS_GRAPH,
  LOADER_RED,
  PATIENT_DATA,
  SOAP_CONFIGURATION_LIST,
  BMI_CALCULATE,
  BSA_CALCULATE,
  HOSPITALDEFAULTS,
  VISIT_COUNT,
  NO_KNOWN_OBJECTIVE,
  ISALLOWEDITSOAP,
  ALLERGY_TAB_STATUS,
  ALLERGY_TAB_TYPE,
  DEFAULTVITALGROUPID,
  DISABLED_FUTURE_DATES,
  KEY_FINDING_VITALS_CONSTANTS,
  KEYFINDINGS_FILTER_SEQUENCING_LIST,
  USERTYPES,
  TOGGLE_PRACTICE,
  CONDITION_ADD_MASTER,
  ALLERGY_ADD_MASTER,
  SEARCH_DRUGS,
  PRESCRIPTION_STORE,
  ADD_DRUGS,
  MEDICINES_GETALL_CURRENT_MEDICINES,
  PRESCRIPTION_DATA,
  CIMS_ALLERGY_INTERACTION_EXISTS,
  CIMS_ALLERGY_INTERACTION,
  CIMSALLOW,
  CIMS_ALLOW_HOSPITALS,
  CIMS_ALLERGY_SEARCH
} from "../../utils/constant";
import Moment from "react-moment";
import moment from "moment";
import { Form, Modal, Input, Radio, Button, Select, notification, Badge, Checkbox, DatePicker, Image, AutoComplete } from "antd";
import { jQueryLabels, AntSelectLabels, AntClearLabels, AntDisabledLabels, FixedCta, onlynumbers } from "../../utils/jQueryLabels";
import AsideLeft from "../../components/aside/asideleft";
import SubHeader from "../../components/subheader/subheader";
import API_SERVICE from "../../utils/apiService";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { headerService$, practiceService, selectedVitalGroupService } from '../../utils/rxjs-sharing';
import { headerService$, practiceService } from "../../utils/rxjs-sharing";
import constants from "../../config/constants";
import { element } from "prop-types";
import { asyncScheduler } from "rxjs";

const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
class ObjectiveComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      patient: null,
      patientUrlData: null,
      details: null,
      constants: null,
      visitVitals: [],
      searchDrugs: [],
      drug_id: '',
      drug_name: '',
      showAddDrugButton: true,
      formIsFetchingDrug: false,
      visitVitalsKeyFindings: [],
      allergies: null,
      conditions: null,
      surgical_histories: null,
      objective_data_loaded: false,
      vitals_data_formatted: false,
      visitVitalsFormatted: [],
      showVitalsTable: false,
      formModeEdit: false,
      fromFieldId: -1,
      formFieldEntryId: null,
      surgicalModal: false,
      conditionModal: false,
      allergyModal: false,
      vitalModal: false,
      deleteModal: false,
      chartModal: false,
      keyFindingChartModal: false,
      allergyAlreadyExistModal: false,
      history: "",
      number: null,
      duration: "",
      durationType: 3,
      allergy_duration: "",
      allergyDurationType: '',
      conditionList: [],
      condition_name: "",
      condition_selected: {},
      medication: "0",
      allergy_food: [],
      allergy_other: [],
      allergyList: [],
      allergy_selected: "",
      allergy_selected_data: {},
      allergy_active_tab: ALLERGY_TAB_STATUS[0],
      allergy_reaction_list: [],
      vitalsType: "0",
      patientHeight: null,
      patientWeight: null,
      vitalGroups: [],
      selectedVitalGroups: [],
      displayVitals: [],
      vitalNotInRange: [],
      // V001: null,
      // V002: null,
      // V003: null,
      // V004: null,
      // V005: null,
      // V006: null,
      // V007: null,
      // V008: null,
      // V009: null,
      // V010: null,
      // V011: null,
      // V001_bk: null,
      // V002_bk: null,
      delete_text: "",
      deleteType: "",
      delete_id: "",
      spin_loading: false,
      chartData: {},
      keyFindingsChartData: {},
      vitalsRange: {},
      chartDataLoaded: false,
      chartGraphSettings: "",
      chartsTab: "2",
      chartsType: "all",
      chartHeading: "Vital",
      keyFindingsChartGraphSettings: "",
      keyFindingsChartDataLoaded: false,
      keyFindingsChartsTab: "2",
      keyFindingsChartsType: "all",
      keyFindingsChartHeading: "Key Findings",
      formIsFetching: false,
      allergyAlreadyExistName: "",
      condition_duration_type: "",
      noallergies: false,
      noExistingCondition: false,
      noSurgicalHx: false,
      totalVisit: 0,
      noDataModal: false,
      noDataModalText: "",
      isPracticing: false,
      isPracticingModal: false,
      allowEdit: true,
      range: [],
      key_findings_range: [],
      displayShowingVitals: [],
      vitalsInput: [],
      chartParamerModel: false,
      dateFormat: "YYYY-MM-DD HH:mm:ss",
      timeFormat: "HH:mm:ss",
      key_finding_date_time: moment(),
      calander_open: false,
      keyFindings: [],
      keyFindingsFiltersLoaded: false,
      keyfindings_data_formatted: false,
      visitKeyFindingsFormatted: [],
      bloodSugarChartData: null,
      vitalsRangeWithId: null,
      isNurseLoggined: false,
      continueModalForNurse: false,
      showAddConditionButton: false,
      disableAddConditionButton: false,
      showAddAllergyButton: false,
      disableAddAllergyButton: false,
      allergy_duration_type_required: false,
      condition_duration_type_required: false,
      surgical_duration_type_required: false,
      addedDrugsListsId: [],
      patient_prescription_drug_id:"",
      drug_id_Interaction_Modalurl:[],
			referenceType:0,
			allergyFlag:false,
      patientId: LS_SERVICE.get("call_patient_id") != undefined ? LS_SERVICE.get("call_patient_id") : 0,
			referenceId: LS_SERVICE.get("call_patient_qms_token_id") != undefined ? LS_SERVICE.get("call_patient_qms_token_id") : 0,
			StoreId: LS_SERVICE.get("staff_id") != undefined ? LS_SERVICE.get("staff_id") : 0,
			FacilityId: LS_SERVICE.get("FACILITY_ID") != undefined ? LS_SERVICE.get("FACILITY_ID") : 0,
			enterpriseId: LS_SERVICE.get("ENTERPRISE_ID") != undefined ? LS_SERVICE.get("ENTERPRISE_ID") : 0,
      userId: LS_SERVICE.get("staff_id"),
			userName: LS_SERVICE.get("staff_name"),
      hospitalId: parseInt(LS_SERVICE.get('slot_hospital_id'))
    };

    this.surgeryFormRef = React.createRef();
    this.conditionFormRef = React.createRef();
    this.allergyFormRef = React.createRef();
    this.vitalFormRef = React.createRef();
    this.handleVitalModalButtonDisabled = this.handleVitalModalButtonDisabled.bind(this);
    this.onDrugssearch = this.onDrugssearch.bind(this);

  }

  static getDerivedStateFromProps() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    return true;
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    const { hospital_id, patient_id, reference_id } = params;

    if (LS_SERVICE.get('user_type') == USERTYPES.doctor) {

      const isPracticingHospitalId = LS_SERVICE.get("practicing")?.hospital_id;

      if (+isPracticingHospitalId === +hospital_id) {
        this.setState({
          isPracticing: true,
        });
      }

    } else if (LS_SERVICE.get('user_type') == USERTYPES.nurse) {
      this.setState({
        isNurseLoggined: true,
      });
    }

    const patientUrlData = {
      hospital_id,
      patient_id,
      qms_token_id: reference_id,
    };



    let hospitalConfigData = LS_SERVICE.get("hospitalConfigData") ?? [];
    hospitalConfigData = hospitalConfigData.find((item) => item.hospital_id == hospital_id);

    let allowEdit = hospitalConfigData
      ? ISALLOWEDITSOAP(
        hospitalConfigData.pres_modification,
        hospitalConfigData.allow_hours,
        LS_SERVICE.get("appointmentCompletedTime")[reference_id],
        "button"
      )
      : true;

    this.setState(
      {
        hospital_id,
        patient_id,
        reference_id,
        patientUrlData,
        allowEdit,
      },
      () => {

        if (LS_SERVICE.has("hospitalConfigData") && LS_SERVICE.get('user_type') == USERTYPES.doctor) {
          const hospitalConfigData = LS_SERVICE.get("hospitalConfigData");
          let hospitalConfigObj = hospitalConfigData.find((obj) => obj.hospital_id == hospital_id);
          headerService$.soapConfig({
            soapConfig: hospitalConfigObj.opd_layout,
          }); // IMPORTANT TO HIT AS PER THE VIEW
          this.handleVisitCount();
          this.getPatientData();
          jQueryLabels();
          FixedCta();
          onlynumbers();
          // this.handleVisitCount();
          // this.handleObjectiveGetAllData();
          // this.handleVitalGraphData();
          // this.handleAllergyDropDownData();
          // this.handleSoapFlow();
          // jQueryLabels();
          // FixedCta();
          // onlynumbers();
        } else {
          this.setState({ loginRole: 'nurse' }, () => {
            this.handleVisitCount();
            this.getPatientData();
            jQueryLabels();
            FixedCta();
            onlynumbers();
          });
        }
      }

    );

    this.getAllMedicineDetails(patient_id, reference_id);
    // if (this.props.location.state !== undefined) {
    //     this.setState({
    //         patient: this.props?.location?.state?.patient
    //     }, () => {
    //         this.handleVisitCount();
    //         this.handleObjectiveGetAllData();
    //         this.handleVitalGraphData();
    //         this.handleAllergyDropDownData();
    //     })
    //     jQueryLabels();
    //     FixedCta();
    //     onlynumbers();
    // } else {
    //     this.getPatientData();
    //     jQueryLabels();
    //     FixedCta();
    // }

    // this.subscription = selectedVitalGroupService.status().subscribe(result => {
    //   this.setState({
    //     seletctedSpecialityIdID:result
    //   });
    // });
  }

  // componentWillUnmount() {
  //     this.subscription.unsubscribe();
  // }
  getAllMedicineDetails = (patient_id, reference_id) => {

    const PARAMS = {
      patient_id,
      reference_type: REFERENCE_TYPE,
      reference_id,
      hospital_id: LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null
    }
   
    Axios.get(MEDICINES_GETALL_CURRENT_MEDICINES({ patient_id,reference_type: 0, reference_id }))
      .then(success => {
        var addedDrugsList = [];
        let currentMedsList = success.data.data.details.current_medicines;
        currentMedsList.map((v, k) => { addedDrugsList.push(v.drug_id); });

        Axios.get(PRESCRIPTION_DATA(PARAMS))
          .then(success => {
            let { data } = success.data,
              drugId = data.details.map((v, k) => { addedDrugsList.push(v.drug_id)});
          }).catch(err => console.log(err.response));
         this.setState({
          addedDrugsListsId:addedDrugsList,
         })
      }).catch(err => console.log(err.response));

  };

  getPatientData = (_) => {
    const {
      match: { params },
    } = this.props;
    const { reference_id } = params;

    Axios.get(PATIENT_DATA({ reference_id })).then((success) => {
      const patient = {
        ...success.data.data.results,
        qms_token_id: success.data.data.results?.qmsToken?.qms_token_id || reference_id,
      };
      //Check if nurse logged in and set doctor speciality
      if (this.state.isNurseLoggined) {
        var allDocSpecialities = LS_SERVICE.get('doctor_speciality_id');
        if (allDocSpecialities) {
          var returnValue = this.filterArray(success?.data?.data?.results?.doctor_id, allDocSpecialities);
          if (returnValue && returnValue.length > 0) {
            LS_SERVICE.set('speciality_id', returnValue[0]);
          }
        }
      }

      this.setState(
        {
          patient,
        },
        () => {
          this.handleObjectiveGetAllData();
          this.handleVitalGraphData();
          this.handleAllergyDropDownData();
        }
      );
    });
  };


  filterArray = (doc_id, doctor_specialities) => {
    var element = [];
    for (var i = 0; i < doctor_specialities.length; i++) {
      if (doctor_specialities[i].id == doc_id) {
        element = doctor_specialities[i].specialtiesIds;
        break;
      }
    }
    return element;

  };



  handleSoapFlow = (_) => {
    if (!LS_SERVICE.has("goToSoapPatientPageList") || LS_SERVICE.get("goToSoapPatientPageList").length === 0) {
      this.handleSoapInit();
    }
  };

  handleSoapInit = async (_) => {
    const { hospital_id } = this.state;

    const soapList = await API_SERVICE.post(SOAP_CONFIGURATION_LIST, {
      hospital_id,
      staff_id: LS_SERVICE.get("staff_id"),
      speciality_id: LS_SERVICE.get("speciality_id") || null,
    });

    const pageList = [];
    const newList = [];

    const newSoapList = Object.values(soapList).map((data) => {
      return data.sort((a, b) => a.display_ordering - b.display_ordering);
    });

    Object.values(newSoapList)
      .flat()
      .forEach((en) => {
        if (en.display === "Y") {
          if (!pageList.includes(en.parent_module === "subjective" || en.parent_module === "objective" ? en.parent_module : en.name)) {
            pageList.push(en.parent_module === "subjective" || en.parent_module === "objective" ? en.parent_module : en.name);
            newList.push({
              url: en.alternate_url_format,
              parent_module: en.parent_module,
              module: en.parent_module === "subjective" || en.parent_module === "objective" ? en.parent_module : en.name,
            });
          }
        }
      });

    LS_SERVICE.set("goToSoapPatientUrl", newList);
    LS_SERVICE.set("goToSoapPatientPageList", pageList);
  };

  handleOnChange = (event, type = null) => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    });

    this.setState({
      allergy_duration_type_required: (type == "allergies" && name == 'duration' && value != '' && value != null) ? true : false,
      condition_duration_type_required: (type == "conditions" && name == 'condition_duration' && value != '' && value != null) ? true : false,
    });

    if (name == 'duration' && type == "allergies") {
      this.allergyFormRef.current.setFieldsValue({
        allergyDurationType: (name == 'duration' && value != '' && value != null) ? "3" : ""
      });
    }
    if (name == 'condition_duration' && type == "conditions") {
      this.conditionFormRef.current.setFieldsValue({
        condition_duration_type: (name == 'condition_duration' && value != '' && value != null) ? "3" : "",
      });
    }
  };

  handleOnChangeNumbers = (event, min, max, decimal_value, type = "", isDate = "", date = "") => {
    let { name, value } = event.currentTarget;
    let vitalNotInRange = this.state.vitalNotInRange;
    value = value.replace(decimal_value == 0 ? /\D/ : /[^0-9.]/g, "");
    if (decimal_value != 0 && value.includes(".")) {
      let fraction = value.split(".");
      let valueInt = parseInt(fraction[0]);
      fraction = "" + fraction[1];
      if (fraction.length != 0 && fraction.length > decimal_value) {
        fraction = fraction.substring(0, decimal_value);
        fraction = fraction && fraction != "" ? fraction : 0;
        value = valueInt + "." + fraction;
      }
    }
    var index = vitalNotInRange.indexOf(name);
    if (parseFloat(value) < min || parseFloat(value) > max) {
      if (index == -1) vitalNotInRange.push(name);
    } else {
      if (index != -1) vitalNotInRange.splice(index, 1);
    }

    this.setState(
      {
        [name]: value,
        vitalNotInRange,
      },
      () => {
        this.vitalFormRef.current.setFieldsValue({
          [name]: value,
        });
        this.handleBmiCalculate();
      }
    );
  };

  handleDateRangeGraphData = async (reset = false) => {
    const { patient_id, range } = this.state;

    const PARAMS = {
      patient_id,
      start_date: !reset ? moment(range[0]._d).format("YYYY-MM-DD HH:mm:ss") : undefined,
      end_date: !reset ? moment(range[1]._d).format("YYYY-MM-DD HH:mm:ss") : undefined,
    };

    const vitalGraphRangePromise = await Axios.get(VITALS_GRAPH(PARAMS));

    if (vitalGraphRangePromise.data.status === "success") {
      const { data } = vitalGraphRangePromise;

      this.setState(
        {
          chartDataLoaded: false,
          chartData: data.details,
          bloodSugarChartData: data.blood_sugar_data,
          vitalsRange: Object.values(data.constants.range),
        },
        () =>
          this.setState({
            chartDataLoaded: true,
          })
      );
    }
  };

  handleKeyFindingsDateRangeGraphData = async (reset = false) => {
    const { patient_id, key_findings_range } = this.state;

    const PARAMS = {
      patient_id,
      start_date: !reset ? moment(key_findings_range[0]._d).format("YYYY-MM-DD HH:mm:ss") : undefined,
      end_date: !reset ? moment(key_findings_range[1]._d).format("YYYY-MM-DD HH:mm:ss") : undefined,
    };

    const vitalGraphRangePromise = await Axios.get(VITALS_GRAPH(PARAMS));

    if (vitalGraphRangePromise.data.status === "success") {
      const { data } = vitalGraphRangePromise;

      this.setState(
        {
          keyFindingsChartDataLoaded: false,
          keyFindingsChartData: data.details,
          vitalsRange: Object.values(data.constants.range),
        },
        () =>
          this.setState({
            keyFindingsChartDataLoaded: true,
          })
      );
    }
  };

  handleBmiCalculate = (_) => {
    let { V001, V002, patientHeight, patientWeight, V014, V015 } = this.state;

    V001 = V001 !== null ? V001 : patientHeight;
    V002 = V002 !== null ? V002 : patientWeight;

    // if(typeof V014!='undefined' && typeof V015!='undefined' && V014!=null  && V015!=null){
    //     let val = BMI_CALCULATE(V014, V015);
    //     let bsa = BSA_CALCULATE(V014, V015);
    //     this.setState({
    //         V003: val,
    //         V017:bsa
    //     }, () => { AntSelectLabels() })

    //     this.vitalFormRef.current.setFieldsValue({
    //         V003: val,
    //         V017:bsa
    //     }, () => { AntSelectLabels() })

    //     AntSelectLabels();
    // }

    if (V001 > 0 && V002 > 0) {
      let val = BMI_CALCULATE(V001, V002);
      let bsa = BSA_CALCULATE(V001, V002);

      this.setState(
        {
          V003: val,
          V017: bsa,
        },
        () => {
          AntSelectLabels();
        }
      );

      this.vitalFormRef.current.setFieldsValue(
        {
          V003: val,
          V017: bsa,
        },
        () => {
          AntSelectLabels();
        }
      );

      AntSelectLabels();
    } else {
      this.setState(
        {
          V003: "",
          V017: "",
        },
        () => {
          AntSelectLabels();
        }
      );

      this.vitalFormRef.current.setFieldsValue({
        V003: "",
      });

      AntSelectLabels();
    }
  };

  radioChange = (event, state) => {
    this.setState({
      [state]: event.target.value,
    });
  };

  handleAllergyDropDownData = async (_) => {
    const foodPromise = await Axios.get(ALLERGIES_DROPDOWN({ type: "food" }));
    const otherPromise = await Axios.get(ALLERGIES_DROPDOWN({ type: "others" }));

    if (foodPromise.status) {
      this.setState({
        allergy_food: foodPromise.data.result,
      });
    }

    if (otherPromise.status) {
      this.setState({
        allergy_other: otherPromise.data.result,
      });
    }
  };

  handleVisitCount = async () => {
    const { patient_id } = this.state.patientUrlData;
    const visitDetailsPromise = await Axios.post(VISIT_COUNT, {
      patient_id: patient_id,
    });
    if (visitDetailsPromise && visitDetailsPromise.data.visit) {
      this.setState({ totalVisit: visitDetailsPromise.data.visit.count });
    }
  };

  handleVitalGraphData = async (_) => {
    const { patient_id, qms_token_id } = this.state.patientUrlData;

    const vitalGraphPromise = await Axios.get(VITALS_GRAPH({ patient_id }));
    const vitalPDFVitalGraphPromise = await Axios.get(
      PDF_CONFIG_VITALS_GRAPH({
        reference_type: REFERENCE_TYPE,
        reference_id: qms_token_id,
      })
    );

    if (vitalGraphPromise.data.status) {
      const { data } = vitalGraphPromise;

      this.setState({
        chartData: data.details,
        bloodSugarChartData: data.blood_sugar_data,
        keyFindingsChartData: data.details,
        vitalsRange: Object.values(data.constants.range),
        vitalsRangeWithId: data.constants.range,
      });
    }

    if (vitalPDFVitalGraphPromise.data.status) {
      const { data } = vitalPDFVitalGraphPromise;

      this.setState({
        chartGraphSettings: data.data.details !== null ? (data.data.details.graph_settings !== null ? data.data.details.graph_settings : "") : "",
        keyFindingsChartGraphSettings:
          data.data.details !== null
            ? data.data.details.key_findings_graph_settings !== null
              ? data.data.details.key_findings_graph_settings
              : ""
            : "",
      });
    }
  };

  makeVitalsData() {
    let vitalDetails = [];
    this.setState({
      patientHeight: null,
      patientWeight: null,
    });
    let { patient, patientHeight, patientWeight } = this.state;
    let allVitalIds = [];
    this.state.constants?.vitals_details.map((v, k) => {
      allVitalIds.push(v.id);
      vitalDetails[v.id] = [];
      this.setState({ [v.id]: null });
    });
    vitalDetails["date"] = [];
    vitalDetails["source"] = [];
    vitalDetails["update_date"] = [];
    vitalDetails["source_name"] = [];
    allVitalIds.push("date");
    allVitalIds.push("source");
    allVitalIds.push("update_date");
    allVitalIds.push("source_name");

    if (Object.keys(this.state.visitVitals).length > 0) {
      let index = 0;
      this.state.visitVitals.map((temp_vitals, k) => {
        // Object.keys(temp_vitals).forEach(j => {
        allVitalIds.forEach((j) => {
          if (vitalDetails[j]) vitalDetails[j][index] = temp_vitals[j] ?? "";
          if (
            j == "V001" &&
            patientHeight == null &&
            patient.age > HOSPITALDEFAULTS.age &&
            moment().diff(moment(temp_vitals.date[j]), "months") < 2 &&
            temp_vitals[j]
          )
            patientHeight = temp_vitals[j];
          if (
            j == "V002" &&
            patientWeight == null &&
            patient.age > HOSPITALDEFAULTS.age &&
            moment().diff(moment(temp_vitals.date[j]), "months") < 2 &&
            temp_vitals[j]
          )
            patientWeight = temp_vitals[j];
        });
        index++;
      });
    }
    this.setState({
      patientHeight: patientHeight,
      patientWeight: patientWeight,
      V001: patientHeight,
      V002: patientWeight,
    });
    let vitals_data = {};
    let newVitalEmptyArray = vitalDetails["date"] ? Array.from({ length: vitalDetails["date"].length }, (i) => "") : [];
    this.state.constants?.vitals_details.map((v, k) => {
      vitals_data[v.id] = vitalDetails[v.id] && vitalDetails[v.id].length > 0 ? vitalDetails[v.id] : newVitalEmptyArray;
    });
    vitals_data.date = vitalDetails["date"];
    vitals_data.source = vitalDetails["source"];
    vitals_data.update_date = vitalDetails["update_date"];
    vitals_data.source_name = vitalDetails["source_name"];

    //vitals groups
    let vitalGroupData = [];


    const doctorSpeciality = LS_SERVICE.get("speciality_id");
    console.log("doctorSpeciality", doctorSpeciality)


    let allVital = null;
    let allVitals = [];

    //Code according to speciality, comment this code and uncomment code from static 'line 121'  if want to display adult group start
    let seletctedSpecialityIdID = DEFAULTVITALGROUPID;
    /* For dispalying fields on basis of dropdown select*/

    let selectedVitalGroup = null;

    if (typeof this.state?.constants?.vital_groups != 'undifined') {
      selectedVitalGroup = Object.values(this.state?.constants?.vital_groups).find((item) => {
        let item_arr = [];
        item_arr = item.speciality_id != null ? item.speciality_id.split(",") : item_arr;
        if (item_arr.indexOf(doctorSpeciality?.toString()) != -1) return true;
      });
    }

    let defaultVitalGroup = null;

    console.log("this.state.constants?.vital_groups: ", this.state.constants?.vital_groups);

    if (selectedVitalGroup == undefined && this.state.constants?.vital_groups) {
      defaultVitalGroup = Object.values(this.state.constants?.vital_groups).find((item) => item.id == seletctedSpecialityIdID);
      selectedVitalGroup = defaultVitalGroup;
    }

    var vitalSignIds = [];
    for (const [key, vitalSign] of Object.entries(selectedVitalGroup.vital_sign)) {
      vitalSignIds.push(vitalSign.vital_id);
    }

    //console.log("selectedVitalGroup: ", selectedVitalGroup);
    selectedVitalGroup.vitalSignIds = vitalSignIds.toString().replace(/(^,)|(,$)/g, "");
    vitalGroupData.push(selectedVitalGroup);

    allVital = vitalGroupData;
    allVitals.push(vitalGroupData);
    //Code according to speciality, comment this code and uncomment code from static 'line 121' if want to display adult group end

    //Comment code is for displaying adults with other vital group start - line 121
    // for (const [key, vitalGroup] of Object.entries(this.state.constants.vital_groups)) {

    //     if (vitalGroup.speciality_id == doctorSpeciality) {
    //         var vitalSignIds = [];
    //         for (const [key, vitalSign] of Object.entries(vitalGroup.vital_sign)) {
    //             vitalSignIds.push(vitalSign.vital_id);
    //         }
    //         vitalGroup.vitalSignIds = vitalSignIds.toString().replace(/(^,)|(,$)/g, "");
    //         vitalGroupData.push(vitalGroup);
    //     }
    //     if (vitalGroup.id == 1 || vitalGroup.id == 2) {
    //         var vitalSignIds2 = [];
    //         for (const [key, vitalSign] of Object.entries(vitalGroup.vital_sign)) {
    //             vitalSignIds2.push(vitalSign.vital_id);
    //         }
    //         vitalGroup.vitalSignIds = vitalSignIds2.toString().replace(/(^,)|(,$)/g, "");
    //         allVital = vitalGroup;
    //         allVitals.push(vitalGroup);
    //     }
    // }
    // if (vitalGroupData.length == 0) {
    //     // vitalGroupData.push(allVital);
    //     vitalGroupData = allVitals;
    // }
    //Comment code is for displaying adults with other vital group end

    this.setState({
      selectedVitalGroups: vitalGroupData,
      vitalsType: vitalGroupData[0].id,
      displayVitals: vitalGroupData[0].vitalSignIds.split(","),
      vitalsInput: vitalGroupData[0].vital_sign,
      visitVitalsFormatted: vitals_data,
      vitals_data_formatted: true,
    });
    // this.makeVitalsHTML();
    // return vitals_data;
  }

  makeKeyFindingsData() {
    let keyFindingDetails = [];
    let allKeyFindingsIds = [];
    Object.values(this.state.constants.findingGroupData).map((f, k) => {
      f.vital_sign.map((v, k2) => {
        allKeyFindingsIds.push(v.id);
        keyFindingDetails[v.id] = [];
      });
    });
    keyFindingDetails["date"] = [];
    keyFindingDetails["source"] = [];
    keyFindingDetails["update_date"] = [];
    keyFindingDetails["source_name"] = [];
    allKeyFindingsIds.push("date");
    allKeyFindingsIds.push("source");
    allKeyFindingsIds.push("update_date");
    allKeyFindingsIds.push("source_name");

    if (Object.keys(this.state.visitVitalsKeyFindings).length > 0) {
      let index = 0;
      this.state.visitVitalsKeyFindings.map((temp_vitals, k) => {
        allKeyFindingsIds.forEach((j) => {
          if (keyFindingDetails[j]) keyFindingDetails[j][index] = temp_vitals[j] ?? "";
        });
        index++;
      });
    }

    let keyfindings_data = {};
    let newKeyFindingsEmptyArray = keyFindingDetails["date"] ? Array.from({ length: keyFindingDetails["date"].length }, (i) => "") : [];
    Object.values(this.state.constants.findingGroupData).map((f, k) => {
      f.vital_sign.map((v, k2) => {
        keyfindings_data[v.id] = keyFindingDetails[v.id] && keyFindingDetails[v.id].length > 0 ? keyFindingDetails[v.id] : newKeyFindingsEmptyArray;
      });
    });

    keyfindings_data.date = keyFindingDetails["date"];
    keyfindings_data.source = keyFindingDetails["source"];
    keyfindings_data.update_date = keyFindingDetails["update_date"];
    keyfindings_data.source_name = keyFindingDetails["source_name"];

    this.setState({
      visitKeyFindingsFormatted: keyfindings_data,
      keyfindings_data_formatted: true,
    });
  }

  handleObjectiveGetAllData = async () => {
    const { patient_id } = this.state.patient;
    const objectiveGetAllPromise = await Axios.get(OBJECTIVE_GETALL({ patient_id }));
    if (objectiveGetAllPromise.data.status) {
      const { data } = objectiveGetAllPromise.data;
      const { details, constants } = data;
      const { allergies, conditions, surgical_histories } = details;

      await this.setState({
        details,
        visitVitals: Object.values(details.visitVitals),
        visitVitalsKeyFindings: Object.values(details.visitVitalsKeyFindings),
        allergies,
        conditions,
        surgical_histories,
        constants,
        objective_data_loaded: true,
        noallergies: details.no_known_allergy,
        noExistingCondition: details.no_known_condition,
        noSurgicalHx: details.no_known_surgical_hsitory,
      });
      this.makeVitalsData();
      await this.handleKeyFindingFilterData();
      this.makeKeyFindingsData();
    }
  };

  handleKeyFindingFilterData = async () => {
    let details= LS_SERVICE.get('call_patient_detail');

    const keyFindingFilterPromise = await Axios.get(KEYFINDINGS_FILTER_SEQUENCING_LIST({doctor_id:details.doctor_id}));

    if (keyFindingFilterPromise.data.status) {
      this.setState({
        keyFindings: keyFindingFilterPromise.data.data,
        keyFindingsFiltersLoaded: true,
      });
    }
  };

  handleModalPopup = (e, popup, data = null, deleteType = "") => {
    if (e !== null) e.preventDefault();

    const { isPracticing } = this.state;

    if (LS_SERVICE.get('user_type') == USERTYPES.doctor) {
      if (!isPracticing && popup !== "chartModal" && popup !== "keyFindingChartModal") {
        this.setState({
          isPracticingModal: true,
        });
        return;
      }
    }

    this.setState(
      {
        [popup]: !this.state[popup],
        showAddDrugButton: true,
        drug_id: '',
        drug_name: '',
        searchDrugs: ''
      },
      () => {
        const { vitalModal, allergyModal } = this.state;
        const delete_text =
          deleteType === "surgical"
            ? data.reason
            : deleteType === "condition"
              ? data.condition_name
              : deleteType === "allergy"
                ? data.allergy_name
                : "";

        if (!vitalModal) {
          this.state.constants.vitals_details.map((v, k) => {
            this.setState({ [v.id]: null });
          });

          if (this.vitalFormRef.current !== null) {
            this.vitalFormRef.current.resetFields();
          }
        } else {
          let seletctedSpecialityIdID = DEFAULTVITALGROUPID;
          /* For dispalying fields on basis of dropdown select*/
          let defaultVitalGroup = Object.values(this.state.constants.vital_groups).find((item) => item.id == seletctedSpecialityIdID);
          let selectedVitalGroup = Object.values(this.state.constants.vital_groups).find((item) => {
            let item_arr = [];
            let specialityIds = "" + item.speciality_id;
            item_arr = item.speciality_id != null ? specialityIds.split(",") : item_arr;
            // if (item_arr.indexOf(LS_SERVICE.get('speciality_id').toString()) != -1)
            if (
              LS_SERVICE.get("speciality_id") &&
              item_arr.indexOf(LS_SERVICE.get("speciality_id")) &&
              item_arr.indexOf(LS_SERVICE.get("speciality_id").toString()) != -1
            )
              return true;
          });

          if (selectedVitalGroup == undefined) {
            selectedVitalGroup = defaultVitalGroup;
          }

          if (typeof selectedVitalGroup != "undefined" && selectedVitalGroup != "") {
            seletctedSpecialityIdID = selectedVitalGroup.id;
            this.setState({
              seletctedSpecialityIdID: seletctedSpecialityIdID,
            });
          }
          let showingVitals = selectedVitalGroup.vital_sign;
          if (this.state.loginRole === "nurse") {
            showingVitals = this.state.selectedVitalGroups[0].vital_sign;
          }

          let displayShowingVitals1 = [];
          let displayVitals = [];
          showingVitals.map((v, k) => {
            displayShowingVitals1.push(v.id);
            displayVitals.push(v.vital_id);
          });

          displayVitals = displayVitals.toString();
          if (this.state.loginRole === "nurse") {
            this.setState({
              displayShowingVitals: displayShowingVitals1,
              displayVitals: displayVitals,
              vitalsType: this.state.selectedVitalGroups[0].id,
            });
          }
          else {
            this.setState({
              displayShowingVitals: displayShowingVitals1,
              displayVitals: displayVitals,
              vitalsType: selectedVitalGroup.id,
            });
          }

          // orderVitalsService.init(displayShowingVitals1);
          let { patientHeight, patientWeight, patient } = this.state;

          if (patient.age > HOSPITALDEFAULTS.age) {
            if (this.vitalFormRef.current !== null) {
              this.vitalFormRef.current.setFieldsValue({
                V001: patientHeight,
                V002: patientWeight,
              });
              this.handleBmiCalculate();
            }
          }
          AntSelectLabels();
        }

        if (!allergyModal) {
          if (this.allergyFormRef.current !== null) {
            this.allergyFormRef.current.resetFields();
          }
          this.setState({
            allergyList: [],
          });
        }

        this.setState(
          {
            spin_loading: false,
            // chartsTab: '2',
            // chartsType: 'all',
            // allergy_active_tab: ALLERGY_TAB_STATUS[0],
            //vitalsType: this.state.selectedVitalGroups[0].id,
            formModeEdit: false,
            fromFieldId: -1,
            medication: "0",
            delete_text,
            deleteType,
            delete_id: data !== null ? data.id : "",
          },
          () => {
            let { surgicalModal, conditionModal, allergyModal, chartModal, keyFindingChartModal, allergyAlreadyExistModal, allergyAlreadyExistName } =
              this.state;

            if (surgicalModal) {
              this.surgeryFormRef.current.resetFields();
            } else if (conditionModal) {
              this.conditionFormRef.current.resetFields();
              this.conditionFormRef.current.setFieldsValue({
                condition_duration_type: "",
                medication: "0",
              });
            } else if (allergyModal) {
              if (allergyAlreadyExistName === "") {
                this.allergyFormRef.current.resetFields();
                this.allergyFormRef.current.setFieldsValue({
                  intensity: "",
                  informedby: "1",
                  allergyDurationType: "",
                });
              }
            } else if (chartModal) {
              this.setState({
                chartDataLoaded: true,
                chartsType: data.type,
                chartHeading: data.name,
              });
            } else if (keyFindingChartModal) {
              this.setState({
                keyFindingsChartDataLoaded: true,
                keyFindingsChartsType: data.type,
                keyFindingsChartHeading: data.name,
                keyFindingsChartId: data.id ?? null,
              });
            }

            if (data !== null) {
              if (popup === "surgicalModal") {
                if (surgicalModal) {
                  this.setState({
                    formModeEdit: true,
                    fromFieldId: data.id,
                  });
                  this.surgeryFormRef.current.setFieldsValue({
                    history: data.reason,
                    remarks: data.remarks
                  });
                }
              } else if (popup === "conditionModal") {
                if (conditionModal) {
                  this.setState({
                    formModeEdit: true,
                    fromFieldId: data.id,
                    medication: (data.onMedication) ? JSON.stringify(data.onMedication) : '',
                    formFieldEntryId: data.conditionId,
                    condition: data.condition_name
                  });
                  this.conditionFormRef.current.setFieldsValue({
                    condition_name: data.condition_name,
                    condition_duration: data.duration ? JSON.stringify(data.duration) : '',
                    condition_duration_type: JSON.stringify(data.durationType),
                    medication: JSON.stringify(data.onMedication),
                    medication_name: data.medicineName,
                  });
                }
              } else if (popup === "allergyModal") {
                if (allergyModal) {
                  this.setState({
                    formModeEdit: true,
                    fromFieldId: data.id,
                    allergy_selected_data: data,
                    allergy_active_tab: data.allergyType.toUpperCase() === "DRUG" ? "DRUGS" : data.allergyType.toUpperCase(),
                    formFieldEntryId: data.allergyId,
                    allergy_duration: data.duration,
                    allergyDurationType: data.durationType,
                    allergy: data.allergy_name
                  });

                  if (allergyAlreadyExistName === "") {
                    this.allergyFormRef.current.setFieldsValue({
                      allergy_selected: data.allergy_name,
                      reaction: data.reaction ? data.reaction?.split(",") : [],
                      intensity: (data.intensity || data.intensity == 0) ? JSON.stringify(data.intensity) : '',
                      informedby: JSON.stringify(data.informedBy),
                      allergy_duration: data.duration == null ? null : JSON.stringify(data.duration),
                      allergyDurationType: JSON.stringify(data.durationType),
                    });
                  }
                }
              } else if (popup === "allergyAlreadyExistModal") {
                this.setState(
                  {
                    formModeEdit: true,
                    fromFieldId: data.id,
                    formFieldEntryId: data.allergyId,
                    allergy_active_tab: data.allergyType.toUpperCase() === "DRUG" ? "DRUGS" : data.allergyType.toUpperCase(),
                  },
                  () => this.handleAllergyAddSubmit()
                );
              }
            }

            if (!allergyAlreadyExistModal) {
              if (allergyAlreadyExistName !== "") {
                this.setState({
                  allergyAlreadyExistName: "",
                });

                if (allergyModal) {
                  this.setState(
                    {
                      allergy_active_tab: this.state.allergy_active_tab,
                      allergy_selected: "",
                      allergy_selected_data: {},
                    },
                    () => {
                      if (this.allergyFormRef.current !== undefined) {
                        this.allergyFormRef.current.setFieldsValue({
                          allergy_selected: "",
                          reaction: [],
                          intensity: "",
                          informedby: "1",
                        });
                      }
                    }
                  );
                }
              }
            }
            if (!conditionModal) {
              this.setState({
                condition: '',
                showAddConditionButton: false,
                disableAddConditionButton: false,
              });
            }

            if (!allergyModal) {
              this.setState({
                allergy: '',
                showAddAllergyButton: false,
                disableAddAllergyButton: false,
              });
            }

            AntDisabledLabels();
            AntSelectLabels();

            // if (clear) {
            //     this.setState({
            //         spin_loading: false
            //     })
            // }
          }
        );
      }
    );
  };

  handleSurgeryAddSubmit = async (_) => {
    const { history, remarks } = this.surgeryFormRef.current.getFieldValue();
    const { patient, fromFieldId, spin_loading } = this.state;
    const { qms_token_id, patient_id } = patient;

    const PARAMS = {
      type: "history",
      objective_details: [
        {
          history,
          remarks: remarks ? remarks : null,
          id: fromFieldId,
        },
      ],
      reference_type: REFERENCE_TYPE,
      reference_id: qms_token_id,
      patient_id: patient_id,
    };

    this.setState({
      spin_loading: true,
    });

    const surgerySubPromise = await Axios.post(OBJECTIVE_STORE, qs.stringify(PARAMS));

    if (surgerySubPromise.data.status) {
      this.handleObjectiveGetAllData();
      this.handleModalPopup(null, "surgicalModal");
      this.setState({
        spin_loading: false,
      });
    }
  };

  handleConditionAddSubmit = async (_) => {
    const { condition_name, medication, medication_name, condition_duration, condition_duration_type } =
      this.conditionFormRef.current.getFieldValue();
    const { patient, fromFieldId, condition_selected, spin_loading, formFieldEntryId, formModeEdit  } = this.state;
    const { qms_token_id, patient_id } = patient;

    this.setState({
      spin_loading: true,
    });
    let medicineType='F'
    if (this.state.medication !== undefined && this.state.medication !== "" && this.state.medication !== "0") {
      if (this.state.drug_id == '' || this.state.drug_id == null) {
        let emailParams = {
          drug_name: this.state.drug_name,
        }

        await Axios.post(ADD_DRUGS, {
          hospital_id: LS_SERVICE.get('slot_hospital_id'),
          hospital_master: LS_SERVICE.get('slot_hospital_master'),
          name: this.state.drug_name,
          isCurrentMeds: true,
          emailParams: emailParams
        })
          .then(success => {
            this.setState({
              drug_id: success.data.data.id,
            });
            medicineType='N';
          }).catch(err => {
            console.log(err);
          })
      }
      if (!this.state.drug_id == '' && !this.state.drug_name == '') {
        const medicine_data = {
          drug_id: this.state.drug_id,
          drug_name: this.state.drug_name,
          prescription_drug_id: -1,
          active: 'Y',
          isOnlyCurrentMeds: 'Y',
          medicine_type:medicineType
        }
        const PRESCRIPTION_PARAMS = {
          reference_type: REFERENCE_TYPE,
          reference_id: qms_token_id,
          patient_id,
          medicine_data: [medicine_data],
          isAddedExistingMeds: 'Y',
        };
        await Axios.post(PRESCRIPTION_STORE, qs.stringify(PRESCRIPTION_PARAMS)).then(success => {
          let patient_prescription_drugId =success.data.storedDrugObject
           this.setState({
            drug_id: '',
            drug_name: '',
            patient_prescription_drug_id:patient_prescription_drugId[0].id
          })

        })
          .catch(err => console.log(err))
      }
    }
    const PARAMS = {
      type: "condition",
      objective_details: [
        {
          condition: formModeEdit ? formFieldEntryId : condition_selected.id,
          condition_name,
          condition_duration,
          condition_duration_type,
          medication,
          medicine_name: medication == 1 ? medication_name : "",
          id: fromFieldId,
          patient_prescription_drug_id:this.state.patient_prescription_drug_id,
        },
      ],
      reference_type: REFERENCE_TYPE,
      reference_id: qms_token_id,
      patient_id,
    };
    const conditionDataPromise = await Axios.post(OBJECTIVE_STORE, qs.stringify(PARAMS));
    if (conditionDataPromise.data.status) {
      const data = conditionDataPromise;
      this.handleObjectiveGetAllData();
      this.handleModalPopup(null, "conditionModal");
      notification.success({
        message: conditionDataPromise.data.message,
        placement: "topRight",
      });
      this.setState({
        spin_loading: false,
        patient_prescription_drug_id:""
      });
    }
  };

  handleAllergyAddSubmit = async (_) => {
    const { reaction, intensity, informedby, allergy_duration, allergyDurationType } = this.allergyFormRef.current.getFieldValue();
    const allergy_selected = this.state.allergy;

    const {
      patient,
      allergy_selected_data,
      allergy_active_tab,
      fromFieldId,
      spin_loading,
      formModeEdit,
      formFieldEntryId,
      allergies,
      allergyAlreadyExistName,
    } = this.state;
    const { qms_token_id, patient_id } = patient;

    if (allergies.find((all) => all.allergy_name.toLowerCase() === allergy_selected?.toLowerCase()) !== undefined && !formModeEdit) {
      // notification.info({
      //     message: 'Value already added, please try edit',
      //     placement: 'topRight'
      // })
      if (allergyAlreadyExistName === "") {
        const data = allergies.find((all) => all.allergy_name.toLowerCase() === allergy_selected.toLowerCase());
        this.setState(
          {
            allergyAlreadyExistName: allergy_selected,
            allergy_selected_data: { ...data },
          },
          () => this.handleModalPopup(null, "allergyAlreadyExistModal")
        );
        return;
      }
    }

    if (reaction?.toString().length > 250) {
      notification.error({
        message: "Reaction input exceeded",
        placement: "topRight",
      });
      return;
    }

    let objectiveObj =  {
      allergy: formModeEdit ? formFieldEntryId : allergy_selected_data.id,
      allergy_name: allergy_selected_data.long,
      allergy_type: ALLERGY_TAB_TYPE[allergy_active_tab],
      informed_by: informedby,
      reaction: reaction?.toString(),
      intensity,
      duration: allergy_duration,
      durationType: allergyDurationType,
      id: fromFieldId,
    }

    //if allergy is of type drug and cims is true
		if(ALLERGY_TAB_TYPE[allergy_active_tab] == 0 && CIMSALLOW && CIMS_ALLOW_HOSPITALS.includes(this.state.hospitalId)){
			objectiveObj["is_cims"] = "Y";
		}else{
			objectiveObj["is_cims"] = "N";
		}

    const PARAMS = {
      type: "allergy",
      objective_details: [
        [
          objectiveObj
        ],
      ],
      reference_type: REFERENCE_TYPE,
      reference_id: qms_token_id,
      patient_id,
    };

    // if (formModeEdit) {
    //     PARAMS.objective_details[0][0].allergy = formFieldEntryId;
    // }

    this.setState({
      spin_loading: true,
    });

    

    const allergyPromise = await Axios.post(OBJECTIVE_STORE, qs.stringify(PARAMS)).catch((err) => {
      const res = err.response;
      if (res.status === 500) {
        notification.error({
          message: err?.response?.data?.err,
          placement: "topRight",
        });
        this.setState({
          spin_loading: false,
        });
      }
    });

    if (allergyPromise?.status) {
      this.handleObjectiveGetAllData();
      this.handleModalPopup(null, "allergyModal");
      const { data } = allergyPromise;
      notification.success({
        message: data.message,
        placement: "topRight",
      });
      this.setState({
        spin_loading: false,
        allergyAlreadyExistName: "",
      });
    }
  };

  handleOnConditionChange = (condition_name, props) => {
    const condition_selected = {};
    Object.assign(condition_selected, props.dataid);
    this.setState({
      condition_name,
      condition_selected,
      condition: props.dataid.name
    });

    this.conditionFormRef.current.setFieldsValue({
      condition_name: condition_name,
    });

    document.getElementById("condition_duration").focus();
    AntSelectLabels();
  };

  handleOnAllergyChange = (allergy_selected, props) => {
    const allergy_selected_data = {};
    Object.assign(allergy_selected_data, props.dataid);
    this.setState(
      {
        allergy_selected,
        allergy_selected_data,
        allergy: props.dataid.long
      },
      () => {
        AntSelectLabels();
      }
    );

    document.getElementById("reaction").focus();

    let {referenceId,StoreId,FacilityId,enterpriseId,patientId,referenceType} = this.state;
		let orederSetId         = 0;
		let careProtoColId      = 0;
		let drugIds             = 0;
		let addedAssessmentList = props.dataid.id;
		let medicineScreen      = 'medicineform';
		let showInteractionModal = false;  
		let allergyCimsGuid = props.dataid.allergies_cims_guid;
		let allergyCimsType = props.dataid.allergies_cims_type;

    let isHospitalAllowed = CIMS_ALLOW_HOSPITALS.includes(this.state.hospitalId)

		if (CIMSALLOW && isHospitalAllowed) {
			Axios.get(CIMS_ALLERGY_INTERACTION_EXISTS({ drugIds, referenceType, referenceId, orederSetId, patientId, enterpriseId, FacilityId, StoreId, careProtoColId, medicineScreen, addedAssessmentList, allergyCimsGuid, allergyCimsType }))
				.then(success => {
					if (success.data.interaction) {
						showInteractionModal = true;
						this.setState(
							{
								drug_id_Interaction_Modalurl: drugIds, referenceType: referenceType, referenceId: referenceId, orederSetId: orederSetId, enterpriseId: enterpriseId, FacilityId: FacilityId, StoreId: StoreId, patientId: patientId, addedAssessmentList: addedAssessmentList, medicineScreen: medicineScreen, allergyFlag: true
							},
							() => this.setState({ isModalInteractionVisible: showInteractionModal })
						);
					} else {
						this.setState({ isModalInteractionVisible: false })
					}
				})
				.catch(err => console.log(err))
		} else {
			this.setState({ isModalInteractionVisible: false })
		}
  };

  handleOnReactionChange = (e) => {
    //console.log(e.target.value);
  };

  fetchConditionData = async (search) => {
    await this.setState({
      conditionList: [],
      formIsFetching: !this.state.formIsFetching,
      condition: search,
    });

    const conditionDataPromise = await Axios.post(CONDITION_SEARCH, qs.stringify({ search }));

    if (conditionDataPromise.status) {
      let { result } = conditionDataPromise.data;
      this.setState({
        conditionList: result,
        formIsFetching: !this.state.formIsFetching,
        showAddConditionButton: (search.length > 2 && result.length == 0) ? true : false,
      });
    }
  };

  handleAddCondition = async () => {
    const conditionAddPromise = await Axios.post(CONDITION_ADD_MASTER, { text: this.state.condition });
    this.setState({
      disableAddConditionButton: true
    });

    if (conditionAddPromise.status == 200) {
      let conditionList = this.state.conditionList;
      conditionList.push(conditionAddPromise.data);
      this.setState({
        disableAddConditionButton: false,
        showAddConditionButton: false,
        conditionList: conditionList,
      })
      this.handleOnConditionChange(conditionAddPromise.data.id, { children: conditionAddPromise.data.name, value: String(conditionAddPromise.data.id), dataid: conditionAddPromise.data });
    }
  }

  handleAddAllergy = async (type) => {
    const { hospital_id } = this.state;
    const allergyAddPromise = await Axios.post(ALLERGY_ADD_MASTER, { hospital_id, type, text: this.state.allergy });
    this.setState({
      disableAddAllergyButton: true
    });

    if (allergyAddPromise.status == 200) {
      if (type === 'drug') {
        let allergyList = this.state.allergyList;
        allergyList.push(allergyAddPromise.data);
        this.setState({
          disableAddAllergyButton: false,
          showAddAllergyButton: false,
          allergyList: allergyList,
        })
      }

      if (type === 'food') {
        let allergy_food = this.state.allergy_food;
        allergy_food.push(allergyAddPromise.data);
        this.setState({
          disableAddAllergyButton: false,
          showAddAllergyButton: false,
          allergy_food: allergy_food,
        })
      }

      if (type === 'others') {
        let allergy_other = this.state.allergy_other;
        allergy_other.push(allergyAddPromise.data);
        this.setState({
          disableAddAllergyButton: false,
          showAddAllergyButton: false,
          allergy_other: allergy_other,
        })
      }

      this.handleOnAllergyChange(allergyAddPromise.data.id, { children: allergyAddPromise.data.name, value: String(allergyAddPromise.data.id), dataid: allergyAddPromise.data });
    }
  }

  handleAntFormOnChange = (d) => {
    let { name, value } = d.currentTarget.hasOwnProperty("name") ? d.currentTarget : d.target;

    this.setState({
      [name]: value,
    });
  };

  // fetchAllergyData = async (search) => {
  //   await this.setState({
  //     allergyList: [],
  //     formIsFetching: !this.state.formIsFetching,
  //     allergy: search
  //   });

  //   const allergyDataPromise = await Axios.get(ALLERGIES_DROPDOWN({ search, type: "drug" }));

  //   if (allergyDataPromise.status) {
  //     let { result } = allergyDataPromise.data;
  //     this.setState({
  //       allergyList: result,
  //       formIsFetching: !this.state.formIsFetching,
  //       showAddAllergyButton: (search.length > 2 && result.length == 0) ? true : false,
  //     });
  //   }
  // };

  fetchAllergyData = async (search) => {
		await this.setState({
			allergyList: [],
			formIsFetching: true,
			allergy: search
		});
		// CIMSALLOW
    let isHospitalAllowed = CIMS_ALLOW_HOSPITALS.includes(this.state.hospitalId)
		if(CIMSALLOW && isHospitalAllowed){
			if(search.length > 3){
				let params = {
					search
				}
				let allergyRes = await Axios.post(CIMS_ALLERGY_SEARCH,params);
				if(allergyRes.status == 200){
					this.setState({
						allergyList: allergyRes.data.data,
						formIsFetching: false,
						showAddAllergyButton: (search.length > 2 && allergyRes.data.data.length == 0) ? true : false,
					});
				}
			}
		}else{
			const allergyDataPromise = await Axios.get(ALLERGIES_DROPDOWN({ search, type: "drug" }));
			if (allergyDataPromise.status) {
				let { result } = allergyDataPromise.data;
				this.setState({
					allergyList: result,
					formIsFetching: !this.state.formIsFetching,
					showAddAllergyButton: (search.length > 2 && result.length == 0) ? true : false,
				});
			}
		}
	};

  fetchAllergyFoodData = async (search) => {
    await this.setState({
      allergy_food: [],
      formIsFetching: !this.state.formIsFetching,
      allergy: search
    });

    const allergyDataPromise = await Axios.get(ALLERGIES_DROPDOWN({ search, type: "food" }));

    if (allergyDataPromise.status) {
      let { result } = allergyDataPromise.data;
      this.setState({
        allergy_food: result,
        formIsFetching: !this.state.formIsFetching,
        showAddAllergyButton: (search.length > 2 && result.length == 0) ? true : false,
      });
    }
  };

  fetchAllergyOthersData = async (search) => {
    await this.setState({
      allergy_other: [],
      formIsFetching: !this.state.formIsFetching,
      allergy: search
    });

    const allergyDataPromise = await Axios.get(ALLERGIES_DROPDOWN({ search, type: "others" }));

    if (allergyDataPromise.status) {
      let { result } = allergyDataPromise.data;
      this.setState({
        allergy_other: result,
        formIsFetching: !this.state.formIsFetching,
        showAddAllergyButton: (search.length > 2 && result.length == 0) ? true : false,
      });
    }
  };

  handleAllergyTab = (e, TAB) => {
    e.preventDefault();

    this.setState(
      {
        allergy_active_tab: TAB,
        allergy_selected: "",
        allergy_selected_data: {},
        showAddAllergyButton: false
      },
      () => {
        this.allergyFormRef.current.setFieldsValue({
          allergy_selected: "",
          reaction: [],
          intensity: "",
          informedby: "1",
        });
      }
    );
  };

  handleVitalSelect = (val, props) => {
    this.setState({ V001: "", V002: "" });
    let seletctedShowingVitalGroup = Object.values(this.state.constants.vital_groups).find((item) => item.id == val);

    let showingVitals = seletctedShowingVitalGroup.vital_sign;
    let displayShowingVitals = [];
    let displayShowingVitalsValidations = [];
    showingVitals.map((v, k) => {
      if (v.id == "V001") {
        this.setState({ V001: this.state.patientHeight });
      }
      if (v.id == "V002") {
        this.setState({ V002: this.state.patientWeight });
      }

      if (this.state.vitalNotInRange.includes(v.id)) {
        displayShowingVitalsValidations.push(v.id);
      }
      displayShowingVitals.push(v.id);
    });

    this.setState({ vitalNotInRange: displayShowingVitalsValidations });

    let displayVitals = props.datavitals.split(",");

    this.setState({
      vitalsType: val,
      displayVitals: displayVitals,
      displayShowingVitals: displayShowingVitals,
      vitalsInput: showingVitals,
    });

    AntSelectLabels();
  };

  handleVitalAddSubmit = async (_) => {

    if (this.handleVitalModalButtonDisabled()) return;

    let vitalsData = this.vitalFormRef.current.getFieldsValue(true);

    //code for saving only fields which are displaying
    let postdata = {};

    for (let element of this.state.displayShowingVitals) {
      if (vitalsData[element] != undefined) {
        postdata[element] = vitalsData[element];
      }
    }
    postdata.V006 = this.state.V006 || "";
    postdata.V003 = this.state.V003 || "";
    postdata.V017 = this.state.V017 || "";
    if (postdata.V014 == "" && typeof postdata.V014 != "undefined" && postdata.V015 == "" && typeof postdata.V015 != "undefined") {
      postdata.V003 = "";
    }

    //code for saving vitals key findings separately
    let keyFindingsPostData = {};

    for (var [key, value] of Object.entries(vitalsData)) {
      if (postdata[key] == undefined && key != 'vitalsType' && key != 'bp') {
        if (value != null) {
          if (value.charAt(0) == '.') {
            value = '0' + value
          }
        }
        else {
          value = ''
        }
        keyFindingsPostData[key] = value;
      }
    }

    vitalsData = postdata;

    let datetime_keyfindings = moment(this.state.key_finding_date_time).format(this.state.dateFormat);

    const { patient, spin_loading } = this.state;
    const { patient_id, qms_token_id } = patient;
    const PARAMS = {
      vitals_data: vitalsData,
      key_findings_data: keyFindingsPostData,
      key_findings_date: datetime_keyfindings,
      reference_type: REFERENCE_TYPE,
      reference_id: qms_token_id,
      patient_id,
    };

    this.setState({
      spin_loading: true,
    });

    const vitalAddPromise = await Axios.post(OBJECTIVE_VITAL_STORE, qs.stringify(PARAMS));

    if (vitalAddPromise.status) {
      this.handleObjectiveGetAllData();
      this.handleVitalGraphData();
      this.handleModalPopup(null, "vitalModal");
      const { data } = vitalAddPromise;
      notification.success({
        message: data.message,
        placement: "topRight",
      });
      this.setState({
        spin_loading: false,
      });
    }
  };

  handleDeletePopSubmit = async (e) => {
    e.preventDefault();

    let { type, deleteType, delete_id, spin_loading } = this.state;

    type = deleteType === "surgical" ? "surgical-history" : deleteType === "condition" ? "condition" : "allergy";

    this.setState({
      spin_loading: true,
    });

    const deletePromise = await Axios.get(HISTORY_DELETE({ type, id: delete_id }));

    if (deletePromise.status) {
      let { data } = deletePromise;
      this.handleModalPopup(null, "deleteModal");
      this.handleObjectiveGetAllData();
      notification.success({
        message: data.message,
        placement: "topRight",
      });
      this.setState({
        spin_loading: false,
      });
    }
  };

  handleGraphClick = (e, type = "2") => {
    this.setState(
      {
        chartDataLoaded: false,
        chartsTab: type,
      },
      () => this.setState({ chartDataLoaded: true })
    );
  };

  handleKeyGraphClick = (e, type = "2") => {
    this.setState(
      {
        keyFindingsChartDataLoaded: false,
        keyFindingsChartsTab: type,
      },
      () => this.setState({ keyFindingsChartDataLoaded: true })
    );
  };

  handleObjectiveContinue = () => {
    const { patient, patientUrlData } = this.state;
    const { patient_id, qms_token_id, hospital_id } = patientUrlData;

    const isTodayVisitArr = LS_SERVICE.has("isTodayVisit") ? LS_SERVICE.get("isTodayVisit") : [];
    const pageListArr = LS_SERVICE.get("goToSoapPatientPageList");
    let index = pageListArr.indexOf("objective");
    let childDevIndex = pageListArr.indexOf("child_development");
    let mensuralHxIndex = pageListArr.indexOf('mensural_hx');
    let sexualHxIndex = pageListArr.indexOf('sexual_hx');
    let obstetricsHxIndex = pageListArr.indexOf('obstetrics_hx');
    let physicalExaminationIndex = pageListArr.indexOf('physical_examination');

    const isPaediatrics = LS_SERVICE.get("isPaediatrics") ? LS_SERVICE.get("isPaediatrics") : false;


    const isObsGynaePatient = LS_SERVICE.get('isObsGynaePatient') ? LS_SERVICE.get('isObsGynaePatient') : false;
    const isObsGynaeDoctor = LS_SERVICE.get('isObsGynaeDoctor') ? LS_SERVICE.get('isObsGynaeDoctor') : 0;
    if (isObsGynaePatient == true && isObsGynaeDoctor == 1) {
      index++;
    } else {
      /* if (isObsGynaeDoctor == 1) { */
      if (mensuralHxIndex != -1) {
        index++;
      }
      if (sexualHxIndex != -1) {
        index++;
      }
      if (obstetricsHxIndex != -1) {
        index++;
      }
      if (physicalExaminationIndex != -1) {
        index++;
      }
      //index++;
      /* } else { */
      if (childDevIndex == -1) {
        let vaccChartIndex = pageListArr.indexOf("vaccination_chart");
        if (vaccChartIndex == -1) {
          index++;
        } else {
          index = index + 2;
        }
      } else {
        if (isPaediatrics) {
          index++;
        } else {
          let vaccChartIndex = pageListArr.indexOf("vaccination_chart");
          if (vaccChartIndex == -1) {
            index = index + 2;
          } else {
            index = index + 3;
          }
        }
      }
      //}
    }

    if (index < pageListArr.length && !isTodayVisitArr.includes(qms_token_id)) {
      let soapurl = LS_SERVICE.get("goToSoapPatientUrl")[index].url;
      soapurl = soapurl.replace("HOSPITALID", hospital_id);
      soapurl = soapurl.replace("REFID", qms_token_id);
      soapurl = soapurl.replace("REFTYPE", REFERENCE_TYPE);
      soapurl = soapurl.replace("PATID", patient_id);
      this.props.history.push({
        pathname: soapurl,
        state: { patient },
      });
    } else {
      this.props.history.push({
        pathname: `/patient/todays-visit/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`,
        state: { patient },
      });
    }
  };

  handleObjectiveSubmit = () => {
    const { allergies, conditions, surgical_histories, totalVisit, noExistingCondition, noallergies, noSurgicalHx, isPracticing, patientUrlData, patient } = this.state;

    const { hospital_id } = patientUrlData;

    if (this.state.loginRole === "nurse") {
      this.props.history.push({
        pathname: `/patient-queue/${hospital_id}`,
        state: { patient },
      });
    }

    if (!this.state.allowEdit) {
      this.handleObjectiveContinue();
      return;
    }

    if (!isPracticing) {
      this.handleModalPopup(null, "isPracticingModal");
      return;
    }

    if (parseInt(totalVisit) <= 1) {
      let allergyText = "";
      if (allergies.length == 0) {
        allergyText = "Allergies";
      }

      let existingText = "";
      if (conditions.length == 0) {
        existingText = "Existing Condition";
      }

      let historyText = "";
      if (surgical_histories.length == 0) {
        historyText = "History";
      }

      if ((allergyText !== "" && noallergies == 0) || (existingText != "" && noExistingCondition == 0) || (historyText != "" && noSurgicalHx == 0)) {
        var htmlTxt = "";
        if (allergyText !== "" && noallergies == 0) htmlTxt += allergyText + ", ";
        if (existingText !== "" && noExistingCondition == 0) htmlTxt += existingText + ", ";
        if (historyText !== "" && noSurgicalHx == 0) htmlTxt += historyText;

        htmlTxt += " are not entered. Do you want to proceed?";
        htmlTxt = htmlTxt.replace(/,([^,]*)$/, " and$1");
        this.setState({ noDataModalText: htmlTxt });
        this.setState({ noDataModal: true });
      } else {
        this.handleObjectiveContinue();
      }
    } else {
      this.handleObjectiveContinue();
    }
  };

  handleObjectiveContinueForNurse = () => {
    const { patient, patientUrlData } = this.state;
    const { patient_id, qms_token_id, hospital_id } = patientUrlData;

    SoapFlow.hospital(hospital_id)
    const mensural_hx = SoapFlow.showElement('obsgynae', 'mensural_hx');
    const sexual_hx = SoapFlow.showElement('obsgynae', 'sexual_hx');
    const obstetrics_hx = SoapFlow.showElement('obsgynae', 'obstetrics_hx');
    const physical_examination = SoapFlow.showElement('obsgynae', 'physical_examination');

    if (LS_SERVICE.get("isObsGynaePatient") && LS_SERVICE.get('isObsGynaeDoctor') === 1 &&
      (mensural_hx || sexual_hx || obstetrics_hx || physical_examination)
    ) {
      this.props.history.push({
        pathname: `/patient/obs-gynae/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`,
        state: { patient },
      });
    } else {
      this.setState({ continueModalForNurse: !this.state.continueModalForNurse });
    }
  }

  continueActionForNurse = (action = "") => {
    const { patient, patientUrlData } = this.state;
    const { patient_id, qms_token_id, hospital_id } = patientUrlData;

    this.setState({ continueModalForNurse: false }, () => {
      if (action === "yes") {
        this.props.history.push({
          pathname: `/patient/history/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`,
          state: { patient },
        });
        return;
      }

      if (action === "no") {
        this.props.history.push('/patient-queue/' + LS_SERVICE.get('slot_hospital_id'));
        return;
      }
    });

  }

  handleVitalModalButtonDisabled = (_) => {
    if (this.state.constants && this.state.constants.vitals_details) {
      var vitalFieldsCount = this.state.constants.vitals_details.length;
      var emptyCount = 0;
      //pick vital group from constants.vital_group
      let seletctedVitalGroup = Object.values(this.state.constants.vital_groups).find((item) => item.id == this.state.vitalsType);
      // debugger;
      if (typeof seletctedVitalGroup != "undefined") {
        let applicableVitals = seletctedVitalGroup.vital_sign;
        applicableVitals.map((v, k) => {
          if (parseFloat(this.state[v.id]) == 0) emptyCount++;
        });
      }

      // this.state.constants.vitals_details.map((v, k) => {
      //     if (parseFloat(this.state[v.id]) == 0) emptyCount++;
      // });

      if (emptyCount > 0) return true;

      if (this.state.V005 != null && this.state.V005 != "" && (this.state.V006 == "" || this.state.V006 == null)) return true;

      if (this.state.V006 != null && this.state.V006 != "" && (this.state.V005 == "" || this.state.V005 == null)) return true;

      if (this.state.vitalNotInRange.length > 0) return true;

      return false;
    } else return false;
  };

  handleOnCheckBoxChange = async (e, checkeBox) => {
    const { name } = e.target;
    const { patient_id } = this.state.patient;
    // const { allergies, conditions, surgical_histories } = this.state;

    if (this.state[checkeBox].length === 0) {
      this.setState({
        [name]: !this.state[name],
      });
    } else {
      e.preventDefault();
      return;
    }

    let type =
      name == "noallergies" ? "allergy" : name == "noExistingCondition" ? "existing-condition" : name == "noSurgicalHx" ? "surgical-history" : "";

    await Axios.post(NO_KNOWN_OBJECTIVE, {
      objectiveType: type,
      objectiveStatus: !this.state[name],
      patientId: patient_id,
    });
    // if (allergies.length === 0) {
    //     this.setState({
    //         [name]: !this.state[name]
    //     })
    // } else if (conditions.length === 0) {
    //     this.setState({
    //         [name]: !this.state[name]
    //     })
    // } else if (surgical_histories.length === 0) {
    //     this.setState({
    //         [name]: !this.state[name]
    //     })
    // } else {
    //     e.preventDefault();
    // }
  };

  handleVitalModalClear = (_) => {
    this.state.constants.vitals_details.map((v, k) => {
      this.setState({ [v.id]: null });
    });
    this.vitalFormRef.current.resetFields();
    // const { patientHeight, patientWeight, patient } = this.state;

    // if (patient.age > HOSPITALDEFAULTS.age) {
    //   if (this.vitalFormRef.current !== null) {
    //     this.vitalFormRef.current.setFieldsValue({
    //       V001: patientHeight,
    //       V002: patientWeight,
    //     });
    //     this.handleBmiCalculate();
    //   }
    // }

    AntClearLabels();

  };

  handleMaxLimit = (type) => {
    if (type == '') {
      type = 3;
    }
    const { patient } = this.state;
    if (patient !== null) {
      const sD = moment(patient.dob);
      const eD = moment();
      const maxVal = eD.diff(sD, DURATION_TYPE[type]);
      return maxVal;
    }
  };

  chartStateUpdate = (data) => {
    this.setState({
      chartGraphSettings: data,
    });
  };

  chartStateUpdateKeyFindigs = (data) => {
    this.setState({
      keyFindingsChartGraphSettings: data,
    });
  };

  enablePractice = async (e) => {
    e.preventDefault();
    practiceService.init({ initiater: "SOAP Pages", status: "start" });
    const practice = await API_SERVICE.post(TOGGLE_PRACTICE, {
      hospital_id: this.state.hospital_id,
      practice_session: 'start'
    });

    let is_practicing;
    if (practice.data && practice.data.is_practicing === 'Y') {
      is_practicing = true;
      LS_SERVICE.set('practicing', {
        status: true,
        hospital_id: this.state.hospital_id
      });
    } else {
      is_practicing = false;
    }

    this.setState(
      {
        isPracticing: is_practicing,
      },
      () => {
        this.handleModalPopup(null, "isPracticingModal");
      }
    );
  };

  handleRangeChange = (dates) => {
    this.setState(
      {
        range: dates !== null ? dates : [],
      },
      () => {
        this.handleDateRangeGraphData(dates == null);
      }
    );
  };

  handleKeyFindingsRangeChange = (dates) => {
    this.setState(
      {
        key_findings_range: dates !== null ? dates : [],
      },
      () => {
        this.handleKeyFindingsDateRangeGraphData(dates == null);
      }
    );
  };

  handleKeyFindingsUpdate = async (keyfindings) => {
    await this.setState({
      keyFindings: keyfindings,
    });
  };

  setDateTimeHandler = (date) => {
    //console.log("date", moment(date).format(this.state.dateFormat));
    if (date !== null) {
      this.setState({
        key_finding_date_time: moment(date),
      });
    }
  };

  disabledDate(current) {
    return current && current > moment();
  }

  getKeyFindingFFormItem() {
    let KeyFindingsConstants = this.state?.constants?.findingGroupData;
    let keyFindingsOrder = this.state.keyFindings;

    let new_key_findings_order = [];
    if (keyFindingsOrder.length) {
      KeyFindingsConstants = Object.values(KeyFindingsConstants);
      keyFindingsOrder.map((fo, k2) => {
        let obj = { display_order: fo.display_order, finding_group_id: fo.finding_group_id, vital_sign: [] };
        let at_least_one_av = false;
        let findex = KeyFindingsConstants.findIndex((x) => x.id == fo.finding_group_id);
        if (findex !== -1) {
          fo.vital_sign.map((v, k) => {
            let obj_vital_sign = { ...v };
            let findex2 = KeyFindingsConstants[findex].vital_sign.findIndex((x) => x.vital_id == v.vital_id);
            if (findex2 !== -1) {
              at_least_one_av = true;
              obj.vital_sign.push(obj_vital_sign);
            }
          });
        }
        obj.one_value_available = at_least_one_av;
        new_key_findings_order.push(obj);
      });
    }
    let one_group_avail = false;
    new_key_findings_order.forEach((data) => {
      if (!one_group_avail) {
        one_group_avail = data.one_value_available;
      }
    });
    if (new_key_findings_order.length && one_group_avail) {
      return (
        <React.Fragment>
          <div className="row mb-2 mt-4 key__finding">
            <div className="col-2 center__align">
              <h2 className="model_head" style={{ paddingTop: "0px" }}>
                Add Key Findings
              </h2>
            </div>
            <div className="col-7 center__align">

              <div className={`form-group ${this.state.key_finding_date_time ? "hasdata" : ""}`}>
                <DatePicker
                  id="key_finding_date_time"
                  name="key_finding_date_time"
                  format="MMM, DD YYYY hh:mm A"
                  disabledDate={this.disabledDate}
                  showNow={false}
                  placeholder={""}
                  allowClear
                  defaultPickerValue={this.state.key_finding_date_time}
                  showTime={{ defaultValue: moment() }}
                  defaultValue={this.state.key_finding_date_time}
                  value={this.state.key_finding_date_time}
                  className="form-control datetimepicker"
                  onOk={this.setDateTimeHandler}
                />
                <label htmlFor="key_finding_date_time">Select Date Time</label>
              </div>
            </div>
            <div className="col-3 center__align">
              <span>
                <Badge className="mr-2 vr-badge" color={"#ffe6e8"} text={"Entered by Patient"} />
              </span>
            </div>
          </div>

          <div className="clearfix"></div>
          {new_key_findings_order.map((fo, k2) => {
            {
              let findex = KeyFindingsConstants.findIndex((x) => x.id == fo.finding_group_id);
              if (findex !== -1) {
                if (fo.one_value_available) {
                  /* const __name = KeyFindingsConstants[findex].name.replace(/\s+/g, "__").toLowerCase(); */
                  return (
                    <React.Fragment key={k2}>
                      <h2 className="model_head">{KeyFindingsConstants[findex].name}</h2>
                      <div className="row model_bg_blue">
                        {fo.vital_sign.map((v, k) => {
                          let findex2 = KeyFindingsConstants[findex].vital_sign.findIndex((x) => x.vital_id == v.vital_id);
                          if (findex2 !== -1) {
                            let vital = KeyFindingsConstants[findex].vital_sign[findex2];
                            return (
                              <div className="col-4 antInputItem">
                                <Form.Item
                                  key={v.vital_id}
                                  /* id={`${__name}_${vital.id}`} */
                                  name={vital.id}
                                  label={vital.display_name}
                                  rules={[
                                    {
                                      required: vital.required,
                                      type: "number",
                                      min: vital.min,
                                      max: vital.max,
                                      transform: function (value) {
                                        return value === "" || value === undefined ? value : +value;
                                      },
                                    },
                                  ]}
                                >
                                  <Input
                                    addonAfter={vital.display_value}
                                    name={vital.id}
                                    /* id={`${__name}_${vital.id}`} */
                                    autoComplete="off"
                                    maxLength={vital.maxlength}
                                    data-vitalid={vital.vital_id}
                                    value={this.state[vital.id]}
                                    onChange={(e) => {
                                      this.handleOnChangeNumbers(
                                        e,
                                        vital.min,
                                        vital.max,
                                        vital.decimal_value,
                                        KeyFindingsConstants[findex].name,
                                        "1",
                                        ""
                                      );
                                    }}
                                  />
                                </Form.Item>

                                {/* {1 == 1 && (
                                <div
                                  style={{
                                    display: "flex",
                                    marginBottom: "10px",
                                  }}
                                  className="datepicker"
                                >
                                  <DatePicker
                                    name={vital.id}
                                    defaultValue={moment()}
                                    value={moment()}
                                    format="YYYY-MM-DD"
                                    onChange={(e) =>
                                      this.onDateTimeChangeHandler(e, "date")
                                    }
                                  />                      
                                </div>
                              )} */}
                              </div>
                            );
                          }
                        })}
                      </div>
                    </React.Fragment>
                  );
                }
              }
            }
          })}
        </React.Fragment>
      );
    }
  }

  showHeaderForNurse(patient) {
    if (this.state.loginRole === 'nurse') {
      if (patient != undefined && patient.patient_id != undefined && patient.patient_id != null) {
        return null;
      }
    } else {
      if (patient != undefined && patient.patient_id != undefined && patient.patient_id != null) {
        return <SubHeader patient={patient} />
      }
    }
  }
  onDrugssearch(search) {
    this.setState({
      formIsFetchingDrug: true
    })
    let params = { search: search };
    if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
      params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('admin_hospital_id') : null;
    } else if (LS_SERVICE.get("user_type") == USERTYPES.superadmin) {
      params.hospital_id = LS_SERVICE.get('master_hospital_id') ?? null;
    } else {
      params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null;
    }
    Axios.post(SEARCH_DRUGS, qs.stringify(params)).then(success => {
      this.setState({
        searchDrugs: success.data.medicine_list,
        formIsFetchingDrug: false,
        showAddDrugButton: (search.length > 2 && success.data?.medicine_list?.length == 0) ? false : true,
      })
    }).catch(err => console.log(err));
  }

  onChangeDrugsSelect = async (value, props) => {
    await this.setState({
      drug_id: props.drug_id,
      drug_name: value,
    });
  }

  optionDrugLoad() {
    if (this.state.searchDrugs !== undefined && this.state.searchDrugs.length > 0) {
      return this.state.searchDrugs.map((obj, i) => {

        return (<Option key={i} value={obj.name} drug_id={obj.id} fav={obj.fav} disabled={(this.state.addedDrugsListsId && this.state.addedDrugsListsId.includes(obj.id))} >
          <span className="desc">
            {obj.name}
          </span>
          {/* <span className="float-right text-primary">
            {(LS_SERVICE.get("user_type") == USERTYPES.doctor) ?
              <i className={`icon_unique icon_star align-star ${obj.fav ? "favourite" : ""}`}></i>
              : null}
            {this.state.cimsallow ? (
              <Button onClick={(e) => { e.stopPropagation(); this.showModalInfo() }} className="nostyle-link p-0">
                <i className='icon_info'></i>
              </Button>
            ) : null}
          </span> */}
        </Option>
        )
      })
    } else return null
  }
  handleAddDrug = async () => {
    const { medication_name } =
      this.conditionFormRef.current.getFieldValue();
    this.setState({
      showAddDrugButton: true,
      drug_id: null,
      drug_name: medication_name,
    });
  }

  Formclear = async () => {
		this.setState({
			allergyList: [],
			allergy_message: false,
		});
		this.allergyFormRef.current.resetFields();
		this.allergyFormRef.current.setFieldsValue({
			intensity: "",
			informedby: "1",
			allergyDurationType: "",
		});
	}

  handleInteractionOk     = () => { this.setState({ isModalInteractionVisible: false }) };
  handleInteractionCancel = () => { this.setState({ isModalInteractionVisible: false }, () => { this.Formclear();  }); }; 

  render() {
    const {
      objective_data_loaded,
      allergies,
      conditions,
      surgical_histories,
      allergy_selected_data,
      conditionList,
      condition,
      allergyList,
      allergy_food,
      allergy_other,
      allergy_selected,
      allergy,
      formModeEdit,
      patient
    } = this.state;

    var show_graph = false;
    if (this?.state?.visitVitalsFormatted?.date?.length > 0) {
      show_graph = true;
    }
    var show_graph_key_findings = false;
    if (this?.state?.visitKeyFindingsFormatted?.date?.length > 0) {
      show_graph_key_findings = true;
    }
    return (
      <>
        {patient != undefined && patient.patient_id != undefined ? (
          <AsideLeft patient_id={patient.patient_id} reference_id={patient.qms_token_id} loginRole={this.state.loginRole} />
        ) : null}

        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="row emrfrm">
              {/* {patient != undefined && patient.patient_id != undefined && patient.patient_id != null ? <SubHeader patient={patient} /> : null} */}

              {this.showHeaderForNurse(patient)}

              <div className="col-12">
                <h1 className="page-title">Objective</h1>
              </div>

              {objective_data_loaded && patient !== null ? (
                <>
                  <div className="col-12 mb-4">
                    <div className="card rounded-0 cardbg">
                      <div className="card-header card-flex">
                        <label className="float-left">Vitals</label>

                        <div className="vital-right ml-auto d-flex align-content-center">
                          <Badge className="mr-2 vr-badge" color={"#ffe6e8"} text={"Vitals Entered by Patient"} />
                          {show_graph == true ? (
                            <button
                              type="button"
                              className="btn top-chart-btn p-0"
                              onClick={(e) =>
                                this.handleModalPopup(e, "chartModal", {
                                  type: "all",
                                  name: "Trend Graphs",
                                })
                              }
                            >
                              <i className="icon-chart"></i>
                            </button>
                          ) : null}
                          {this.state.allowEdit && (
                            <button
                              type="button"
                              className="btn btn-outline-primary text-uppercase ml-2"
                              onClick={(e) => this.handleModalPopup(e, "vitalModal")}
                            >
                              + Vitals / Findings
                            </button>
                          )}
                        </div>
                        {/* {this.state.allowEdit?<button type="button" className="float-md-right btn btn-outline-primary text-uppercase"
                          onClick={e => this.handleModalPopup(e, 'vitalModal')}>+ Vitals</button>:null}

                          <button type="button" className="btn top-chart-btn"
                                  onClick={(e) => this.handleModalPopup(e, 'chartModal', { type: 'all', name: 'Trend Graphs' })}>
                              <i className="icon-chart"></i>
                          </button> */}
                      </div>
                      {this.state.vitals_data_formatted > 0 ? (
                        <Vitals
                          dataLoaded={this.state.vitals_data_formatted}
                          constants={this.state.constants}
                          visitVitalsFormatted={this.state.visitVitalsFormatted}
                          handleModalPopupStateLift={this.handleModalPopup}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12">
                    <h1 className="page-title">Key Findings</h1>
                  </div>
                  <div className="col-12 mb-4">
                    <div className="card rounded-0 cardbg">
                      <div className="card-header card-flex">
                        <label className="float-left">Parameters</label>
                        {this.state.keyFindingsFiltersLoaded ? (
                          <button
                            className="btn_filter"
                            onClick={async (e) => {
                              await this.handleModalPopup(e, "chartParamerModel");
                            }}
                          ></button>
                        ) : null}
                        <div className="vital-right ml-auto d-flex align-content-center">
                          <Badge className="mr-2 vr-badge" color={"#ffe6e8"} text={"Entered by Patient"} />
                          {this.state.keyFindingsFiltersLoaded && show_graph_key_findings ? (
                            <button
                              type="button"
                              className="btn top-chart-btn p-0"
                              onClick={(e) =>
                                this.handleModalPopup(e, "keyFindingChartModal", {
                                  type: "all",
                                  name: "Key Findings Trend Graphs",
                                })
                              }
                            >
                              <i className="icon-chart"></i>
                            </button>
                          ) : null}
                        </div>
                        {/* {this.state.allowEdit?<button type="button" className="float-md-right btn btn-outline-primary text-uppercase"
                          onClick={e => this.handleModalPopup(e, 'vitalModal')}>+ Vitals</button>:null}

                          <button type="button" className="btn top-chart-btn"
                                  onClick={(e) => this.handleModalPopup(e, 'chartModal', { type: 'all', name: 'Trend Graphs' })}>
                              <i className="icon-chart"></i>
                          </button> */}
                      </div>
                      {this.state.keyFindingsFiltersLoaded && this.state.keyfindings_data_formatted ? (
                        <KeyFindings
                          dataLoaded={this.state.keyfindings_data_formatted}
                          keyFindingsOrder={this.state.keyFindings}
                          constants={this.state.constants}
                          visitKeyFindingsFormatted={this.state.visitKeyFindingsFormatted}
                          handleModalPopupStateLift={this.handleModalPopup}
                        />
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 mb-4">
                    <div className="card rounded-0 cardbg">
                      <div className="card-header">
                        <div className="row">
                          <label className="col pt-2 col-sm-3">Known Allergies</label>
                          <div className="col check_no_know">
                            <div
                              className="custom-control custom-checkbox mt-2"
                            // style={{ pointerEvents: (this.state.allergies.length > 0 ? 'none' : 'all') }}
                            >
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="known_allergies"
                                name="noallergies"
                                // checked={this.state.noallergies}
                                checked={this.state.noallergies}
                                value={this.state.noallergies}
                                disabled={!this.state.allowEdit}
                                onChange={(e) => this.handleOnCheckBoxChange(e, "allergies")}
                              />
                              <label className="custom-control-label text-basic" htmlFor="known_allergies">
                                No Known Allergies
                              </label>
                            </div>
                          </div>
                          <div className="col-auto pt-2">
                            <label className="d-inline-block mr-3">Severity</label>
                            <ul className="severity_list">
                              <li>
                                <i className="icon-severity-low"></i> Low
                              </li>
                              <li>
                                <i className="icon-severity-medium"></i> Moderate
                              </li>
                              <li>
                                <i className="icon-severity-high"></i> High
                              </li>
                            </ul>
                          </div>
                          {this.state.allowEdit ? (
                            <div className="col">
                              <button
                                type="button"
                                className="float-md-right btn btn-outline-primary text-uppercase"
                                disabled={this.state.noallergies}
                                onClick={(e) => this.handleModalPopup(e, "allergyModal")}
                              >
                                + Allergies
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {allergies.length > 0 ? (
                        <div className="card-body pt-2 allergies">
                          <div className="row">
                            <div className="col-md-4 col-12">
                              <div className="card rounded-0 p-3 drug-content">
                                <h5 className="text-primary">Drugs</h5>
                                <div className="list-content">
                                  {allergies.map((all) =>
                                    all.allergyType === "drug" ? (
                                      <ul className="allergy-container" key={all.id}>
                                        <li>
                                          {all.allergy_name}
                                          {this.state.allowEdit ? (
                                            <span className="float-right">
                                              <a
                                                className="nostyle-link edit-allergy mr-3"
                                                onClick={(e) => this.handleModalPopup(e, "allergyModal", all)}
                                              >
                                                <i className="icon-edit"></i>
                                              </a>
                                              <a
                                                className="nostyle-link remove-list remove-allergy"
                                                onClick={(e) => this.handleModalPopup(e, "deleteModal", all, "allergy")}
                                              >
                                                <i className="icon_delete"></i>
                                              </a>
                                            </span>
                                          ) : null}
                                        </li>
                                        <li>
                                          <span className="text-muted">{all.reaction}</span>
                                        </li>
                                        <li>
                                          <span className="text-muted">
                                            {all.duration} {DURATION_TYPE[all.durationType]}
                                          </span>
                                        </li>
                                        <li>
                                          <span className={all.informedBy ? "text-danger" : "text-success"}>{INFORMED_BY[all.informedBy]}</span>
                                          <i
                                            className={(all.intensity != null) ? `float-right 
                                            ${all.intensity === 0
                                                ? "icon-severity-low"
                                                : all.intensity === 1
                                                  ? "icon-severity-medium"
                                                  : "icon-severity-high"
                                              }` : ''}
                                          ></i>
                                        </li>
                                      </ul>
                                    ) : null
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                              <div className="card rounded-0 p-3 food-content">
                                <h5 className="text-primary">Food</h5>
                                <div className="list-content">
                                  {allergies.map((all) =>
                                    all.allergyType === "food" ? (
                                      <ul className="allergy-container" key={all.id}>
                                        <li>
                                          {all.allergy_name}
                                          {this.state.allowEdit ? (
                                            <span className="float-right">
                                              <a
                                                className="nostyle-link edit-allergy mr-3"
                                                onClick={(e) => this.handleModalPopup(e, "allergyModal", all)}
                                              >
                                                <i className="icon-edit"></i>
                                              </a>
                                              <a
                                                className="nostyle-link remove-list remove-allergy"
                                                onClick={(e) => this.handleModalPopup(e, "deleteModal", all, "allergy")}
                                              >
                                                <i className="icon_delete"></i>
                                              </a>
                                            </span>
                                          ) : null}
                                        </li>
                                        <li>
                                          <span className="text-muted">{all.reaction}</span>
                                        </li>
                                        <li>
                                          <span className="text-muted">
                                            {all.duration} {DURATION_TYPE[all.durationType]}
                                          </span>
                                        </li>
                                        <li>
                                          <span className={all.informedBy ? "text-danger" : "text-success"}>{INFORMED_BY[all.informedBy]}</span>
                                          <i
                                            className={(all.intensity != null) ? `float-right 
                                              ${all.intensity === 0
                                                ? "icon-severity-low"
                                                : all.intensity === 1
                                                  ? "icon-severity-medium"
                                                  : "icon-severity-high"
                                              }` : ''}
                                          ></i>
                                        </li>
                                      </ul>
                                    ) : null
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                              <div className="card rounded-0 p-3 others-content">
                                <h5 className="text-primary">Others</h5>
                                <div className="list-content">
                                  {allergies.map((all) =>
                                    all.allergyType === "others" ? (
                                      <ul className="allergy-container" key={all.id}>
                                        <li>
                                          {all.allergy_name}
                                          {this.state.allowEdit ? (
                                            <span className="float-right">
                                              <a
                                                className="nostyle-link edit-allergy mr-3"
                                                onClick={(e) => this.handleModalPopup(e, "allergyModal", all)}
                                              >
                                                <i className="icon-edit"></i>
                                              </a>
                                              <a
                                                className="nostyle-link remove-list remove-allergy"
                                                onClick={(e) => this.handleModalPopup(e, "deleteModal", all, "allergy")}
                                              >
                                                <i className="icon_delete"></i>
                                              </a>
                                            </span>
                                          ) : null}
                                        </li>
                                        <li>
                                          <span className="text-muted">{all.reaction}</span>
                                        </li>
                                        <li>
                                          <span className="text-muted">
                                            {all.duration} {DURATION_TYPE[all.durationType]}
                                          </span>
                                        </li>
                                        <li>
                                          <span className={all.informedBy ? "text-danger" : "text-success"}>{INFORMED_BY[all.informedBy]}</span>
                                          <i
                                            className={(all.intensity != null) ? `float-right 
                                              ${all.intensity === 0
                                                ? "icon-severity-low"
                                                : all.intensity === 1
                                                  ? "icon-severity-medium"
                                                  : "icon-severity-high"
                                              }` : ''}
                                          ></i>
                                        </li>
                                      </ul>
                                    ) : null
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 mb-4">
                    <div className="card rounded-0 cardbg">
                      <div className="card-header">
                        <div className="row">
                          <label className="col-sm-3 col-12t">Existing Conditions</label>
                          <div className="col-sm-6 col-12 check_no_know">
                            <div className="custom-control custom-checkbox mt-2">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="known_existing_conditions"
                                name="noExistingCondition"
                                value={this.state.noExistingCondition}
                                checked={this.state.noExistingCondition}
                                disabled={!this.state.allowEdit}
                                onChange={(e) => this.handleOnCheckBoxChange(e, "conditions")}
                              />
                              <label className="custom-control-label text-basic" htmlFor="known_existing_conditions">
                                No Known Existing Conditions
                              </label>
                            </div>
                          </div>
                          {this.state.allowEdit ? (
                            <div className="col-sm-3 col-12">
                              <button
                                type="button"
                                className="float-md-right btn btn-outline-primary text-uppercase"
                                disabled={this.state.noExistingCondition}
                                onClick={(e) => this.handleModalPopup(e, "conditionModal")}
                              >
                                + Condition
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {conditions.length > 0 ? (
                        <div className="card-body pt-1">
                          <div className="row">
                            <div className="col-12 table-responsive">
                              <table className="table table-default conditions-content">
                                <thead>
                                  <tr>
                                    <th>Conditions</th>
                                    <th>Duration</th>
                                    <th>On Medication</th>
                                    <th>Medicine Name</th>
                                    {this.state.allowEdit ? <th width="100">Action</th> : null}
                                  </tr>
                                </thead>
                                <tbody>
                                  {conditions.map((c, i) => (
                                    <tr key={i}>
                                      <td>{c.condition_description}</td>
                                      <td>
                                        {(c.duration) ? `${c.duration} ${DURATION_TYPE[c.durationType]}` : '-'}
                                      </td>
                                      <td>{MEDICATION_ON[c.onMedication] ? MEDICATION_ON[c.onMedication] : '-'}</td>
                                      <td>{c.medicineName || "NA"}</td>
                                      {this.state.allowEdit ? (
                                        <td className="action">
                                          <a className="nostyle-link edit-condition" onClick={(e) => this.handleModalPopup(e, "conditionModal", c)}>
                                            <i className="icon-edit"></i>
                                          </a>
                                          <a
                                            className="nostyle-link remove-list remove-condition"
                                            onClick={(e) => this.handleModalPopup(e, "deleteModal", c, "condition")}
                                          >
                                            <i className="icon_delete"></i>
                                          </a>
                                        </td>
                                      ) : null}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 mb-5">
                    <div className="card rounded-0 cardbg mb-4">
                      <div className="card-header">
                        <div className="row">
                          <label className="col-sm-3 col-12">Surgical Hx</label>
                          <div className="col-sm-6 col-12 check_no_know">
                            <div className="custom-control custom-checkbox mt-2">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="known_surgicalHx"
                                name="noSurgicalHx"
                                checked={this.state.noSurgicalHx}
                                disabled={!this.state.allowEdit}
                                value={this.state.noSurgicalHx}
                                onChange={(e) => this.handleOnCheckBoxChange(e, "surgical_histories")}
                              />
                              <label className="custom-control-label text-basic" htmlFor="known_surgicalHx">
                                No Known Surgical Hx
                              </label>
                            </div>
                          </div>
                          {this.state.allowEdit ? (
                            <div className="col-sm-3 col-12">
                              <button
                                type="button"
                                className="float-md-right btn btn-outline-primary text-uppercase"
                                disabled={this.state.noSurgicalHx}
                                onClick={(e) => this.handleModalPopup(e, "surgicalModal")}
                              >
                                + Surgical Hx
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {surgical_histories.length > 0 ? (
                        <div className="card-body pt-1">
                          <div className="row">
                            <div className="col-12 table-responsive">
                              <table className="table table-default history-content">
                                <thead>
                                  <tr>
                                    <th>Reason for Surgery</th>
                                    <th>Remarks</th>
                                    {this.state.allowEdit ? <th width="100">Action</th> : null}
                                  </tr>
                                </thead>
                                <tbody>
                                  {surgical_histories.length > 0
                                    ? surgical_histories.map((sh, idx) => (
                                      <tr key={idx}>
                                        <td>{sh.reason}</td>
                                        <td>
                                          {sh.remarks ? sh.remarks : '-'}
                                        </td>
                                        {this.state.allowEdit ? (
                                          <td className="action">
                                            <a className="nostyle-link edit-history" onClick={(e) => this.handleModalPopup(e, "surgicalModal", sh)}>
                                              <i className="icon-edit"></i>
                                            </a>
                                            <a
                                              className="nostyle-link remove-list remove-history"
                                              onClick={(e) => this.handleModalPopup(e, "deleteModal", sh, "surgical")}
                                            >
                                              <i className="icon_delete"></i>
                                            </a>
                                          </td>
                                        ) : null}
                                      </tr>
                                    ))
                                    : null}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fixed-cta">
                    {this.state.isNurseLoggined ? (
                      <button
                        className="btn btn-primary px-4 nostyle-link"
                        onClick={this.handleObjectiveContinueForNurse}
                      >
                        CONTINUE
                      </button>
                    ) : (<button
                      className="btn btn-primary px-4 nostyle-link"
                      onClick={this.state.allowEdit ? this.handleObjectiveSubmit : this.handleObjectiveContinue}
                    >
                      CONTINUE
                    </button>)}

                  </div>
                </>
              ) : (
                <div className="w-100 align-items-center justify-content-center mt-4 d-flex">{LOADER_RED}</div>
              )}
            </div>
          </div>
        </div>

        {/* || SURGICAL MODAL */}
        <Modal
          title={`${formModeEdit ? "Edit " : "Add "} Surgical History`}
          visible={this.state.surgicalModal}
          onCancel={(e) => this.handleModalPopup(e, "surgicalModal")}
          style={{ top: 40 }}
          bodyStyle={{ paddingBottom: "10px" }}
          width="550px"
          className="emrfrm"
          footer={[
            <div className="text-center">
              <Button form="surgeryForm" type="primary" className="px-4" htmlType="submit" disabled={this.state.spin_loading}>
                SAVE{this.state.spin_loading ? LOADER : ""}
              </Button>
            </div>,
          ]}
        >
          <div className="row">
            <div className="col-12">
              <Form
                id="surgeryForm"
                ref={this.surgeryFormRef}
                validateMessages={HANDLEVALIDANTMESSAGESPATIENTAGE}
                onFinish={this.handleSurgeryAddSubmit}
                layout="vertical"
              >
                <div className="antInputItem">
                  <Form.Item
                    name="history"
                    label="Surgery Name"
                    rules={[
                      { required: true, message: "Please enter surgery" },
                      {
                        pattern: /^(?=[a-zA-Z\d\s]{0,48}$)([a-zA-Z01-9]+ ?)*$/,
                        message: "Maximum 50 characters.",
                      },
                    ]}
                  >
                    <Input autoFocus={true} autoComplete="off" />
                  </Form.Item>
                </div>
                <div className="antInputItem">
                  <Form.Item
                    name="remarks"
                    label="Remarks"
                  >
                    <TextArea autoComplete="off" name="remarks" onChange={(e) => this.handleOnChange(e, 'surgicalHx')} rows={3} />
                  </Form.Item>
                </div>
                {/* <Form.Item>
                                    <Button type="primary" htmlType="submit" disabled={this.state.spin_loading}>
                                        SAVE{this.state.spin_loading ? LOADER : ''}
                                    </Button>
                                </Form.Item> */}
              </Form>
            </div>
          </div>
        </Modal>

        {/* || CONDITION MODAL */}
        <Modal
          destroyOnClose={true}
          title={`${formModeEdit ? "Edit " : "Add "} Condition`}
          visible={this.state.conditionModal}
          onCancel={(e) => this.handleModalPopup(e, "conditionModal")}
          style={{ top: 40 }}
          bodyStyle={{ paddingBottom: "10px" }}
          className="emrfrm"
          width="530px"
          footer={[
            <div className="text-center">
              <Button form="conditionForm" type="primary" htmlType="submit" disabled={this.state.spin_loading}>
                SAVE{this.state.spin_loading ? LOADER : ""}
              </Button>
            </div>,
          ]}
        >
          <div className="row">
            <div className="col-12">
              <Form
                id="conditionForm"
                ref={this.conditionFormRef}
                validateMessages={HANDLEVALIDANTMESSAGESPATIENTAGE}
                onFinish={this.handleConditionAddSubmit}
                layout="vertical"
              >
                <div className="antSelectItem mt-2">
                  <div className="form-group add_buttons font-col">
                    <Form.Item name="condition_name" label="Condition" rules={[{ required: true }]}>
                      {(this.state.showAddConditionButton) ?
                        <button className={"btn btn-outline-primary"} disabled={this.state.disableAddConditionButton} onClick={() => this.handleAddCondition()}>ADD</button>
                        :
                        null
                      }
                      <AutoComplete
                        id="condition_name"
                        name="condition_name"
                        showSearch
                        placeholder=" "
                        showArrow={!this.state.showAddConditionButton}
                        value={condition}
                        notFoundContent={this.state.formIsFetching ? LOADER_BLACK : null}
                        loading={this.state.formIsFetching}
                        filterOption={false}
                        onSearch={this.fetchConditionData}
                        onSelect={this.handleOnConditionChange}
                        style={{ width: "100%" }}
                        disabled={this.state.formModeEdit}
                      >
                        {conditionList !== undefined && conditionList.length > 0
                          ? conditionList.map((d) => (
                            <Option key={d.id} dataid={d}>
                              {d.name}
                            </Option>
                          ))
                          : null}
                      </AutoComplete>
                    </Form.Item>
                  </div>
                </div>

                <div className="antInputItem">
                  <Form.Item
                    name="condition_duration"
                    label="Duration"
                    className="onlynumbers"
                    rules={[
                      {
                        // required: true,
                        type: "number",
                        min: 0,
                        max: this.handleMaxLimit(this.state.condition_duration_type),
                        transform: function (value) {
                          return value === "" || value === undefined ? value : +value;
                        }
                      },
                    ]}
                  >
                    <Input maxLength="2" autoComplete="off" name="condition_duration" onChange={(e) => this.handleOnChange(e, "conditions")} />
                  </Form.Item>
                </div>
                <Form.Item
                  name="condition_duration_type"
                  className="mt-3"
                  rules={[
                    {
                      required: this.state.condition_duration_type_required,
                      message: "Please choose one!",
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={(e) => {
                      this.radioChange(e, "condition_duration_type");
                      this.conditionFormRef.current.validateFields();
                    }}
                  >
                    <Radio value="0">Days ago</Radio>
                    <Radio value="1">Weeks ago</Radio>
                    <Radio value="2">Months ago</Radio>
                    <Radio value="3">Years ago</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  name="medication"
                  label={<strong>On Medication?</strong>}
                  className="mt-3"
                  onChange={this.handleAntFormOnChange}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please choose one!",
                //   },
                // ]}
                >
                  <Radio.Group name="medication">
                    <Radio value="1">Yes</Radio>
                    <Radio value="0">No</Radio>
                  </Radio.Group>
                </Form.Item>
                <span className="d-none">{this.state.medication}</span>
                {this.state.medication !== undefined && this.state.medication !== "" && this.state.medication !== "0" ? (
                  <div className="antSelectItem mt-2">
                    <div className="form-group add_buttons font-col">
                      {(this.state.showAddDrugButton) ?
                        null
                        :
                        <a className={"btn btn-outline-primary"} disabled={this.state.showAddDrugButton} onClick={() => this.handleAddDrug()}>ADD</a>
                      }
                      <Form.Item name='medication_name' label='Medication Name' rules={[{
                        required: true,
                        message: 'please choose medicine name'
                      }]}>
                        <AutoComplete
                          id='medication_name'
                          name='medication_name'
                          style={{ width: 100 + '%' }}
                          showSearch={true}
                          showArrow={this.state.showAddDrugButton}
                          notFoundContent={this.state.formIsFetchingDrug ? LOADER_BLACK : null}
                          loading={this.state.formIsFetchingDrug}
                          onSelect={this.onChangeDrugsSelect}
                          onSearch={this.onDrugssearch}
                          className="select-drugs"
                          value={this.state.drug_name}
                          filterOption={false}
                          dropdownClassName="custom_drug_select"
                          autoFocus={this.state.drug_name != null && this.state.drug_name != '' ? false : true}
                          showAction={this.state.drug_name != null && this.state.drug_name != '' ? [] : ['focus', 'click']}
                          disabled={this.state.formModeEdit}
                        >
                          {this.optionDrugLoad()}
                        </AutoComplete>
                      </Form.Item>
                    </div>
                  </div>
                ) : null}
                {/* <Form.Item>
                                    <Button type="primary" htmlType="submit" disabled={this.state.spin_loading}>
                                        SAVE{this.state.spin_loading ? LOADER : ''}
                                    </Button>
                                </Form.Item> */}
              </Form>
            </div>
          </div>
        </Modal>

        {/* || ALLERGIES MODAL */}
        {this.state.allergyModal ? (
          <Modal
            title={`${formModeEdit ? "Edit " : "Add "} Allergies`}
            visible={this.state.allergyModal}
            onCancel={(e) => this.handleModalPopup(e, "allergyModal")}
            style={{ top: 40 }}
            width="530px"
            bodyStyle={{ paddingBottom: "10px" }}
            className="emrfrm"
            footer={[
              <div className="text-center">
                <Button form="allergyForm" type="primary" htmlType="submit" className="px-4" disabled={this.state.spin_loading}>
                  SAVE{this.state.spin_loading ? LOADER : ""}
                </Button>
              </div>,
            ]}
          >
            <div className="row">
              <div className="col-12">
                <Form
                  id="allergyForm"
                  ref={this.allergyFormRef}
                  validateMessages={HANDLEVALIDANTMESSAGESPATIENTAGE}
                  onFinish={this.handleAllergyAddSubmit}
                  layout="vertical"
                >
                  {!formModeEdit ? (
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <a
                          className={`nav-link allergy-type ${this.state.allergy_active_tab === ALLERGY_TAB_STATUS[0] ? "active" : ""}`}
                          onClick={(e) => this.handleAllergyTab(e, ALLERGY_TAB_STATUS[0])}
                        >
                          Drugs
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link allergy-type ${this.state.allergy_active_tab === ALLERGY_TAB_STATUS[1] ? "active" : ""}`}
                          onClick={(e) => this.handleAllergyTab(e, ALLERGY_TAB_STATUS[1])}
                        >
                          Food
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link allergy-type ${this.state.allergy_active_tab === ALLERGY_TAB_STATUS[2] ? "active" : ""}`}
                          onClick={(e) => this.handleAllergyTab(e, ALLERGY_TAB_STATUS[2])}
                        >
                          Others
                        </a>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}

                  {this.state.allergy_active_tab === ALLERGY_TAB_STATUS[0] ? (
                    <div className="antSelectItem mt-4">
                      <div className="form-group add_buttons font-col">
                        {(this.state.showAddAllergyButton) ?
                          <a className={"btn btn-outline-primary"} disabled={this.state.disableAddAllergyButton} onClick={() => this.handleAddAllergy('drug')}>ADD</a>
                          :
                          null
                        }
                        <Form.Item name="allergy_selected" label={this.state.allergy_active_tab.toLowerCase()} rules={[{ required: true }]}>
                          <AutoComplete
                            id="allergy_selected"
                            name="allergy_selected"
                            autoFocus
                            showSearch
                            showArrow={!this.state.showAddAllergyButton}
                            value={allergy}
                            notFoundContent={this.state.formIsFetching ? LOADER_BLACK : null}
                            loading={this.state.formIsFetching}
                            filterOption={false}
                            onSearch={this.fetchAllergyData}
                            onSelect={this.handleOnAllergyChange}
                            style={{ width: "100%" }}
                            disabled={this.state.formModeEdit}
                          >
                            {allergyList !== undefined && allergyList.length > 0
                              ? allergyList.map((d) => (
                                <Option key={d.id} value={d.short} dataid={d} label={d.code}>
                                  {d.long}
                                </Option>
                              ))
                              : null}
                          </AutoComplete>
                        </Form.Item>
                      </div>
                    </div>
                  ) : null}

                  {this.state.allergy_active_tab === ALLERGY_TAB_STATUS[1] ? (
                    <div className="antSelectItem mt-4">
                      <div className="form-group add_buttons font-col">
                        {(this.state.showAddAllergyButton) ?
                          <a className={"btn btn-outline-primary"} disabled={this.state.disableAddAllergyButton} onClick={() => this.handleAddAllergy('food')}>ADD</a>
                          :
                          null
                        }
                        <Form.Item name="allergy_selected" label={this.state.allergy_active_tab.toLowerCase()} rules={[{ required: true }]}>
                          <AutoComplete
                            id="allergy_selected"
                            name="allergy_selected"
                            showSearch
                            autoFocus
                            defaultOpen
                            showArrow={!this.state.showAddAllergyButton}
                            value={allergy_selected}
                            optionFilterProp="children"
                            notFoundContent={null}
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            onSearch={this.fetchAllergyFoodData}
                            onSelect={this.handleOnAllergyChange}
                            style={{ width: "100%" }}
                            disabled={this.state.formModeEdit}
                          >
                            {allergy_food !== undefined && allergy_food.length > 0
                              ? allergy_food.map((d) => (
                                <Option key={d.id} value={d.short} dataid={d} label={d.code}>
                                  {d.long}
                                </Option>
                              ))
                              : null}
                          </AutoComplete>
                        </Form.Item>
                      </div>
                    </div>
                  ) : null}

                  {this.state.allergy_active_tab === ALLERGY_TAB_STATUS[2] ? (
                    <div className="antSelectItem mt-4">
                      <div className="form-group add_buttons font-col">
                        {(this.state.showAddAllergyButton) ?
                          <a className={"btn btn-outline-primary"} disabled={this.state.disableAddAllergyButton} onClick={() => this.handleAddAllergy('others')}>ADD</a>
                          :
                          null
                        }
                        <Form.Item name="allergy_selected" label={this.state.allergy_active_tab.toLowerCase()} rules={[{ required: true }]}>
                          <AutoComplete
                            id="allergy_selected"
                            name="allergy_selected"
                            showSearch
                            autoFocus
                            defaultOpen
                            showArrow={!this.state.showAddAllergyButton}
                            value={allergy_selected}
                            optionFilterProp="children"
                            notFoundContent={null}
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            onSearch={this.fetchAllergyOthersData}
                            onSelect={this.handleOnAllergyChange}
                            style={{ width: "100%" }}
                            disabled={this.state.formModeEdit}
                          >
                            {allergy_other !== undefined && allergy_other.length > 0
                              ? allergy_other.map((d) => (
                                <Option key={d.id} value={d.short} dataid={d} label={d.code}>
                                  {d.long}
                                </Option>
                              ))
                              : null}
                          </AutoComplete>
                        </Form.Item>
                      </div>
                    </div>
                  ) : null}

                  <div className="antSelectItem mt-4">
                    <Form.Item
                      name="reaction"
                      onChange={(e) => this.handleOnReactionChange(e)}
                      label="Reaction"
                    // rules={[
                    //   {
                    //     required: true,
                    //   },
                    // ]}
                    >
                      <Select mode="tags" style={{ width: "100%" }} dropdownClassName="d-none"></Select>
                    </Form.Item>
                  </div>
                  <Form.Item
                    name="intensity"
                    className="mt-2"
                    label={<strong>Intensity </strong>}
                    rules={[
                      {
                        required: false,
                        message: "Please choose one!",
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value="0" className="mr-4">
                        Low <i className="icon-severity-low"></i>
                      </Radio>
                      <Radio value="1" className="mr-4">
                        Moderate <i className="icon-severity-medium"></i>
                      </Radio>
                      <Radio value="2">
                        High <i className="icon-severity-high"></i>
                      </Radio>
                    </Radio.Group>
                  </Form.Item>

                  <div className="antInputItem">
                    <Form.Item
                      name="allergy_duration"
                      label="Duration"
                      className="onlynumbers"
                      rules={[
                        {
                          required: false,
                          type: "number",
                          min: 0,
                          max: this.handleMaxLimit(this.state.allergyDurationType),
                          transform: function (value) {
                            return value === "" || value === undefined ? value : +value;
                          },
                        },
                      ]}
                    >
                      <Input maxLength="2" autoComplete="off" name="duration" onChange={(e) => this.handleOnChange(e, "allergies")} />
                    </Form.Item>
                  </div>
                  <Form.Item
                    name="allergyDurationType"
                    rules={[
                      {
                        required: this.state.allergy_duration_type_required,
                        message: "Please choose one!",
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={(e) => {
                        this.radioChange(e, "allergyDurationType");
                        this.allergyFormRef.current.validateFields();
                      }}
                    >
                      <Radio value="0">Days ago</Radio>
                      <Radio value="1">Weeks ago</Radio>
                      <Radio value="2">Months ago</Radio>
                      <Radio value="3">Years ago</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    name="informedby"
                    className="mt-3"
                    label={<strong>Informed by? </strong>}
                    rules={[
                      {
                        required: true,
                        message: "Please choose one!",
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value="0" className="mr-4">
                        {INFORMED_BY[0]}
                      </Radio>
                      <Radio value="1">{INFORMED_BY[1]}</Radio>
                    </Radio.Group>
                  </Form.Item>
                  {/* <Form.Item>
                                    <Button type="primary" htmlType="submit" disabled={this.state.spin_loading}>
                                        SAVE{this.state.spin_loading ? LOADER : ''}
                                    </Button>
                                </Form.Item> */}
                </Form>
              </div>
            </div>
          </Modal>
        ) : null}

        {/* || VITAL MODAL */}
        <Modal
          title={`${formModeEdit ? "Edit " : "Add "} Vitals`}
          visible={this.state.vitalModal}
          onCancel={(e) => this.handleModalPopup(e, "vitalModal")}
          style={{ top: 40 }}
          footer={[
            <div className="text-center">
              <Button
                form="vitalForm"
                type="outline-secondary"
                onClick={this.handleVitalModalClear}
                htmlType="button"
                className="btn btn-outline-secondary cursor-pointer"
              >
                CLEAR
              </Button>
              <Button
                form="vitalForm"
                type="primary"
                htmlType="submit"
                className="btn btn-primary text-uppercase ml-2"
                disabled={this.handleVitalModalButtonDisabled() || this.state.spin_loading}
              >
                CONFIRM{this.state.spin_loading ? LOADER : ""}
              </Button>
            </div>,
          ]}
          width="980px"
          bodyStyle={{ paddingBottom: "10px", maxHeight: "650px", overflow: "scroll" }}
          className="emrfrm"
        >
          <Form
            id="vitalForm"
            className="add-vitals"
            ref={this.vitalFormRef}
            validateMessages={HANDLEVALIDANTMESSAGES}
            onFinish={this.handleVitalAddSubmit}
            layout="vertical"
          >
            <div className="row">
              <div className="col-4">
                <Form.Item name="vitalType">
                  <span className="d-none">{this.state.vitalsType}</span>
                  <div className="form-group mb-1 hasdata">
                    <Select
                      name="vitalsType"
                      id="vitalsType"
                      value={this.state.vitalsType}
                      defaultValue={this.state.vitalsType}
                      onChange={this.handleVitalSelect}
                    >
                      {this.state.selectedVitalGroups.map((vitalGroup, index) => (
                        <Option key={index} value={vitalGroup.id} datavitals={vitalGroup.vitalSignIds}>
                          {vitalGroup.long_description}
                        </Option>
                      ))}
                    </Select>
                    <label htmlFor="vitalsType">Vital Group</label>
                  </div>
                </Form.Item>
              </div>
            </div>

            <div className="row">
              {this.state.vitalsInput
                ? this.state.vitalsInput.map((vital, idx) => {
                  const tabIndx = idx + 1;
                  if (!this.state.displayShowingVitals.includes(vital.id)) return null;
                  if (vital.id == "V006") return null;
                  else if (vital.id == "V005")
                    return (
                      <div className="col-4 antInputItem multi-col" style={!this.state.displayVitals.includes("5") ? { display: "none" } : {}}>
                        <Form.Item
                          name="bp"
                          label="Blood Pressure"
                          rules={[
                            {
                              required: vital.required,
                              type: "number",
                              min: vital.min,
                              max: vital.max,
                              transform: function (value) {
                                return value === "" || value === undefined ? value : +value;
                              },
                            },
                          ]}
                        >
                          <div className="multi-input-col-inner">
                            <div className="ant-input-wrapper ant-input-group">
                              <div className="ant-input">
                                <Input
                                  name="V006"
                                  data-vitalid="6"
                                  tabIndex="5"
                                  autoComplete="off"
                                  maxLength={vital.maxlength}
                                  min={vital.min}
                                  max={vital.max}
                                  value={this.state.V006}
                                  onChange={(e) => {
                                    this.handleOnChangeNumbers(e, vital.min, vital.max, vital.decimal_value);
                                  }}
                                />
                                <Input
                                  name="V005"
                                  data-vitalid="5"
                                  tabIndex="6"
                                  autoComplete="off"
                                  maxLength={vital.maxlength}
                                  min={vital.min}
                                  max={vital.max}
                                  className="multi"
                                  value={this.state.V005}
                                  onChange={(e) => {
                                    this.handleOnChangeNumbers(e, vital.min, vital.max, vital.decimal_value);
                                  }}
                                />
                              </div>

                              <span className="ant-input-group-addon">mmHg</span>
                            </div>
                          </div>
                        </Form.Item>
                      </div>
                    );
                  else
                    return (
                      <>
                        <div
                          className="col-4 antInputItem"
                          style={!this.state.displayVitals.includes("" + vital.vital_id) ? { display: "none" } : {}}
                        >
                          <Form.Item
                            name={vital.id}
                            label={vital.display_name}
                            rules={[
                              {
                                required: vital.required,
                                type: "number",
                                min: vital.min,
                                max: vital.max,
                                transform: function (value) {
                                  return value === "" || value === undefined || value === null ? '' : +value;
                                },
                              },
                            ]}
                          >
                            <Input
                              addonAfter={vital.display_value}
                              name={vital.id}
                              autoComplete="off"
                              maxLength={vital.maxlength}
                              data-vitalid={vital.vital_id}
                              tabIndex={tabIndx}
                              value={this.state[vital.id]}
                              onChange={(e) => {
                                this.handleOnChangeNumbers(e, vital.min, vital.max, vital.decimal_value);
                              }}
                              disabled={vital.id == "V003"}
                            />
                          </Form.Item>
                        </div>
                      </>
                    );
                })
                : null}
            </div>
            {/* New Form Added in HTML */}
            {this.state.keyFindingsFiltersLoaded ? this.getKeyFindingFFormItem() : null}
            {/* New Form Added in HTML End */}
          </Form>
        </Modal>

        {/* || FILTERS & SEQUENCING */}
        {this.state.keyFindingsFiltersLoaded ? (
          <Modal
            title={"Parameters"}
            destroyOnClose={true}
            visible={this.state.chartParamerModel}
            onCancel={async (e) => {
              this.handleModalPopup(e, "chartParamerModel");
            }}
            style={{ top: "40px" }}
            width="910px"
            footer={false}
            closable={true}
          >
            {this.state.constants && (
              <KeyFindingsFilters
                keyFindingsConstants={this.state.constants.findingGroupData}
                handleModalPopup={this.handleModalPopup}
                updateParentState={this.handleKeyFindingsUpdate}
              />
            )}
          </Modal>
        ) : null}

        {/* || DELETE MODAL */}
        <Modal
          visible={this.state.deleteModal}
          onCancel={(e) => this.handleModalPopup(e, "deleteModal")}
          style={{ top: 40 }}
          footer={[
            <div className="text-center">
              <button className="btn btn-outline-secondary px-5  mr-2 cancel-delete-history" onClick={(e) => this.handleModalPopup(e, "deleteModal")}>
                CANCEL
              </button>
              <button
                className="btn btn-primary px-5 submit-delete-history"
                disabled={this.state.spin_loading}
                onClick={(e) => this.handleDeletePopSubmit(e)}
              >
                DELETE{this.state.spin_loading ? LOADER : ""}
              </button>
            </div>,
          ]}
        >
          <div className="row">
            <div className="col-12 my-3 text-center confirm-message">
              <h6>Are you sure you want to delete {this.state.delete_text}?</h6>
            </div>
          </div>
        </Modal>

        {/* || CHART MODAL */}
        {this.state.chartModal ? (
          <Modal
            // title={`${this.state.chartsType === 'all' ? 'Vital' : this.state.chartsType} Graphs`}
            title={this.state.chartHeading}
            visible={this.state.chartModal}
            onCancel={(e) => this.handleModalPopup(e, "chartModal")}
            style={{
              top: "40px",
              maxWidth: this.state.chartsType === "all" ? "90%" : "600px",
            }}
            width="100%"
            className={`${this.state.chartsType === "all" ? "all_vital" : "single_vital"}`}
            bodyStyle={{ maxHeight: "700px", overflowY: "auto", overflowX: "hidden" }}
            footer={
              this.state.chartsType === "all" ? (
                <div className="text-center justify-content-center">
                  <button type="submit" className="btn btn-primary text-uppercase ml-2" onClick={(e) => this.handleModalPopup(e, "chartModal")}>
                    Confirm
                  </button>
                </div>
              ) : null
            }
          >
            <div
              className={`col wrap_chart_filter d-flex justify-content-around align-items-center ${this.state.chartsType !== "all" ? "wrap_chart_filter_small" : ""
                }`}
            >
              <ul className="chart_filter">
                <li>
                  <button
                    type="button"
                    className={`btn btn-default chart-day ${this.state.chartsTab === "0" ? "active" : ""}`}
                    onClick={(e) => this.handleGraphClick(e, "0")}
                  >
                    <span>DAY</span>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className={`btn btn-default chart-week ${this.state.chartsTab === "1" ? "active" : ""}`}
                    onClick={(e) => this.handleGraphClick(e, "1")}
                  >
                    <span>  WEEK</span>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className={`btn btn-default chart-month ${this.state.chartsTab === "2" ? "active" : ""}`}
                    onClick={(e) => this.handleGraphClick(e, "2")}
                  >
                    <span>  MONTH </span>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className={`btn btn-default chart-year ${this.state.chartsTab === "3" ? "active" : ""}`}
                    onClick={(e) => this.handleGraphClick(e, "3")}
                  >
                    <span>  YEAR </span>
                  </button>
                </li>
              </ul>

              {this.state.chartsType === "all" && (
                <RangePicker
                  allowClear={true}
                  value={this.state.range}
                  onChange={this.handleRangeChange}
                  disabledDate={DISABLED_FUTURE_DATES}
                  format="DD/MM/YYYY"
                />
              )}
            </div>

            {this.state.chartDataLoaded ? (
              <Chart
                chartData={this.state.chartData}
                bloodSugarChartData={this.state.bloodSugarChartData}
                vitalsRangeWithId={this.state.vitalsRangeWithId}
                dataLoaded={this.state.chartDataLoaded}
                chartsType={this.state.chartsType}
                reference_id={this.state.patient.qms_token_id}
                chartGraphSetting={this.state.chartGraphSettings}
                chartsTab={this.state.chartsTab}
                constants={this.state.constants}
                updateData={this.chartStateUpdate}
                checkBoxDisable={!this.state.allowEdit}
                isNurseLoggined={this.state.isNurseLoggined}
              />
            ) : null}
          </Modal>
        ) : null}

        {/* || KEY FINDINGS CHART MODAL */}
        {this.state.keyFindingChartModal && this.state.keyFindingsFiltersLoaded ? (
          <Modal
            destroyOnClose={true}
            // title={`${this.state.chartsType === 'all' ? 'Vital' : this.state.chartsType} Graphs`}
            title={this.state.keyFindingsChartHeading}
            visible={this.state.keyFindingChartModal}
            onCancel={(e) => this.handleModalPopup(e, "keyFindingChartModal")}
            style={{
              top: "40px",
              maxWidth: this.state.keyFindingsChartsType === "all" || this.state.keyFindingsChartsType === "finding_group" ? "90%" : "600px",
            }}
            width="100%"
            className={`${this.state.keyFindingsChartsType === "all" || this.state.keyFindingsChartsType === "finding_group" ? "all_vital" : "single_vital"
              }`}
            bodyStyle={{ maxHeight: "700px", overflowY: "auto" }}
            footer={
              this.state.keyFindingsChartsType === "all" || this.state.keyFindingsChartsType === "finding_group" ? (
                <div className="text-center justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-primary text-uppercase ml-2"
                    onClick={(e) => this.handleModalPopup(e, "keyFindingChartModal")}
                  >
                    Confirm
                  </button>
                </div>
              ) : null
            }
          >
            <div
              className={`col wrap_chart_filter d-flex justify-content-around align-items-center ${this.state.keyFindingsChartsType !== "all" && this.state.keyFindingsChartsType !== "finding_group" ? "wrap_chart_filter_small" : ""
                }`}
            >
              <ul className="chart_filter">
                <li>
                  <button
                    type="button"
                    className={`btn btn-default chart-day ${this.state.keyFindingsChartsTab === "0" ? "active" : ""}`}
                    onClick={(e) => this.handleKeyGraphClick(e, "0")}
                  >
                    DAY
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className={`btn btn-default chart-week ${this.state.keyFindingsChartsTab === "1" ? "active" : ""}`}
                    onClick={(e) => this.handleKeyGraphClick(e, "1")}
                  >
                    WEEK
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className={`btn btn-default chart-month ${this.state.keyFindingsChartsTab === "2" ? "active" : ""}`}
                    onClick={(e) => this.handleKeyGraphClick(e, "2")}
                  >
                    MONTH
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className={`btn btn-default chart-year ${this.state.keyFindingsChartsTab === "3" ? "active" : ""}`}
                    onClick={(e) => this.handleKeyGraphClick(e, "3")}
                  >
                    YEAR
                  </button>
                </li>
              </ul>

              {(this.state.keyFindingsChartsType === "all" || this.state.keyFindingsChartsType === "finding_group") && (
                <RangePicker
                  allowClear={true}
                  value={this.state.key_findings_range}
                  onChange={this.handleKeyFindingsRangeChange}
                  disabledDate={DISABLED_FUTURE_DATES}
                  format="DD/MM/YYYY"
                />
              )}
            </div>

            {this.state.keyFindingsChartDataLoaded ? (
              <KeyFindingsChart
                chartData={this.state.keyFindingsChartData}
                dataLoaded={this.state.keyFindingsChartDataLoaded}
                keyFindingsOrder={this.state.keyFindings}
                chartsType={this.state.keyFindingsChartsType}
                chartsId={this.state.keyFindingsChartId}
                reference_id={this.state.patient.qms_token_id}
                chartGraphSetting={this.state.keyFindingsChartGraphSettings}
                chartsTab={this.state.keyFindingsChartsTab}
                constants={this.state.constants}
                updateData={this.chartStateUpdateKeyFindigs}
                checkBoxDisable={!this.state.allowEdit}
                isNurseLoggined={this.state.isNurseLoggined}
              />
            ) : null}
          </Modal>
        ) : null}

        {/* || ALLERGY ALREADY EXIST MODAL */}
        <Modal
          visible={this.state.allergyAlreadyExistModal}
          onCancel={(e) => this.handleModalPopup(e, "allergyAlreadyExistModal")}
          style={{ top: 40 }}
          destroyOnClose={true}
          footer={[
            <div className="text-center">
              <button
                className="btn btn-outline-secondary px-5 cancel-delete-history"
                onClick={(e) => this.handleModalPopup(e, "allergyAlreadyExistModal")}
              >
                NO
              </button>
              <button
                className="btn btn-primary px-5 submit-delete-history"
                disabled={this.state.spin_loading}
                onClick={(e) => this.handleModalPopup(e, "allergyAlreadyExistModal", allergy_selected_data)}
              >
                YES{this.state.spin_loading ? LOADER : ""}
              </button>
            </div>,
          ]}
        >
          <div className="row">
            <div className="col-12 my-3 text-center confirm-message">
              <h6>Allergy to {this.state.allergyAlreadyExistName} already added. Would you like to replace?</h6>
            </div>
          </div>
        </Modal>

        {/* NO DATA ADDED WARNING MODAL */}
        <Modal
          visible={this.state.noDataModal}
          onCancel={(e) => this.handleModalPopup(e, "noDataModal")}
          style={{ top: 40 }}
          footer={[
            <div className="text-center">
              <button className="btn btn-outline-secondary px-5 cancel-delete-history mr-2" onClick={(e) => this.handleModalPopup(e, "noDataModal")}>
                NO
              </button>
              <button
                className="btn btn-primary px-5 submit-delete-history"
                disabled={this.state.spin_loading}
                onClick={(e) => this.handleObjectiveContinue()}
              >
                YES{this.state.spin_loading ? LOADER : ""}
              </button>
            </div>,
          ]}
        >
          <div className="row">
            <div className="col-12 my-3 text-center">
              <h6>{this.state.noDataModalText}</h6>
            </div>
          </div>
        </Modal>

        {/** || isPracticing Modal */}
        <Modal title={false} closable={false} visible={this.state.isPracticingModal} footer={false}>
          <div className="row">
            <div className="col-12 my-3 text-center">
              <h6>Do you want to Start Practice?</h6>
            </div>
          </div>
          <div className="modal-footer justify-content-center">
            <div className="btn btn-outline-secondary px-5" onClick={(e) => this.setState({ isPracticingModal: false })}>
              No
            </div>
            <div className="btn btn-primary px-5" onClick={this.enablePractice}>
              Yes
            </div>
          </div>
        </Modal>

        {/** || Continue Modal for nurse */}
        <Modal title={false} closable={false} visible={this.state.continueModalForNurse} footer={false}>
          <div className="row">
            <div className="col-12 my-3 text-center">
              <h6>Do you want to add history for this patient?</h6>
            </div>
          </div>
          <div className="modal-footer justify-content-center">
            <div className="btn btn-outline-secondary px-5" onClick={(e) => this.continueActionForNurse("no")}>
              No
            </div>
            <div className="btn btn-primary px-5" onClick={(e) => this.continueActionForNurse("yes")}>
              Yes
            </div>
          </div>
        </Modal>

        {/* ************************* ALERGY MODEL POPUP FOR CIMS * ************************* */}
					<Modal className="myDrugsInteraction_modal" id="myDrugsInteraction"
					title="Allergy Interaction"
					visible={this.state.isModalInteractionVisible}
					onOk={() => this.handleInteractionOk()}
					onCancel={() => this.handleInteractionCancel()}
					footer={false}
					style={{width:1000}}
					zIndex={9999}
					>
					<iframe id="drug_interaction_widget_iframe2" title="interaction" className="cimsiframe" src={`${CIMS_ALLERGY_INTERACTION}?drugIds=${this.state.drug_id_Interaction_Modalurl}&referenceId=${this.state.referenceId}&referenceType=${this.state.referenceType}&enterpriseId=${this.state.enterpriseId}&FacilityId=${this.state.FacilityId}&StoreId=${this.state.StoreId}&patientId=${this.state.patientId}&orederSetId=${this.state.orederSetId}&careProtoColId=${0}&medicineScreen=${this.state.medicineScreen}&addedAssessmentList=${this.state.addedAssessmentList}`}></iframe>
					<div className="modal-footer justify-content-center emrfrm pb-0">
						<button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handleInteractionCancel(e)}>DON’T PROCEED</button>
						<button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.handleInteractionOk(e)}>PROCEED TO ADD ALLERGY</button>
					</div>
					</Modal>
      </>
    );
  }
}

export default ObjectiveComponent;
