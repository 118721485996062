import React, { Component } from 'react';
import Axios from 'axios';
import LS_SERVICE from '../../utils/localStorage';
import {
    LOADER_RED,
    LOADER,
    SUPPORTING_MATERIAL,
    FETCH_SUPPORTING_MATERIAL,
    DELETE_SUPPORTING_MATERIAL
} from '../../utils/constant';
import { notification, Modal, Upload, Button } from 'antd';

class SupportingMaterialFormSS extends Component {

    constructor(props) {
        super(props)
        this.state = {
            materialList: (props.allSupportingMaterial.length > 0) ? props.allSupportingMaterial : [],
            isFilesUploaded: false,
            pdfPath: "",
            fileType: "",
            showPdfModal: false,
            deleteConfirmModal: false,
            isMaterialEmpty: false,
            showSupportingLoader: false
        }
        this.deleteButton = React.createRef();
        this.SupportingRef = React.createRef();
    }

    static getDerivedStateFromProps() {
        Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
        return true;
    }

    componentDidMount() {
        if (this.props.allSupportingMaterial.length === 0) {
            this.fetchSupportingMaterial();
        }
    }

    handleModalPopup = (e, popup) => {

        if (e !== null) e.preventDefault();

        this.setState({
            [popup]: !this.state[popup]
        })
    }

    fetchSupportingMaterial = () => {
        let formType = this.props.formType;
        var fetchUrl = `${FETCH_SUPPORTING_MATERIAL}?formType=${formType}`;
        if (formType == "template" && this.props.isEdit == 'edit') {
            fetchUrl = `${FETCH_SUPPORTING_MATERIAL}?formType=${formType}&templateId=${this.props.templateId}`;
        }
        if (formType == "order-set" && this.props.isEdit == 'edit') {
            fetchUrl = `${FETCH_SUPPORTING_MATERIAL}?formType=${formType}&orderSetId=${this.props.orderSetId}`;
        }
        if (formType == "all") {
            fetchUrl = `${FETCH_SUPPORTING_MATERIAL}?formType=${formType}&referenceId=${this.props.referenceId}`;
        }
        if (formType == 'template' || formType == 'order-set') {
            if (this.state.materialList.length == 0 && this.state.isMaterialEmpty) {
                return;
            }
        }
        this.setState({ showSupportingLoader: true });
        Axios.get(fetchUrl)
            .then(
                response => {
                    let filesData = response.data.supportingFiles;
                    if (response.status) {
                        this.setState({ showSupportingLoader: false });
                        if ((formType == 'template' || formType == 'order-set')) {
                            if (this.props.supportingMaterial.length == 0 && this.props.isEdit !== 'edit') {
                                filesData = filesData.map((obj, index) => {
                                    return { ...obj, selected: false };
                                });
                            }
                            else {
                                this.props.supportingMaterial.map(
                                    file => {
                                        filesData = filesData.map(
                                            obj => {
                                                if (formType == 'template') {
                                                    if (file.id == obj.id && file.templateId == this.props.templateId) {
                                                        return { ...obj, selected: true }
                                                    }
                                                    else {
                                                        return { ...obj }
                                                    }
                                                }

                                                if (formType == 'order-set') {
                                                    if (file.id == obj.id && file.orderSetId == this.props.orderSetId) {
                                                        return { ...obj, selected: true }
                                                    }
                                                    else {
                                                        return { ...obj }
                                                    }
                                                }

                                            }
                                        )
                                    }
                                )
                            }
                        }
                        this.setState({
                            materialList: filesData,
                            isMaterialEmpty: this.state.materialList.length == 0 ? false : true,
                        });
                        this.props.setMaterialData(this.state.materialList)
                    }
                }
            )
    }

    handleFileRequest = ({ file }) => {
        const doctor_id = LS_SERVICE.get('staff_id');
        let fileData = file;
        let data = new FormData();
        if (this.props.hospitalId) {
            data.append('hospital_id', this.props.hospitalId);
        }
        data.append('doctor_id', doctor_id);
        data.append('file', fileData);
        this.setState({ isFilesUploaded: true });
        Axios.post(SUPPORTING_MATERIAL, data).then(response => {
            if (response.data.status) {
                this.fetchSupportingMaterial();
                this.setState({ isFilesUploaded: false, isMaterialEmpty: false });
            } else {
                notification.error({
                    message: `File(s) upload failed!`,
                    placement: 'topRight'
                })
            }
        });
    };

    handleSupportingFileClick = (e, index) => {
        const { checked } = e.currentTarget;
        const { materialList } = this.state;
        materialList[index].selected = checked;
        this.setState(
            materialList
        );
        this.props.updateSupportingMaterial(materialList);
    }

    openSupportingFile(e, file_url, file_name) {
        const splitArray = file_name.split('.');
        const extension = splitArray[splitArray.length - 1];
        this.setState({
            pdfPath: file_url,
            fileType: extension,
            showPdfModal: true
        });
    }

    validateUploadFiles(file) {
        const splitArray = file.name.split('.');
        const extension = splitArray[splitArray.length - 1];
        let isValidType = extension === 'pdf' || extension === 'jpeg' || extension === 'png' || extension === 'jpg';
        if (!isValidType) {
            notification.error({
                message: `Supported Formats:jpg,jpeg,png,pdf`,
                placement: 'topRight'
            })
            return isValidType;
        }
        // let fileSize = (file.size / (Math.pow(10, 6)));
        // let maxSize = 5;
        // let checkCondition = !isValidType || (fileSize > maxSize);
        // if (checkCondition) {
        //     if (!isValidType) {
        //         notification.error({
        //             message: `Only Pdf and Images are allowed!`,
        //             placement: 'topRight'
        //         })
        //     }

        //     if ((fileSize > maxSize)) {
        //         notification.error({
        //             message: `Can't upload file of size larger than 5 Mb`,
        //             placement: 'topRight'
        //         })
        //     }

        // }
        // return isValidType && !(fileSize > maxSize);
    }

    handleEditDelete = (e, modal, data, action, idx) => {
        this.handleDeleteModalPopup(e, modal, data, action, idx);
    }

    handleDeleteModalPopup = (e, popupName, data = null, actionType = "", objId = "", patientDocs) => {
        if (e !== null) e.preventDefault();
        this.setState(
            {
                [popupName]: !this.state[popupName],
            },
            () => {
                const { viewDocumentModal } = this.state;
                this.setState(
                    {
                        actionText: "Add",
                        editData: null,
                        editIndex: -1,
                    },
                    () => {
                        const { deleteConfirmModal } = this.state;
                        if (deleteConfirmModal) {
                            // For Supporting Materials
                            if (actionType === "deleteSupportingMaterials") {
                                this.setState({ deleteActionType: actionType, deleteObjId: objId, deleteMessage: "Are you sure you want to delete file?" });
                                this.deleteButton.current.focus();
                            }
                        }
                    }
                );
            }
        );
    };

    handleOnItemDelete = async (e, actionType, objId) => {
        console.log(e, actionType, objId)
        e.preventDefault();
        var stateVariable = null;
        var stateAddedVariable = null;
        var propKey = null;
        var deleteUrl = null;
        // For Supporting Material
        if (actionType === "deleteSupportingMaterials") {
            stateVariable = "materialList";
            stateAddedVariable = "materialList";
            propKey = "id";
            deleteUrl = DELETE_SUPPORTING_MATERIAL;
        }
        console.log(this.state.materialList);
        let dataArray = this.state[stateVariable];
        var deleteObj = dataArray[objId];
        var deleteId = deleteObj.id;
        var doctorId = deleteObj.doctorId;
        this.setState({
            spin_loading: true,
            deleteConfirmModal: false
        });
        console.log({
            file_id: deleteId, doctor_id: doctorId, formType: this.props.formType
        })
        const supportingDelPromise = await Axios.post(deleteUrl({ file_id: deleteId, doctor_id: doctorId, formType: this.props.formType }))
        if (supportingDelPromise.data) {
            this.fetchSupportingMaterial()
            notification.success({
                message: supportingDelPromise.data.message,
                placement: 'topRight'
            })
            let removedSupportingFile = this.state.materialList.filter(function (ele, i) {
                return i !== objId;
            });
            this.setState({
                materialList: removedSupportingFile, spin_loading: false,
            })
        }
    };

    render() {
        if(this.state.materialList.filter(file => file.selected)?.length > 0){
            if (this.SupportingRef?.current?.classList.contains("collapsed")) {
               this.SupportingRef?.current?.click();
            }
           }
        const { patient } = this.state;
        const { formType } = this.props;
        return (
            <>
                <div className="accordion soap_accordion py-2 mb-0" id="accordionExample">
                    <div className="card supportmaterial-card mb-0" id="med_scroll">
                        <div className="card-header supportmaterial-card-head p-0" id="support-accordion">
                            <button ref={this.SupportingRef} className={this.state?.materialList?.length > 0 ? "btn btn-block text-left collapsed arrow float-right mb-0 custom-card-pink" : "btn  btn-block text-left collapsed float-right mb-0 custom-card-pink"} type="button" data-toggle="collapse" data-target="#collapseSupporting" aria-expanded="false" aria-controls="collapseSupporting">
                                <div className='row'>
                                    <div className='col-3 d-flex align-self-center'>
                                        <h6 className='mb-0'>SUPPORTING MATERIAL</h6>
                                    </div>
                                    <div className='col-9 text-right pr-5'>
                                        <Upload
                                        name='file'
                                        multiple={true} progress={false} showUploadList={false}
                                        beforeUpload={(file) => this.validateUploadFiles(file)}
                                        accept=".pdf, .jpeg, .jpg, .png"
                                        customRequest={this.handleFileRequest}
                                        >
                                            {this.state.isFilesUploaded ?
                                                <Button disabled className="btn btn-outline-primary small" >
                                                    UPLOADING {LOADER}
                                                </Button>
                                                :
                                                <Button className="btn btn-outline-primary small px-2">
                                                    + UPLOAD
                                                </Button>
                                            }
                                        </Upload>
                                    </div>
                                </div>
                            </button>
                        </div>
                        {
                           this.state.materialList?.length > 0
                        ?
                            <div id="collapseSupporting" className="collapse" aria-labelledby="support-accordion" >
                                <div className="card-body supportmaterial_body p-0">
                                    <div className='container-fluid support-material_container'>
                                    <div className="row instructions_rows">
                                    {
                                        !this.state.showSupportingLoader ?
                                            (this.state.materialList.map((element, i) => {
                                                return (
                                                    <div className="row col-md-6 d-flex align-items-center" key={i}>
                                                        <div className="custom-control custom-checkbox ml-2">
                                                            <input type="checkbox" className="custom-control-input"
                                                                id={`supporting_file_${element.id}`} name="supporting"
                                                                checked={element.selected}
                                                                onChange={e => this.handleSupportingFileClick(e, i)}
                                                            />
                                                            <label className="custom-control-label" htmlFor={`supporting_file_${element.id}`}>&nbsp;</label>
                                                        </div>

                                                        <div className="instructions_text" style={{wordWrap: 'break-word', display: 'inline-block', width: '80%', textDecoration:'underline'}}>
                                                            <a href="javascript:void(0)" onClick={e => this.openSupportingFile(e, element.fileUrl, element.fileName)} className="nostyle-link remove-list remove-instruction text-primary"
                                                            >
                                                                {element.fileName}
                                                            </a>
                                                        </div>
                                                        <div className="col action text-right pt-1 pr-3">
                                                            <a href="javascript:void(0)" className="nostyle-link remove-list remove-instruction"
                                                            >
                                                                <i className="icon_delete" onClick={(e) => this.handleEditDelete(e, 'deleteConfirmModal', element, 'deleteSupportingMaterials', i)}></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                )
                                            })) :
                                            <div className={`w-100 align-items-center justify-content-center mt-4 ${this.state.showSupportingLoader ? 'd-flex' : 'd-none'}`}>
                                                {LOADER_RED}
                                            </div>
                                    }
                                    </div>
                                    </div>
                                </div>
                            </div>
                        :
                            null
                        }
                    </div>
                </div>

                {/* For Viewing  Supporting Material */}
                <Modal
                    title="View Supporting Material"
                    visible={this.state.showPdfModal}
                    footer={false}
                    width={850}
                    onCancel={() => { this.setState({ showPdfModal: false, pdfPath: null }) }}
                    style={{ top: 20 }}
                >
                    <div className="row">
                        <div className="col-12 my-3 text-center">
                            {this.state.pdfPath != null && this.state.fileType == 'pdf' ? <iframe src={this.state.pdfPath} frameBorder="0" scrolling="no" style={{ overflow: 'hidden' }} width="100%" height="700">
                                {LOADER_RED}
                            </iframe> : null}
                            {this.state.pdfPath != null && this.state.fileType != 'pdf' ?
                                <img src={this.state.pdfPath} alt={this.state.pdfPath} style={{ width: '100%' }} />
                                : null}
                        </div>
                    </div>
                </Modal>

                {/* For Modal Delete Confirm  */}
                <Modal
                    title={false}
                    visible={this.state.deleteConfirmModal}
                    onCancel={(e) => this.handleDeleteModalPopup(e, "deleteConfirmModal")}
                    footer={false}
                    closable={false}
                >
                    <div className="row mb-3">
                        <h6 className="col-12 text-center">{this.state.deleteMessage}</h6>
                    </div>
                    <div className="modal-footer justify-content-center emrfrm pb-0">
                        <button type="button" className="btn btn-outline-secondary px-5  mr-2" onClick={(e) => this.handleDeleteModalPopup(e, "deleteConfirmModal")}>
                            CANCEL
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary px-5"
                            ref={this.deleteButton}
                            onClick={(e) => this.handleOnItemDelete(e, this.state.deleteActionType, this.state.deleteObjId)}
                        >
                            DELETE
                        </button>
                    </div>
                </Modal>
            </>
        )
    }
}

export default SupportingMaterialFormSS;