import React, { Component } from 'react';
import { HOSPITAL_NAME_LIST, PATIENT_DETAIL_LIST, DOCTOR_NAME_LIST} from '../../utils/constant';
import { Select, Form, Modal, Input, Pagination, Button } from 'antd';
import Axios from 'axios';
import moment from "moment";
import LS_SERVICE from '../../utils/localStorage';
const { Option } = Select;
class completedVisit extends Component {
  state = {
    patient_uhid: '',
    booking_code: '',
    hospital: '',
    doctor: '',
    loading: false,
  }

  constructor(props) {
    super(props);
    this.appointmentDetailsModalClose = this.appointmentDetailsModalClose.bind(this);
    this.myRef = React.createRef();
    this.search = this.search.bind(this);
    this.state = {
      hospitalName: [],
      doctorName: [],
      allAppointmentDetails: [],
      page: 1,
      appointmentDetailsModalShow: false,
      thisAppointmentDetails: {},
    }
  }

  componentDidMount() {
    this.handleHospitalNameDropdown();
    this.handleDoctorNameDropdown();
    this.handleAppointmentDetailList(1, {});
  }

  static getDerivedStateFromProps() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    return true;
  }

  // List of Doctors
  async handleDoctorNameDropdown() {
    try {
      let doctorData = await Axios.get(DOCTOR_NAME_LIST);
      this.setState({
        doctorName: doctorData.data.data,
      });
    }
    catch (err) {
      console.log(err)
    };
  }

  // List of Hospitals
  async handleHospitalNameDropdown() {
    try {
      let hospitalData = await Axios.get(HOSPITAL_NAME_LIST);
      this.setState({
        hospitalName: hospitalData.data.data,
      });
    }
    catch (err) {
      console.log(err)
    };
  }

  // Fetch appointment Details
  async handleAppointmentDetailList(page, data) {
    try {
      this.setState({ page });
      if(!data){
        data={
          page: page
        }
      }
      let Url = PATIENT_DETAIL_LIST;
      data.limit = 100;
      data.skip = data.limit*(page-1)
      let patientData = await Axios.post(Url, data);
      this.setState({
        allAppointmentDetails: patientData.data.data != undefined ? patientData.data.data : [],
        sampleCounts: patientData.data.count,
      });
    }
    catch (err) {
      console.log(err)
    };
  }

  // Perform filter search
  search(values) {
    this.handleAppointmentDetailList(1, values);
  }

  // Open appointment details popup
  appointmentDetailsModalOpen = async (thisAppointment) => {
    await this.setState({
      appointmentDetailsModalShow: true,
      thisAppointmentDetails: thisAppointment
    });
  };

  // Close appointment details popup
  appointmentDetailsModalClose(e) {
    try {
      this.setState({
        appointmentDetailsModalShow: false,
        thisAppointmentDetails: {}
      });
    } catch (error) {
      console.log(error)
    }
  };

  render() {
    const { sampleCounts, hospitalName, doctorName, allAppointmentDetails, thisAppointmentDetails } = this.state;
    return (
      <>
        <div className="container-fluid">
          <Form
            ref={this.myRef}
            layout="vertical"
            className="mt-3 emrfrm"
            colon={false}
            onFinish={this.search.bind(this)}
            initialValues={this.state.initialValues}>
            <div className="row mt-3">
              <div className="col-2">
                <div className="antInputItem position-relative hasdata">
                  <Form.Item
                    name="patient_uhid"
                    label="Patient UHID"
                    id="patient_uhid"
                  >
                    <Input
                      defaultValue={this.state.patient_uhid}
                      value={this.state.patient_uhid}
                      autoComplete='off'
                      className="select-diagnosis"
                      maxLength={50}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="col-2">
                <div className="antInputItem position-relative hasdata">
                  <Form.Item
                    name="booking_code"
                    label="Booking Code"
                    id="booking_code"
                  >
                    <Input
                      defaultValue={this.state.booking_code}
                      value={this.state.booking_code}
                      autoComplete='off'
                      className="select-diagnosis"
                      maxLength={30}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="col-3">
                <div className="antInputItem position-relative hasdata">
                  <Form.Item
                    name="hospital"
                    label="Hospital"
                    id="hospital"
                    rules={[
                    ]}
                  >
                    <Select
                      defaultValue={this.state.hospital}
                      value={this.state.hospital}
                      showSearch
                      className="select-diagnosis"
                      filterOption={false}
                    >
                      {hospitalName.map(function (hospitalName, index) {
                        return <Option key={index} value={hospitalName.hospital_id}>{hospitalName.hospital_name}</Option>
                      })
                      }
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="col-3">
                <div className="antInputItem position-relative hasdata">
                  <Form.Item
                    name="doctor"
                    label="Doctor"
                    id="doctor"
                    rules={[
                    ]}
                  >
                    <Select
                      defaultValue={this.state.doctor}
                      value={this.state.doctor}
                      showSearch
                      className="select-diagnosis"
                      filterOption={false}
                    >
                      {doctorName.map(function (doctorName, index) {
                        return <Option key={index} value={doctorName.doctor_id}>{doctorName.doctor_name}</Option>
                      })
                      }
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="col-2">
                <input type="hidden" value="1" id="role_id" autoComplete="off" />
                <button disabled={this.state.loading} type="submit" className="btn btn-primary px-5 btn-fit-height" id="search-submit">SEARCH</button>
              </div>
            </div>
            <div className="row">
              <div className="col-12 px-3">
                <hr />
              </div>
            </div>
          </Form>
          <React.Fragment>
            <table
              id='Test_Method_Table'
              className="table table-bordered table-hover"
              action=""
              method="get"
            >
              <thead>
                <tr >
                  <th className='col-auto text-left'>#</th>
                  <th className='col-auto text-left'>Booking Code</th>
                  <th className='col-auto text-left' >Consult Type</th>
                  <th className='col-auto text-left' >Patient UHID</th>
                  <th className='col-auto text-left'>Patient Name</th>
                  <th className='col-auto text-left'>Hospital Name</th>
                  <th className='col-auto text-left'>Doctor Name</th>
                  <th className='col-auto text-left' >EMR Type</th>
                  <th className='col-auto text-left' >Completion Type</th>
                  <th className='col-auto text-left'>ACTION</th>
                </tr>
              </thead>
              <tbody className="">
                {
                  allAppointmentDetails.map((thisAppointment, index) =>
                    <tr key={index} >
                      <td>{((100*(this.state.page-1))+index+1)}</td>
                      <td>{thisAppointment.booking_code}</td>
                      <td>{thisAppointment.consult_type}</td>
                      <td>{thisAppointment.patient_uhid}</td>
                      <td>{thisAppointment.patient_name}</td>
                      <td>{thisAppointment.hospital_name}</td>
                      <td>{thisAppointment.doctor_name}</td>
                      <td>{thisAppointment.type}</td>
                      <td>{thisAppointment.completion_method}</td>
                      <td style={{ textAlign: 'center' }}>
                        <Button type="link p-0" className="nostyle-link view_order_set mr-2" onClick={(e) => this.appointmentDetailsModalOpen(thisAppointment)}>
                          <i className="icon_view"></i>
                        </Button>
                        {
                          thisAppointment?.prescription_url && thisAppointment?.prescription_url != '' && thisAppointment?.prescription_url != null
                        ?
                          <a target="_blank" href={thisAppointment?.prescription_url}>
                            <i className="icon-pdf-small"></i>
                          </a>
                        :
                          null
                        }
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
            <Pagination className='col-12 text-right'
              total={sampleCounts}
              onChange={(e) => this.handleAppointmentDetailList(e)}
              pageSize={100}
              defaultCurrent={1}
            />
          </React.Fragment>
          <Modal
            title="APPOINTMENT DETAIL"
            visible={this.state.appointmentDetailsModalShow}
            onCancel={this.appointmentDetailsModalClose}
            width={1700}
            footer={false}
          >

            {/* Appointment Basic details */}
            <div className="row">
              <div className='col-3'><strong>Booking Code: </strong>{thisAppointmentDetails?.booking_code}</div>
              <div className='col-3'><strong>Patient (Name / UHID / ID) </strong>{thisAppointmentDetails?.patient_name} / {thisAppointmentDetails?.patient_uhid && thisAppointmentDetails?.patient_uhid != null ? thisAppointmentDetails?.patient_uhid : '-'} / {thisAppointmentDetails?.patient_id}</div>
              <div className='col-3'><strong>Doctor (Name / ID): </strong>{thisAppointmentDetails?.doctor_name} / {thisAppointmentDetails?.doctor_id}</div>
              <div className='col-3'><strong>Hospital (Name / ID): </strong>{thisAppointmentDetails?.hospital_name} / {thisAppointmentDetails?.hospital_id}</div>
            </div>

            <div className="row mt-3">
              <div className='col-3'><strong>Appointment Date/Time: </strong>{moment(thisAppointmentDetails?.appointment_date_time).format('DD MMM YYYY | h:mm A')}</div>
              <div className='col-3'><strong>Completion Method: </strong>{thisAppointmentDetails?.completion_method}</div>
              <div className='col-3'><strong>Reference (ID / Type): </strong>{thisAppointmentDetails?.reference_id} / {thisAppointmentDetails?.reference_type}</div>
              <div className='col-3'><strong>Consult Type: </strong>{thisAppointmentDetails?.consult_type}</div>
            </div>

            {/* Basic details */}
            {
              thisAppointmentDetails?.basic_details?.symptom_diagnosis && thisAppointmentDetails?.basic_details?.symptom_diagnosis != '' && thisAppointmentDetails?.basic_details?.symptom_diagnosis != null
            ?
              <div className="row mt-3">
                <div className='col-12 text-bold'>
                  Symptoms & Diagnosis
                </div>
                <div className='col-12 text-left'>
                  <div class="card rounded-0">
                    <div class="card-body py-3">
                      <pre>{thisAppointmentDetails?.basic_details?.symptom_diagnosis}</pre>
                    </div>
                  </div>
                </div>
              </div>
            :
              null
            }
            {
              thisAppointmentDetails?.basic_details?.medicine_test && thisAppointmentDetails?.basic_details?.medicine_test != '' && thisAppointmentDetails?.basic_details?.medicine_test != null
            ?
              <div className="row mt-3">
                <div className='col-12 text-bold'>
                  Medicines and Tests advised
                </div>
                <div className='col-12 text-left'>
                  <div class="card rounded-0">
                    <div class="card-body py-3">
                      <pre>{thisAppointmentDetails?.basic_details?.medicine_test}</pre>
                    </div>
                  </div>
                </div>
              </div>
            :
              null
            }
            {
              thisAppointmentDetails?.basic_details?.tests_adviced && thisAppointmentDetails?.basic_details?.tests_adviced != '' && thisAppointmentDetails?.basic_details?.tests_adviced != null
            ?
              <div className="row mt-3">
                <div className='col-12 text-bold'>
                  Tests Advised
                </div>
                <div className='col-12 text-left'>
                  <div class="card rounded-0">
                    <div class="card-body py-3">
                      <pre>{thisAppointmentDetails?.basic_details?.tests_adviced}</pre>
                    </div>
                  </div>
                </div>
              </div>
            :
              null
            }
            {
              thisAppointmentDetails?.basic_details?.instructions_to_patients && thisAppointmentDetails?.basic_details?.instructions_to_patients != '' && thisAppointmentDetails?.basic_details?.instructions_to_patients != null
            ?
              <div className="row mt-3">
                <div className='col-12 text-bold'>
                  Instructions to patient
                </div>
                <div className='col-12 text-left'>
                  <div class="card rounded-0">
                    <div class="card-body py-3">
                      <pre>{thisAppointmentDetails?.basic_details?.instructions_to_patients}</pre>
                    </div>
                  </div>
                </div>
              </div>
            :
              null
            }

            {/* Vitals */}
            {
              thisAppointmentDetails?.vitals
            ?
              <>
                <div className="row mt-3">
                  <div className='col-12 text-bold'>
                    Vitals
                  </div>
                </div>
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr>
                      {
                        Object.keys(thisAppointmentDetails?.vitals).map(key => 
                          <th className='text-left'>{key}</th>
                        )
                      }
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {
                        Object.keys(thisAppointmentDetails?.vitals).map(key => 
                          <td className='text-left'>{thisAppointmentDetails?.vitals?.[key]}</td>
                        )
                      }
                    </tr>
                  </tbody>
                </table>
              </>
            :
              null
            }

            {/* Key Findings */}
            {
              thisAppointmentDetails?.keyFindings
            ?
              Object.keys(thisAppointmentDetails?.keyFindings).map(key =>
                <>
                  <div className="row mt-3">
                    <div className='col-12 text-bold'>
                      {key}
                    </div>
                  </div>
                  <table className="table table-bordered table-hover">
                    <thead>
                      <tr>
                        {
                          Object.keys(thisAppointmentDetails?.keyFindings?.[key]).map(key_child => 
                            <th className='text-left'>{key_child}</th>
                          )
                        }
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {
                          Object.keys(thisAppointmentDetails?.keyFindings?.[key]).map(key_child => 
                            <th className='text-left'>{thisAppointmentDetails?.keyFindings?.[key]?.[key_child]}</th>
                          )
                        }
                      </tr>
                    </tbody>
                  </table>
                </>
              )
            :
              null
            }

            {/* Allergies */}
            {
              thisAppointmentDetails?.allergies?.length > 0
            ?
              <div className="row mt-3">
                <div className='col-12 text-bold'>
                  Allergies
                </div>
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr >
                      <th className='col-2 text-left'>Name (Type)</th>
                      <th className='col-2 text-left'>Reaction</th>
                      <th className='col-2 text-left'>Intensity</th>
                      <th className='col-2 text-left'>Duration</th>
                      <th className='col-2 text-left'>Informed By</th>
                      <th className='col-2 text-left'>Comments</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {
                      thisAppointmentDetails?.allergies?.map((data, index) => {
                        return (
                          <tr>
                            <td>{data.allergy_name}({data.allergyType})</td>
                            <td>{data.reaction}</td>
                            <td>{data.intensity}</td>
                            <td>{data.duration} {data.durationType}</td>
                            <td>{data.informedBy}</td>
                            <td>{data.comments}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            :
              null
            }

            {/* Exisiting Conditions */}
            {
              thisAppointmentDetails?.conditions?.length > 0
            ?
              <div className="row mt-3">
                <div className='col-12 text-bold'>
                  Conditions
                </div>
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr >
                      <th className='col-2 text-left'>Name</th>
                      <th className='col-2 text-left'>Duration</th>
                      <th className='col-2 text-left'>On Medication</th>
                      <th className='col-2 text-left'>Medicine Name</th>
                      <th className='col-4 text-left'>Comments</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {
                      thisAppointmentDetails?.conditions?.map((data, index) => {
                        return (
                          <tr>
                            <td>{data.condition_name}</td>
                            <td>{data.duration} {data.durationType}</td>
                            <td>{data.onMedication}</td>
                            <td>{data.medicineName}</td>
                            <td>{data.comments}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            :
              null
            }

            {/* Surgical Hx */}
            {
              thisAppointmentDetails?.surgicalHistories?.length > 0
            ?
              <div className="row mt-3">
                <div className='col-12 text-bold'>
                  Surgical Hx
                </div>
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr >
                      <th className='col-2 text-left'>Surgery Name</th>
                      <th className='col-2 text-left'>Comments</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {
                      thisAppointmentDetails?.surgicalHistories?.map((data, index) => {
                        return (
                          <tr>
                            <td>{data.reason}</td>
                            <td>{data.remarks}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            :
              null
            }

            {/* Social Hx */}
            {
              thisAppointmentDetails?.socialHistories?.length > 0
            ?
              <div className="row mt-3">
                <div className='col-12 text-bold'>
                  Social Hx
                </div>
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr >
                      <th className='col-2 text-left'>Type (Yes/No)</th>
                      <th className='col-2 text-left'>Since</th>
                      <th className='col-2 text-left'>Frequency</th>
                      <th className='col-2 text-left'>Quantity</th>
                      <th className='col-2 text-left'>Drug Name (If any)</th>
                      <th className='col-2 text-left'>Route</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {
                      thisAppointmentDetails?.socialHistories?.map((data, index) => {
                        return (
                          <tr>
                            <td>{data.details} - {data.options}</td>
                            <td>{data.since} {data.time}</td>
                            <td>{data.frequency}</td>
                            <td>{data.quantity} {data.quantityUnit}</td>
                            <td>{data.drugName}</td>
                            <td>{data.route}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            :
              null
            }

            {/* Family Hx */}
            {
              thisAppointmentDetails?.familyHistories?.length > 0
            ?
              <div className="row mt-3">
                <div className='col-12 text-bold'>
                  Social Hx
                </div>
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr >
                      <th className='col-6 text-left'>Name</th>
                      <th className='col-6 text-left'>Relationship</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {
                      thisAppointmentDetails?.familyHistories?.map((data, index) => {
                        return (
                          <tr>
                            <td>{data.disease_name}</td>
                            <td>{data.family_relationship_name}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            :
              null
            }

            {/* Subjective Data */}
            {
              thisAppointmentDetails?.subjective?.map((subjectiveData, index) =>
                <>
                  {
                    subjectiveData?.description && subjectiveData?.description != null && subjectiveData?.description != '' 
                  ?
                    <div className="row mt-3">
                      <div className='col-12 text-bold'>
                        {subjectiveData.subjective_title}
                      </div>
                      <div className='col-12 text-left'>
                        <div class="card rounded-0">
                          <div class="card-body py-3">
                            <pre>{subjectiveData.description}</pre>
                          </div>
                        </div>
                      </div>
                    </div>
                  :
                    null
                  }
                </>
              )
            }

            {/* Subjective Custom Data */}
            {
              thisAppointmentDetails?.subjective_custom
            ?
              Object.keys(thisAppointmentDetails?.subjective_custom).map(key => 
                <div className="row mt-3">
                  <div className='col-12 text-bold'>
                    {thisAppointmentDetails?.subjective_custom?.[key]?.subjective_title}
                  </div>
                  <div className='col-12 text-left'>
                    <div class="card rounded-0">
                      <div class="card-body py-3">
                        <pre>{thisAppointmentDetails?.subjective_custom?.[key]?.description}</pre>
                      </div>
                    </div>
                  </div>
                </div>
              )
            :
              null
            }

            {/* Diagnosis */}
            {
              thisAppointmentDetails?.diagnosis?.length > 0
            ?
              <div className="row mt-3">
                <div className='col-12 text-bold'>
                  Diagnosis
                </div>
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr >
                      <th className='col-2 text-left'>Diagnosis</th>
                      <th className='col-2 text-left'>ICD</th>
                      <th className='col-2 text-left'>Diagnosis Notes</th>
                      <th className='col-2 text-left'>Status</th>
                      <th className='col-2 text-left'>Type</th>
                      <th className='col-2 text-left'>Stage</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {
                      thisAppointmentDetails?.diagnosis?.map((data, index) => {
                        return (
                          <tr>
                            <td>{data.description}</td>
                            <td>{data.icd_code}</td>
                            <td>{data.diagnosisNotes}</td>
                            <td>{data.status}</td>
                            <td>{data.type}</td>
                            <td>{data.stage}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            :
              null
            }

            {/* Medicines */}
            {
              thisAppointmentDetails?.medicines?.length > 0
            ?
              <div className="row mt-3">
                <div className='col-12 text-bold'>
                  Medicines
                </div>
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr >
                      <th className='col-2 text-left' >Medicine Name</th>
                      <th className='col-2 text-left' >Route</th>
                      <th className='col-2 text-left' >Frequency Type</th>
                      <th className='col-2 text-left' >Frequency</th>
                      <th className='col-2 text-left' >Schedule</th>
                      <th className='col-2 text-left' >Days in week</th>
                      <th className='col-2 text-left'>Dosage</th>
                      <th className='col-2 text-left'>Instructions</th>
                      <th className='col-2 text-left'>Duration</th>
                      <th className='col-2 text-left'>Start Date</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {
                      thisAppointmentDetails?.medicines?.map((data, index) => {
                        return (
                          <tr>
                            <td>{data.drug_name}</td>
                            <td>{data.route_name}</td>
                            <td>{data.drug_frequency_name}</td>
                            <td>{data.daily_frequency_name}</td>
                            <td>{data.schedule_name}</td>
                            <td>{data.days_in_week_text}</td>
                            <td>{data.dosage_value} {data.dosage_value_form}</td>
                            <td>{data.instruction_name}</td>
                            <td>{data.continueTillReview == 'N' ? data.number_of_days + ' ' + data.number_of_days_type : 'Till review'}</td>
                            <td>{data.start_date}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            :
              null
            }
            
            {/* Tests */}
            {
              thisAppointmentDetails?.test?.length > 0
            ?
              <div className="row mt-3">
                <div className='col-12 text-bold'>
                  Tests
                </div>
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr >
                      <th className='col-2 text-left' >Test Name</th>
                      <th className='col-2 text-left' >Urgency</th>
                      <th className='col-2 text-left' >Instructions</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {
                      thisAppointmentDetails?.test?.map((data, index) => {
                        return (
                          <tr>
                            <td>{data.test_name}</td>
                            <td>{data.urgency_name}</td>
                            <td>{data.instructions}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            :
              null
            }
            
            {/* Instructions */}
            {
              thisAppointmentDetails?.instructions?.length > 0
            ?
              <div className="row mt-3">
                <div className='col-12 text-bold'>
                  Instructions
                </div>
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr >
                      <th className='col-12 text-left' >Instruction</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {
                      thisAppointmentDetails?.instructions?.map((data, index) => {
                        return (
                          <tr>
                            <td>{data.instruction}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            :
              null
            }

            {/* Supporting material */}
            {
              thisAppointmentDetails?.supportingMaterials?.length > 0
            ?
              <div className="row mt-3">
                <div className='col-12 text-bold'>
                  Supporting materials
                </div>
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr >
                      <th className='col-12 text-left' >Supporting Material</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {
                      thisAppointmentDetails?.supportingMaterials?.map((data, index) => {
                        return (
                          <tr>
                            <td>
                              <a href={data.file_url} target="_blank">
                                {data.file_name}
                              </a>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            :
              null
            }

            {/* Parent Custom fields */}
            {
              thisAppointmentDetails?.parentCustom
            ?
              Object.keys(thisAppointmentDetails?.parentCustom).map(key => 
                <div className="row mt-3">
                  <div className='col-12 text-bold'>
                    {thisAppointmentDetails?.parentCustom?.[key]?.title}
                  </div>
                  <div className='col-12 text-left'>
                    <div class="card rounded-0">
                      <div class="card-body py-3">
                        <pre>{thisAppointmentDetails?.parentCustom?.[key]?.description}</pre> 
                      </div>
                    </div>
                  </div>
                </div>
              )
            :
              null
            }

            {/* Referrals */}
            {
              thisAppointmentDetails?.referrals?.length > 0
            ?
              <div className="row mt-3">
                <div className='col-12 text-bold'>
                  Referrals
                </div>
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr >
                      <th className='col-2 text-left' >Referral Type</th>
                      <th className='col-2 text-left' >Priority</th>
                      <th className='col-2 text-left' >Doctor</th>
                      <th className='col-2 text-left' >Speciality</th>
                      <th className='col-4 text-left' >Instructions</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {
                      thisAppointmentDetails?.referrals?.map((data, index) => {
                        return (
                          <tr>
                            <td>{data.referral_type}</td>
                            <td>{data.priority_name}</td>
                            <td>{data.doctor_name}</td>
                            <td>{data.speciality_name}</td>
                            <td>{data.instructions}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            :
              null
            }

            {/* Phisiotherapy */}
            {
              thisAppointmentDetails?.physiotherapy?.physiotherapyAdvised == 'Yes'
            ?
              <div className="row mt-3">
                <div className='col-12 text-bold'>
                Phisiotherapy
                </div>
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr >
                      <th className='col-3 text-left' >Physiotherapy Advised</th>
                      <th className='col-9 text-left' >Comment</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    <tr>
                      <td>{thisAppointmentDetails?.physiotherapy?.physiotherapyAdvised}</td>
                      <td>{thisAppointmentDetails?.physiotherapy?.physiotherapyComment}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            :
              null
            }

            {/* Admission Advised */}
            {
              thisAppointmentDetails?.admission?.admissionAdvised == 'Yes'
            ?
              <div className="row mt-3">
                <div className='col-12 text-bold'>
                  Admission
                </div>
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr >
                      <th className='col-2 text-left' >Admission Advised</th>
                      <th className='col-2 text-left' >Priority</th>
                      <th className='col-8 text-left' >Comment</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    <tr>
                      <td>{thisAppointmentDetails?.admission?.admissionAdvised}</td>
                      <td>{thisAppointmentDetails?.admission?.admissionAdvisedPriority}</td>
                      <td>{thisAppointmentDetails?.admission?.admissionComment}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            :
              null
            }

            {/* Follow up */}
            {
              thisAppointmentDetails?.followup?.followUpDays >= 0
            ?
              <div className="row mt-3">
                <div className='col-12 text-bold'>
                  Follow Up
                </div>
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr >
                      <th className='col-4 text-left' >Follow Up (In days)</th>
                      <th className='col-6 text-left' >Booking Date</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    <tr>
                      <td>{thisAppointmentDetails?.followup?.followUpDays} Days</td>
                      <td>{thisAppointmentDetails?.followup?.followUpBookedByDoctor}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            :
              null
            }
          </Modal>
        </div>
      </>
    )
  }
}
export default completedVisit;