import React, { Component } from 'react'
import Axios from 'axios';
import LS_SERVICE from '../../utils/localStorage';
import { Form, Select } from 'antd';
import { Link } from 'react-router-dom'
import Asideleft from '../../components/aside/asideleft'
import {
  REFERENCE_TYPE,
  LOADER_RED,
  SHARED_INTERNAL_NOTES,
  STORE_SHARED_INTERNAL_NOTES,
} from '../../utils/constant';
import { headerService$ , speechService, speechTextService } from '../../utils/rxjs-sharing';
import { SPECIALITY_LIST } from '../../utils/constant';
import moment from 'moment';
import qs from 'qs';
const { Option } = Select;

export default class SharedInternalNotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saveBtnDisable: true,
            patient: null,
            hospital_id: null,
            patient_id: null,
            reference_id: null,
            allowEdit: true,
            shared_internal_notes: '',
            sharedInternalNotesInfo: [],
            speech: {
                identifier: null,
                state: false
            },
            specialityList: [],
            speciality_list_id: []      
        }
        this.specialityFormRef = React.createRef();
    }
    autoHeight = (e) => {
        e.target.style.height = e.target.scrollHeight + "px";
    }

    async componentWillUnmount() {
        this.subscription.unsubscribe();
    }

    async componentDidMount() {
        Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
        const { match: { params } } = this.props;
        const { hospital_id, patient_id, reference_id } = params;

        const patient = {
          hospital_id,
          patient_id,
          qms_token_id: reference_id
        }

        let hospitalConfigData = LS_SERVICE.get('hospitalConfigData') ?? [];
        hospitalConfigData = hospitalConfigData.find(item => item.hospital_id == hospital_id);
        this.subscription = speechTextService.status().subscribe(async data => {
          if (this.state.speech.state) {
              if (this.state.speech.identifier === 'shared_internal_notes') {
                this.setState({ shared_internal_notes: data.text, detect_change: true }, () => { /* this.handleConfirmBtnStatus() */ });
                document.querySelector("#" + this.state.speech.identifier).style.height = document.querySelector("#" + this.state.speech.identifier).scrollHeight + "px";
              }
          }
        });
        this.handleSerachSpecList('');
        this.setState({
          hospital_id,
          patient_id,
          reference_id,
          patient,
        }, () => {
          if (LS_SERVICE.has('hospitalConfigData')) {
            const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
            let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
            headerService$.soapConfig({ soapConfig: hospitalConfigObj.opd_layout }); // IMPORTANT TO HIT AS PER THE VIEW
            this.getSharedInternalNotes();
          }
        });
    }

    handleSerachSpecList = search => {
      const PARAMS = { search }
      Axios.post(SPECIALITY_LIST, qs.stringify(PARAMS))
        .then(res => {
          this.setState({
            specialityList: res.data.status ? res.data.data.results : [],
          })
        })
    }
  
    async getSharedInternalNotes() {
        const { reference_id } = this.state;
        const { patient } = this.state;
        const { patient_id } = patient;
        const notes = await Axios.get(SHARED_INTERNAL_NOTES({ reference_type: REFERENCE_TYPE, reference_id: reference_id, type: 'soap',patient_id:patient_id }));
        if (typeof notes.data != 'undefined' && typeof notes.data.data != 'undefined') {
          await this.setState({
            sharedInternalNotesInfo: notes.data.data
          });
        }
        console.log(this.state.sharedInternalNotesInfo.length)
    }

    handleOnChange = event => {
        const { name, value } = event.currentTarget;
        var identifierValue = event.target.id;

        this.setState((prevState) => ({
          [name]: value,
          saveBtnDisable: false,
          speech: {
            ...prevState.speech,
            identifier: identifierValue,
            initialText: value
          }
        }), () => { /* this.handleConfirmBtnStatus(); */ })
    }

    initSpeech(info) {
        let initialText = '';
        if (info.identifier === 'shared_internal_notes') {
          initialText = this.state.shared_internal_notes != undefined ? this.state.shared_internal_notes : '';
        }

        if (info.identifier === this.state.speech.identifier) {
          this.setState({
            speech: {
              identifier: info.identifier,
              state: !this.state.speech.state,
              initialText: initialText
            },
            saveBtnDisable: false,
          }, () => {
            speechService.init(this.state.speech);
          });
        } else {
          // console.log("IN Different MIC Previous Unclosed")
          this.setState({
            speech: {
              identifier: this.state.speech.identifier,
              state: false,
              initialText: ''
            },
            saveBtnDisable: false,
          }, () => {
            speechService.init(this.state.speech);

            if (!this.state.speech.state) {
              this.handleRestartMic(info, initialText)
            }
            // console.log("State in init:",this.state.speech)

          });

        }
    }

    handleRestartMic = (info, initialText) => {
        // debugger
        this.setState({
          speech: {
            identifier: info.identifier,
            state: true,
            initialText: initialText
          },
          saveBtnDisable : false
        }, () => {
          // console.log("State in init:",this.state.speech)
          speechService.init(this.state.speech);
        });
    }

    handleOnFocus = (e) => {
        var identifierValue = this.state.speech.identifier === e.target.id ? e.target.id : this.state.speech.identifier;
        this.setState(prevState => ({
          speech: {
            ...prevState.speech,
            identifier: identifierValue,
            state: false,
            initialText: identifierValue === "shared_internal_notes" ? this.state.shared_internal_notes : ''
          }
        }), () => {
          speechService.init(this.state.speech)
          // this.initSpeech({identifier: e.target.id})
        })
    }

    handleFormSubmit = async () => {
        const { shared_internal_notes, patient ,speciality_list_id} = this.state;

        const { qms_token_id } = patient;
        const {patient_id} =patient;
        const PARAMS = {
          reference_type: REFERENCE_TYPE,
          reference_id: qms_token_id,
          shared_internal_notes: shared_internal_notes,
          staff_id : LS_SERVICE.get("staff_id"),
          speciality_list_id: speciality_list_id,
          patient_id:patient_id
        };

        let submitPromise;
          submitPromise = await Axios.post(STORE_SHARED_INTERNAL_NOTES, PARAMS);

        if (submitPromise.data?.status) {
          await this.setState({
            saveBtnDisable: true,
            shared_internal_notes: '',
            speciality_list_id: []
          });
          this.getSharedInternalNotes();
          this.specialityFormRef.current.resetFields();
        }
    }

    handleSpecialListChange = async (speciality_list_id) => {
      await this.setState({ speciality_list_id: speciality_list_id })
    }
  

    render() {
        const { patient, sharedInternalNotesInfo ,specialityList} = this.state;
        return (
            <>
                <Asideleft />
                <div className="content-wrapper">
                    <div className="container-fluid emrfrm ">
                        { patient !== null ? (
                            <>
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <div class="float-left">
                                            <h1 class="page-title">Shared Notes</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                    <div className="row mt-4">
                      <div className="col-md-12">
                        <Form ref={this.specialityFormRef}>
                          <Form.Item
                            id="speciality_selected"
                            name="speciality_selected"
                            label={<span className='speciality_lable'>Speciality (Non-Mandatory)</span>}
                          >
                            <span className="d-none">Speciality Name</span>
                            <Select
                              showSearch
                              autoFocus
                              mode="multiple"
                              // value={speciality_selected_name}
                              optionFilterProp="children"
                              notFoundContent={null}
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={this.handleSpecialListChange}
                              style={{ width: '100%' }}
                            >
                              {specialityList !== undefined && specialityList.length > 0 ? specialityList.map(d => (
                                <Option key={d.speciality_id} dataid={d} label={d.name}>{d.name}</Option>
                              )) : null}
                            </Select>
                          </Form.Item>
                        </Form>
                      </div>
                    </div>
                      <div className="form-group micwrap normal">
                                            <Link
                                              onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: 'shared_internal_notes' }) }}
                                              className={(this.state.speech.identifier === 'shared_internal_notes' && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} >
                                            </Link>
                                            <div className='d-flex'>
                                                <label htmlFor={"ss"} className="control-label">Shared Notes </label>
                                            </div>
                                            <textarea placeholder={'Type Here'} className={"form-control subjective-field show-placeholder"} id='shared_internal_notes' name='shared_internal_notes'
                                                value={this.state.shared_internal_notes} onChange={this.handleOnChange} onFocus={this.handleOnFocus} rows="1" onInput={(e) => this.autoHeight(e)}>
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-4 offset-8 text-right'>
                                        <button type="button" onClick={this.handleFormSubmit} disabled={this.state.saveBtnDisable} class="btn btn-primary text-uppercase px-4">SAVE</button>
                                    </div>
                                </div>
                            </>
                        ) : (
                          <div className="w-100 align-items-center justify-content-center mt-4 d-flex">
                            {LOADER_RED}
                          </div>
                        )}

                        {
                            sharedInternalNotesInfo.length > 0 ?
                                <div className='row mt-4'>
                                    {
                                        sharedInternalNotesInfo?.map(item => (
                                            <div className='col-12 mb-3'>
                                                <div className="card bg-grey">
                                                    <div className="card-body">
                                                        <div className='row'>
                                                            <div className='col-8'>
                                                                <span className='font-weight-bold text-dark'>{item.user ? item.user.role_id == '3' ? 'Dr' : 'Nr' : ''} {item.user ? item.user.name +" ": '' }</span><span>{item.user ? item.user.specialityname : '' }</span>
                                                            </div>
                                                            <div className='col-4 text-right'>
                                                                <h6>{moment(item.createdAt).format("DD MMM YYYY | hh:mm A")}</h6>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <span>{item.description}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            :
                            null
                        }
                    </div>
                </div>
            </>
        )
    }
}
