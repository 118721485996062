import React, { PureComponent } from "react";
import { Modal, Button, Select, Spin, notification } from "antd";
import "antd/dist/antd.css";
import axios from "axios";
import Axios from "axios";
import qs from "qs";

import AssessmentForm from "../../components/assessments/assessment-form";
import AssessmentTable from "../../components/assessments/assessment-table";
import MedicineForm from "../../components/drugs/medicine-form";
import MedicineTable from "../../components/drugs/medicine-table";
import TestForm from "../../components/tests/test-form";
import TestTable from "../../components/tests/test-table";
import InstructionsForm from "../../components/instructions/instructions-form";
import InstructionTable from "../../components/instructions/instructions-table";
import SupportingMaterial from '../../components/supporting-material/supporting-material-form';
import SupportingMaterialTable from '../../components/supporting-material/supporting-material-table';

import LS_SERVICE from "../../utils/localStorage";
import {
    jQueryLabels,
    AntSelectLabels,
    FixedCta,
} from "../../utils/jQueryLabels";
import {
    CREATE_TEMPLATE,
    VIEW_TEMPLATE,
    EDIT_TEMPLATE,
    ALL_SPECIALITY_AGENT_LIST,
    ALL_DOCTOR_AGENT_LIST,
    ALL_SPECIALITY_DOCTOR_AGENT_LIST,
    ALL_HOSPITAL_DOCTOR_AGENT_LIST,
    DRUGS_CONSTANT,
    LAB_TEST_CONSTANTS,
    SPECIALITY_AGENT_LIST,
    DOCTOR_AGENT_LIST,
    HOSPITAL_AGENT_LIST,
    USERTYPES,
    TEMPLATE_FILTER,
    LOADER_RED,
    LOADER,
    INSTRUCTION_STORE,
    REFERENCE_TYPE,
    ALL_CIMS_INTERACTION,
    ALL_CIMS_INTERACTION_EXISTS,
    CIMSALLOW,
    CIMS_ALLOW_HOSPITALS
} from "../../utils/constant";

const { Option } = Select;

class CreateTemplateList extends PureComponent {
    constructor(props) {
        var defaultHospital;
        if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
            defaultHospital = LS_SERVICE.get("admin_hospital_id") ? LS_SERVICE.get("admin_hospital_id") : undefined;
        }
        super(props);
        this.state = {
            spin_loading: false,
            disableConfirmBtn: true,
            istemplateErrorVisible: false,
            showAssessmentModal: false,
            showMedicineModal: false,
            showTestModal: false,
            showInstructionModal: false,
            showSupportingModal: false,
            dataLoaded: false,
            showtapper: true,
            alertMessage: "",
            templateView: [
                {
                    constants: [],
                    diagnosisList: [],
                    drugList: [],
                    templateInstruction: [],
                    testList: [],
                },
            ],
            medicineFlag: 'careprotcol',
            editData: null,
            editIndex: -1,
            constants: [],
            eventBased: false,
            templateName: "",
            assessmentData: [],
            addedAssessmentList: [],
            drugsData: [],
            addedDrugsList: [],
            testData: [],
            addedTestList: [],
            instructionsData: [],
            allSupportingMaterial: [],
            supportingMaterial: [],
            actionText: "",
            deleteConfirmModal: false,
            deleteMessage: "",
            deleteActionType: null,
            deleteObjId: null,
            templateId: "",
            idisNaN: true,
            pagename: "Create Care Protocol",
            name: "",
            isEdit: "",
            specialityList: [],
            doctorList: [],
            hospitalList: [],
            hospital_name: "",
            owner_id: [],
            speciality_id: [],
            hospital_id: defaultHospital,
            HospitalDefaultValue: defaultHospital,
            SpecialityDefaultValue: [],
            DoctorDefaultValue: [],
            OwnerDefaultValue: [],
            doctor_id: [],
            spinner: false,
            copybuttonchk: true,
            TemplateList: [],
            createRoldId: "",
            createdId: "",
            showLoader: false,
            login_id:
                LS_SERVICE.get("user_type") == USERTYPES.admin
                    ? LS_SERVICE.get("admin_hospital_id")
                    : LS_SERVICE.get("staff_id"),
            isHospitalHaveMaster: false,
            isHospitalHaveMasterVisible: false,
            hospitalId: parseInt(LS_SERVICE.get('slot_hospital_id')),
            showAllInteractionBtn: false
        };
        this.childMedicine = React.createRef();
        this.childTest = React.createRef();
    }

    async componentDidMount() {
        axios.defaults.headers.common["Authorization"] =
            LS_SERVICE.get("token");
        LS_SERVICE.set('master_hospital_id', null);
        jQueryLabels();
        FixedCta();
        AntSelectLabels();
        this.setState({
            dataLoaded: false
        })
        await this.loadData();
        const arrRoute = this.props.location.pathname.split("/");
        const templateId = arrRoute[arrRoute.length - 1];
        const isEdit = arrRoute[arrRoute.length - 2];
        await this.setState({ idisNaN: isNaN(templateId), isEdit: isEdit }, async () => {
            if (!isNaN(templateId)) {
                AntSelectLabels();
                this.setState({
                    templateId: templateId,
                    pagename: "Edit Care Protocol",
                    templateName:
                        this.props.history.location.state.template.name,
                });
                await this.TemplateOnView(templateId);
            } else {
                this.setState({ pagename: "Create Care Protocol" });
                if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
                    this.setState({
                        hospitalSelected: LS_SERVICE.get("admin_hospital_id"),
                        hospital_id: LS_SERVICE.get("admin_hospital_id"),
                    });
                }
            }
        });
        await this.hospitalList();
        await this.specialityList();
        await this.doctorList();
        // this.setState({ 
        //     dataLoaded: true
        // })
        this.TemplateList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        setTimeout(() => {
            FixedCta();
        }, 100);
    }

    // loadData = () => {
    //     let { doctor_id, constants } = this.state;
    //     const drugsConstantsPromise = Axios.get(DRUGS_CONSTANT);
    //     const testConstantsPromise = Axios.get(LAB_TEST_CONSTANTS);

    //     Axios.all([drugsConstantsPromise, testConstantsPromise]).then(
    //         Axios.spread((...responses) => {
    //             const responseOne = responses[0];
    //             const responseTwo = responses[1];

    //             if (responseOne.data.hasOwnProperty("data") === true) {
    //                 constants = responseOne.data.data;
    //             }
    //             if (responseTwo.data.hasOwnProperty("data") === true) {
    //                 constants["radiologyViews"] = responseTwo.data.data;
    //             }
    //             this.setState({
    //                 dataLoaded: true,
    //                 constants,
    //             });
    //         })
    //     );
    // };

    // Load all the drug & medicine constants
    loadData = async () => {
        let { constants } = this.state;

        const drugsConstantsPromise = await axios.get(DRUGS_CONSTANT);
        const testConstantsPromise = await axios.get(LAB_TEST_CONSTANTS);

        axios.all([drugsConstantsPromise, testConstantsPromise]).then(
            axios.spread((...responses) => {
                const responseOne = responses[0];
                const responseTwo = responses[1];

                if (responseOne.data.hasOwnProperty("data") === true) {
                    constants = responseOne.data.data;
                }
                if (responseTwo.data.hasOwnProperty("data") === true) {
                    constants["radiologyViews"] = responseTwo.data.data;
                }
                this.setState({
                    dataLoaded: true,
                    constants,
                });
            })
        );
    };

    // Show Template Error Model
    showTemplateErrorModal = () => {
        this.setState({ istemplateErrorVisible: true });
    };

    // Template Error Model - Click OK
    handleTemplateErrorOk = () => {
        this.setState({
            istemplateErrorVisible: false,
            disableConfirmBtn: false,
        });
    };

    // Template Error Model - Click Cancel
    handleTemplateErrorCancel = () => {
        this.setState({
            istemplateErrorVisible: false,
            disableConfirmBtn: false,
        });
    };

    handleHospitalMasterError = () => {
        this.setState({
            isHospitalHaveMasterVisible: false,
        });
    };

    // Change template name to Sentance case
    savename = (e) => {
        this.setState(
            {
                templateName:
                    e.target.value.charAt(0).toUpperCase() +
                    e.target.value.slice(1),
            },
            () => {
                this.checkSaveBtnStatus();
            }
        );
    };

    // Get hospital list
    hospitalList = async () => {
        let PARAMS = {};
        if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
            PARAMS = { hospital_id: LS_SERVICE.get("admin_hospital_id") };
            await this.setState({
                hospital_id: LS_SERVICE.get("admin_hospital_id"),
            });
        }
        if (LS_SERVICE.get("user_type") == USERTYPES.superadmin || LS_SERVICE.get("user_type") == USERTYPES.admin) {
            await Axios.post(HOSPITAL_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                this.setState({
                    hospitalList: res?.data?.data?.results ? res?.data?.data?.results : [],
                });
            });
        }
    };

    // Get speciality list
    specialityList = async () => {
        var PARAMS = {};

        if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
            PARAMS = { hospital_id: LS_SERVICE.get("admin_hospital_id") };
            await this.setState({
                hospital_id: LS_SERVICE.get("admin_hospital_id"),
            });
        }
        if (this.state.hospital_id == -1) {
            await axios.post(ALL_SPECIALITY_AGENT_LIST).then((res) => {
                this.setState({
                    specialityList: res.data.status ? res.data.data.results : [],
                });
            });
        }
        else {
            if (this.state.hospital_id) {
                PARAMS = {
                    hospital_id: this.state.hospital_id
                };
                await Axios.post(SPECIALITY_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                    this.setState({
                        specialityList: res.data.status ? res.data.data.results : [],
                    });
                });
            }
            else {
                this.setState({
                    specialityList: [],
                });
            }
        }
        var final_speciality = [];
        var speciality_array = this.state.specialityList
        if (speciality_array && speciality_array.length > 0) {
            var SpecialityDefaultValue = this.state.SpecialityDefaultValue;
            for (var sp of SpecialityDefaultValue) {
                if (speciality_array.some(el => el.speciality_id === sp)) {
                    final_speciality.push(sp)
                }
            }
        }
        await this.setState({
            speciality_id: final_speciality.join(),
            SpecialityDefaultValue: final_speciality,
        });
    };

    // Get doctor list
    doctorList = async () => {
        var PARAMS = {};

        if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
            PARAMS = { hospital_id: LS_SERVICE.get("admin_hospital_id") };
            await this.setState({
                hospital_id: LS_SERVICE.get("admin_hospital_id"),
            });
        }

        if (this.state.hospital_id == -1 && this.state.speciality_id) {
            PARAMS = {
                speciality_id: this.state.speciality_id
            }
            await axios.post(DOCTOR_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                var final_doctor = [];
                for (var doc of res?.data?.data?.results) {
                    final_doctor.push(doc)
                }
                this.setState({
                    doctorList: final_doctor,
                });
            });
        }
        else if (this.state.hospital_id && this.state.speciality_id) {
            PARAMS = {
                hospital_id: this.state.hospital_id,
                speciality_id: this.state.speciality_id
            };

            await Axios.post(DOCTOR_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                this.setState({
                    doctorList: res.data.status ? res.data.data.results : [],
                });
            });
        }
        else if (this.state.hospital_id && !this.state.speciality_id) {
            if (LS_SERVICE.get("user_type") == USERTYPES.superadmin) {
                if (this.state.hospital_id == -1) {
                    await axios.post(DOCTOR_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                        this.setState({
                            doctorList: res?.data?.data?.results || [],
                        });
                    });
                }
                else {
                    PARAMS = { hospital_id: this.state.hospital_id };
                    await axios.post(DOCTOR_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                        this.setState({
                            doctorList: res?.data?.data?.results || [],
                        });
                    });
                }
            }
            else {
                PARAMS = { hospital_id: this.state.hospital_id };
                await axios.post(DOCTOR_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                    this.setState({
                        doctorList: res?.data?.data?.results || [],
                    });
                });
            }
        }
        // else if(!this.state.hospital_id && !this.state.speciality_id && this.state.doctor_id){
        //     if (LS_SERVICE.get("user_type") == USERTYPES.superadmin) {
        //         await axios.post(DOCTOR_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
        //             var final_doctor = [];
        //             for(var doc of res?.data?.data?.results){
        //                 if(doc.staff_id == this.state.doctor_id) {
        //                     final_doctor.push(doc)
        //                 }
        //             }
        //             this.setState({
        //                 doctorList: final_doctor,
        //             });
        //         });
        //     }
        // }
        else {
            this.setState({
                doctorList: [],
            });
        }

        var final_doctor = [];
        var final_owner = [];
        var doctor_array = this.state.doctorList;
        if (doctor_array && doctor_array.length > 0) {
            var DoctorDefaultValue = this.state.DoctorDefaultValue;
            for (var doc of DoctorDefaultValue) {
                if (doctor_array.some(el => el.staff_id === doc)) {
                    final_doctor.push(doc)
                }
            }
            var OwnerDefaultValue = this.state.OwnerDefaultValue;
            for (var own of OwnerDefaultValue) {
                if (doctor_array.some(el => el.staff_id === own)) {
                    final_owner.push(own)
                }
            }
        }
        await this.setState({
            doctor_id: final_doctor.join(),
            DoctorDefaultValue: final_doctor,
            owner_id: final_owner.join(),
            OwnerDefaultValue: final_owner
        });
    };

    getUserRole() {
        let role = "";
        if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
            role = "admin";
        }
        else if (LS_SERVICE.get("user_type") == USERTYPES.superadmin) {
            role = "superadmin";
        } else {
            role = "doctor";
        }
        return role;
    }

    // Get template list
    TemplateList = (search) => {
        let params;
        if (LS_SERVICE.get("user_type") == USERTYPES.superadmin) {
            params = {
                hospital_id: LS_SERVICE.get('master_hospital_id') ?? null,
                login_role: this.getUserRole(),
                logined_id: this.state.login_id,
                hospital_master: LS_SERVICE.get('master_hospital_id') ? 'Y' : 'N'
            }
        } else {
            params = {
                hospital_id: LS_SERVICE.get("user_type") == USERTYPES.admin ? LS_SERVICE.get("admin_hospital_id") : "",
                login_role: this.getUserRole(),
                logined_id: this.state.login_id,
                hospital_master: LS_SERVICE.get('slot_hospital_master') ?? 'N',
            }
        }
        axios.post(
            TEMPLATE_FILTER,
            qs.stringify(params)
        )
            .then((res) => {
                this.setState({
                    TemplateList: res.data.status && res.data?.data?.templates ? res.data.data.templates : [],
                });
            });
    };

    // Handle hospital change
    selectHospitalChange = async (val, props) => {
        let hospital_id = [];
        props.forEach((element) => {
            hospital_id.push(element.dataid.hospital_id);
        });

        if (this.state?.HospitalDefaultValue?.includes(-1) == false && hospital_id?.includes(-1)) {
            hospital_id = [-1]
            await this.setState({
                hospital_id: hospital_id.join(),
                HospitalDefaultValue: hospital_id
            });
        }
        else if (this.state?.HospitalDefaultValue?.includes(-1) && hospital_id?.includes(-1)) {
            var index_of_all = hospital_id.indexOf(-1);
            hospital_id.splice(index_of_all, 1);
            await this.setState({
                hospital_id: hospital_id.join(),
                HospitalDefaultValue: hospital_id
            });
        }
        else {
            await this.setState({
                hospital_id: hospital_id.join(),
                HospitalDefaultValue: hospital_id
            });
        }
        await this.setState({
            isHospitalHaveMaster: false,
            TemplateList: [],
            copybuttonchk: true,
        });
        let master_hospital_id = null;
        if (!hospital_id?.includes(-1)) {
            if (hospital_id.length > 1) {
                props.forEach(async (element) => {
                    if (element.own_master == 'Y') {
                        await this.setState({
                            isHospitalHaveMaster: true,
                            isHospitalHaveMasterVisible: true
                        });
                        return false;
                    }
                });
            } else if (hospital_id.length == 1 && props[0].own_master == 'Y') {
                master_hospital_id = props[0].dataid.hospital_id;
            }
        }
        if (LS_SERVICE.get('master_hospital_id') != master_hospital_id) {
            await this.setState({
                drugsData: [],
                addedDrugsList: [],
                testData: [],
                addedTestList: [],
            });
            this.childMedicine.current.Formclear();
            this.childTest.current.Formclear();
        }
        LS_SERVICE.set('master_hospital_id', master_hospital_id);
        this.TemplateList();

        await this.specialityList();
        var final_speciality = [];
        var speciality_array = this.state.specialityList
        if (speciality_array && speciality_array.length > 0) {
            var SpecialityDefaultValue = this.state.SpecialityDefaultValue;
            for (var sp of SpecialityDefaultValue) {
                if (speciality_array.some(el => el.speciality_id === sp)) {
                    final_speciality.push(sp)
                }
            }
        }
        await this.setState({
            speciality_id: final_speciality.join(),
            SpecialityDefaultValue: final_speciality,
        });

        await this.doctorList();
        var final_doctor = [];
        var final_owner = [];
        var doctor_array = this.state.doctorList;
        if (doctor_array && doctor_array.length > 0) {
            var DoctorDefaultValue = this.state.DoctorDefaultValue;
            for (var doc of DoctorDefaultValue) {
                if (doctor_array.some(el => el.staff_id === doc)) {
                    final_doctor.push(doc)
                }
            }
            var OwnerDefaultValue = this.state.OwnerDefaultValue;
            for (var own of OwnerDefaultValue) {
                if (doctor_array.some(el => el.staff_id === own)) {
                    final_owner.push(own)
                }
            }
        }
        await this.setState({
            doctor_id: final_doctor.join(),
            DoctorDefaultValue: final_doctor,
            owner_id: final_owner.join(),
            OwnerDefaultValue: final_owner
        });

        // if(this.state.hospital_id){
        //     var final_speciality = [];
        //     await this.specialityList();
        //     var speciality_array = this.state.specialityList
        //     if(speciality_array && speciality_array.length > 0){
        //         var SpecialityDefaultValue = this.state.SpecialityDefaultValue;
        //         for(var sp of SpecialityDefaultValue){
        //             if(speciality_array.some(el => el.speciality_id === sp)) {
        //                 final_speciality.push(sp)
        //             }
        //         }
        //     }
        //     await this.setState({
        //         speciality_id: final_speciality.join(),
        //         SpecialityDefaultValue: final_speciality,
        //     });

        //     await this.doctorList();

        // }
        // else{
        //     await this.setState({
        //         speciality_id: "",
        //         SpecialityDefaultValue: [],
        //         doctor_id: "",
        //         DoctorDefaultValue: [],
        //         owner_id: "",
        //         OwnerDefaultValue: [],
        //         specialityList: [],
        //         doctorList: [],
        //     });
        // }
        this.checkSaveBtnStatus();
    };

    // Handle speciality change
    selectSpecialityChange = async (val, props) => {
        let speciality_id = [];
        props.forEach((element) => {
            speciality_id.push(element.dataid.speciality_id);
        });
        await this.setState({
            speciality_id: speciality_id.join(),
            SpecialityDefaultValue: speciality_id,
            primaryDetailEdit: true,
        });

        await this.doctorList();
        var final_doctor = [];
        var final_owner = [];
        var doctor_array = this.state.doctorList;
        if (doctor_array && doctor_array.length > 0) {
            var DoctorDefaultValue = this.state.DoctorDefaultValue;
            for (var doc of DoctorDefaultValue) {
                if (doctor_array.some(el => el.staff_id === doc)) {
                    final_doctor.push(doc)
                }
            }
            var OwnerDefaultValue = this.state.OwnerDefaultValue;
            for (var own of OwnerDefaultValue) {
                if (doctor_array.some(el => el.staff_id === own)) {
                    final_owner.push(own)
                }
            }
        }
        await this.setState({
            doctor_id: final_doctor.join(),
            DoctorDefaultValue: final_doctor,
            owner_id: final_owner.join(),
            OwnerDefaultValue: final_owner
        });
        // if(this.state.speciality_id){

        // }
        // else{
        //     await this.setState({
        //         doctor_id: "",
        //         DoctorDefaultValue: [],
        //         owner_id: "",
        //         OwnerDefaultValue: [],
        //         doctorList: [],
        //     });
        // }

        this.checkSaveBtnStatus();
    };

    // Handle doctor change
    selectDoctorChange = async (val, props) => {
        let doctor_id = [];
        let doctor_name = [];
        props.forEach((element) => {
            doctor_id.push(element.value);
            doctor_name.push(
                props
                    .map(function (obj) {
                        return obj.children;
                    })
                    .toString()
                    .replace(/,/g, "-")
            );
        });

        await this.setState(
            {
                doctor_id: doctor_id.join(),
                doctor_name: doctor_name.join(),
                DoctorDefaultValue: doctor_id,
                doctorSelected: val,
            },
            () => {
                this.checkSaveBtnStatus();
            }
        );
    };

    // Handle owner change
    selectOwnerChange = async (val, props) => {
        let owner_id = [];
        let owner_name = [];
        props.forEach((element) => {
            owner_id.push(element.value);
            owner_name.push(
                props
                    .map(function (obj) {
                        return obj.children;
                    })
                    .toString()
                    .replace(/,/g, "-")
            );
        });

        await this.setState(
            {
                owner_id: owner_id.join(),
                owner_name: owner_name.join(),
                OwnerDefaultValue: owner_id,
                ownerSelected: val,
            },
            () => {
                this.checkSaveBtnStatus();
            }
        );
    };

    // Create hospital dropdown
    selectHospitalList() {
        if (this.state.hospitalList) {
            return this.state.hospitalList.map((obj, i) => {
                return (
                    <Option dataid={obj} key={i} value={obj.hospital_id} own_master={obj.own_master}>
                        {obj.name}
                    </Option>
                );
            });
        }
    }

    // Create speciality dropdown
    selectSpecialityList() {
        if (this.state.specialityList) {
            return this.state.specialityList.map((obj, i) => {
                return (
                    <Option
                        dataid={obj}
                        key={i}
                        value={obj.speciality_id}
                    >
                        {obj.name}
                    </Option>
                );
            });
        }
    }

    // Create doctors dropdown
    selectDoctorList() {
        if (this.state.doctorList) {
            return this.state.doctorList.map((obj, i) => {
                if (!("last_name" in obj)) {
                    return (
                        <Option dataid={obj} key={i} value={obj.staff_id}>
                            {obj.first_name} {obj.woodlands_mobile ? '(' + obj.woodlands_mobile + ')' : null}
                        </Option>
                    );
                }
                else {
                    return (
                        <Option dataid={obj} key={i} value={obj.staff_id}>
                            Dr. {obj.first_name} {obj.last_name} {obj.woodlands_mobile ? '(' + obj.woodlands_mobile + ')' : null}
                        </Option>
                    );
                }
            });
        }
    }

    // Handle copy from list change
    selectCopyFromChange = async (val, props) => {
        if (props?.value) {
            await this.setState({
                copybuttonchk: false,
                selectedTemplate: props?.value,
            });
        } else {
            await this.setState({
                copybuttonchk: true,
            });
        }
    };

    // Handle copy from existing template
    copyFromTemplate = async () => {
        this.setState({
            // dataLoaded: false,
        });
        if (this.state.selectedTemplate) {
            await this.TemplateOnView(this.state.selectedTemplate);
        }
        this.checkSaveBtnStatus();
    };

    // Get all details of one template (for edit purpose)
    TemplateOnView = async (templateId) => {
        await axios
            .post(
                VIEW_TEMPLATE,
                qs.stringify({
                    id: templateId,
                })
            )
            .then(async (success) => {
                var instructionsTemp = success.data.data.templateInstruction;
                var instructionsData = [];
                instructionsTemp.map((v, k) => {
                    instructionsData.push({
                        id: v.instructionId,
                        instruction: v.instruction,
                    });
                });
                var supportingTemp = success.data.data.supportingMaterial;
                var supportingData = [];
                supportingTemp.map(file=>{
                    supportingData.push({
                        id: file.id,
                        fileName: file.file_name,
                        fileUrl: file.file_url
                    })
                })
                var addedDrugsList = [];
                success.data.data.drugList.map((v, k) => {
                    addedDrugsList.push(v.drug_id);
                });
                var addedTestList = [];
                success.data.data.testList.map((v, k) => {
                    addedTestList.push(v.test_id);
                });
                this.setState({
                    templateView: success.data.data,
                    eventBased: success.data?.data?.template?.is_event_based,
                    assessmentData: success.data.data.diagnosisList,
                    drugsData: success.data.data.drugList,
                    addedDrugsList,
                    testData: success.data.data.testList,
                    addedTestList,
                    createId: success.data?.data?.template?.createdBy,
                    instructionsData: instructionsData,
                    supportingMaterial : supportingData
                    //dataLoaded: true
                });
                this.updateAllInteraction();
                if (LS_SERVICE.get("user_type") == USERTYPES.superadmin && !this.state.selectedTemplate) {
                    //for multiple select edit
                    let templateArray = [];
                    templateArray.push({
                        ownerId: success.data.data.template?.ownerId != "" ? success.data.data.template?.ownerId?.split(",") : [],
                        doctorId: success.data.data.template?.doctorId != "" ? success.data.data.template?.doctorId?.split(",") : [],
                        specialityId: success.data.data.template?.specialityId != "" ? success.data.data.template?.specialityId?.split(",") : [],
                        hospitalId: (success.data.data.template?.hospitalId != null && success.data.data.template?.hospitalId != "") ? success.data.data.template?.hospitalId?.split(",") : [],
                    });
                    LS_SERVICE.set('master_hospital_id', success.data.data.template?.master_hospital_id != null ? parseInt(success.data.data.template?.master_hospital_id) : null);
                    let data = templateArray[0];
                    if (data.ownerId !== undefined && data.ownerId !== null && data.ownerId !== "") {
                        data.ownerId = data.ownerId.map((data) =>
                            parseInt(data)
                        );
                    } else { data.ownerId = null }
                    if (data.specialityId !== undefined && data.specialityId !== null && data.specialityId !== "") {
                        data.specialityId = data.specialityId.map((data) =>
                            parseInt(data)
                        );
                    } else { data.specialityId = null }
                    if (data.doctorId !== undefined && data.doctorId !== null && data.doctorId !== "") {
                        data.doctorId = data.doctorId.map((data) =>
                            parseInt(data)
                        );
                    } else { data.doctorId = null }
                    if (data.hospitalId !== undefined && data.hospitalId !== null && data.hospitalId !== "") {
                        data.hospitalId = data.hospitalId.map((data) =>
                            parseInt(data)
                        );
                    } else { data.hospitalId = null }
                    this.setState({
                        owner_id: success.data.data.template?.ownerId || null,
                        doctor_id: success.data.data.template?.doctorId || null,
                        speciality_id: success.data.data.template?.specialityId || null,
                        hospital_id: success.data.data.template?.hospitalId || null,
                        createRoldId: success.data.data.template.createdByRoleId,
                    });
                    await this.setState({
                        templateView: success.data.data,
                        assessmentData: success.data.data.diagnosisList,
                        drugsData: success.data.data.drugList,
                        addedDrugsList,
                        testData: success.data.data.testList,
                        addedTestList,
                        instructionsData: instructionsData,
                        supportingMaterial : supportingData,
                        ownerSelected: data.ownerId ?? [],
                        OwnerDefaultValue: data.ownerId ?? [],
                        specialitySelected: data.specialityId ?? [],
                        SpecialityDefaultValue: data.specialityId ?? [],
                        doctorSelected: data.doctorId ?? [],
                        DoctorDefaultValue: data.doctorId ?? [],
                        hospitalSelected: data.hospitalId,
                        HospitalDefaultValue: data.hospitalId
                    });
                    await this.hospitalList();
                    await this.specialityList();
                    await this.doctorList();
                }
                else if (LS_SERVICE.get("user_type") == USERTYPES.admin && !this.state.selectedTemplate) {
                    //for multiple select edit
                    let templateArray = [];
                    templateArray.push({
                        ownerId: success.data.data.template?.ownerId != "" ? success.data.data.template?.ownerId?.split(",") : [],
                        doctorId: success.data.data.template?.doctorId != "" ? success.data.data.template?.doctorId?.split(",") : [],
                        specialityId: success.data.data.template?.specialityId != "" ? success.data.data.template?.specialityId?.split(",") : [],
                        hospitalId: LS_SERVICE.get("user_type") == USERTYPES.admin ? LS_SERVICE.get("admin_hospital_id") : success.data.data.template.hospitalId,
                    });
                    await this.setState({
                        owner_id: success.data.data.template.ownerId,
                        doctor_id: success.data.data.template.doctorId,
                        speciality_id: success.data.data.template.specialityId,
                        hospital_id: LS_SERVICE.get("user_type") == USERTYPES.admin ? LS_SERVICE.get("admin_hospital_id") : success.data.data.template.hospitalId,
                        createRoldId: success.data.data.template.createdByRoleId,
                        hospitalSelected: LS_SERVICE.get("user_type") == USERTYPES.admin ? LS_SERVICE.get("admin_hospital_id") : success.data.data.template.hospitalId,
                        HospitalDefaultValue: LS_SERVICE.get("user_type") == USERTYPES.admin ? LS_SERVICE.get("admin_hospital_id") : success.data.data.template.hospitalId,
                    });
                    let data = templateArray[0];
                    if (data.ownerId !== undefined && data.ownerId !== null && data.ownerId !== "") {
                        data.ownerId = data.ownerId.map((data) =>
                            parseInt(data)
                        );
                    }
                    if (data.specialityId !== undefined && data.specialityId !== null && data.specialityId !== "") {
                        data.specialityId = data.specialityId.map((data) =>
                            parseInt(data)
                        );
                    }
                    if (data.doctorId !== undefined && data.doctorId !== null && data.doctorId !== "") {
                        data.doctorId = data.doctorId.map((data) =>
                            parseInt(data)
                        );
                    }
                    await this.setState({
                        ownerSelected: data.ownerId ?? [],
                        OwnerDefaultValue: data.ownerId ?? [],
                        specialitySelected: data.specialityId ?? [],
                        SpecialityDefaultValue: data.specialityId ?? [],
                        doctorSelected: data.doctorId ?? [],
                        DoctorDefaultValue: data.doctorId ?? [],
                        hospitalSelected: data.hospitalId && data.hospitalId != "" ? parseInt(data.hospitalId) : null,
                        HospitalDefaultValue: data.hospitalId && data.hospitalId != "" ? parseInt(data.hospitalId) : null,
                        hospital_id: data.hospitalId && data.hospitalId != "" ? parseInt(data.hospitalId) : null,
                    });
                    await this.hospitalList();
                    await this.specialityList();
                    await this.doctorList();
                } else {
                    this.setState({
                        templateView: success.data.data,
                        assessmentData: success.data.data.diagnosisList,
                        drugsData: success.data.data.drugList,
                        addedDrugsList,
                        testData: success.data.data.testList,
                        addedTestList,
                        instructionsData: instructionsData,
                        supportingMaterial : supportingData,
                        createRoldId: success.data.data.template.createdByRoleId,
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    // Update the assessment data
    updateAssessmentData = (data) => {
        let assessmentData = this.state.assessmentData;
        let addedAssessmentList = [];
        var index = data.i;
        if (index == -1) index = assessmentData.length;
        assessmentData[index] = data;

        addedAssessmentList = assessmentData.map((v, k) => {
            return v.diagnosis_id;
        });

        this.setState(
            {
                assessmentData,
                addedAssessmentList,
                editData: null,
                editIndex: -1,
            },
            () => {
                this.checkSaveBtnStatus();
            }
        );
    };

    eventBased = (val) => {
        const { eventBased } = this.state;
        this.setState({
            eventBased: !eventBased
        })
    }

    // Update the medicine data
    updateMedicineData = (data, showTaper) => {
        let drugsData = this.state.drugsData;
        let addedDrugsList = [];
        var index = data.i;
        if (index == -1) index = drugsData.length;
        drugsData[index] = data;

        let showAllInteraction = false;
        addedDrugsList = drugsData.map((v, k) => {
            if(v.drug_cims && v.drug_cims!=null){
                showAllInteraction = true
            }
            return v.drug_id;
        });

        this.setState(
            {
                drugsData,
                addedDrugsList,
                editData: null,
                editIndex: -1,
                showAllInteractionBtn : showAllInteraction
            },
            () => {
                this.checkSaveBtnStatus();
                this.updateAllInteraction();
                if (!showTaper)
                    this.handleModalPopup(null, "showMedicineModal");
            }
        );
    };

    // Update the test data
    updateTestData = (data) => {
        let testData = this.state.testData;
        let addedTestList = [];
        var index = data.i;
        if (index == -1) index = testData.length;
        testData[index] = data;

        addedTestList = testData.map((v, k) => {
            return v.test_id;
        });

        this.setState(
            {
                testData,
                addedTestList,
                editData: null,
                editIndex: -1,
            },
            () => {
                this.checkSaveBtnStatus();
            }
        );
    };

    // Update the instructions data
    updateInstructionsData = (data) => {
        this.setState(
            {
                instructionsData: data,
                showInstructionModal: false,
            },
            () => {
                this.checkSaveBtnStatus();
            }
        );
    };

    async checkDataIsTapper(index){
        let data_is = this.state.drugsData;
        let index_is = parseInt(index);
        let index_length = parseInt(data_is?.length);
        let finaldata = [...data_is]?.sort((a, b) => a.drug_id - b.drug_id);
        let on_samll = false;
        let on_big = false;
        let show_taper =false;
        if(index_is != 0){
          if(finaldata[index_is -1].drug_id == finaldata[index_is].drug_id){
            on_samll = true
          }
        }
        if(index_is+1 != index_length){
          if(finaldata[index_is +1].drug_id == finaldata[index_is].drug_id){
            on_big = true
          }
        }
        if(on_samll || on_big){
          show_taper = true;
        }
        console.log("show_taper",show_taper)
        return show_taper;
      }

    // populate the details on form for edit (medicine)
    handleEdit = async(data, i) => {
        let showtapper = await this.checkDataIsTapper(i);
        this.setState({
            editData: null,
            editIndex: -1,
            showtapper: showtapper
        });
        let finaldata = [...this.state.drugsData]?.sort((a, b) => a.drug_id - b.drug_id);
        this.setState(
            {
                actionText: "Edit",
                editData: finaldata[i],
                editIndex: i,
            },
            () => {
                const tabId = document.getElementById(
                    "create-medicine-details"
                );
                window.scrollTo({
                    top: tabId.offsetTop - 10,
                    behavior: "smooth",
                });
            }
        );
        AntSelectLabels();
    };

    // Handle all the popup models
    handleModalPopup = (
        e,
        popupName,
        data = null,
        actionType = "",
        objId = null,
        patientDocs
    ) => {
        if (e !== null) e.preventDefault();
        this.setState(
            {
                [popupName]: !this.state[popupName],
            },
            () => {
                const { viewDocumentModal } = this.state;

                if (viewDocumentModal) {
                    this.carouselRef.current.goTo(patientDocs);
                }

                this.setState(
                    {
                        actionText: "Add",
                    },
                    () => {
                        const {
                            showVitalsModal,
                            showAssessmentModal,
                            showMedicineModal,
                            showInstructionModal,
                            showSupportingModal,
                            showTestModal,
                            deleteConfirmModal,
                            showDrugAllergyModal,
                            showDrugPolyPharmacyModal,
                        } = this.state;
                        if (showVitalsModal) {
                            this.setState(
                                {
                                    V001: this.state.patientHeight,
                                    V002: this.state.patientWeight,
                                },
                                () => {
                                    this.handleBmiCalculate();
                                    AntSelectLabels();
                                }
                            );
                        }

                        if (showAssessmentModal) {
                            if (actionType === "editAssessment") {
                                this.setState({
                                    actionText: "Edit",
                                    editData: this.state.assessmentData[objId],
                                    editIndex: objId,
                                });
                                AntSelectLabels();
                            }
                        }
                        if (showMedicineModal) {
                            /*if (actionType === 'editMedicines') {
                this.setState({ actionText: "Edit" })
              }*/
                            if (actionType === "editMedicines") {
                                this.setState({
                                    actionText: "Edit",
                                    editData:
                                        data !== null
                                            ? this.state.drugsData[objId]
                                            : null,
                                    editIndex: data !== null ? objId : -1,
                                });
                            }
                        }

                        if (showInstructionModal) {
                            // const { allInstructionsData } = this.state;
                            // this.setState({
                            //   isNewInstruction: false,
                            //   instructionOption: allInstructionsData
                            // });
                        }

                        if (deleteConfirmModal) {
                            // this.setState({ actionType, objId });
                            // For Assessment
                            if (actionType === "deleteAssessment") {
                                this.setState({
                                    deleteActionType: actionType,
                                    deleteObjId: objId,
                                    deleteMessage:
                                        "Are you sure you want to delete assessment?",
                                });
                            }

                            // For Medicines
                            if (actionType === "deleteMedicine") {
                                this.setState({
                                    deleteActionType: actionType,
                                    deleteObjId: objId,
                                    deleteMessage:
                                        "Are you sure you want to delete medicine?",
                                });
                            }

                            // For Tests
                            if (actionType === "deleteTest") {
                                this.setState({
                                    deleteActionType: actionType,
                                    deleteObjId: objId,
                                    deleteMessage:
                                        "Are you sure you want to delete test?",
                                });
                            }
                        }

                        if (showDrugAllergyModal) {
                            this.setState({
                                allergiesMessage: data.message,
                                deleteObjId:
                                    data.type == "all" ? data.deleteObj : objId,
                            });
                        }

                        if (showDrugPolyPharmacyModal) {
                            this.setState({
                                polyPharmacyMessage: data.message,
                                deleteObjId: data.type == "all" ? null : objId,
                            });
                        }
                    }
                );
            }
        );
    };

    // handle the confirm popup for delete for all (i.e. Are you sure you want to delete?)
    handleOnItemDelete = (e, actionType, objId) => {
        e.preventDefault();

        var stateVariable = null;
        var stateAddedVariable = null;
        var propKey = null;
        // For Assessment
        if (actionType === "deleteAssessment") {
            stateVariable = "assessmentData";
            stateAddedVariable = "addedAssessmentList";
            propKey = "diagnosis_id";
        }
        // For Medicine
        if (actionType === "deleteMedicine") {
            stateVariable = "drugsData";
            stateAddedVariable = "addedDrugsList";
            propKey = "drug_id";
        }
        // For Test
        if (actionType === "deleteTest") {
            stateVariable = "testData";
            stateAddedVariable = "addedTestList";
            propKey = "test_id";
        }

        let dataArray = this.state[stateVariable];
        if (actionType === "deleteMedicine") {
            dataArray = [...dataArray]?.sort((a, b) => a.drug_id - b.drug_id);;
        }
        dataArray.splice(objId, 1);
        let addedArray = [];
        dataArray.map((v, k) => {
            addedArray.push(v[propKey]);
        });
        
        this.setState(
            {
                deleteConfirmModal: false,
                [stateVariable]: dataArray,
                [stateAddedVariable]: addedArray,
               
            },
            () => {
                this.checkSaveBtnStatus();
                this.updateAllInteraction();
            }
        );
    };

    updateAllInteraction = () =>{
        let { drugsData } = this.state;
        let showInteraction = false;
        if (drugsData.length > 0) {
            drugsData.map(obj => {
                if (obj.drug_cims && obj.drug_cims != null) {
                    showInteraction = true;
                }
            })
        }
        this.setState({
            showAllInteractionBtn: showInteraction
        })
    }

    // Create/Update new template
    saveTemplate = () => {
        this.setState({ disableConfirmBtn: true, spin_loading: true });

        let {
            templateId,
            templateName,
            assessmentData,
            drugsData,
            testData,
            instructionsData,
            supportingMaterial
        } = this.state;

        drugsData.map((v, k) => {
            delete drugsData[k]["previousDrug"];
            delete drugsData[k]["templateId"];
            delete drugsData[k]["i"];
            delete drugsData[k]["id"];
            delete drugsData[k]["prescription_drug_id"];
            delete drugsData[k]["active"];
            delete drugsData[k]["drug_cims"];
        });
        testData.map((v, k) => {
            delete testData[k]["templateId"];
            delete testData[k]["is_requistion"];
            delete testData[k]["i"];
            delete testData[k]["fav"];
        });

        var other_details = {};
        if (
            LS_SERVICE.get("user_type") == USERTYPES.superadmin ||
            LS_SERVICE.get("user_type") == USERTYPES.admin
        ) {
            other_details = {
                hospital_id: this.state.hospital_id,
                doctor_id: this.state.doctor_id,
                owner_id: this.state.owner_id,
                speciality_id: this.state.speciality_id,
                is_event_based: this.state.eventBased || 'N'
            };
        } else {
            other_details = {
                hospital_id: LS_SERVICE.get("slot_hospital_id"),
                speciality_id: LS_SERVICE.get("speciality_id"),
                owner_id: LS_SERVICE.get("staff_id"),
                is_event_based: this.state.eventBased || 'N',
                logined_id: LS_SERVICE.get("staff_id"),
                login_role: 'doctor',
                hospital_master: 'N',
            };
        }
        var params = {
            name: templateName,
            diagnosis_data: assessmentData,
            drugs_data: drugsData,
            tests_data: testData,
            instruction: instructionsData,
            supporting_material_data: supportingMaterial,
            ...other_details,
        };

        var saveUrl = CREATE_TEMPLATE;
        if (templateId != "" && !isNaN(templateId)) {
            if (LS_SERVICE.get("user_type") != USERTYPES.superadmin) {
                if (LS_SERVICE.get("user_type") == USERTYPES.doctor && this.state.createRoldId != USERTYPES.doctor) {
                    saveUrl = CREATE_TEMPLATE;
                }
                else if (LS_SERVICE.get("user_type") == USERTYPES.doctor && this.state.createRoldId == USERTYPES.doctor && LS_SERVICE.get("staff_id") != this.state.createId) {
                    saveUrl = CREATE_TEMPLATE;
                }
                else if (LS_SERVICE.get("user_type") == USERTYPES.admin && this.state.createRoldId == USERTYPES.superadmin) {
                    saveUrl = CREATE_TEMPLATE;
                }
                else {
                    params.template_id = templateId;
                    saveUrl = EDIT_TEMPLATE;
                }
            }
            else {
                params.template_id = templateId;
                saveUrl = EDIT_TEMPLATE;
            }
            // if (this.state.createRoldId === LS_SERVICE.get("user_type")) {
            //     params.template_id = templateId;
            //     saveUrl = EDIT_TEMPLATE;
            // } else {
            //     saveUrl = CREATE_TEMPLATE;
            // }
        }

        axios
            .post(saveUrl, qs.stringify(params))
            .then((success) => {
                if (success.data.status) {
                    this.setState({
                        spin_loading: false
                    });
                    this.props.history.push({
                        pathname: `/template-list`,
                    });
                } else {
                    this.setState({
                        spin_loading: false
                    });
                    this.setState(
                        { alertMessage: success.data.data.name + " already exists . Kindly review the same or use a different name" },
                        () => {
                            this.showTemplateErrorModal();
                        }
                    );
                }
            })
            .catch((err) => console.log(err));
    };

    // Clear the edit form (medicine / tests)
    clearEdit = () => {
        this.setState({
            editData: null,
            editIndex: -1,
        });
    };

    // Enable / Disable submit button based on the status
    checkSaveBtnStatus = () => {
        const { templateName, assessmentData, drugsData, testData, isHospitalHaveMaster } = this.state;
        var allowSave = true;
        if (templateName == "") allowSave = false;
        if (assessmentData.length == 0) allowSave = false;
        if (drugsData.length == 0 && testData.length == 0) allowSave = false;
        if (LS_SERVICE.get("user_type") !== USERTYPES.doctor && (!this.state.hospital_id || this.state.hospital_id == "")) {
            allowSave = false;
        }
        if (LS_SERVICE.get("user_type") == USERTYPES.superadmin && isHospitalHaveMaster) {
            allowSave = false;
        }
        this.setState({ disableConfirmBtn: !allowSave });
    };

    /*edittemplate = () => {
    this.setState({ disableConfirmBtn:true })
    if(this.drugs_data.length==0) this.medicine(this.state.templateView.drugList);
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get('token');
    axios.post(EDIT_TEMPLATE, qs.stringify({
      name: this.name,
      template_id: this.state.templateId,
      diagnosis_data: this.diagnosis_data,
      drugs_data: this.drugs_data,
      tests_data: this.tests_data,
      instruction: this.instruction_data
    })).then(success => {
      if (success.data.status) {
        this.props.history.push({
          pathname: `/template-list`
        })
      }
      else {
        this.setState({ alertMessage: success.data.message }, () => {
          this.showTemplateErrorModal()
        })
      }
    }).catch(err => console.log(err))
  }*/


  handleAllInteractionCancel = (e) => { this.setState({ isModalAllInteractionVisible: false }); };

    handleAllInteractionOk = () => { this.setState({ isModalAllInteractionVisible: false }) };

    showModalInteraction = () => {

        let { addedAssessmentList, addedDrugsList } = this.state;

        if (typeof addedAssessmentList == 'undefined' || !addedAssessmentList || addedAssessmentList.length === 0 || addedAssessmentList === "") {
            addedAssessmentList = 0;
        } else {

            var result = Array.isArray(addedAssessmentList);
            if (result) {
                addedAssessmentList = addedAssessmentList.join('-');
            }
            else {
                if (typeof addedAssessmentList == 'undefined' || !addedAssessmentList || addedAssessmentList.length === 0 || addedAssessmentList === "") {
                    addedAssessmentList = 0;
                } else {
                    addedAssessmentList = addedAssessmentList.split(',');
                    addedAssessmentList = addedAssessmentList.join('-');
                }
            }
        }
        if (typeof addedDrugsList == 'undefined' || !addedDrugsList || addedDrugsList.length === 0 || addedDrugsList === "") {
            addedDrugsList = 0;
        } else {
            if (addedDrugsList && addedDrugsList.length > 0) {
                addedDrugsList = addedDrugsList.join('-');
            } else {
                addedDrugsList = 0;
            }
        }
        let medicineScreen = 'careprotocol';
        let referenceId = LS_SERVICE.get("call_patient_qms_token_id");
        let StoreId = LS_SERVICE.get("staff_id") ? LS_SERVICE.get("staff_id") : 0;
        let FacilityId = LS_SERVICE.get("FACILITY_ID") ? LS_SERVICE.get("FACILITY_ID") : 0;
        let enterpriseId = LS_SERVICE.get("ENTERPRISE_ID") ? LS_SERVICE.get("ENTERPRISE_ID") : 0;
        let patientId = LS_SERVICE.get("call_patient_id") ? LS_SERVICE.get("call_patient_id") : 0;
        let referenceType = 0;
        let orederSetId = 0;
        let careProtoColId = 0;
        let showAllInteractionModal = false;

        let isHospitalAllowed = CIMS_ALLOW_HOSPITALS.includes(this.state.hospitalId);

        if (CIMSALLOW && isHospitalAllowed) {
            let drugIds = addedDrugsList;
            axios.get(ALL_CIMS_INTERACTION_EXISTS({ drugIds, referenceType, referenceId, orederSetId, patientId, enterpriseId, FacilityId, StoreId, careProtoColId, medicineScreen, addedAssessmentList }))
                .then(success => {
                    if (success.data.interaction) {
                        showAllInteractionModal = true;
                        this.setState({ drug_id_Interaction_Modalurl: drugIds, referenceType: referenceType, referenceId: referenceId, orederSetId: orederSetId, enterpriseId: enterpriseId, FacilityId: FacilityId, StoreId: StoreId, patientId: patientId, addedAssessmentList: addedAssessmentList, medicineScreen: medicineScreen, isModalAllInteractionVisible: showAllInteractionModal });
                    } else {
                        notification.error({
                            message: `No interactions Found`,
                            placement: 'topRight'
                        })
                    }
                })
                .catch(err => console.log(err))
        } else {
            this.setState({ isModalAllInteractionVisible: false })
        }
    };



    getMaterialData = (materialData) => {
        this.setState({ allSupportingMaterial: materialData });
    }


    saveSupportingMaterial = async _ => {
        this.setState({ supportingMaterial: this.state.allSupportingMaterial.filter(file => file.selected) }, () => {
            this.checkSaveBtnStatus();
        });
        this.handleModalPopup(null, 'showSupportingModal');

    }

    render() {
        const {
            ownerSelected,
            specialitySelected,
            doctorSelected,
            TemplateList,
            addedDrugsList,
            addedAssessmentList
        } = this.state;
        return (
            <>
                <div className="container-fluid">
                    <div
                        className="row"
                        id="data-preloader"
                        style={
                            this.state.dataLoaded ? { display: "none" } : null
                        }
                    >
                        <div className="col-12">
                            <div className="text-center">{LOADER_RED}</div>
                        </div>
                    </div>
                    <div
                        className="px-2"
                        id="main-page-container"
                        style={
                            !this.state.dataLoaded ? { display: "none" } : null
                        }
                    >
                        <div className="row mt-2">
                            <h1 className="col-12 page-title">
                                {this.state.pagename}
                            </h1>
                            <div className="col-12 emrfrm">
                                <div className="row">
                                    {LS_SERVICE.get("user_type") ==
                                        USERTYPES.doctor ? (
                                        <div className="col-12">
                                            <div className="col-md-12 mt-2">
                                                <div className="row">
                                                    <div className="col-12 col-md-10">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                id="template-name"
                                                                name="template_name"
                                                                onChange={this.savename}
                                                                value={this.state.templateName}
                                                                className="form-control"
                                                                placeholder="Care Protocol Name"
                                                                maxLength="80"
                                                            />
                                                            <label htmlFor="template-name">
                                                                Care Protocol Name<span className="text-danger">*</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 text-right">
                                                        <div className="custom-control custom-checkbox custom-control-inline mt-2" style={{ display: 'inline-flex' }}>
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                id="event_based"
                                                                name="event_based"
                                                                checked={this.state.eventBased}
                                                                value={this.state.eventBased}
                                                                disabled={this.state.testData?.length > 0 || this.state.drugsData?.length > 0 ? true : false}
                                                                onChange={() => this.eventBased(this.state.drug_id)}
                                                            />
                                                            <label style={{ fontSize: "16px" }} className="custom-control-label text-secondary cursor-pointer" htmlFor="event_based">
                                                                Event Based
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="col-12">
                                            <div className="col-12 mt-2">
                                                <div className="row">
                                                    <div className="col-12 col-md-3">
                                                        <div className="form-group">
                                                            <Select
                                                                showSearch
                                                                id="hospital_name"
                                                                placeholder="Hospital"
                                                                name="hospital_name"
                                                                style={{
                                                                    width:
                                                                        100 +
                                                                        "%",
                                                                }}
                                                                disabled={LS_SERVICE.get("user_type") == USERTYPES.admin ? true : false}
                                                                onChange={this.selectHospitalChange}
                                                                className=""
                                                                allowClear
                                                                mode={
                                                                    LS_SERVICE.get(
                                                                        "user_type"
                                                                    ) ==
                                                                        USERTYPES.superadmin
                                                                        ? "multiple"
                                                                        : "single"
                                                                }
                                                                defaultValue={
                                                                    this.state
                                                                        .HospitalDefaultValue
                                                                }
                                                                value={
                                                                    this.state
                                                                        .HospitalDefaultValue
                                                                }
                                                                // value={
                                                                //     this.state
                                                                //         .hospitalSelected
                                                                // }
                                                                filterOption={(
                                                                    input,
                                                                    option
                                                                ) => {
                                                                    return (
                                                                        option.children
                                                                            .toString()
                                                                            .toLowerCase()
                                                                            .indexOf(
                                                                                input
                                                                                    .toString()
                                                                                    .toLowerCase()
                                                                            ) >=
                                                                        0
                                                                    );
                                                                }}
                                                                notFoundContent={
                                                                    this.state
                                                                        .spinner ? (
                                                                        <Spin size="small" />
                                                                    ) : (
                                                                        <div>
                                                                            No
                                                                            Result
                                                                            Found
                                                                        </div>
                                                                    )
                                                                }
                                                            >
                                                                <Option dataid={{ hospital_id: -1 }} value={-1} key={-1}>
                                                                    MHC Hospitals
                                                                </Option>
                                                                {this.selectHospitalList()}
                                                            </Select>
                                                            <label htmlFor="hospital_name">
                                                                Hospital
                                                                <span className="text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-3 ">
                                                        <div className="form-group ">
                                                            <Select
                                                                showSearch
                                                                id="speciality_name"
                                                                name="speciality_name"
                                                                style={{
                                                                    width:
                                                                        100 +
                                                                        "%",
                                                                }}
                                                                onChange={
                                                                    this
                                                                        .selectSpecialityChange
                                                                }
                                                                className=""
                                                                defaultValue={
                                                                    this.state
                                                                        .SpecialityDefaultValue
                                                                }
                                                                value={
                                                                    this.state
                                                                        .SpecialityDefaultValue
                                                                }
                                                                // value={
                                                                //     specialitySelected
                                                                // }
                                                                mode="multiple"
                                                                allowClear
                                                                filterOption={(
                                                                    input,
                                                                    option
                                                                ) => {
                                                                    return (
                                                                        option.children
                                                                            .toString()
                                                                            .toLowerCase()
                                                                            .indexOf(
                                                                                input
                                                                                    .toString()
                                                                                    .toLowerCase()
                                                                            ) >=
                                                                        0
                                                                    );
                                                                }}
                                                                notFoundContent={
                                                                    this.state
                                                                        .spinner ? (
                                                                        <Spin size="small" />
                                                                    ) : (
                                                                        <div>
                                                                            No
                                                                            Result
                                                                            Found
                                                                        </div>
                                                                    )
                                                                }
                                                            >
                                                                {this.selectSpecialityList()}
                                                            </Select>
                                                            <label htmlFor="speciality_name">
                                                                Speciality
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-3">
                                                        <div className="form-group">
                                                            <Select
                                                                showSearch
                                                                id="doctor_name"
                                                                name="doctor_name"
                                                                style={{
                                                                    width:
                                                                        100 +
                                                                        "%",
                                                                }}
                                                                onChange={
                                                                    this
                                                                        .selectDoctorChange
                                                                }
                                                                className=""
                                                                mode="multiple"
                                                                value={
                                                                    this.state
                                                                        .DoctorDefaultValue
                                                                }
                                                                allowClear
                                                                filterOption={(
                                                                    input,
                                                                    option
                                                                ) => {
                                                                    return (
                                                                        option.children
                                                                            .toString()
                                                                            .toLowerCase()
                                                                            .indexOf(
                                                                                input
                                                                                    .toString()
                                                                                    .toLowerCase()
                                                                            ) >=
                                                                        0
                                                                    );
                                                                }}
                                                                notFoundContent={
                                                                    this.state
                                                                        .spinner ? (
                                                                        <Spin size="small" />
                                                                    ) : (
                                                                        <div>
                                                                            No
                                                                            Result
                                                                            Found
                                                                        </div>
                                                                    )
                                                                }
                                                            >
                                                                {this.selectDoctorList()}
                                                            </Select>
                                                            <label htmlFor="doctor_name">
                                                                Doctor's Name
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-3">
                                                        <div className="form-group">
                                                            <Select
                                                                showSearch
                                                                id="owner_name"
                                                                name="owner_name"
                                                                style={{
                                                                    width:
                                                                        100 +
                                                                        "%",
                                                                }}
                                                                onChange={
                                                                    this
                                                                        .selectOwnerChange
                                                                }
                                                                className=""
                                                                mode="multiple"
                                                                defaultValue={
                                                                    this.state
                                                                        .OwnerDefaultValue
                                                                }
                                                                value={
                                                                    this.state
                                                                        .OwnerDefaultValue
                                                                }
                                                                allowClear
                                                                filterOption={(
                                                                    input,
                                                                    option
                                                                ) => {
                                                                    return (
                                                                        option.children
                                                                            .toString()
                                                                            .toLowerCase()
                                                                            .indexOf(
                                                                                input
                                                                                    .toString()
                                                                                    .toLowerCase()
                                                                            ) >=
                                                                        0
                                                                    );
                                                                }}
                                                                notFoundContent={
                                                                    this.state
                                                                        .spinner ? (
                                                                        <Spin size="small" />
                                                                    ) : (
                                                                        <div>
                                                                            No
                                                                            Result
                                                                            Found
                                                                        </div>
                                                                    )
                                                                }
                                                            >
                                                                {this.selectDoctorList()}
                                                            </Select>
                                                            <label htmlFor="owner_name">
                                                                Owner
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {this.state.isEdit ==
                                                        "edit" ? (
                                                        <>
                                                            <div className="col-12 col-md-10">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        id="template-name"
                                                                        name="template_name"
                                                                        onChange={
                                                                            this
                                                                                .savename
                                                                        }
                                                                        value={
                                                                            this
                                                                                .state
                                                                                .templateName
                                                                        }
                                                                        className="form-control"
                                                                        placeholder="Care Protocol Name"
                                                                        maxLength="80"
                                                                    />
                                                                    <label htmlFor="template-name">
                                                                        Care
                                                                        Protocol
                                                                        Name
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2 text-right">
                                                                <div className="custom-control custom-checkbox custom-control-inline mt-2" style={{ display: 'inline-flex' }}>
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        id="event_based"
                                                                        name="event_based"
                                                                        checked={this.state.eventBased}
                                                                        value={this.state.eventBased}
                                                                        onChange={() => this.eventBased(this.state.drug_id)}
                                                                    />
                                                                    <label style={{ fontSize: "16px" }} className="custom-control-label text-secondary cursor-pointer" htmlFor="event_based">
                                                                        Event Based
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="col-12 col-md-5">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        id="template-name"
                                                                        name="template_name"
                                                                        onChange={
                                                                            this
                                                                                .savename
                                                                        }
                                                                        value={
                                                                            this
                                                                                .state
                                                                                .templateName
                                                                        }
                                                                        className="form-control"
                                                                        placeholder="Care Protocol Name"
                                                                        maxLength="80"
                                                                    />
                                                                    <label htmlFor="template-name">
                                                                        Care
                                                                        Protocol
                                                                        Name
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4">
                                                                <div className="form-group">
                                                                    <Select
                                                                        showSearch
                                                                        id="copy_from_list"
                                                                        name="copy_from_list"
                                                                        onChange={
                                                                            this
                                                                                .selectCopyFromChange
                                                                        }
                                                                        style={{
                                                                            width:
                                                                                100 +
                                                                                "%",
                                                                        }}
                                                                        className=""
                                                                        allowClear
                                                                        filterOption={(
                                                                            input,
                                                                            option
                                                                        ) => {
                                                                            return (
                                                                                option.children
                                                                                    .toString()
                                                                                    .toLowerCase()
                                                                                    .indexOf(
                                                                                        input
                                                                                            .toString()
                                                                                            .toLowerCase()
                                                                                    ) >=
                                                                                0
                                                                            );
                                                                        }}
                                                                        notFoundContent={
                                                                            this
                                                                                .state
                                                                                .spinner ? (
                                                                                <Spin size="small" />
                                                                            ) : (
                                                                                <div>
                                                                                    No
                                                                                    Result
                                                                                    Found
                                                                                </div>
                                                                            )
                                                                        }
                                                                    >
                                                                        {this
                                                                            .state
                                                                            .TemplateList &&
                                                                            TemplateList !==
                                                                            undefined &&
                                                                            TemplateList.length >
                                                                            0
                                                                            ? TemplateList.map(
                                                                                (
                                                                                    d
                                                                                ) => (
                                                                                    ((LS_SERVICE.get("user_type") == USERTYPES.superadmin && d.own_master == 'N' && !LS_SERVICE.get('master_hospital_id')) || (LS_SERVICE.get("user_type") == USERTYPES.superadmin && LS_SERVICE.get('master_hospital_id')) || (LS_SERVICE.get("user_type") != USERTYPES.superadmin)) ?
                                                                                        <Option
                                                                                            dataid={
                                                                                                d
                                                                                            }
                                                                                            value={
                                                                                                d.id
                                                                                            }
                                                                                            key={
                                                                                                d.id
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                d.name
                                                                                            }
                                                                                        </Option>
                                                                                        : null
                                                                                )
                                                                            )
                                                                            : null}
                                                                    </Select>
                                                                    <label htmlFor="copy_from_list">
                                                                        Copy
                                                                        from
                                                                        list
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-2">
                                                                <div className="custom-control custom-checkbox custom-control-inline mt-2" style={{ display: 'inline-flex' }}>
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        id="event_based"
                                                                        name="event_based"
                                                                        checked={this.state.eventBased}
                                                                        value={this.state.eventBased}
                                                                        onChange={() => this.eventBased(this.state.drug_id)}
                                                                    />
                                                                    <label style={{ fontSize: "16px" }} className="custom-control-label text-secondary cursor-pointer" htmlFor="event_based">
                                                                        Event Based
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-1">
                                                                <div className="float-right py-1">
                                                                    <Button
                                                                        type="button"
                                                                        className="btn btn-primary px-4 small text-uppercase"
                                                                        id="drug-save"
                                                                        data-drug="-1"
                                                                        data-prescriptionid="-1"
                                                                        disabled={
                                                                            this
                                                                                .state
                                                                                .copybuttonchk
                                                                        }
                                                                        onClick={() =>
                                                                            this.copyFromTemplate()
                                                                        }
                                                                    >
                                                                        Copy
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {this.state.dataLoaded ? (
                                        <>
                                            <div className="col-md-12 pt-2">
                                                <AssessmentForm
                                                    formType={"template"}
                                                    actionText={
                                                        this.state.actionText
                                                    }
                                                    updateData={
                                                        this
                                                            .updateAssessmentData
                                                    }
                                                    // editData={
                                                    //     this.state.editData
                                                    // }
                                                    autofocus={false}
                                                    editIndex={
                                                        this.state.editIndex
                                                    }
                                                    addedAssessmentList={
                                                        this.state
                                                            .addedAssessmentList
                                                    }
                                                    handleTemplateRadioSubmit={
                                                        null
                                                    }
                                                    clearEdit={this.clearEdit}
                                                    addedDrugsList={this.state.addedDrugsList}
                                                />
                                            </div>
                                            <div className="col-md-12 my-4">
                                                <AssessmentTable
                                                    assessmentData={
                                                        this.state
                                                            .assessmentData
                                                    }
                                                    viewType={"template"}
                                                    statusSwitch={
                                                        this.handleStatusSwitch
                                                    }
                                                    showAction={true}
                                                    showSelectCheck={false}
                                                    constants={
                                                        this.state.constants
                                                    }
                                                    updateSelection={
                                                        this.handleSelectCheck
                                                    }
                                                    handlePopUp={
                                                        this.handleModalPopup
                                                    }
                                                />
                                            </div>
                                        </>
                                    ) : null}

                                    {this.state.dataLoaded ? (
                                        <>
                                            <MedicineForm
                                                ref={this.childMedicine}
                                                formType={"template"}
                                                actionText={this.state.actionText}
                                                updateData={this.updateMedicineData}
                                                editData={this.state.editData}
                                                eventBased={this.state.eventBased}
                                                drugsDataCount={this.state.drugsData?.length || 0}
                                                autofocus={false}
                                                editIndex={this.state.editIndex}
                                                addedDrugsList={this.state.addedDrugsList}
                                                constants={this.state.constants}
                                                patient={this.state.patient}
                                                clearEdit={this.clearEdit}
                                                showtapper={this.state.showtapper}
                                                medicineFlag={	
                                                    this.state.medicineFlag	
                                                }	
                                                addedAssessmentList={	
                                                    this.state	
                                                        .addedAssessmentList	
                                                }
                                            />
                                            <div className="col-md-12 my-4">
                                                <MedicineTable
                                                    drugsData={this.state.drugsData}
                                                    viewType={"template"}
                                                    showAction={true}
                                                    showSelectCheck={false}
                                                    eventBased={this.state.eventBased}
                                                    constants={this.state.constants}
                                                    handlePopUp={this.handleModalPopup}
                                                    handleEdit={this.handleEdit}
                                                />
                                            </div>
                                        </>
                                    ) : null}

                                    {this.state.dataLoaded ? (
                                        <>
                                            <TestForm
                                                ref={this.childTest}
                                                formType={"template"}
                                                actionText={this.state.actionText}
                                                updateData={this.updateTestData}
                                                // editData={this.state.editData}
                                                eventBased={this.state.eventBased}
                                                testDataCount={this.state.testData?.length || 0}
                                                autofocus={false}
                                                editIndex={this.state.editIndex}
                                                addedTestList={this.state.addedTestList}
                                                constants={this.state.constants}
                                                clearEdit={this.clearEdit}
                                                hospital_id={this.state.hospital_id}
                                            />
                                            <div className="col-md-12 my-4">
                                                <TestTable
                                                    testData={this.state.testData}
                                                    viewType={"template"}
                                                    eventBased={this.state.eventBased}
                                                    showAction={true}
                                                    showSelectCheck={false}
                                                    constants={this.state.constants}
                                                    handlePopUp={this.handleModalPopup}
                                                />
                                            </div>
                                        </>
                                    ) : null}

                                    {this.state.dataLoaded ? (
                                        <>
                                            <div className="col-12">
                                                <div className="float-left">
                                                    <h3 className="page-title mt-2">
                                                        Instructions
                                                    </h3>
                                                </div>
                                                <div className="text-right">
                                                    <a
                                                        href="#"
                                                        className="btn btn-outline-primary small"
                                                        onClick={(e) =>
                                                            this.handleModalPopup(
                                                                e,
                                                                "showInstructionModal"
                                                            )
                                                        }
                                                    >
                                                        + INSTRUCTIONS
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-12 mb-3 pb-5">
                                                <ul className="custom-list list-success">
                                                    <InstructionTable
                                                        instructionsData={
                                                            this.state
                                                                .instructionsData
                                                        }
                                                        viewType={"full"}
                                                        constants={
                                                            this.state.constants
                                                        }
                                                    />
                                                </ul>
                                            </div>
                                        </>
                                    ) : null}

                                    {this.state.dataLoaded ? (
                                        <>
                                            <div className="col-12">
                                                <div className="float-left">
                                                    <h3 className="page-title mt-2">
                                                        Supporting Material
                                                    </h3>
                                                </div>
                                                <div className="text-right">
                                                    <a
                                                        href="#"
                                                        className="btn btn-outline-primary small"
                                                        onClick={(e) =>
                                                            this.handleModalPopup(
                                                                e,
                                                                "showSupportingModal"
                                                            )
                                                        }
                                                    >
                                                        + SUPPORTING MATERIAL
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-12 mb-5 pb-5">
                                                <SupportingMaterialTable
                                                    supportingMaterialData={this.state.supportingMaterial}
                                                    viewType={'full'}
                                                    hospitalId={this.state.hospital_id}
                                                    constants={this.state.constants} />
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                                <div className="fixed-cta full">
                                    {
                                        CIMSALLOW && CIMS_ALLOW_HOSPITALS.includes(this.state.hospitalId) 
                                        && this.state.showAllInteractionBtn 
                                        ?
                                            <Button
                                                type="submit"
                                                className="btn btn-outline-secondary px-4 text-uppercase template-save mr-2"
                                                onClick={() => this.showModalInteraction()}>
                                                <i className="icon-medinfo drug_info mr-2" style={{ width: '20px', height: '20px', verticalAlign: 'middle' }}></i>
                                                VIEW ALL INTERACTIONS
                                            </Button> :
                                            null
                                    }
                                    <Button
                                        type="submit"
                                        className="btn btn-primary px-5 text-uppercase template-save"
                                        disabled={this.state.disableConfirmBtn || this.state.spin_loading}
                                        onClick={() => this.saveTemplate()}
                                    >
                                        Save Care Protocol {this.state.spin_loading ? LOADER : ''}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* For Modal Instruction  */}
                {this.state.showInstructionModal ? (
                    <Modal
                        title={this.state.actionText + " Instructions"}
                        visible={this.state.showInstructionModal}
                        onCancel={(e) =>
                            this.handleModalPopup(e, "showInstructionModal")
                        }
                        footer={false}
                    // bodyStyle={{ padding: "10px 20px" }}
                    >
                        {this.state.showInstructionModal ? (
                            <InstructionsForm
                                actionText={this.state.actionText}
                                updateData={this.updateInstructionsData}
                                addedInstructionsData={
                                    this.state.instructionsData
                                }
                                handleModalPopup={this.handleModalPopup}
                            />
                        ) : null}
                    </Modal>
                ) : null}

                {/* For Modal Supporting Material */}
                {this.state.showSupportingModal ?
                    <Modal
                        title={false}
                        visible={this.state.showSupportingModal}
                        onCancel={(e) => this.handleModalPopup(e, 'showSupportingModal')}
                        width="850px"
                        footer={false}
                    // bodyStyle={{ padding: "10px 20px" }}
                    >
                        <form className="template-form" autoComplete="off" encType="multipart/form-data">
                            <div className="pb-5">
                                {this.state.showSupportingModal ? <SupportingMaterial
                                    formType={"template"}
                                    templateId={this.state.templateId}
                                    actionText={this.state.actionText}
                                    isEdit={this.state.isEdit}
                                    setMaterialData={(data) => this.getMaterialData(data)}
                                    allSupportingMaterial={this.state.allSupportingMaterial}
                                    supportingMaterial={this.state.supportingMaterial}
                                /> : null}
                            </div>
                            <div className="modal-footer pb-0">
                                <div className="col-12 text-center instructions_cta">
                                    <button type="button" className="btn btn-outline-secondary px-4 mr-2" onClick={(e) => this.handleModalPopup(e, 'showSupportingModal')}>CANCEL</button>
                                    <div className="btn btn-primary px-4 text-uppercase confirm-instructions"
                                        onClick={this.saveSupportingMaterial}>SAVE</div>
                                </div>
                            </div>
                        </form>
                    </Modal>
                    : null
                }

                {/* For Modal Delete Confirm  */}
                <Modal
                    title={false}
                    visible={this.state.deleteConfirmModal}
                    onCancel={(e) =>
                        this.handleModalPopup(e, "deleteConfirmModal")
                    }
                    footer={false}
                    closable={false}
                >
                    <div className="row mb-3">
                        <h6 className="col-12 text-center">
                            {this.state.deleteMessage}
                        </h6>
                    </div>
                    <div className="modal-footer justify-content-center emrfrm pb-0">
                        <button
                            type="button"
                            className="btn btn-outline-secondary px-5  mr-2"
                            onClick={(e) =>
                                this.handleModalPopup(e, "deleteConfirmModal")
                            }
                        >
                            CANCEL
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary px-5"
                            onClick={(e) =>
                                this.handleOnItemDelete(
                                    e,
                                    this.state.deleteActionType,
                                    this.state.deleteObjId
                                )
                            }
                        >
                            DELETE
                        </button>
                    </div>
                </Modal>

                {/* For Modal Error  */}
                <Modal
                    className="templateError"
                    id="templateError"
                    title={this.state.alertMessage}
                    visible={this.state.istemplateErrorVisible}
                    onOk={() => this.handleTemplateErrorOk()}
                    onCancel={() => this.handleTemplateErrorCancel()}
                    footer={[
                        <Button
                            type="button"
                            className="btn btn-outline-primary px-5"
                            onClick={() => this.handleTemplateErrorCancel()}
                        >
                            OK
                        </Button>,
                    ]}
                ></Modal>

                {/* Modal For Hospital Master Error  */}
                <Modal
                    title="Hospital Master Error"
                    visible={this.state.isHospitalHaveMasterVisible}
                    onCancel={() => this.handleHospitalMasterError()}
                    footer={false}
                >
                    <div className="row mb-3">
                        <h6 className="col-12 text-center">
                            Error! Protocols for multiple hospitals selection can only be created if all selected hospitals don't have their own masters. <br /><br />
                            Protocol can't be created for this combination.
                        </h6>
                    </div>
                    <div className="modal-footer justify-content-center emrfrm pb-0">
                        <button
                            type="button"
                            className="btn btn-primary px-5"
                            onClick={() => this.handleHospitalMasterError()}
                        >
                            OK
                        </button>
                    </div>
                </Modal>

                <Modal className="myDrugsInteraction_modal" id="myDrugsInteraction"
                    title="All Drug Interaction"
                    visible={this.state.isModalAllInteractionVisible}
                    onOk={() => this.handleAllInteractionOk()}
                    onCancel={() => this.handleAllInteractionCancel()}
                    footer={false}
                    style={{ width: 2000 }}
                    zIndex={9999}
                >
                    <iframe id="drug_interaction_widget_iframe2" title="interaction" className="cimsiframe" src={`${ALL_CIMS_INTERACTION}?drugIds=${this.state.drug_id_Interaction_Modalurl}&referenceId=${this.state.referenceId}&referenceType=${this.state.referenceType}&enterpriseId=${this.state.enterpriseId}&FacilityId=${this.state.FacilityId}&StoreId=${this.state.StoreId}&patientId=${this.state.patientId}&orederSetId=${this.state.orederSetId}&careProtoColId=${0}&medicineScreen=${this.state.medicineScreen}&addedAssessmentList=${this.state.addedAssessmentList}`}></iframe>
                    {/* <div className="modal-footer justify-content-center emrfrm pb-0">
                    <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handleAllInteractionCancel(e)}>DON’T PROCEED</button>
                    <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.handleAllInteractionOk(e)}>PROCEED TO ORDER</button>
                </div> */}
                </Modal>
            </>
        );
    }
}

export default CreateTemplateList;
