import React, { Component } from 'react';
import Axios from 'axios';
import {
    LOADER,
    LOADER_RED,
    LOGIN,
    OTP_LOGIN,
    SYNC_TOKEN,
    USERTYPES,
    APPS_FEATURES_LIST,
    REQUEST_PIN,
    MYHC_APPTYPE,
    WOODLANDS
} from '../../utils/constant';
import qs from 'qs';
// import LOGO from '../../assets/images/logo-myhealthcare.svg';
import LOGO from '../../assets/images/logo-myhealthcare-doctor.svg';
import LOGIN_BACKGROUND from '../../assets/images/ic-loginimage.png';
import LS_SERVICE from '../../utils/localStorage';
import { headerService$ } from '../../utils/rxjs-sharing';
import { notification } from 'antd';
import { jQueryLabels } from '../../utils/jQueryLabels';

class LoginComponent extends Component {

    state = {
        username: '',
        password: '',
        mobile: '',
        showMobile: true,
        syncTokenProcessing: false,
        staff_id: null,
        staff_name: '',
        status: null,
        token: '',
        showPassword: false,
        gidFormSubmitted: false,
        otpFormSubmitted: false,
        loginType:''
    }

    constructor(props) {
        super(props);

        if (LS_SERVICE.has('is_authenticated') && LS_SERVICE.get('is_authenticated')) {
            this.props.history.push('/dashboard');
        }

        this.userRefs = React.createRef();
        this.passRefs = React.createRef();
        this.mobileRefs = React.createRef();
        this._headerService = null;
        this.handleUserOnChange = this.handleUserOnChange.bind(this);
    }

    // componentDidMount() {

    //     Axios.defaults.headers.common["Authorization"] = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoxMjE0LCJpYXQiOjE2MDczMTk5MTgsImV4cCI6MTYwNzQwNjMxOH0.Fg29Wdsv09Jr9VaJKTyje6tk8AQA38L3eoU-Xukz5Vk';

    //     Axios.post('https://dev.opdemr.myhealthcare.co/api/opdemr/template/search', qs.stringify({
    //         query: '',
    //         doctor_Id: '1214',
    //         list: 'all'
    //     }))
    //         .then(success => console.log(success))
    //         .catch(err => console.log(err))


    // }

    // componentDidUpdate(prevProps, prevState) {
    //     if (prevState.username !== this.state.username)
    //         this.submitForm();
    // }
    componentDidMount() { 
        // $(document).ready(function () {

        // })

        this.setState({
            username: '',
            password: '',
            mobile: '',
            loginType:this.props.loginType
        })

        jQueryLabels();
    }
    componentWillUnmount() {
        this.setState({
            syncTokenProcessing: false
        })
    }

    handleOnChange = (event) => {
        event.preventDefault();
        const { name, value } = event.currentTarget;

        const re = /^[0-9\b]+$/;
        if (value === '' || re.test(value)) {
            this.setState({ [name]: value });
        }

        // this.setState({
        //     [name]: value
        // })
    }

    handleUserOnChange = (event) => {
        event.preventDefault();
        const { name, value } = event.currentTarget;

        this.setState({
            [name]: value
        });
    }

    handleLoginOtpClick = (event) => {
        event.preventDefault();


        this.setState({
            username: '',
            password: '',
            mobile: ''

        })

        this.setState({
            showMobile: !this.state.showMobile
        })
    }



    handleOnSubmitUP = (event) => {
        event.preventDefault();
        let { username, password } = this.state;
        if (username === '' || password === '') {
            return;
        }

        if ((username || password).length === 0) {
            username = this.userRefs.current.value;
            password = this.passRefs.current.value;
        }

        this.setState({
            username,
            password
        }, () => this.submitFormUP())
    }

    handleOnSubmitMN = (event) => {
        event.preventDefault();
        let { mobile } = this.state;

        if (mobile.length < 10) {
            mobile = this.mobileRefs.current.value
        }

        this.setState({
            mobile
        }, () => {
            if (APPS_FEATURES_LIST.pin_based_login) {
                this.pinBasedLogin();
            } else {
                this.sumbitFormMN();
            }
        })
    }

    pinBasedLogin = () => {
        const { mobile } = this.state;
        this.setState({ otpFormSubmitted: true });
        Axios.post(REQUEST_PIN, qs.stringify({ mobile,loginType:this.state.loginType }))
            .then(success => {
                this.setState({ otpFormSubmitted: false });
                let data = success.data;
                LS_SERVICE.set('loginMobile', mobile);

                if (!data.response.is_active) {
                    notification.error({
                        message: data.response.message,
                        placement: 'topRight'
                    });
                    return;
                }

                if (data.response.is_pin_available) {
                    this.props.history.push('/verify-pin',this.state.loginType);
                } else {
                    LS_SERVICE.set('reset_user_pin', true);
                    this.sumbitFormMN();
                }
            })
            .catch(err => {
                this.setState({ otpFormSubmitted: false });

                if (err.response && err.response.data && err.response.data.message) {
                    notification.error({
                        message: err.response.data.message,
                        placement: 'topRight'
                    });
                }
            });
    }

    sumbitFormMN = () => {
        const { mobile } = this.state;

        this.setState({ otpFormSubmitted: true });

        Axios.post(OTP_LOGIN, qs.stringify({ mobile,loginType:this.state.loginType }))
            .then(success => {
                this.setState({ otpFormSubmitted: false });

                let data = success.data;
                notification.info({
                    message: data.message,
                    placement: 'topRight'
                });
                LS_SERVICE.set('loginMobile', mobile);
                this.props.history.push('/otp',this.state.loginType);
            })
            .catch(err => {
                this.setState({ otpFormSubmitted: false });

                if (err.response && err.response.data && err.response.data.message) {
                    notification.error({
                        message: err.response.data.message,
                        placement: 'topRight'
                    });
                }
            });
    }

    submitFormUP = () => {
        const { username, password } = this.state

        this.setState({ gidFormSubmitted: true });

        Axios.post(LOGIN, qs.stringify({ username, password,loginType:this.state.loginType }))
            .then(success => {
                this.setState({ gidFormSubmitted: false });

                let { staff_id,
                    staff_name,
                    status,
                    token,
                    user_type } = success.data;

                if (status) {

                    this.setState({
                        staff_id,
                        staff_name,
                        token
                    })

                    LS_SERVICE.set('token', token);
                    LS_SERVICE.set('staff_name', staff_name);
                    LS_SERVICE.set('staff_id', staff_id);
                    LS_SERVICE.set('is_authenticated', true);
                    LS_SERVICE.set('user_type', user_type);
                    this.handleSyncToken(staff_id);
                }
            })
            .catch(err => {
                this.setState({ gidFormSubmitted: false });

                let error = err.response;
                if (error?.data?.message) {
                    notification.error({
                        message: error.data.message,
                        placement: "bottomRight"
                    })
                }
            })
    }

    handleSyncToken = () => {

        const { staff_id } = this.state;

        this.setState({
            syncTokenProcessing: true
        })

        Axios.post(SYNC_TOKEN, qs.stringify({ staff_id }))
            .then(success => {
                const result = success.data;
                if (result.status) {
                    LS_SERVICE.set('staff_image', result.staff.profile_picture);
                    // debugger;
                    if (result.staff.role_id == USERTYPES.doctor) {
                        LS_SERVICE.set('doctor', {
                            name: ('Dr ' + result.staff.first_name + (result.staff.last_name != null ? result.staff.last_name : '')),
                            code: result.staff.code
                        });

                        if (result.slots && result.slots.length > 0) {
                            LS_SERVICE.set(
                                'slot_hospital_id',
                                result.slots[0]['id']
                            );
                            LS_SERVICE.set(
                                'slot_hospital_master',
                                result.slots[0]['own_master']
                            );
                            LS_SERVICE.set('slot_hospitals', result.slots);
                        } else {
                            LS_SERVICE.delete('slot_hospital_id');
                        }
                        if (result.pending_patients && result.pending_patients.length > 0) {
                            LS_SERVICE.set(
                                'pending_patients',
                                result.pending_patients
                            );
                        } else {
                            LS_SERVICE.delete('pending_patients');
                        }
                        if (result.staff.doctor_speciality != undefined && result.staff.doctor_speciality.length > 0) {
                            LS_SERVICE.set('speciality_id', result.staff.doctor_speciality[0]);
                        } else {
                            LS_SERVICE.delete('speciality_id');
                        }
                        if (result.slots && result.slots.length == 1) {
                            this.props.history.push('/patient-queue/' + result.slots[0].id);
                        } else {
                            this.props.history.push('dashboard');
                        }
                    }

                    if (result.staff.role_id == USERTYPES.nurse) {
                        if (result.hospital && result.hospital.length > 0) {
                            LS_SERVICE.set(
                                'slot_hospital_id',
                                result.hospital[0]['hospital_id']
                            );
                            LS_SERVICE.set(
                                'slot_hospital_master',
                                result.hospital[0]['own_master']
                            );
                        } else {
                            LS_SERVICE.delete('slot_hospital_id');
                        }
                        if (result.doctor && result.doctor.length > 0) {
                            LS_SERVICE.set('nurse_doctors', result.doctor);
                        } else {
                            LS_SERVICE.delete('nurse_doctors');
                        }
                        this.props.history.push('/patient-queue/' + result.hospital[0]['hospital_id']);
                    }

                    headerService$.showHeader({ showHeader: 'true' });
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    // handleBasicOrSoap = (hospital_id) => {
    //     const PARAMS = {
    //         hospital_id,
    //         speciality_id: LS_SERVICE.get('speciality_id'),
    //         staff_id: LS_SERVICE.get('staff_id')
    //     }


    //     Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get('token');
    //     Axios.post(HOSPITAL_CONFIG, qs.stringify(PARAMS))
    //         .then(success => {
    //             headerService$.soapConfig({ soapConfig: success.data.data.opd_layout });
    //             LS_SERVICE.set('soapConfig', success.data.data.opd_layout);
    //             this.props.history.push('/patient-queue/' + hospital_id);
    //         })
    //         .catch(error => console.log(error))
    // }

    render() {

        const { showMobile, mobile, syncTokenProcessing, showPassword } = this.state;

        return (
            <>
                <div className="container-fluid">
                    <div className="full-height">
                        <div className="row">
                            <div className="col-md-6 align-self-center">
                                <div className="emrfrm">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-8 text-center my-5">
                                            <img src={LOGO} alt="logo" className="img-logo" />
                                        </div>
                                        <div className="col-12 col-md-8 login-form-container">

                                            {!syncTokenProcessing ? (!showMobile ? (
                                                <form id="gid_login_form" autoComplete="off" onSubmit={this.handleOnSubmitUP}>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <p className="alert alert-danger d-none js-flash-msg"></p>
                                                            <div className="form-group">
                                                                <input type="text" id="username" name="username" className="form-control" placeholder="Doctor ID" value={this.state.username} ref={this.userRefs}
                                                                    autoComplete="new-username" onChange={this.handleUserOnChange} />
                                                                <label htmlFor="username">Doctor ID</label>
                                                            </div>

                                                            <div className="form-group mb-3 password_attribute">
                                                                <input type={`${showPassword ? "text" : "password"}`} id="password" name="password" className="form-control" placeholder="Password" ref={this.passRefs}
                                                                    autoComplete="new-password" value={this.state.password} onChange={this.handleUserOnChange} />
                                                                <label htmlFor="password">Password</label>
                                                                <span className={`show_password ${showPassword ? 'on' : ''}`} onClick={() => this.setState(prev => ({ showPassword: !prev.showPassword }))}><i className="icon_view"></i></span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mt-3 mb-2">
                                                            <button disabled={this.state.gidFormSubmitted} className="btn btn-primary text-uppercase btn-block" type="submit">Login{this.state.gidFormSubmitted ? LOADER : null}</button>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-12 pt-4 pt-sm-2">
                                                                    <a className="login-tc js-show-login-form" href="#" onClick={this.handleLoginOtpClick}>Click here</a> to login with Mobile Number<br /></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            ) : (
                                                <form id="otp_login_form" autoComplete="off" onSubmit={this.handleOnSubmitMN}>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <p className="alert alert-danger d-none js-flash-msg"></p>
                                                            <div className="form-group">
                                                                <input type="text" id="mobile" className="form-control onlynumbers" maxLength="10"
                                                                    placeholder="Mobile Number" ref={this.mobileRefs} value={mobile} onChange={this.handleOnChange}
                                                                    name="mobile" autoComplete="new-mobile" />
                                                                <label htmlFor="mobile">Mobile Number</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 my-3">
                                                            <button disabled={mobile === '' || this.state.otpFormSubmitted} className="btn btn-primary text-uppercase btn-block" type="submit">
                                                                Login{this.state.otpFormSubmitted ? LOADER : null} </button>
                                                        </div>
                                                        {/* <div className="col-12 col-md-8">
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <a className="login-tc js-show-login-form" href="#" onClick={this.handleLoginOtpClick}>Click here</a> to login with doctor id and password</div>
                                                                </div>
                                                            </div> */}
                                                    </div>
                                                </form>
                                            )) : (
                                                <div className="text-center" id="js-sync-widget">
                                                    <p className="alert alert-danger d-none js-sync-flash-msg"></p>
                                                    <p id="js-staff-info"></p>
                                                    {LOADER_RED}
                                                    <p>Please hold on for couple of seconds while we sync your hospital(s) and appointments.</p>
                                                </div>
                                            )}



                                            <div style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: '999' }}>
                                                <div className="fade toast text-white font-weight-bold bg-danger" role="alert" aria-live="assertive" aria-atomic="true">
                                                    <div className="toast-body"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-8">
                                            <p className="my-4">By logging in, you agree to our {(MYHC_APPTYPE === WOODLANDS) ? <a href="#" data-toggle="modal" data-target="#terms-conditions">Terms and Conditions</a> : <a href="#" data-toggle="modal" data-target="#terms-conditions-mhvc">Terms and Conditions</a>} and {(MYHC_APPTYPE === WOODLANDS) ? <a href="#" data-toggle="modal" data-target="#privacy-policy">Privacy Policy</a> : <a href="https://www.myhealthcare.co/privacy-policy/" target="_blank">Privacy Policy</a>}
                                            </p>

                                            <p>If you are a registered doctor with this hospital and unable to log in, please contact your Administrator.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="login-image col-md-6 col-12" style={{ backgroundImage: 'url(' + LOGIN_BACKGROUND + ')' }}></div>
                        </div>
                    </div>
                </div>

                {/* *modal for terms and conditions* */}
                <div className="modal modal-max-width chart-modal-trend" id="terms-conditions" style={{ display: 'none' }} aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header border-bottom-0 mb-3">
                                <h3 className="font-weight-normal">Terms and Conditions</h3>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>
                            <div className="modal-body py-0">
                                <p>Innocirc Ventures Pvt Ltd&nbsp;(“MyHealthcare”) is the author and publisher of the internet resource
                                www.MyHealthcare.co and the mobile application ‘MyHealthcare’ (together, “Website”). MyHealthcare owns and
                operates the services provided through the Mobile Applications and Website.</p>
                                <h6>1.NATURE AND APPLICABILITY OF TERMS</h6>
                                <p>Please carefully go through these terms and conditions (“Terms”) and the privacy policy available at
                                https://www.MyHealthcare.co/privacy (“Privacy Policy”) before you decide to access the Website or avail the
                                services made available on the Website by MyHealthcare. These Terms and the Privacy Policy together
                                constitute a legal agreement (“Agreement”) between you and MyHealthcare in connection with your visit to the
                Website and your use of the Services (as defined below).</p>
                                <p>The Agreement applies to you whether you are –</p>
                                <p>A medical practitioner or health care provider (whether an individual professional or an organization) or
                                similar institution wishing to be listed, or already listed, on the Website, including designated,
                authorized associates of such practitioners or institutions (“Practitioner(s)”, “you” or “User”); or</p>
                                <p>A patient, his/her representatives or affiliates, searching for Practitioners through the Website
                (“End-User”, “you” or “User”); or</p>
                                <p>Otherwise a user of the Website (“you” or “User”).</p>
                                <p>This Agreement applies to those services made available by MyHealthcare through its platform and associated
                                white labelled mobile applications and web based applications, which are offered with a Convenience Fee to
                the Users (“Services”), including the following:</p>
                                <p>For Practitioners: Listing of Practitioners and their profiles and contact details, to be made available to
                the other Users and visitors to the Website;</p>
                                <p>For other Users: Facility to (i) create and maintain ‘Health Accounts’, (ii) search for Practitioners by
                                name, specialty, and geographical area, or any other criteria that may be developed and made available by
                MyHealthcare, and (iii) to make appointments with Practitioners.</p>
                                <p>The Services may change from time to time, at the sole discretion of MyHealthcare, and the Agreement will
                                apply to your visit to and your use of the Website to avail the Service, as well as to all information
                provided by you on the Website at any given point in time.</p>
                                <p>This Agreement defines the terms and conditions under which you are allowed to use the Website and
                                describes the manner in which we shall treat your account while you are registered as a member with us. If
                                you have any questions about any part of the Agreement, feel free to contact us at support@MyHealthcare.co.
              </p>
                                <p>By downloading or accessing the Website to use the Services, you irrevocably accept all the conditions
                                stipulated in this Agreement, the Subscription Terms of Service and Privacy Policy, as available on the
                                Website, and agree to abide by them. This Agreement supersedes all previous oral and written terms and
                                conditions (if any) communicated to you relating to your use of the Website to avail the Services. By
                availing any Service, you signify your acceptance of the terms of this Agreement.</p>
                                <p>We reserve the right to modify or terminate any portion of the Agreement for any reason and at any time,
                                and such modifications shall be informed to you in writing You should read the Agreement at regular
                                intervals. Your use of the Website following any such modification constitutes your agreement to follow and
                be bound by the Agreement so modified.</p>
                                <p>You acknowledge that you will be bound by this Agreement for availing any of the Services offered by us. If
                you do not agree with any part of the Agreement, please do not use the Website or avail any Services.</p>
                                <p>Your access to use of the Website and the Services will be solely at the discretion of MyHealthcare.</p>
                                <p>The Agreement is published in compliance of, and is governed by the provisions of Indian law, including but
                not limited to:</p>
                                <p>the Indian Contract Act, 1872, the (Indian) Information Technology Act, 2000, and the rules, regulations,
                                guidelines and clarifications framed there under, including the (Indian) Information Technology (Reasonable
                                Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 (the “SPI Rules”), and the
                (Indian) Information Technology (Intermediaries Guidelines) Rules, 2011 (the “IG Rules”).</p>
                                <h6>2. CONDITIONS OF USE</h6>
                                <p>You must be 18 years of age or older to register, use the Services, or visit or use the Website in any
                                manner. By registering, visiting and using the Website or accepting this Agreement, you represent and
                                warrant to MyHealthcare that you are 18 years of age or older, and that you have the right, authority and
                                capacity to use the Website and the Services available through the Website, and agree to and abide by this
                Agreement.</p>
                                <h6>3. TERMS OF USE APPLICABLE TO ALL USERS OTHER THAN PRACTITIONERS</h6>
                                <p>The terms in this Clause 3 are applicable only to Users other than Practitioners.</p>
                                <p>3.1 END-USER ACCOUNT AND DATA PRIVACY</p>
                                <p>3.1.1 The terms “personal information” and “sensitive personal data or information” are defined under the
                SPI Rules, and are reproduced in the Privacy Policy.</p>
                                <p>3.1.2 MyHealthcare may by its Services, collect information relating to the devices through which you
                                access the Website, and anonymous data of your usage. The collected information will be used only for
                improving the quality of MyHealthcare’s services and to build new services.</p>
                                <p>3.1.3 The Website allows MyHealthcare to have access to registered Users’ personal email or phone number,
                                for communication purpose so as to provide you a better way of booking appointments and for obtaining
                feedback in relation to the Practitioners and their practice.</p>
                                <p>3.1.4 The Privacy Policy sets out, inter-alia:</p>
                                <p>The type of information collected from Users, including sensitive personal data or information;</p>
                                <p>The purpose, means and modes of usage of such information;</p>
                                <p>How and to whom MyHealthcare will disclose such information; and,</p>
                                <p>Other information mandated by the SPI Rules.</p>
                                <p>3.1.5 The User is expected to read and understand the Privacy Policy, so as to ensure that he or she has
                the knowledge of, inter-alia:</p>
                                <p>the fact that certain information is being collected;</p>
                                <p>the purpose for which the information is being collected;</p>
                                <p>the intended recipients of the information;</p>
                                <p>the nature of collection and retention of the information; and</p>
                                <p>the name and address of the agency that is collecting the information and the agency that will retain the
                information; and</p>
                                <p>the various rights available to such Users in respect of such information.</p>
                                <p>3.1.6 MyHealthcare shall not be responsible in any manner for the authenticity of the personal information
                                or sensitive personal data or information supplied by the User to MyHealthcare or to any other person acting
                on behalf of MyHealthcare.</p>
                                <p>3.1.7 The User is responsible for maintaining the confidentiality of the User’s account access information
                                and password, if the User is registered on the Website. The User shall be responsible for all usage of the
                                User’s account and password, whether or not authorized by the User. The User shall immediately notify
                                MyHealthcare of any actual or suspected unauthorized use of the User’s account or password. Although
                                MyHealthcare will not be liable for your losses caused by any unauthorized use of your account, you may be
                                liable for the losses of MyHealthcare or such other parties as the case may be, due to any unauthorized use
                of your account.</p>
                                <p>3.1.8 If a User provides any information that is untrue, inaccurate, not current or incomplete (or becomes
                                untrue, inaccurate, not current or incomplete), or MyHealthcare has reasonable grounds to suspect that such
                                information is untrue, inaccurate, not current or incomplete, MyHealthcare has the right to discontinue the
                Services to the User at its sole discretion.</p>
                                <p>3.1.9 MyHealthcare may use such information collected from the Users from time to time for the purposes of
                debugging customer support related issues.</p>
                                <p>3.1.10 Against every Practitioner listed in MyHealthcare.co, you may see a ‘show number’ option. When you
                                choose this option, you choose to call the number through a free telephony service provided by MyHealthcare,
                                and the records of such calls are recorded and stored in MyHealthcare’s servers. Such records are dealt with
                                only in accordance with the terms of the Privacy Policy. Such call facility provided to you by MyHealthcare
                                should be used only for appointment and booking purposes, and not for consultation on health-related issues.
                MyHealthcare accepts no liability if the call facility is not used in accordance with the foregoing.</p>
                                <p>3.2 RELEVANCE ALGORITHM</p>
                                <p>MyHealthcare’s relevance algorithm for the Practitioners is a fully automated system that lists the
                                Practitioners, their profile and information regarding their Practice on its Website. These listings of
                                Practitioners do not represent any fixed objective ranking or endorsement by MyHealthcare. MyHealthcare will
                                not be liable for any change in the relevance of the Practitioners on search results, which may take place
                                from time to time. The listing of Practitioners will be based on automated computation of the various
                                factors including inputs made by the Users including their comments and feedback. Such factors may change
                                from time to time, in order to improve the listing algorithm. MyHealthcare in no event will be held
                responsible for the accuracy and the relevancy of the listing order of the Practitioners on the Website.</p>
                                <p>3.3 LISTING CONTENT AND DISSEMINATING INFORMATION</p>
                                <p>3.3.1 MyHealthcare collects, directly or indirectly, and displays on the Website, relevant information
                                regarding the profile and practice of the Practitioners listed on the Website, such as their specialization,
                                qualification, fees, location, visiting hours, and similar details. MyHealthcare takes reasonable efforts to
                                ensure that such information is updated at frequent intervals. Although MyHealthcare screens and vets the
                                information and photos submitted by the Practitioners, it cannot be held liable for any inaccuracies or
                incompleteness represented from it, despite such reasonable efforts.</p>
                                <p>3.3.2 The Services provided by MyHealthcare or any of its licensors or service providers are provided on an
                                “as is” and “as available’ basis, and without any warranties or conditions (express or implied, including
                                the implied warranties of merchantability, accuracy, fitness for a particular purpose, title and
                                non-infringement, arising by statute or otherwise in law or from a course of dealing or usage or trade).
                                MyHealthcare does not provide or make any representation, warranty or guarantee, express or implied about
                                the Website or the Services. MyHealthcare does not guarantee the accuracy or completeness of any content or
                                information provided by Users on the Website. To the fullest extent permitted by law, MyHealthcare disclaims
                                all liability arising out of the User’s use or reliance upon the Website, the Services, representations and
                                warranties made by other Users, the content or information provided by the Users on the Website, or any
                                opinion or suggestion given or expressed by MyHealthcare or any User in relation to any User or services
                provided by such User.</p>
                                <p>3.3.3 The Website may be linked to the website of third parties, affiliates and business partners.
                                MyHealthcare has no control over, and not liable or responsible for content, accuracy, validity,
                                reliability, quality of such websites or made available by/through our Website. Inclusion of any link on the
                                Website does not imply that MyHealthcare endorses the linked site. User may use the links and these services
                at User’s own risk.</p>
                                <p>3.3.4 MyHealthcare assumes no responsibility, and shall not be liable for, any damages to, or viruses that
                                may infect User’s equipment on account of User’s access to, use of, or browsing the Website or the
                                downloading of any material, data, text, images, video content, or audio content from the Website. If a User
                is dissatisfied with the Website, User’s sole remedy is to discontinue using the Website.</p>
                                <p>3.3.5 If MyHealthcare determines that you have provided fraudulent, inaccurate, or incomplete information,
                                including through feedback, MyHealthcare reserves the right to immediately suspend your access to the
                                Website or any of your accounts with MyHealthcare and makes such declaration on the website alongside your
                                name/your clinic’s name as determined by MyHealthcare for the protection of its business and in the
                                interests of Users. You shall be liable to indemnify MyHealthcare for any losses incurred as a result of
                your misrepresentations or fraudulent feedback that has adversely affected MyHealthcare or its Users.</p>
                                <p>3.4 BOOK APPOINTMENT</p>
                                <p>MyHealthcare enables Users to connect with Practitioners through two methods: a) Book facility that allows
                Users book an appointment through the Website; b) MyHealthcare Mobile application</p>
                                <p>3.4.1 MyHealthcare will ensure Users are provided confirmed appointment on the Book facility. However,
                                MyHealthcare has no liability if such an appointment is later cancelled by the Practitioner, or the same
                                Practitioner is not available for appointment. Provided, it does not fall under the heads listed under the
                MyHealthcare Guarantee Program, in which case the terms of this program shall apply.</p>
                                <p>3.4.2 If a User has utilized the telephonic services, MyHealthcare reserves the right to share the
                                information provided by the User with the Practitioner and store such information and/or conversation of the
                User with the Practitioner, in accordance with our Privacy Policy.</p>
                                <p>3.4.3 The results of any search Users perform on the Website for Practitioners should not be construed as
                                an endorsement by MyHealthcare of any such particular Practitioner. If the User decides to engage with a
                Practitioner to seek medical services, the User shall be doing so at his/her own risk.</p>
                                <p>3.4.4 Without prejudice to the generality of the above, MyHealthcare is not involved in providing any
                                healthcare or medical advice or diagnosis and hence is not responsible for any interactions between User and
                the Practitioner. User understands and agrees that MyHealthcare will not be liable for:</p>
                                <p>User interactions and associated issues User has with the Practitioner;</p>
                                <p>the ability or intent of the Practitioner(s) or the lack of it, in fulfilling their obligations towards
                Users;</p>
                                <p>any wrong medication or quality of treatment being given by the Practitioner(s), or any medical negligence
                on part of the Practitioner(s);</p>
                                <p>inappropriate treatment, or similar difficulties or any type of inconvenience suffered by the User due to a
                failure on the part of the Practitioner to provide agreed Services;</p>
                                <p>any misconduct or inappropriate behaviour by the Practitioner or the Practitioner’s staff;</p>
                                <p>cancellation or no show by the Practitioner or rescheduling of booked appointment or any variation in the
                fees charged, provided these have been addressed to under, MyHealthcare Guarantee Program.</p>
                                <p>3.4.5 Users are allowed to provide feedback about their experiences with the Practitioner, however, the
                                User shall ensure that, the same is provided in accordance with applicable law. User however understands
                                that, MyHealthcare shall not be obliged to act in such manner as may be required to give effect to the
                                content of Users feedback, such as suggestions for delisting of a particular Practitioner from the Website.
              </p>
                                <p>3.4.6 In case of a ‘Patient-No-Show (P.N.S)’ (defined below), where the User does not show-up at the
                concerned Practitioner’s clinic, the charges paid will not be refunded</p>
                                <p>3.4.7 Cancellation and Refund Policy</p>
                                <p>In the event that, the Practitioner with whom User has booked a paid appointment via the Website, has not
                                been able to meet the User, User will need to write to us at support@MyHealthcare.co within five (5) days
                                from the occurrence of such event; in which case, the entire consultation amount as mentioned on the Website
                                will be refunded to the User within the next five (5) to six (6) business days in the original mode of
                                payment done by the User while booking. In case where the User, does not show up for the appointment booked
                                with a Practitioner, without cancelling the appointment beforehand, the amount will not be refunded, and
                                treated as under Clause 3.4.6. However, where cancellation charges have been levied (as charged by the
                                Practitioner/Practice), you would not be entitled to complete refund even if you have cancelled beforehand.
              </p>
                                <p>Users will not be entitled for any refunds in cases where, the Practitioner is unable to meet the User at
                                the exact time of the scheduled appointment time and the User is required to wait, irrespective of the fact
                                whether the User is required to wait or choose to not obtain the medical services from the said
                Practitioner.</p>
                                <p>3.5 NO DOCTOR-PATIENT RELATIONSHIP; NOT FOR EMERGENCY USE</p>
                                <p>3.5.1 Please note that some of the content, text, data, graphics, images, information, suggestions,
                                guidance, and other material (collectively, “Information”) that may be available on the Website (including
                                information provided in direct response to your questions or postings) may be provided by individuals in the
                                medical profession. The provision of such Information does not create a licensed medical
                                professional/patient relationship, between MyHealthcare and you and does not constitute an opinion, medical
                                advice, or diagnosis or treatment of any particular condition, but is only provided to assist you with
                locating appropriate medical care from a qualified practitioner.</p>
                                <p>3.5.2 It is hereby expressly clarified that, the Information that you obtain or receive from MyHealthcare,
                                and its employees, contractors, partners, sponsors, advertisers, licensors or otherwise on the Website is
                                for informational purposes only. We make no guarantees, representations or warranties, whether expressed or
                                implied, with respect to professional qualifications, quality of work, expertise or other information
                                provided on the Website. In no event shall we be liable to you or anyone else for any decision made or
                action taken by you in reliance on such information.</p>
                                <p>3.5.3 The Services are not intended to be a substitute for getting in touch with emergency healthcare. If
                                you are an End-User facing a medical emergency (either on your or a another person’s behalf), please contact
                an ambulance service or hospital directly.</p>
                                <p>3.6 MyHealthcare HEALTH FEED</p>
                                <p>3.6.1 MyHealthcare Health feed is an online content platform available on the website, wherein
                                Practitioners who have created a MyHealthcare profile and Users who have created a health account can login
                and post health and wellness related content.</p>
                                <p>3.6.2 A User can use MyHealthcare Health feed by logging in from their health account, creating original
                                content comprising text, audio, video, images, data or any combination of the same (“Content”), and
                                uploading said Content to MyHealthcare’s servers. MyHealthcare will make available to the User a gallery of
                                images licensed by MyHealthcare from a third party stock image provider (“MyHealthcare Gallery”). The User
                                can upload their own images or choose an image from the MyHealthcare Gallery. MyHealthcare does not provide
                                any warranty as to the ownership of the intellectual property in the MyHealthcare Gallery and the User
                                acknowledges that the User will use the images from the MyHealthcare Gallery at their own risk. MyHealthcare
                                shall post such Content to MyHealthcare Health feed at its own option and subject to these Terms and
                                Conditions. The Content uploaded via MyHealthcare Health feed does not constitute medical advice and may not
                be construed as such by any person.</p>
                                <p>3.6.3 The User acknowledges that they are the original authors and creators of any Content uploaded by them
                                via MyHealthcare Health feed and that no Content uploaded by them would constitute infringement of the
                                intellectual property rights of any other person. MyHealthcare reserves the right to remove any Content
                                which it may determine at its own discretion as violating the intellectual property rights of any other
                                person. The User agrees to absolve MyHealthcare from and indemnify MyHealthcare against all claims that may
                                arise as a result of any third party intellectual property right claim that may arise from the user’s
                                uploading of any Content on MyHealthcare Health feed. The User may not use the images in the MyHealthcare
                                Gallery for any purpose other than those directly related to the creation and uploading of Content to
                                MyHealthcare Health feed. The User also agrees to absolve MyHealthcare from and indemnify MyHealthcare
                                against all claims that may arise as a result of any third party intellectual property claim if the User
                                downloads, copies or otherwise utilizes an image from the MyHealthcare Gallery for his/her personal or
                commercial gain.</p>
                                <p>3.6.4 The user hereby assigns to MyHealthcare, in perpetuity and worldwide, all intellectual property
                rights in any Content created by the User and uploaded by the User via MyHealthcare Health feed.</p>
                                <p>3.6.5 MyHealthcare shall have the right to edit or remove the Content and any comments in such manner as it
                may deem MyHealthcare Health feed at any time.</p>
                                <p>3.6.6 The User agrees not to upload Content which is defamatory, obscene or objectionable in nature and
                                MyHealthcare reserves the right to remove any Content which it may determine at its own discretion to
                                violate these Terms and Conditions or be violative of any law or statute in force at the time. The User
                                agrees to absolve MyHealthcare from and indemnify MyHealthcare against all claims that may arise as a result
                                of any legal claim arising from the nature of the Content posted by the User on MyHealthcare Health Feed.
              </p>
                                <p>3.6.7 A User may also use MyHealthcare Health feed in order to view original content created by
                Practitioners and to create and upload comments on such Content, where allowed.</p>
                                <p>3.6.8 User acknowledges that the Content on MyHealthcare Health feed reflects the views and opinions of the
                authors of such Content and do not necessarily reflect the views of MyHealthcare.</p>
                                <p>3.6.9 User agrees that the content they access on MyHealthcare Health feed does not in any way constitute
                                medical advice and that the responsibility for any act or omission by the User arising from the User’s
                                interpretation of the Content, is solely attributable to the user. The User agrees to absolve MyHealthcare
                                from and indemnify MyHealthcare against all claims that may arise as a result of the User’s actions
                resulting from the User’s viewing of Content on MyHealthcare Health feed.</p>
                                <p>3.6.10 The User acknowledges that all intellectual property rights in the Content on MyHealthcare Health
                                feed vests with MyHealthcare. The User agrees not to infringe upon MyHealthcare’s intellectual property by
                                copying or plagiarizing content on MyHealthcare Health feed. MyHealthcare reserves its right to initiate all
                                necessary legal remedies available to them in case of such an infringement by the User. All comments created
                                and uploaded by the User on MyHealthcare Health feed will be the sole intellectual property of MyHealthcare.
                                The User agrees not to post any comments on MyHealthcare Health feed that violate the intellectual property
                                of any other person. MyHealthcare reserves the right to remove any comments which it may determine at its
                                own discretion as violating the intellectual property rights of any other person. The User agrees to absolve
                                MyHealthcare from and indemnify MyHealthcare against all claims that may arise as a result of any third
                                party intellectual property right claim that may arise from the User’s uploading of any comment on
                MyHealthcare Health feed.</p>
                                <p>3.6.11 User agrees not to post any comments which are defamatory, obscene, objectionable or in nature and
                                MyHealthcare reserves the right to remove any comments which it may determine at its own discretion to
                                violate these Terms and Conditions or be violative of any law or statute in force at the time. The User
                                agrees to absolve MyHealthcare from and indemnify MyHealthcare against all claims that may arise as a result
                                of any legal claim arising from the nature of the comments posted by the User on MyHealthcare Health feed.
              </p>
                                <p>3.7 CONTENT OWNERSHIP AND COPYRIGHT CONDITIONS OF ACCESS</p>
                                <p>3.7.1 The contents listed on the Website are (i) User generated content, or (ii) belong to MyHealthcare.
                                The information that is collected by MyHealthcare directly or indirectly from the End- Users and the
                                Practitioners shall belong to MyHealthcare. Copying of the copyrighted content published by MyHealthcare on
                                the Website for any commercial purpose or for the purpose of earning profit will be a violation of copyright
                and MyHealthcare reserves its rights under applicable law accordingly.</p>
                                <p>3.7.2 MyHealthcare authorizes the User to view and access the content available on or from the Website
                                solely for ordering, receiving, delivering and communicating only as per this Agreement. The contents of the
                                Website, information, text, graphics, images, logos, button icons, software code, design, and the
                                collection, arrangement and assembly of content on the Website (collectively, “MyHealthcare Content”), are
                                the property of MyHealthcare and are protected under copyright, trademark and other laws. User shall not
                                modify the MyHealthcare Content or reproduce, display, publicly perform, distribute, or otherwise use the
                MyHealthcare Content in any way for any public or commercial purpose or for personal gain.</p>
                                <p>3.7.3 User shall not access the Services for purposes of monitoring their availability, performance or
                functionality, or for any other benchmarking or competitive purposes.</p>
                                <p>3.8 REVIEWS AND FEEDBACK</p>
                                <p>By using this Website, you agree that any information shared by you with MyHealthcare or with any
                Practitioner will be subject to our Privacy Policy.</p>
                                <p>You are solely responsible for the content that you choose to submit for publication on the Website,
                                including any feedback, ratings, or reviews (“Critical Content”) relating to Practitioners or other
                                healthcare professionals. The role of MyHealthcare in publishing Critical Content is restricted to that of
                                an ‘intermediary’ under the Information Technology Act, 2000. MyHealthcare disclaims all responsibility with
                                respect to the content of Critical Content, and its role with respect to such content is restricted to its
                                obligations as an ‘intermediary’ under the said Act. MyHealthcare shall not be liable to pay any
                consideration to any User for re-publishing any content across any of its platforms.</p>
                                <p>Your publication of reviews and feedback on the Website is governed by Clause 5 of these Terms. Without
                                prejudice to the detailed terms stated in Clause 5, you hereby agree not to post or publish any content on
                                the Website that (a) infringes any third-party intellectual property or publicity or privacy rights, or (b)
                                violates any applicable law or regulation, including but not limited to the IG Rules and SPI Rules.
                                MyHealthcare, at its sole discretion, may choose not to publish your reviews and feedback, if so required by
                                applicable law, and in accordance with Clause 5 of these Terms. You agree that MyHealthcare may contact you
                through telephone, email, SMS, or any other electronic means of communication for the purpose of:</p>
                                <p>Obtaining feedback in relation to Website or MyHealthcare’s services; and/or</p>
                                <p>Obtaining feedback in relation to any Practitioners listed on the Website; and/or</p>
                                <p>Resolving any complaints, information, or queries by Practitioners regarding your Critical Content;</p>
                                <p>and you agree to provide your fullest co-operation further to such communication by MyHealthcare.</p>
                                <p>MyHealthcare’s Feedback Collection and Fraud Detection Policy, is annexed as the Schedule hereto, and
                remains subject always to these Terms.</p>
                                <p>3.9 RECORDS</p>
                                <p>MyHealthcare may provide End-Users with a free facility known as ‘Records’ on its mobile application
                ‘MyHealthcare’. Information available in your Records is of two types:</p>
                                <p>User-created: Information uploaded by you or information generated during your interaction with
                MyHealthcare ecosystem, eg: appointment, medicine order placed by you.</p>
                                <p>Practice-created: Health Records generated by your interaction with a Practitioner who uses ‘MyHealthcare
                Ray’ or other Services of MyHealthcare software.</p>
                                <p>The specific terms relating to such Health Account are as below, without prejudice to the rest of these
                Terms and the Privacy Policy:</p>
                                <p>3.9.1 Your Records is only created after you have signed up and explicitly accepted these Terms.</p>
                                <p>3.9.2 Any Practice created Health Record is provided on an as-is basis at the sole intent, risk and
                                responsibility of the Practitioner and MyHealthcare does not validate the said information and makes no
                                representation in connection therewith. You should contact the relevant Practitioner in case you wish to
                point out any discrepancies or add, delete, or modify the Health Record in any manner.</p>
                                <p>3.9.3 The Health Records are provided on an as-is basis. While we strive to maintain the highest levels of
                                service availability, MyHealthcare is not liable for any interruption that may be caused to your access of
                the Services.</p>
                                <p>3.9.4 The reminder provided by the Records is only a supplementary way of reminding you to perform your
                                activities as prescribed by your Practitioner. In the event of any medicine reminders provided by
                                MyHealthcare, you should refer to your prescription before taking any medicines. MyHealthcare is not liable
                                if for any reason reminders are not delivered to you or are delivered late or delivered incorrectly, despite
                                its best efforts. In case you do not wish to receive the reminders, you can switch it off through the
                MyHealthcare app.</p>
                                <p>3.9.5 It is your responsibility to keep your correct mobile number and email ID updated in the Records. The
                                Health Records will be sent to the Records associated with this mobile number and/or email ID. Every time
                                you change any contact information (mobile or email), we will send a confirmation. MyHealthcare is not
                                responsible for any loss or inconvenience caused due to your failure in updating the contact details with
                MyHealthcare.</p>
                                <p>3.9.6 MyHealthcare uses industry–level security and encryption to your Health Records. However,
                                MyHealthcare does not guarantee to prevent unauthorized access if you lose your login credentials or they
                                are otherwise compromised. In the event you are aware of any unauthorized use or access, you shall
                                immediately inform MyHealthcare of such unauthorized use or access. Please safeguard your login credentials
                and report any actual suspected breach of account to <a href="mailto:support@MyHealthcare.co">support@MyHealthcare.co</a>.</p>
                                <p>3.9.7 If you access your dependents’ Health Records by registering your dependents with your own Records,
                                you are deemed to be responsible for the Health Records of your dependents and all obligations that your
                                dependents would have had, had they maintained their own separate individual Records. You agree that it
                                shall be your sole responsibility to obtain prior consent of your dependent and shall have right to share,
                                upload and publish any sensitive personal information of your dependent. MyHealthcare assumes no
                                responsibility for any claim, dispute or liability arising in this regard, and you shall indemnify
                                MyHealthcare and its officers against any such claim or liability arising out of unauthorized use of such
                information.</p>
                                <p>3.9.8 In case you want to delete your Records, you can do so by contacting our service support team.
                                However only your account and any associated Health Records will be deleted, and your Health Records stored
                by your Practitioners will continue to be stored in their respective accounts.</p>
                                <p>3.9.9 You may lose your “User created” record, if the data is not synced with the server.</p>
                                <p>3.9.10 If the Health Record is unassessed for a stipulated time, you may not be able to access your Health
                Records due to security reasons.</p>
                                <p>3.9.11 MyHealthcare is not liable if for any reason, Health Records are not delivered to you or are
                delivered late despite its best efforts.</p>
                                <p>3.9.12 The Health Records are shared with the phone numbers that are provided by your Practitioner.
                                MyHealthcare is not responsible for adding the Heath Records with incorrect numbers if those incorrect
                numbers are provided by the Practitioner.</p>
                                <p>3.9.13 MyHealthcare is not responsible or liable for any content, fact, Health Records, medical deduction
                                or the language used in your Health Records whatsoever. Your Practitioner is solely responsible and liable
                                for your Health Records and any information provided to us including but not limited to the content in them.
              </p>
                                <p>3.9.14 MyHealthcare has the ability in its sole discretion to retract Health Records without any prior
                notice if they are found to be shared incorrectly or inadvertently.</p>
                                <p>3.9.15 MyHealthcare will follow the law of land in case of any constitutional court or jurisdiction
                mandates to share the Health Records for any reason.</p>
                                <p>3.9.16 You agree and acknowledge that MyHealthcare may need to access the Health Record for cases such as
                any technical or operational issue of the End User in access or ownership of the Records.</p>
                                <p>3.9.17 You acknowledge that the Practitioners you are visiting may engage MyHealthcare’s software or third
                                party software for the purposes of the functioning of the Practitioner’s business and MyHealthcare’s
                                services including but not limited to the usage and for storage of Records (as defined in Section 3.10) in
                India and outside India, in accordance with the applicable laws.</p>
                                <p>3.9.18 To the extent that your Records have been shared with MyHealthcare or stored on any of the
                                MyHealthcare products used by Practitioner’s you are visiting, and may in the past have visited, You hereby
                                agree to the storage of your Records by MyHealthcare pertaining to such previously visited clinics and
                                hospitals who have tie ups with MyHealthcare for the purposes of their business and for MyHealthcare’s
                                services including but not limited to the usage and for storage of Records (as defined in Section 3.10) in
                                India and outside India, in accordance with the applicable laws and further agree, upon creation of your
                                account with MyHealthcare, to the mapping of such Records as may be available in MyHealthcare’s database to
                your User account.</p>
                                <ul>
                                    <li><u>Prescription Drugs</u>
                                        <ul>
                                            <li>The Website can be used by the Users to purchase various drugs and pharmaceutical products that
                                            requires a valid medical prescription issued by a Practitioner to be provided to a registered
                                            pharmacist for the purpose of dispensing such medicine (“Prescription Drugs”), In order to purchase
                                            Prescription Drugs from the Website, The drugs can only be offered as prescribed by Practitioner using
                                            MyHealthcare Platform. Pharmacies will verify the prescription forwarded by You and in case of
                                            Pharmacy(s) observe any discrepancy in the prescription, the Pharmacy(s) will cancel the order
                      immediately.</li>
                                            <li>Website shall maintain a record of all the prescriptions uploaded by the Users.</li>
                                            <li>4. TERMS OF USE PRACTITIONERS</li>
                                        </ul>
                                    </li>
                                </ul>
                                <p>The terms in this Clause 4 are applicable only to Practitioners.</p>
                                <h6>4.1 LISTING POLICY</h6>
                                <p>4.1.1 MyHealthcare, directly and indirectly, collects information regarding the Practitioners’ profiles,
                                contact details, and practice. MyHealthcare reserves the right to take down any Practitioner’s profile as
                                well as the right to display the profile of the Practitioners, with or without notice to the concerned
                                Practitioner. This information is collected for the purpose of facilitating interaction with the End-Users
                                and other Users. If any information displayed on the Website in connection with you and your profile is
                                found to be incorrect, you are required to inform MyHealthcare immediately to enable MyHealthcare to make
                the necessary amendments.</p>
                                <p>4.1.2 MyHealthcare shall not be liable and responsible for the ranking of the Practitioners on external
                websites and search engines</p>
                                <p>4.1.3 MyHealthcare shall not be responsible or liable in any manner to the Users for any losses, damage,
                                injuries or expenses incurred by the Users as a result of any disclosures or publications made by
                                MyHealthcare, where the User has expressly or implicitly consented to the making of disclosures or
                                publications by MyHealthcare. If the User had revoked such consent under the terms of the Privacy Policy,
                                then MyHealthcare shall not be responsible or liable in any manner to the User for any losses, damage,
                                injuries or expenses incurred by the User as a result of any disclosures made by MyHealthcare prior to its
                actual receipt of such revocation.</p>
                                <p>4.1.4 MyHealthcare reserves the right to moderate the suggestions made by the Practitioners through
                                feedback and the right to remove any abusive or inappropriate or promotional content added on the Website.
                                However, MyHealthcare shall not be liable if any inactive, inaccurate, fraudulent, or non- existent profiles
                of Practitioners are added to the Website.</p>
                                <p>4.1.5 Practitioners explicitly agree that MyHealthcare reserves the right to publish the Content provided
                by Practitioners to a third party including content platforms.</p>
                                <p>4.1.6 When you are listed on MyHealthcare.co, End-Users may see a ‘show number’ option. When End-Users
                                choose this option, they choose to call your number through a free telephony service provided by
                                MyHealthcare, and the records of such calls are recorded and stored in MyHealthcare’s servers. Such records
                                are dealt with only in accordance with the terms of the Privacy Policy. Such call facility provided to
                                End-Users and to you by MyHealthcare should be used only for appointment and booking purposes, and not for
                                consultation on health-related issues. MyHealthcare accepts no liability if the call facility is not used in
                accordance with the foregoing.</p>
                                <p>4.1.7 You as a Practitioner hereby represent and warrant that you will use the Services in accordance with
                                applicable law. Any contravention of applicable law as a result of your use of these Services is your sole
                responsibility, and MyHealthcare accepts no liability for the same.</p>
                                <p>4.2 PROFILE OWNERSHIP AND EDITING RIGHTS</p>
                                <p>MyHealthcare ensures easy access to the Practitioners by providing a tool to update your profile
                                information. MyHealthcare reserves the right of ownership of all the Practitioner’s profile and photographs
                                and to moderate the changes or updates requested by Practitioners. However, MyHealthcare takes the
                                independent decision whether to publish or reject the requests submitted for the respective changes or
                                updates. You hereby represent and warrant that you are fully entitled under law to upload all content
                                uploaded by you as part of your profile or otherwise while using MyHealthcare’s services, and that no such
                                content breaches any third party rights, including intellectual property rights. Upon becoming aware of a
                                breach of the foregoing representation, MyHealthcare may modify or delete parts of your profile information
                at its sole discretion with or without notice to you.</p>
                                <p>4.3 REVIEWS AND FEEDBACK DISPLAY RIGHTS OF MyHealthcare</p>
                                <p>4.3.1 All Critical Content is content created by the Users of www.MyHealthcare.co (“Website”) and the
                                clients of MyHealthcare customers and Practitioners, including the End-Users. As a platform, MyHealthcare
                                does not take responsibility for Critical Content and its role with respect to Critical Content is
                                restricted to that of an ‘intermediary’ under the Information Technology Act, 2000. The role of MyHealthcare
                                and other legal rights and obligations relating to the Critical Content are further detailed in Clauses 3.9
                                and 5 of these Terms. MyHealthcare’s Feedback Collection and Fraud Detection Policy, is annexed as the
                Schedule hereto, and remains subject always to these Terms.</p>
                                <p>4.3.2 MyHealthcare reserves the right to collect feedback and Critical Content for all the Practitioners,
                Clinics and Healthcare Providers listed on the Website.</p>
                                <p>4.3.3 MyHealthcare shall have no obligation to pre-screen, review, flag, filter, modify, refuse or remove
                any or all Critical Content from any Service, except as required by applicable law.</p>
                                <p>4.3.4 You understand that by using the Services you may be exposed to Critical Content or other content
                                that you may find offensive or objectionable. MyHealthcare shall not be liable for any effect on
                                Practitioner’s business due to Critical Content of a negative nature. In these respects, you may use the
                                Service at your own risk. MyHealthcare however, as an ‘intermediary, takes steps as required to comply with
                                applicable law as regards the publication of Critical Content. The legal rights and obligations with respect
                                to Critical Content and any other information sought to be published by Users are further detailed in
                Clauses 3.9 and 5 of these Terms.</p>
                                <p>4.3.5 MyHealthcare will take down information under standards consistent with applicable law, and shall in
                                no circumstances be liable or responsible for Critical Content, which has been created by the Users. The
                                principles set out in relation to third party content in the terms of Service for the Website shall be
                applicable mutatis mutandis in relation to Critical Content posted on the Website.</p>
                                <p>4.3.6 If MyHealthcare determines that you have provided inaccurate information or enabled fraudulent
                                feedback, MyHealthcare reserves the right to immediately suspend any of your accounts with MyHealthcare and
                                makes such declaration on the website alongside your name/your clinics name as determined by MyHealthcare
                for the protection of its business and in the interests of Users.</p>
                                <p>4.4 RELEVANCE ALGORITHM</p>
                                <p>MyHealthcare has designed the relevance algorithm in the best interest of the End-User and may adjust the
                                relevance algorithm from time to time to improve the quality of the results given to the patients. It is a
                                pure merit driven, proprietary algorithm which cannot be altered for specific Practitioners. MyHealthcare
                                shall not be liable for any effect on the Practitioner’s business interests due to the change in the
                Relevance Algorithm.</p>
                                <p>4.5 INDEPENDENT SERVICES</p>
                                <p>Your use of each Service confers upon you only the rights and obligations relating to such Service, and not
                to any other service that may be provided by MyHealthcare.</p>
                                <p>4.6 MyHealthcare REACH RIGHTS</p>
                                <p>MyHealthcare reserves the rights to display sponsored ads on the Website. These ads would be marked as
                                “Sponsored ads”. Without prejudice to the status of other content, MyHealthcare will not be liable for the
                                accuracy of information or the claims made in the Sponsored ads. MyHealthcare does not encourage the Users
                                to visit the Sponsored ads page or to avail any services from them. MyHealthcare will not be liable for the
                services of the providers of the Sponsored ads.</p>
                                <p>You represent and warrant that you will use these Services in accordance with applicable law. Any
                                contravention of applicable law as a result of your use of these Services is your sole responsibility, and
                MyHealthcare accepts no liability for the same.</p>
                                <p>4.7 MyHealthcare HEALTH FEED</p>
                                <p>4.7.1 MyHealthcare health feed is an online content platform available on the website, wherein
                                Practitioners who have a MyHealthcare profile and Users who have a health account can login and post health
                and wellness related content.</p>
                                <p>4.7.2 A Practitioner can use health feed by logging in from their profile, creating original content
                                comprising text, audio, video, images data or any combination of the same (“as defined in Clause 3.7.2”),
                                and uploading said Content to MyHealthcare’s servers. The Practitioner can upload their own images or choose
                                an image from the gallery that MyHealthcare provides. MyHealthcare shall post such Content to MyHealthcare
                                health feed at its own option and subject to these Terms and Conditions. The Content uploaded via
                                MyHealthcare health feed does not constitute medical advice and may not be construed as such by any person.
              </p>
                                <p>4.7.3 The Practitioner acknowledges that they are the original authors and creators of any Content or
                                comments uploaded by them via MyHealthcare health feed and that no Content or comment uploaded by them would
                                constitute infringement of the intellectual property rights of any other person. MyHealthcare reserves the
                                right to remove any Content or comment which it may determine at its own discretion as violating the
                                intellectual property rights of any other person. The Practitioner agrees to absolve MyHealthcare from and
                                indemnify MyHealthcare against all claims that may arise as a result of any third party intellectual
                                property right claim that may arise from the Practitioner’s uploading of any Content on MyHealthcare health
                                feed. The Practitioner also agrees to absolve MyHealthcare from and indemnify MyHealthcare against all
                                claims that may arise as a result of any third party intellectual property claim if the Practitioner
                downloads an image from MyHealthcare’s gallery and utilizes it for his/her personal or commercial gain.</p>
                                <p>4.7.4 The Practitioner hereby assigns to MyHealthcare, in perpetuity and worldwide, all intellectual
                                property rights in any Content or comment created by the Practitioner and uploaded by the Practitioner via
                MyHealthcare health feed.</p>
                                <p>4.7.5 MyHealthcare shall have the right to edit or remove the Content and any comments in such manner as it
                may deem fit at any time.</p>
                                <p>4.7.6 The Practitioner may also use MyHealthcare health feed in order to view original content created by
                                Users or other Practitioners and also create and upload comments on such Content including their own content
                where allowed.</p>
                                <p>4.7.7 Practitioner acknowledges that the content on MyHealthcare health feed reflects the views and
                opinions of the authors of such content and does not necessarily reflect MyHealthcare’s views.</p>
                                <p>4.7.8 Practitioner agrees not to post any comments or upload any Content which are defamatory, obscene,
                                objectionable or in nature and MyHealthcare reserves the right to remove any comments which it may determine
                                at its own discretion to violate these Terms and Conditions or be violative of any law or statute in force
                                at the time. The Practitioner agrees to absolve MyHealthcare from and indemnify MyHealthcare against all
                                claims that may arise as a result of any legal claim arising from the nature of the Content or the comments
                posted by the Practitioner on MyHealthcare health feed</p>
                                <p>4.8 MyHealthcare MEDICINE INFORMATION</p>
                                <p>For detailed terms and conditions regarding medicine information click here.</p>
                                <p>4.9 BOOK APPOINTMENT AND CALL FACILITY</p>
                                <p>4.9.1 As a valuable partner on our platform we want to ensure that the Practitioners experience on the
                MyHealthcare booking platform is beneficial to both, Practitioners and their Users.</p>
                                <p>For all terms and conditions of Book facility on MyHealthcare profile check Book Standards .</p>
                                <p>4.9.2 Practitioner understands that, MyHealthcare shall not be liable, under any event, for any comments or
                                feedback given by any of the Users in relation to the Services provided by Practitioner. The option of
                                publishing or modifying or moderating or masking (where required by law or norm etc.) the feedback provided
                by Users shall be solely at the discretion of MyHealthcare.</p>
                                <h6>5. RIGHTS AND OBLIGATIONS RELATING TO CONTENT</h6>
                                <p>5.1 As mandated by Regulation 3(2) of the IG Rules, MyHealthcare hereby informs Users that they are not
                permitted to host, display, upload, modify, publish, transmit, update or share any information that:</p>
                                <p>belongs to another person and to which the User does not have any right to;</p>
                                <p>is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libelous,
                                invasive of another’s privacy, hateful, or racially, ethnically objectionable, disparaging, relating or
                encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;</p>
                                <p>harm minors in any way;</p>
                                <p>infringes any patent, trademark, copyright or other proprietary rights;</p>
                                <p>violates any law for the time being in force;</p>
                                <p>deceives or misleads the addressee about the origin of such messages or communicates any information which
                is grossly offensive or menacing in nature;</p>
                                <p>impersonate another person;</p>
                                <p>contains software viruses or any other computer code, files or programs designed to interrupt, destroy or
                limit the functionality of any computer resource;</p>
                                <p>threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign
                                states, or public order or causes incitement to the commission of any cognizable offence or prevents
                investigation of any offence or is insulting any other nation.</p>
                                <p>5.2 Users are also prohibited from:</p>
                                <p>violating or attempting to violate the integrity or security of the Website or any MyHealthcare Content;
              </p>
                                <p>transmitting any information (including job posts, messages and hyperlinks) on or through the Website that
                is disruptive or competitive to the provision of Services by MyHealthcare;</p>
                                <p>intentionally submitting on the Website any incomplete, false or inaccurate information;</p>
                                <p>making any unsolicited communications to other Users;</p>
                                <p>using any engine, software, tool, agent or other device or mechanism (such as spiders, robots, avatars or
                intelligent agents) to navigate or search the Website;</p>
                                <p>attempting to decipher, decompile, disassemble or reverse engineer any part of the Website;</p>
                                <p>copying or duplicating in any manner any of the MyHealthcare Content or other information available from
                the Website;</p>
                                <p>framing or hot linking or deep linking any MyHealthcare Content.</p>
                                <p>circumventing or disabling any digital rights management, usage rules, or other security features of the
                Software.</p>
                                <p>5.3 MyHealthcare, upon obtaining knowledge by itself or been brought to actual knowledge by an affected
                                person in writing or through email signed with electronic signature about any such information as mentioned
                                above, shall be entitled to disable such information that is in contravention of Clauses 5.1 and 5.2.
                                MyHealthcare shall also be entitled to preserve such information and associated records for at least 90
                (ninety) days for production to governmental authorities for investigation purposes.</p>
                                <p>5.4 In case of non-compliance with any applicable laws, rules or regulations, or the Agreement (including
                                the Privacy Policy) by a User, MyHealthcare has the right to immediately terminate the access or usage
                rights of the User to the Website and Services and to remove non-compliant information from the Website.</p>
                                <p>5.5 MyHealthcare may disclose or transfer User-generated information to its affiliates or governmental
                                authorities in such manner as permitted or required by applicable law, and you hereby consent to such
                                transfer. The SPI Rules only permit MyHealthcare to transfer sensitive personal data or information
                                including any information, to any other body corporate or a person in India, or located in any other
                                country, that ensures the same level of data protection that is adhered to by MyHealthcare as provided for
                                under the SPI Rules, only if such transfer is necessary for the performance of the lawful contract between
                MyHealthcare or any person on its behalf and the User or where the User has consented to data transfer.</p>
                                <p>MyHealthcare respects the intellectual property rights of others and we do not hold any responsibility for
                any violations of any intellectual property rights</p>
                                <h6>6. TERMINATION</h6>
                                <p>6.1 MyHealthcare reserves the right to suspend or terminate a User’s access to the Website and the Services
                with or without notice and to exercise any other remedy available under law, in cases where,</p>
                                <p>Such User breaches any terms and conditions of the Agreement;</p>
                                <p>A third party reports violation of any of its right as a result of your use of the Services;</p>
                                <p>MyHealthcare is unable to verify or authenticate any information provide to MyHealthcare by a User;</p>
                                <p>MyHealthcare has reasonable grounds for suspecting any illegal, fraudulent or abusive activity on part of
                such User; or</p>
                                <p>MyHealthcare believes in its sole discretion that User’s actions may cause legal liability for such User,
                other Users or for MyHealthcare or are contrary to the interests of the Website.</p>
                                <p>6.2 Once temporarily suspended, indefinitely suspended or terminated, the User may not continue to use the
                                Website under the same account, a different account or re-register under a new account. On termination of an
                                account due to the reasons mentioned herein, such User shall no longer have access to data, messages, files
                                and other material kept on the Website by such User. The User shall ensure that he/she/it has continuous
                                backup of any medical services the User has rendered in order to comply with the User’s record keeping
                process and practices.</p>
                                <h6>7. LIMITATION OF LIABILITY</h6>
                                <p>In no event, including but not limited to negligence, shall MyHealthcare, or any of its directors,
                                officers, employees, agents or content or service providers (collectively, the “Protected Entities”) be
                                liable for any direct, indirect, special, incidental, consequential, exemplary or punitive damages arising
                                from, or directly or indirectly related to, the use of, or the inability to use, the Website or the content,
                                materials and functions related thereto, the Services, User’s provision of information via the Website, lost
                                business or lost End-Users, even if such Protected Entity has been advised of the possibility of such
                damages. In no event shall the Protected Entities be liable for:</p>
                                <p>provision of or failure to provide all or any service by Practitioners to End- Users contacted or managed
                through the Website;</p>
                                <p>any content posted, transmitted, exchanged or received by or on behalf of any User or other person on or
                through the Website;</p>
                                <p>any unauthorized access to or alteration of your transmissions or data; or</p>
                                <p>any other matter relating to the Website or the Service.</p>
                                <p>In no event shall the total aggregate liability of the Protected Entities to a User for all damages,
                                losses, and causes of action (whether in contract or tort, including, but not limited to, negligence or
                                otherwise) arising from this Agreement or a User’s use of the Website or the Services exceed, in the
                aggregate Rs. 1000/- (Rupees One Thousand Only).</p>
                                <h6>8. RETENTION AND REMOVAL</h6>
                                <p>MyHealthcare may retain such information collected from Users from its Website or Services for as long as
                                necessary, depending on the type of information; purpose, means and modes of usage of such information; and
                                according to the SPI Rules. Computer web server logs may be preserved as long as administratively necessary.
              </p>
                                <h6>9. APPLICABLE LAW AND DISPUTE SETTLEMENT</h6>
                                <p>9.1 You agree that this Agreement and any contractual obligation between MyHealthcare and User will be
                governed by the laws of India.</p>
                                <p>9.2 Any dispute, claim or controversy arising out of or relating to this Agreement, including the
                                determination of the scope or applicability of this Agreement to arbitrate, or your use of the Website or
                                the Services or information to which it gives access, shall be determined by arbitration in India, before a
                                sole arbitrator appointed by MyHealthcare. Arbitration shall be conducted in accordance with the Arbitration
                                and Conciliation Act, 1996. The seat of such arbitration shall be New Delhi. All proceedings of such
                                arbitration, including, without limitation, any awards, shall be in the English language. The award shall be
                final and binding on the parties to the dispute.</p>
                                <p>9.3 Subject to the above Clause 9.2, the courts at New Delhi shall have exclusive jurisdiction over any
                                disputes arising out of or in relation to this Agreement, your use of the Website or the Services or the
                information to which it gives access.</p>
                                <p>10. CONTACT INFORMATION GRIEVANCE OFFICER</p>
                                <p>10.1 If a User has any questions concerning MyHealthcare, the Website, this Agreement, the Services, or
                                anything related to any of the foregoing, MyHealthcare customer support can be reached at the following
                                email address: support@MyHealthcare.co or via the contact information available from the following
                hyperlink: <a href="https://www.myhealthcare.co/contact">www.MyHealthcare.co/contact</a>.
              </p>
                                <p>10.2 In accordance with the Information Technology Act, 2000, and the rules made there under, if you have
                                any grievance with respect to the Website or the service, including any discrepancies and grievances with
                                respect to processing of information, you can contact our Grievance Officer at:
                                support@MyHealthcare.co.&nbsp; In the event you suffer as a result of access or usage of our Website by any
                person in violation of Rule 3 of the IG Rules, please address your grievance to the above person.</p>
                                <p>11. SEVERABILITY</p>
                                <p>If any provision of the Agreement is held by a court of competent jurisdiction or arbitral tribunal to be
                                unenforceable under applicable law, then such provision shall be excluded from this Agreement and the
                                remainder of the Agreement shall be interpreted as if such provision were so excluded and shall be
                                enforceable in accordance with its terms; provided however that, in such event, the Agreement shall be
                                interpreted so as to give effect, to the greatest extent consistent with and permitted by applicable law, to
                                the meaning and intention of the excluded provision as determined by such court of competent jurisdiction or
                arbitral tribunal.</p>
                                <p>12. WAIVER</p>
                                <p>No provision of this Agreement shall be deemed to be waived and no breach excused, unless such waiver or
                                consent shall be in writing and signed by MyHealthcare. Any consent by MyHealthcare to, or a waiver by
                                MyHealthcare of any breach by you, whether expressed or implied, shall not constitute consent to, waiver of,
                or excuse for any other different or subsequent breach.</p>
                                <p>YOU HAVE READ THESE TERMS OF USE AND AGREE TO ALL OF THE PROVISIONS CONTAINED ABOVE</p>
                                <p>&nbsp;</p>
                                <p>Contact us at:</p>
                                <p>InnoCirc Ventures Pvt Ltd, B-803, The Palm Springs, Golf Course Road, Gurgaon, 122002, India</p>
                                <p>Tel : (0124) 6671977</p>
                            </div>
                        </div>
                    </div>
                </div>




                <div className="modal modal-max-width chart-modal-trend" id="terms-conditions-mhvc" style={{ display: 'none' }} aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header border-bottom-0 mb-3">
                                <h3 className="font-weight-normal">Terms and Conditions</h3>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>
                            <div className="modal-body py-0">
                                <div className="modalwrapper">
                                    <p>By using the platform you hereby confirm that:</p>
                                    <ol>
                                        <li>You are a Registered Medical Practitioner who is enrolled in the Indian Medical Register and / or the State Medical Register under the under the Indian Medical Council Act 1956.</li>
                                        <li>You have read and understood the Telemedicine Practice Guidelines dated 25 March 2020 issued by the Board of Governors ("<strong>Guidelines</strong>") (in supersession of the erstwhile Medical Council of Indian guidelines), which are  applicable to you, and you confirm to abide by these Guidelines.</li>
                                        <li>You agree to comply with the Indian Medical Council (Professional Conduct, Etiquette and Ethics) Regulations 2002 ("<strong>Regulations</strong>").</li>
                                        <li>You agree to display the registration number accorded to you by the Medical Council of Indian and/or the State Medical Council, on prescriptions, website, electronic communication and receipts etc. given to your patients.</li>
                                        <li>You would begin each consultation by informing the patient of your name and qualifications.</li>
                                        <li>You would explicitly ask the age of each patient, and if there is any doubt, seek age proof. Where the patient is a minor, after confirming the age, you will proceed with a virtual consultation only if the minor is consulting along-with an adult (guardian or relative) whose identity will also be ascertained by you.</li>
                                        <li>You will take an explicit consent on mobile app, email, text or audio/video message, if a virtual consultation is initiated by you. Record of each such explicated consent shall be maintained by you along with each patient’s records.  However, If, the patient initiates the telemedicine consultation, then the consent is implied.</li>
                                        <li>You would prescribe medicines set out in List O, List A and List B as appropriate for each patient  with the appropriate / provisional diagnosis as per the Guidelines.</li>
                                        <li>You shall ensure that each prescription is also as per the Regulations and will not contravene the provisions of Drugs and Cosmetic Act, 1940 and the relevant rules made thereunder (as amended for time to time). Further you shall not prescribe medicines set out in the Prohibited List in the Guidelines.</li>
                                        <li>In all cases of emergency, you would advise the patient for an in-person interaction with a Registered Medical Practitioner at the earliest.</li>
                                        <li>If in your clinical / professional judgement, you feel the patient cannot be provided a diagnosis or medication basis the virtual consultation, you shall mention so in the prescription (which you shall maintain as part of each patient’s records) and inform the patient that he or she should visit a hospital or appropriate medical facility.</li>
                                        <li>You will not insist on virtual consultation, when the patient is willing to travel to a facility and/or requests an in-person consultation.</li>
                                        <li>You will ensure confidentiality and not misuse patient images, data, especially private and sensitive in nature in any manner whatsoever.</li>
                                        <li>You will not solicit patients for virtual consultation through any advertisements or inducements whatsoever.</li>
                                        <li>You shall maintain all records, documents logging / recordings of virtual consultation / interaction with each patient, patient records, reports, documents, images, diagnostics, data (digital &nbsp; non-digital) and/or prescription records (as applicable) for each virtual consultation with a patient through the MyHealthcare virtual platform.</li>
                                        <li>You will complete a mandatory online course developed and made available by the Board of Governors in supersession of Medical Council of India within 3 years of the Notification of the Guidelines for such virtual consultations and provide proof thereof to MyHealthcare virtual platform for its records.</li>
                                        <li>In using MyHealthcare virtual platform, you will uphold the same professional and ethical norms and standards as applicable to traditional in-person care, within the intrinsic limitations of telemedicine and you will make all efforts to gather sufficient medical information about each patient’s condition before exercising your clinical / professional judgement.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                <div className="modal modal-max-width chart-modal-trend" id="privacy-policy">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header border-bottom-0 mb-3">
                                <h3 className="font-weight-normal">Privacy Policy</h3>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>
                            <div className="modal-body py-0">
                                <p>Innocirc Ventures Pvt Ltd&nbsp;(“us”, “we”, or “MyHealthcare”, which also includes its affiliates) is the
                                author and publisher of the internet resource www.MyHealthcare.co (“Website”) on the world wide web as well
                                as the software and applications provided by MyHealthcare, including but not limited to the mobile
                                application ‘MyHealth’, and the software and applications of the brand names ‘MyHealthcare iOs Apps’,
                                ‘MyHealthcare Android Apps’, ‘MyHealthcare Agent Portal’ and ‘MyHealthcare Customer Portal’ (together with
                                the Website, referred to as the “Services”).</p>
                                <p>This privacy policy (“Privacy Policy”) explains how we collect, use, share, disclose and protect Personal
                                information about the Users of the Services, including the Practitioners (as defined in the Terms of Use,
                                which may be accessed via the following weblink https://www.myhealthcare.co/privacy (the “Terms of Use”)),
                                the End-Users (as defined in the Terms of Use), and the visitors of Website (jointly and severally referred
                                to as “you” or “Users” in this Privacy Policy). We created this Privacy Policy to demonstrate our commitment
                                to the protection of your privacy and your personal information. Your use of and access to the Services is
                                subject to this Privacy Policy and our Terms of Use. Any capitalized term used but not defined in this
                                Privacy Policy shall have the meaning attributed to it in our Terms of Use.</p>
                                <p>BY USING THE SERVICES OR BY OTHERWISE GIVING US YOUR INFORMATION, YOU WILL BE DEEMED TO HAVE READ,
                                UNDERSTOOD AND AGREED TO THE PRACTICES AND POLICIES OUTLINED IN THIS PRIVACY POLICY AND AGREE TO BE BOUND BY
                                THE PRIVACY POLICY. YOU HEREBY CONSENT TO OUR COLLECTION, USE AND SHARING, DISCLOSURE OF YOUR INFORMATION AS
                                DESCRIBED IN THIS PRIVACY POLICY. WE RESERVE THE RIGHT TO CHANGE, MODIFY, ADD OR DELETE PORTIONS OF THE
                                TERMS OF THIS PRIVACY POLICY, AT OUR SOLE DISCRETION, AT ANY TIME. IF YOU DO NOT AGREE WITH THIS PRIVACY
                                POLICY AT ANY TIME, DO NOT USE ANY OF THE SERVICES OR GIVE US ANY OF YOUR INFORMATION. IF YOU USE THE
                                SERVICES ON BEHALF OF SOMEONE ELSE (SUCH AS YOUR CHILD) OR AN ENTITY (SUCH AS YOUR EMPLOYER), YOU REPRESENT
                                THAT YOU ARE AUTHORISED BY SUCH INDIVIDUAL OR ENTITY TO (I) ACCEPT THIS PRIVACY POLICY ON SUCH INDIVIDUAL’S
                                OR ENTITY’S BEHALF, AND (II) CONSENT ON BEHALF OF SUCH INDIVIDUAL OR ENTITY TO OUR COLLECTION, USE AND
                                DISCLOSURE OF SUCH INDIVIDUAL’S OR ENTITY’S INFORMATION AS DESCRIBED IN THIS PRIVACY POLICY.</p>
                                <h6>1. WHY THIS PRIVACY POLICY?</h6>
                                <p>This Privacy Policy is published in compliance with, inter alia:</p>
                                <p>Section 43A of the Information Technology Act, 2000;<br />
                                Regulation 4 of the Information Technology (Reasonable Security Practices and Procedures and Sensitive
                                Personal Information) Rules, 2011 (the “SPI Rules”);<br />
                                Regulation 3(1) of the Information Technology (Intermediaries Guidelines) Rules, 2011.<br />
                                This Privacy Policy states the following:</p>
                                <p>The type of information collected from the Users, including Personal Information (as defined in paragraph 2
                                below) and Sensitive Personal Data or Information (as defined in paragraph 2 below) relating to an
                                individual;<br />
                                The purpose, means and modes of collection, usage, processing, retention and destruction of such
                                information; and<br />
                                How and to whom MyHealthcare will disclose such information.</p>
                                <h6>2. COLLECTION OF PERSONAL INFORMATION</h6>
                                <p>Generally some of the Services require us to know who you are so that we can best meet your needs. When you
                                access the Services, or through any interaction with us via emails, telephone calls or other correspondence,
                                we may ask you to voluntarily provide us with certain information that personally identifies you or could be
                                used to personally identify you. You hereby consent to the collection of such information by MyHealthcare.
                                Without prejudice to the generality of the above, information collected by us from you may include (but is
                                not limited to) the following:</p>
                                <p>contact data (such as your email address and phone number);<br />
                                demographic data (such as your gender, your date of birth and your pin code);<br />
                                data regarding your usage of the services and history of the appointments made by or with you through the
                                use of Services;<br />
                                insurance data (such as your insurance carrier and insurance plan);<br />
                                other information that you voluntarily choose to provide to us (such as information shared by you with us
                                through emails or letters.<br />
                                The information collected from you by MyHealthcare may constitute ‘personal information’ or ‘sensitive
                                personal data or information’ under the SPI Rules.</p>
                                <p>“Personal Information” is defined under the SPI Rules to mean any information that relates to a natural
                                person, which, either directly or indirectly, in combination with other information available or likely to
                                be available to a body corporate, is capable of identifying such person.</p>
                                <p>The SPI Rules further define “Sensitive Personal Data or Information” of a person to mean personal
                                information about that person relating to:</p>
                                <p>passwords;<br />
                                financial information such as bank accounts, credit and debit card details or other payment instrument
                                details;<br />
                                physical, physiological and mental health condition;<br />
                                sexual orientation;<br />
                                medical records and history;<br />
                                biometric information;<br />
                                information received by body corporate under lawful contract or otherwise;<br />
                                visitor details as provided at the time of registration or thereafter; and<br />
                                call data records.<br />
                                MyHealthcare will be free to use, collect and disclose information that is freely available in the public
                                domain without your consent.</p>
                                <h6>3. PRIVACY STATEMENTS</h6>
                                <p>3.1 ALL USERS NOTE:<br />
                                This section applies to all users.</p>
                                <p>3.1.1 Accordingly, a condition of each User’s use of and access to the Services is their acceptance of the
                                Terms of Use, which also involves acceptance of the terms of this Privacy Policy. Any User that does not
                                agree with any provisions of the same has the option to discontinue the Services provided by MyHealthcare
                                immediately.<br />
                                3.1.2An indicative list of information that MyHealthcare may require you to provide to enable your use of
                                the Services is provided in the Schedule annexed to this Privacy Policy.</p>
                                <p>3.1.3 All the information provided to MyHealthcare by a User, including Personal Information or any
                                Sensitive Personal Data or Information, is voluntary. You understand that MyHealthcare may use certain
                                information of yours, which has been designated as Personal Information or ‘Sensitive Personal Data or
                                Information’ under the SPI Rules, (a) for the purpose of providing you the Services, (b) for commercial
                                purposes and in an aggregated or non-personally identifiable form for research, statistical analysis and
                                business intelligence purposes, (c) for sale or transfer of such research, statistical or intelligence data
                                in an aggregated or non-personally identifiable form to third parties and affiliates (d) for communication
                                purpose so as to provide You a better way of booking appointments and for obtaining feedback in relation to
                                the Practitioners and their practice, (e) debugging customer support related issues.. MyHealthcare also
                                reserves the right to use information provided by or about the End-User for the following purposes:<br />
                                Publishing such information on the Website.<br />
                                Contacting End-Users for offering new products or services.<br />
                                Contacting End-Users for taking product and Service feedback.<br />
                                Analyzing software usage patterns for improving product design and utility.<br />
                                Analyzing anonymized practice information for commercial use.<br />
                                If you have voluntarily provided your Personal Information to MyHealthcare for any of the purposes stated
                                above, you hereby consent to such collection and use of such information by MyHealthcare. However,
                                MyHealthcare shall not contact You on Your telephone number(s) for any purpose including those mentioned in
                                this sub-section 4.1(iii), if such telephone number is registered with the Do Not Call registry (“DNC
                                Registry”) under the PDPA without your express, clear and un-ambiguous written consent.</p>
                                <p>3.1.4 Collection, use and disclosure of information which has been designated as Personal Information or
                                Sensitive Personal Data or Information’ under the SPI Rules requires your express consent. By affirming your
                                assent to this Privacy Policy, you provide your consent to such use, collection and disclosure as required
                                under applicable law.</p>
                                <p>3.1.5 MyHealthcare does not control or endorse the content, messages or information found in any Services
                                and, therefore, MyHealthcare specifically disclaims any liability with regard to the Services and any
                                actions resulting from your participation in any Services, and you agree that you waive any claims against
                                MyHealthcare relating to same, and to the extent such waiver may be ineffective, you agree to release any
                                claims against MyHealthcare relating to the same.</p>
                                <p>3.1.6 You are responsible for maintaining the accuracy of the information you submit to us, such as your
                                contact information provided as part of account registration. If your personal information changes, you may
                                correct, delete inaccuracies, or amend information by making the change on our member information page or by
                                contacting us through privacy@MyHealthcare.co. We will make good faith efforts to make requested changes in
                                our then active databases as soon as reasonably practicable. If you provide any information that is untrue,
                                inaccurate, out of date or incomplete (or becomes untrue, inaccurate, out of date or incomplete), or
                                MyHealthcare has reasonable grounds to suspect that the information provided by you is untrue, inaccurate,
                                out of date or incomplete, MyHealthcare may, at its sole discretion, discontinue the provision of the
                                Services to you. There may be circumstances where MyHealthcare will not correct, delete or update your
                                Personal Data, including (a) where the Personal Data is opinion data that is kept solely for evaluative
                                purpose; and (b) the Personal Data is in documents related to a prosecution if all proceedings relating to
                                the prosecution have not been completed.</p>
                                <p>3.1.7 If you wish to cancel your account or request that we no longer use your information to provide you
                                Services, contact us through support@MyHealthcare.co . We will retain your information for as long as your
                                account with the Services is active and as needed to provide you the Services. We shall not retain such
                                information for longer than is required for the purposes for which the information may lawfully be used or
                                is otherwise required under any other law for the time being in force. After a period of time, your data may
                                be anonymized and aggregated, and then may be held by us as long as necessary for us to provide our Services
                                effectively, but our use of the anonymized data will be solely for analytic purposes. Please note that your
                                withdrawal of consent, or cancellation of account may result in MyHealthcare being unable to provide you
                                with its Services or to terminate any existing relationship MyHealthcare may have with you.</p>
                                <p>3.1.8 If you wish to opt-out of receiving non-essential communications such as promotional and
                                marketing-related information regarding the Services, please send us an email at support@MyHealthcare.co .
                                </p>
                                <p>3.1.9 MyHealthcare may require the User to pay with a credit card, wire transfer, debit card or cheque for
                                Services for which subscription amount(s) is/are payable. MyHealthcare will collect such User’s credit card
                                number and/or other financial institution information such as bank account numbers and will use that
                                information for the billing and payment processes, including but not limited to the use and disclosure of
                                such credit card number and information to third parties as necessary to complete such billing operation.
                                Verification of credit information, however, is accomplished solely by the User through the authentication
                                process. User’s credit-card/debit card details are transacted upon secure sites of approved payment gateways
                                which are digitally under encryption, thereby providing the highest possible degree of care as per current
                                technology. However, MyHealthcare provides you an option not to save your payment details. User is advised,
                                however, that internet technology is not full proof safe and User should exercise discretion on using the
                                same.</p>
                                <p>3.1.10 Due to the communications standards on the Internet, when a User or the End-User or anyone who
                                visits the Website, MyHealthcare automatically receives the URL of the site from which anyone visits.
                                MyHealthcare also receives the Internet Protocol (IP) address of each User’s computer (or the proxy server a
                                User used to access the World Wide Web), User’s computer operating system and type of web browser the User
                                is using, email patterns, as well as the name of User’s ISP. This information is used to analyze overall
                                trends to help MyHealthcare improve its Service. The linkage between User’s IP address and User’s personally
                                identifiable information is not shared with or disclosed to third parties. Notwithstanding the above,
                                MyHealthcare may share and/or disclose some of the aggregate findings (not the specific data) in anonymized
                                form (i.e., non-personally identifiable) with advertisers, sponsors, investors, strategic partners, and
                                others in order to help grow its business.</p>
                                <p>3.1.11 The Website uses temporary cookies to store certain (that is not sensitive personal data or
                                information) that is used by MyHealthcare and its service providers for the technical administration of the
                                Website, research and development, and for User administration. In the course of serving advertisements or
                                optimizing services to its Users, MyHealthcare may allow authorized third parties to place or recognize a
                                unique cookie on the User’s browser. The cookies however, do not store any Personal Information of the User.
                                You may adjust your internet browser to disable cookies. If cookies are disabled you may still use the
                                Website, but the Website may be limited in the use of some of the features.</p>
                                <p>3.1.12 A User may have limited access to the Website without creating an account on the Website.
                                Unregistered Users can make appointments with the doctors by providing their name and phone number. In order
                                to have access to all the features and benefits on our Website, a User must first create an account on our
                                Website. To create an account, a User is required to provide the following information, which such User
                                recognizes and expressly acknowledges is Personal Information allowing others, including MyHealthcare, to
                                identify the User: name, User ID, email address, country, ZIP/postal code, age, phone number, password
                                chosen by the User and valid financial account information. Other information requested on the registration
                                page, including the ability to receive promotional offers from MyHealthcare, is optional. MyHealthcare may,
                                in future, include other optional requests for information from the User to help MyHealthcare to customize
                                the Website to deliver personalized information to the User.</p>
                                <p>3.1.13 This Privacy Policy applies to Services that are owned and operated by MyHealthcare. MyHealthcare
                                does not exercise control over the sites displayed as search results or links from within its Services.
                                These other sites may place their own cookies or other files on the Users’ computer, collect data or solicit
                                personal information from the Users, for which MyHealthcare is not responsible or liable. Accordingly,
                                MyHealthcare does not make any representations concerning the privacy practices or policies of such third
                                parties or terms of use of such websites, nor does MyHealthcare guarantee the accuracy, integrity, or
                                quality of the information, data, text, software, sound, photographs, graphics, videos, messages or other
                                materials available on such websites. The inclusion or exclusion does not imply any endorsement by
                                MyHealthcare of the website, the website’s provider, or the information on the website. If you decide to
                                visit a third party website linked to the Website, you do this entirely at your own risk. MyHealthcare
                                encourages the User to read the privacy policies of that website.</p>
                                <p>3.1.14 The Website may enable User to communicate with other Users or to post information to be accessed by
                                others, whereupon other Users may collect such data. Such Users, including any moderators or administrators,
                                are not authorized MyHealthcare representatives or agents, and their opinions or statements do not
                                necessarily reflect those of MyHealthcare, and they are not authorized to bind MyHealthcare to any contract.
                                MyHealthcare hereby expressly disclaims any liability for any reliance or misuse of such information that is
                                made available by Users or visitors in such a manner.</p>
                                <p>3.1.15 MyHealthcare does not collect information about the visitors of the Website from other sources, such
                                as public records or bodies, or private organisations, save and except for the purposes of registration of
                                the Users (the collection, use, storage and disclosure of which each End User must agree to under the Terms
                                of Use in order for MyHealthcare to effectively render the Services).</p>
                                <p>3.1.16 MyHealthcare maintains a strict “No-Spam” policy, which means that MyHealthcare does not intend to
                                sell, rent or otherwise give your e-mail address to a third party without your consent.</p>
                                <p>3.1.17 MyHealthcare has implemented best international market practices and security policies, rules and
                                technical measures to protect the personal data that it has under its control from unauthorised access,
                                improper use or disclosure, unauthorised modification and unlawful destruction or accidental loss. However,
                                for any data loss or theft due to unauthorized access to the User’s electronic devices through which the
                                User avails the Services, MyHealthcare shall not be held liable for any loss whatsoever incurred by the
                                User.</p>
                                <p>3.1.18 MyHealthcare implements reasonable security practices and procedures and has a comprehensive
                                documented information security programme and information security policies that contain managerial,
                                technical, operational and physical security control measures that are commensurate with respect to the
                                information being collected and the nature of MyHealth’s business.</p>
                                <p>3.1.19 MyHealthcare takes your right to privacy very seriously and other than as specifically stated in
                                this Privacy Policy, will only disclose your Personal Information in the event it is required to do so by
                                law, rule, regulation, law enforcement agency, governmental official, legal authority or similar
                                requirements or when MyHealthcare, in its sole discretion, deems it necessary in order to protect its rights
                                or the rights of others, to prevent harm to persons or property, to fight fraud and credit risk, or to
                                enforce or apply the Terms of Use.</p>
                                <p>3.3 END-USERS NOTE:<br />
                                This section applies to all End-Users.</p>
                                <p>3.3.1 As part of the registration/application creation and submission process that is available to
                                End-Users on this Website, certain information, including Personal Information or Sensitive Personal Data or
                                Information is collected from the End-Users.</p>
                                <p>3.3.2 All the statements in this Privacy Policy apply to all End-Users, and all End-Users are therefore
                                required to read and understand the privacy statements set out herein prior to submitting any Personal
                                Information or Sensitive Personal Data or Information to MyHealthcare, failing which they are required to
                                leave the MyHealthcare immediately.</p>
                                <p>3.3.3 If you have inadvertently submitted any such information to MyHealthcare prior to reading theprivacy
                                statements set out herein, and you do not agree with the manner in which such information is collected,
                                processed, stored, used or disclosed, then you may access, modify and delete such information by using
                                options provided on the Website. In addition, you can, by sending an email to privacy@MyHealthcare.co,
                                inquire whether MyHealthcare is in possession of your personal data, and you may also require MyHealthcare
                                to delete and destroy all such information.</p>
                                <p>3.3.4 End-Users’ personally identifiable information, which they choose to provide on the Website is used
                                to help the End-Users describe/identify themselves. Other information that does not personally identify the
                                End-Users as an individual, is collected by MyHealthcare from End-Users (such as, patterns of utilization
                                described above) and is exclusively owned by MyHealthcare. MyHealthcare may also use such information in an
                                aggregated or non-personally identifiable form for research, statistical analysis and business intelligence
                                purposes, and may sell or otherwise transfer such research, statistical or intelligence data in an
                                aggregated or non-personally identifiable form to third parties and affiliates. In particular, MyHealthcare
                                reserves with it the right to use anonymized End-User demographics information and anonymized End-User
                                health information for the following purposes:</p>
                                <p>Analyzing software usage patterns for improving product design and utility.<br />
                                Analyzing such information for research and development of new technologies.<br />
                                Using analysis of such information in other commercial product offerings of MyHealthcare.<br />
                                Sharing analysis of such information with third parties for commercial use.</p>
                                <p>3.3.5 MyHealthcare will communicate with the End-Users through email, phone and notices posted on the
                                Website or through other means available through the service, including text and other forms of messaging.
                                The End-Users can change their e-mail and contact preferences at any time by logging into their “Account” at
                                www.MyHealthcare.co and changing the account settings.</p>
                                <p>3.3.6 At times, MyHealthcare conducts a User survey to collect information about End-Users’ preferences.
                                These surveys are optional and if End-Users choose to respond, their responses will be kept anonymous.
                                Similarly, MyHealthcare may offer contests to qualifying End-Users in which we ask for contact and
                                demographic information such as name, email address and mailing address. The demographic information that
                                MyHealthcare collects in the registration process and through surveys is used to help MyHealthcare improve
                                its Services to meet the needs and preferences of End-Users.</p>
                                <p>3.3.7 MyHealthcare may keep records of electronic communications and telephone calls received andmade for
                                making appointments or other purposes for the purpose of administration of Services, customer support,
                                research and development and for better listing of Practitioners.</p>
                                <p>3.3.8 All MyHealthcare employees and data processors, who have access to, and are associated with the
                                processing of sensitive personal data or information, are obliged to respect the confidentiality of every
                                End-Users’ Personal Information or Sensitive Personal Data and Information. MyHealthcare has put in place
                                procedures and technologies as per good industry practices and in accordance with the applicable laws, to
                                maintain security of all personal data from the point of collection to the point of destruction. Any
                                third-party data processor to which MyHealthcare transfers Personal Data shall have to agree to comply with
                                those procedures and policies, or put in place adequate measures on their own.</p>
                                <p>3.3.9 MyHealthcare may also disclose or transfer End-Users’ personal and other information provided by a
                                User, to a third party as part of reorganization or a sale of the assets of a MyHealthcare corporation
                                division or company. Any third party to which MyHealthcare transfers or sells its assets to will have the
                                right to continue to use the personal and other information that End-Users provide to us, in accordance with
                                the Terms of Use</p>
                                <p>3.3.10 To the extent necessary to provide End-Users with the Services, MyHealthcare may provide their
                                Personal Information to third party contractors who work on behalf of or with MyHealthcare to provide
                                End-Users with such Services, to help MyHealthcare communicate with End-Users or to maintain the Website.
                                Generally these contractors do not have any independent right to share this information, however certain
                                contractors who provide services on the Website, including the providers of online communications services,
                                may use and disclose the personal information collected in connection with the provision of these Services
                                in accordance with their own privacy policies. In such circumstances, you consent to us disclosing your
                                Personal Information to contractors, solely for the intended purposes only.</p>
                                <p>3.4 CASUAL VISITORS NOTE:</p>
                                <p>3.4.1 No sensitive personal data or information is automatically collected by MyHealthcare from any casual
                                visitors of this website, who are merely perusing the Website.</p>
                                <p>3.4.2 Nevertheless, certain provisions of this Privacy Policy are applicable to even such casual visitors,
                                and such casual visitors are also required to read and understand the privacy statements set out herein,
                                failing which they are required to leave this Website immediately.</p>
                                <p>3.4.3 If you, as a casual visitor, have inadvertently browsed any other page of this Website prior to
                                reading the privacy statements set out herein, and you do not agree with the manner in which such
                                information is obtained, collected, processed, stored, used, disclosed or retained, merely quitting this
                                browser application should ordinarily clear all temporary cookies installed by MyHealthcare. All visitors,
                                however, are encouraged to use the “clear cookies” functionality of their browsers to ensure such clearing /
                                deletion, as MyHealthcare cannot guarantee, predict or provide for the behaviour of the equipment of all the
                                visitors of the Website.</p>
                                <p>3.4.4 You are not a casual visitor if you have willingly submitted any personal data or information to
                                MyHealthcare through any means, including email, post or through the registration process on the Website.
                                All such visitors will be deemed to be, and will be treated as, Users for the purposes of this Privacy
                                Policy, and in which case, all the statements in this Privacy Policy apply to such persons.</p>
                                <h6>4. CONFIDENTIALITY AND SECURITY</h6>
                                <p>4.1 Your Personal Information is maintained by MyHealthcare in electronic form on its equipment, and on the
                                equipment of its employees. Such information may also be converted to physical form from time to time.
                                MyHealthcare takes all necessary precautions to protect your personal information both online and off-line,
                                and implements reasonable security practices and measures including certain managerial, technical,
                                operational and physical security control measures that are commensurate with respect to the information
                                being collected and the nature of MyHealth’s business.</p>
                                <p>4.2 No administrator at MyHealthcare will have knowledge of your password. It is important for you to
                                protect against unauthorized access to your password, your computer and your mobile phone. Be sure to log
                                off from the Website when finished. MyHealthcare does not undertake any liability for any unauthorised use
                                of your account and password. If you suspect any unauthorized use of your account, you must immediately
                                notify MyHealthcare by sending an email to support@MyHealthcare.co You shall be liable to indemnify
                                MyHealthcare due to any loss suffered by it due to such unauthorized use of your account and password.</p>
                                <p>4.3 MyHealthcare makes all User information accessible to its employees, agents or partners and third
                                parties only on a need-to-know basis, and binds only its employees to strict confidentiality obligations.
                                </p>
                                <p>4.4 Part of the functionality of MyHealthcare is assisting the doctors to maintain and organise such
                                information. MyHealthcare may, therefore, retain and submit all such records to the appropriate authorities,
                                or to doctors who request access to such information.<br />
                                4.5 Part of the functionality of the MyHealthcare is assisting the patients to access information relating
                                to them. MyHealthcare may, therefore, retain and submit all such records to the relevant patients, or to
                                their doctors.</p>
                                <p>4.6 Notwithstanding the above, MyHealthcare is not responsible for the confidentiality, security or
                                distribution of your Personal Information by our partners and third parties outside the scope of our
                                agreement with such partners and third parties. Further, MyHealthcare shall not be responsible for any
                                breach of security or for any actions of any third parties or events that are beyond the reasonable control
                                of MyHealthcare including but not limited to, acts of government, computer hacking, unauthorised access to
                                computer data and storage device, computer crashes, breach of security and encryption, poor quality of
                                Internet service or telephone service of the User etc.</p>
                                <h6>5. CHANGE TO PRIVACY POLICY</h6>
                                <p>MyHealthcare may update this Privacy Policy at any time, with or without advance notice. In the event there
                                are significant changes in the way MyHealthcare treats User’s personally identifiable information, or in the
                                Privacy Policy itself, MyHealthcare will display a notice on the Website or send Users an email, as provided
                                for above, so that you may review the changed terms prior to continuing to use the Services. As always, if
                                you object to any of the changes to our terms, and you no longer wish to use the Services, you may contact
                                support@MyHealthcare.co to deactivate your account. Unless stated otherwise, MyHealth’s current Privacy
                                Policy applies to all information that MyHealthcare has about you and your account.</p>
                                <p>If a User uses the Services or accesses the Website after a notice of changes has been sent to such User or
                                published on the Website, such User hereby provides his/her/its consent to the changed terms.</p>
                                <h6>6. CHILDREN’S AND MINOR’S PRIVACY</h6>
                                <p>MyHealthcare strongly encourages parents and guardians to supervise the online activities of their minor
                                children and consider using parental control tools available from online services and software manufacturers
                                to help provide a child-friendly online environment. These tools also can prevent minors from disclosing
                                their name, address, and other personally identifiable information online without parental permission.
                                Although the MyHealthcare Website and Services are not intended for use by minors, MyHealthcare respects the
                                privacy of minors who may inadvertently use the internet or the mobile application.</p>
                                <h6>7. CONSENT TO THIS POLICY</h6>
                                <p>You acknowledge that this Privacy Policy is a part of the Terms of Use of the Website and the other
                                Services, and you unconditionally agree that becoming a User of the Website and its Services signifies your
                                (i) assent to this Privacy Policy, and (ii) consent to MyHealthcare using, collecting, processing and/or
                                disclosing your Personal Information in the manner and for the purposes set out in this Privacy Policy. Your
                                visit to the Website and use of the Services is subject to this Privacy Policy and the Terms of Use.</p>
                                <h6>8. ADDRESS FOR PRIVACY QUESTIONS</h6>
                                <p>Should you have questions about this Privacy Policy or MyHealthcare’s information collection, use and
                                disclosure practices, you may contact, the Data Protection Officer appointed by MyHealthcare in accordance
                                with the provisions of PDPA. We will use reasonable efforts to respond promptly to any requests, questions
                                or concerns, which you may have regarding our use of your personal information. If you have any grievance
                                with respect to our use of your information, you may communicate such grievance to the Data Protection
                                Officer:<br />
                                Name: Mr. Sandeep Singh<br />
                                Innocirc Ventures Pvt Ltd,<br />
                                B-803, The Palm Springs, Golf Course Road, Gurugram 122002, Haryana, India<br />
                                Phone: 0124-6671977 (Ask to be connected to Mr. Sandeep Singh)<br />
                                Email: support@innocirc.com</p>
                                <h6>SCHEDULE</h6>
                                <p>Indicative List of Information by Nature of Service</p>
                                <p>1. End-Users using the Website by registering for an account on the Website or ‘MyHealth’ mobile
                                application:</p>
                                <p>You can create an account by giving us information regarding your [name, mobile number, email address], and
                                such other information as requested on the End-User registration page. This is to enable us to provide you
                                with the facility to use the account to book your appointments and store other health related information.
                                </p>
                                <p>2. End-Users using the Website without registering for an account on the Website or ‘MyHealth’ mobile
                                application (i.e., ‘Guest’ End-User):</p>
                                <p>You can use the Website without registering for an account, but to book an appointment, you may be asked
                                certain information (including your [mobile number], and such other information as requested when you choose
                                to use the Services without registration) to confirm the appointment.</p>
                                <p>You will be required to create an account and may be required to provide MyHealthcare with information
                                regarding your [name, mobile number, email address], and such other information as requested by MyHealthcare
                                on the registration page, in order to complete your registration.</p>
                                <p>Upon registration, MyHealthcare will access non-personally identifiable information of your patients from
                                your patient records. You agree to make your patients fully aware of such access.</p>
                                <p>MyHealthcare reserves the right to extend and withdraw ‘Health Tracker’ (also known as MyHealthcare Health
                                Tracker) functionality to you at its sole discretion. The extension or withdrawal of such facility shall be
                                intimated to you by MyHealthcare.</p>
                                <p>You have an option under these products to switch on ‘End-User Feedback’. This will mean that you are
                                giving one or more patients’ contact details to MyHealthcare’s feedback system. End-Users may choose to send
                                feedback anonymously too, in which case you agree that you have no objection to such anonymous feedback. The
                                feedback system will then send an SMS and email to the patient(s) asking for feedback which may then be
                                published on the Website. You agree to make your patients fully aware of the possibility of their receiving
                                such feedback queries.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

export default LoginComponent;