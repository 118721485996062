import React, { Component } from 'react'
import { Form, Switch ,notification} from 'antd';
import axios from 'axios';
import Axios from 'axios';
import LS_SERVICE from '../../utils/localStorage';
import {
    CIMSCONFIGURATION, CIMSCONFIGURATIONUPDATE
} from '../../utils/constant';



export default class CimsConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            enterpriseId: 0,
            facilityId: 0,
            alertList: []
        }
    }

    async componentDidMount() {
        //jQueryLabels();
        Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
        await this.getCimsConfiguration();

    }

    getCimsConfiguration = async () => {
        const PARAMS = {
            doctor_id: LS_SERVICE.get('staff_id')
        };
        await axios.post(CIMSCONFIGURATION, PARAMS)
            .then(success => {
                let alllist = success.data.data;
                this.setState({ alertList: alllist });

            }).catch(err => console.log(err))
    }

    onFinishForm  = async (values) => {
        let alertId = []
        for (const [alertIds, value] of Object.entries(values)) {
            if (value) {
                let alertIdString = alertIds.split('_');
                alertId.push(parseInt(alertIdString[1]));
            }
        }
        const PARAMS = {
            doctor_id: LS_SERVICE.get('staff_id'),
            alert_id: alertId//LS_SERVICE.get('FACILITY_ID'),
        };
        await axios.post(CIMSCONFIGURATIONUPDATE, PARAMS)
            .then(success => {
                let message = success.data.message;
                let status = success.data.status;
                if(status == true){
                    notification.success({
                        message: message,
                        placement: 'topRight'
                    });
                }
                this.getCimsConfiguration(); 
            }).catch(err => console.log(err))

    }
    render() {
        let { alertList } = this.state
        return (
            <>
                <div id="main" className="emrfrm ml-5">
                    <Form
                        onFinish={this.onFinishForm}
                        layout="vertical"
                        className="emrfrm "
                    >
                        <div className='row pt-2'>
                            <h1 className="page-title col-5">CIMS Configuration</h1>
                        </div>
                        <div className='row mt-4'>
                            <h6 className='col-12'>Alert Name</h6>
                        </div>

                        {
                            alertList.map((data, index) => (
                                // data.id === 1 || data.id === 2 || data.id === 3 || data.id === 4?
                                <div className='row my-2'>
                                    <div className='col-xl-5 col-6'>
                                        <span>{data.warning_name}</span>
                                    </div>
                                    <div className='col-auto'>
                                        {
                                            
                                                data.warning_flag === 1 ?
                                                    <Form.Item
                                                        name={`checked_${data.id}`}
                                                        valuePropName={data.id}
                                                        initialValue={true}>
                                                        <Switch defaultChecked checkedChildren="ON" unCheckedChildren="OFF" size="small" className='cims-switch' />
                                                    </Form.Item>
                                                    :
                                                    <Form.Item
                                                        name={`checked_${data.id}`}
                                                        valuePropName={data.id}
                                                        initialValue={false}>
                                                        <Switch checkedChildren="ON" unCheckedChildren="OFF" size="small" className='cims-switch' />
                                                    </Form.Item>
                                           

                                        }

                                    </div>

                                </div>
                                // :null
                            ))
                        }
                        <div className='row mt-5'>
                            <div className='col-12 text-center'>

                                <button type="submit" className="btn btn-primary px-4 ml-3 mb-3">
                                    SAVE
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </>
        )
    }
}
