import * as React from 'react';
// import './mpc-incoming-call.css';
import '../incoming-call.css';
import { withRouter } from "react-router-dom";
import {
    VONAGE_INCOMING_CALL_SOCKET
} from "../../../utils/constant";
import {PhoneOutlined} from '@ant-design/icons';
import VC_HELPER from '../../../utils/vc-helpers';
import { Tooltip, notification } from 'antd';
import { MultiPartyCallService, callService } from '../../../utils/rxjs-sharing';
import LS_SERVICE from "../../../utils/localStorage";

class MpcIncomingCall extends React.Component {

    state = {
        show: false,
        secondsRemaining: 0,
        info: {
            caller_doctor_name: null,
            patient_name: null,
            appointment_time: null,
            booking_id: null,
            hospital_id: null,
            session_id: null,
            token: null,
            key: null,
            participant_name: null
        },
        socket: {}
    };
    setTimeRemaining;

    constructor(props) {
        super(props);
        this.startTimer = this.startTimer.bind(this);
        this.startConsultation = this.startConsultation.bind(this);
    }

    componentDidMount() {
        this.bindToMpcIncomingCall();
    }

    bindToMpcIncomingCall() {
        console.log('MPC VC - Sock Url', VONAGE_INCOMING_CALL_SOCKET);
        const $this = this;
        const socket = window.io.connect(VONAGE_INCOMING_CALL_SOCKET, { reconnect: true });
        socket.on("connect", function (data) {
            console.log('MPC VC - Connected for IC');
        });
        socket.on("vonage_call", function (data) {
            console.log('>>> MPC incoming call data', data);

            if (data.type === 'add_doctor_to_call_mpc' && LS_SERVICE.get('staff_id') == data?.receiver?.doctor_id) {
                $this.setIncomingCallInfo(data);
                return;
            }

            if (data.type == "declined") {
                let booking_id = null;
                const mpc_dialog = document.getElementById('vmp_vc_dialog');
                if (!mpc_dialog?.classList?.contains("hidden")) {
                    if (mpc_dialog !== null) {
                        booking_id = mpc_dialog.getAttribute('data-booking_id');
                    }
                    if (booking_id == data.booking_id) {
                        MultiPartyCallService.init({ type: "call_declined_by_patient", end: true, notify: false, message: "Patient (" + data.patient_name + ") disconnected the call" });
                        notification.info({
                            message: "Patient (" + data.patient_name + ") disconnected the call",
                            placement: 'topRight'
                        });
                    }

                } else {
                    if (LS_SERVICE.get('call_patient_detail') && LS_SERVICE.get('call_patient_detail')?.booking_id) {
                        let call_boking_id = LS_SERVICE.get('call_patient_detail')?.booking_id;
                        if (call_boking_id == data.booking_id) {
                            MultiPartyCallService.init({ type: "call_declined_by_patient", end: true, notify: false, message: "Patient (" + data.patient_name + ") disconnected the call" });
                            notification.info({
                                message: "Patient (" + data.patient_name + ") disconnected the call",
                                placement: 'topRight'
                            });
                        }
                    }
                    const doctor_id = LS_SERVICE.get('staff_id');
                    const booking_floating_widget = document.getElementById('floating_vc_dialog');
                    if (booking_floating_widget !== null) {
                        booking_id = booking_floating_widget.getAttribute('data-booking_id');
                    }
                    if (((data.is_homecare && data.booking_id == booking_id) || data.doctor_id == doctor_id) && data.type == "declined" && window.InncVonage.data.call_started) {
                        // $this.stopOutring();
                        VC_HELPER.ic_stopOutring();
                        callService.init({ end: true, notify: false, message: "Patient (" + data.patient_name + ") disconnected the call" });
                    }
                }
            }

            // const doctorInfo = LS_SERVICE.get('doctor');
            // if (doctorInfo === null && !data.is_homecare) {
            //     return;
            // }

            // // const doctor = doctorInfo;
            // const doctor_id = LS_SERVICE.get('staff_id');
            // // const booking_id = LS_SERVICE.has('homecare_booking_id') ? LS_SERVICE.get('homecare_booking_id') : '';
            // // const is_bookingidsame = data.booking_id == booking_id;

            // // if (data.doctor_id == doctor_id && data.type == "join") {
            // //     if ($this.setTimeRemaining) {
            // //         clearInterval($this.setTimeRemaining);
            // //     }

            // //     $this.ring();

            // //     $this.setState({
            // //         info: {
            // //             patient_name: data.patient_name,
            // //             appointment_time: data.appointment_time,
            // //             booking_id: data.booking_id,
            // //             session_id: data.session_id,
            // //             token: data.token
            // //         },
            // //         secondsRemaining: 60,
            // //         show: true
            // //     }, () => {
            // //         $this.startTimer();
            // //     });
            // // }

            // const booking_floating_widget = document.getElementById('floating_vc_dialog');

            // let booking_id = null;

            // if (booking_floating_widget !== null) {
            //     booking_id = booking_floating_widget.getAttribute('data-booking_id');
            // }

            // // if ((data.doctor_id == doctor_id || is_bookingidsame) && data.type == "declined" && window.InncVonage.data.call_started) {
            // // console.log('>> DD', data.doctor_id, doctor_id);
            // if (((data.is_homecare && data.booking_id == booking_id) || data.doctor_id == doctor_id) && data.type == "declined" && window.InncVonage.data.call_started) {
            //     $this.stopOutring();
            //     callService.init({ end: true, notify: false, message: "Patient (" + data.patient_name + ") disconnected the call" });
            // }
            // // if ((data.doctor_id == doctor_id || is_bookingidsame) && data.type == "declined" && !window.InncVonage.data.call_started && $this.state.show) {
            // //     $this.stopOutring();
            // //     $this.close();
            // // }
        });
    }

    setIncomingCallInfo(data) {
        this.setState({
            show: true,
            secondsRemaining: 60,
            info: {
                caller_doctor_name: data?.caller?.doctor_name,
                patient_name: data?.patient_name,
                appointment_time: data?.appointment_time,
                booking_id: data?.booking_id,
                session_id: data?.session_id,
                token: data?.token,
                key: data?.key,
                participant_name: data?.receiver?.doctor_name,
                hospital_id: data?.hospital_id,
                number_of_participants: data?.number_of_participants,
                video_call_driver : data.call_vendor,
                room_code: data.doctor_room_code ? data.doctor_room_code :""
            }
        }, () => {
            VC_HELPER.ic_ring();
            this.startTimer();
        });
    }

    startTimer() {
        var $this = this;
        this.setTimeRemaining = setInterval(function () {
            $this.setState({
                secondsRemaining: $this.state.secondsRemaining - 1
            }, () => {
                if ($this.state.secondsRemaining == 0) {
                    clearInterval($this.setTimeRemaining);
                    VC_HELPER.ic_stopOutring();
                    $this.busy();
                }
            });
        }, 1000);
    }

    busy() {
        console.log('>> MPC busy called');
        this.close();
    }

    close() {
        VC_HELPER.ic_stopring();
        this.setState({
            show: false,
            secondsRemaining: 0
        });
    }

    startConsultation = (e) => {
        console.log('>> MPC startConsultation called');
        const {info} = this.state;
        // if (window.InncVonage.data.call_started) {
        //     callService.init({ end: true });
        //     console.log('>> location.pathname', this.props.location);
        //     if (this.props.location != '/doctor-consult') {
        //         window.InncVonage.disconnect();
        //     }
        // }
        if (this.setTimeRemaining) {
            clearInterval(this.setTimeRemaining);
        }
        document.getElementById('vmp_vc_dialog').removeAttribute("style");
        MultiPartyCallService.init({
            start: true, 
            is_homecarecall: false,
            booking_id: info.booking_id,
            hospital_id: info.hospital_id,
            type: 'incoming_call_received_by_doctor',
            key: info.key,
            session_id: info.session_id,
            token: info.token,
            participant_name: info.participant_name,
            number_of_participants: info.number_of_participants,
            video_call_driver: info.video_call_driver,
            room_code: info.room_code ? info.room_code :""
        });
        
        this.close();
    };

    render() {
        const {secondsRemaining, info, show} = this.state;
        const showClass = show ? '' : 'd-none';
        return (
            <React.Fragment>
                <div className={"incoming-call-widget " + showClass}>
                    <div className="call-header">
                        Incoming Call
                        {/* <a onClick={() => this.busy()} className="float-right close-inccall">&times;</a> */}
                        <span className="call-header-btns">
                            <Tooltip title="Start Consult" placement="bottom" color="#ffffff">
                                <button onClick={() => this.startConsultation()} type="button" className="btn btn-success btn-sm receive-call">
                                    <PhoneOutlined />
                                </button>
                            </Tooltip>
                            <Tooltip title="Reject incoming call" placement="bottom" color="#ffffff">
                                <button onClick={() => this.busy()} type="button" className="btn btn-danger btn-sm reject-call">
                                    <PhoneOutlined />
                                </button>
                            </Tooltip>
                        </span>
                        <span className="closingin float-right">
                            Closing in {secondsRemaining} second(s)
                        </span>
                    </div>
                    <div className="call-body">
                        <div className="media">
                            {/* <span className="phoneic">
                                <PhoneOutlined />
                            </span> */}
                            <div className="media-body media-body-txt">
                                <p className="mt-0 mb-0">{info.caller_doctor_name} wants you to join the call with {info.patient_name} for appointment at {info.appointment_time}</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="call-footer">
                        <Tooltip title="Start Consult" placement="bottom" color="#ffffff">
                            <button onClick={() => this.startConsultation()} type="button" className="btn btn-success btn-sm receive-call">
                                <PhoneOutlined />
                            </button>
                        </Tooltip>
                        <Tooltip title="Reject incoming call" placement="bottom" color="#ffffff">
                            <button onClick={() => this.busy()} type="button" className="btn btn-danger btn-sm reject-call">
                                <PhoneOutlined />
                            </button>
                        </Tooltip>
                    </div> */}
                </div>
            </React.Fragment>
        );
    }

}

export default withRouter(MpcIncomingCall);
