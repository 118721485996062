import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Axios from 'axios';
import qs from 'qs';
import LS_SERVICE from '../../utils/localStorage';
import {
  SUBJECTIVE_INFRMATION,
  VIEW_VISIT,
  INSTRUCTION_SEARCH,
  LAB_TEST_SEARCH,
  LAB_TEST_FAVOURITE,
  REFERENCE_TYPE,
  LOADER_RED,
  HANDLEVALIDANTMESSAGES,
  OBJECTIVE_VITAL_STORE,
  LOADER,
  PATIENT_DATA,
  MEDICINES_GETALL,
  PLAN_TEST_ADVICED_LIST,
  PATIENT_OBS_GYNAE_HX,
  ALLERGIES_GETALL,
  BSA_CALCULATE,
  COPY_VISIT_APPLY,
  VITALS_GETALL,
  GET_VISIT_VITALS,
  ASSESSMENT_STATUS_UPDATE,
  PREVIOUS_VISIT_DETAILS,
  HOSPITALDEFAULTS,
  SEPARATEUPLOADEDDOCSNEW,
  VIEW_TEMPLATE, BMI_CALCULATE,
  DEFAULTVITALGROUPID,
  CHIEFCOMPLAINT_LIST,
  FETCH_SUPPORTING_MATERIAL,
  UPDATE_SUPPORTING_MATERIAL,
  CIMS_INTERACTION_EXISTS,
  CIMS_INTERACTION,
  CIMSALLOW,
  CIMS_ALLOW_HOSPITALS,
  CIMS_CAREPLAN_INTERACTION_EXISTS
} from '../../utils/constant';
import moment from "moment";
import { Form, Modal, Input, Button, Select, DatePicker, Carousel, Badge, Tooltip } from 'antd';
import 'antd/dist/antd.css';
import { notification } from 'antd';
import AsideLeft from '../../components/aside/asideleft';
import selecAllImg from '../../assets/images/ic-checkunselected.png';
import selecAllImg2 from '../../assets/images/ic-checkunselected2.png';
import SearchImg from '../../assets/images/ic-search.svg';
import icJpg from '../../assets/images/ic-jpg.png';
import icPdf from '../../assets/images/ic-pdf.png';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { Link } from 'react-router-dom';
import { headerService$, speechService, speechTextService } from '../../utils/rxjs-sharing';
import MyHcSpeechRecognition from "../../pages/speech-to-text/speech-recognition";
import { jQueryLabels, FixedCta, AntSelectLabels, AntClearLabels } from '../../utils/jQueryLabels';
import './copy.visit.scss'
import AssessmentTable from '../../components/assessments/assessment-table';
import MedicineTable from '../../components/drugs/medicine-table';
import TestTable from '../../components/tests/test-table';
import InstructionTable from '../../components/instructions/instructions-table';
import SupportingMaterialTable from '../../components/supporting-material/supporting-material-table';
import Templatetests from '../create-template/template-test';
import AssessmentForm from '../../components/assessments/assessment-form';
import MedicineForm from '../../components/drugs/medicine-form';
import TestForm from '../../components/tests/test-form';
import InstructionsForm from '../../components/instructions/instructions-form';
import SupportingMaterial from '../../components/supporting-material/supporting-material-form';
import MedicineAlertPopup from './medicine-alert-popup';
import { async } from 'rxjs';
const { Option } = Select;
const { RangePicker } = DatePicker;
let count = 0;
class CopyToVisit extends Component {
  constructor(props) {
    super(props);

    const doctor_id = LS_SERVICE.get("staff_id");
    const { match: { params }, location: { state } } = this.props;
    // QMS_TOKEN_ID IS COPY PRESCRIPTION REFERENCE ID AND REFERENCE ID IS CURRENT APPOINTMENT REFERNCE
    let { qms_token_id, reference_id, template_id } = params;
    reference_id = reference_id.split('?')[0];
    const { sameRxdate, patient } = state;
    let pathName = this.props.location.pathname;
    pathName = pathName.split('/');
    this.state = {
      patient,
      doctor_id,
      sameRxdate,
      qms_token_id,
      reference_id,
      testAdvisedDuplicateOrders: [],
      template_id,
      copyType: pathName.includes('template') ? 'template' : 'visit',
      DataLoaded: false,
      showVitalsModal: false,
      showAssessmentModal: false,
      already_prescribed_drugs: [],
      already_prescribed_tests: [],
      showMedicineModal: false,
      showTestModal: false,
      showInstructionModal: false,
      showSupportingModal: false,
      showDrugAllergyModal: false,
      showDrugPolyPharmacyModal: false,
      drugsnotcustom: 0,
      editData: null,
      editIndex: -1,
      subjectiveData: {},
      allViewVisitData: { visit_vitals_data: [] },
      constants: [],
      vitalsConstants: [],
      smallest_protocol: '',
      smallest_due_date: '',
      vitalsType: '0',
      patientHeight: null,
      patientWeight: null,
      vitalGroups: [],
      selectedVitalGroups: [],
      displayVitals: [],
      patientDrugAllergies: [],
      visitSubjectiveData: [], //visit from where data is copied
      assessmentData: [],
      addedAssessmentList: [],
      drugsData: [],
      addedDrugsList: [],
      testData: [],
      template: null,
      addedTestList: [],
      instructionsData: [],
      supportingMaterial: [],
      allSupportingMaterial: [],
      visitVitals: [],
      currentVisitVitals: [],
      vitalNotInRange: [],
      instructionsCheckSelected: false,
      supportingCheckSelected: false,
      actionText: '',
      deleteConfirmModal: false,
      deleteMessage: '',
      deleteActionType: null,
      deleteObjId: null,
      // instructionOption: [],
      // isNewInstruction: false,
      // instructionselected: [],
      // searchval:'',
      spin_loading: false,
      isSelectAllChecked: false,
      CheckSelect: [],
      isOverRule: false,
      disableConfirmBtn: true,
      viewDocumentModal: false,
      patient_docments: [],
      patient_note_doctor: '',
      polyPharmacyWarningShown: false,
      allergiesMessage: '',
      polyPharmacyMessage: '',
      speech: {
        identifier: null,
        state: false
      },
      range: [],
      displayShowingVitals: [],
      vitalsInput: [],
      chiefComplaintList: [],
      ccEditText: '',
      ccEditOriginalText: '',
      cimsallow: CIMSALLOW,
      drug_id_Interaction_Modalurl:[],
      referenceType:0,
      patientId: LS_SERVICE.get("call_patient_id") != undefined ? LS_SERVICE.get("call_patient_id") : 0,
      referenceId: LS_SERVICE.get("call_patient_qms_token_id") != undefined ? LS_SERVICE.get("call_patient_qms_token_id") : 0,
      StoreId: LS_SERVICE.get("staff_id") != undefined ? LS_SERVICE.get("staff_id") : 0,
      FacilityId: LS_SERVICE.get("FACILITY_ID") != undefined ? LS_SERVICE.get("FACILITY_ID") : 0,
      enterpriseId: LS_SERVICE.get("ENTERPRISE_ID") != undefined ? LS_SERVICE.get("ENTERPRISE_ID") : 0,
      hospitalId: parseInt(LS_SERVICE.get('slot_hospital_id'))
    };
    this.chief_complaint = React.createRef();
    this.hpi = React.createRef();
    this.review_of_systems = React.createRef();
    this.carouselRef = React.createRef();
    this.subjectiveDataInitial = [];
    this.vitalFormRef = React.createRef();
    this.handleVitalModalButtonDisabled = this.handleVitalModalButtonDisabled.bind(this);
    this.getMaterialData = this.getMaterialData.bind(this);
    this.updateSelect = this.updateSelect.bind(this);

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { match: { params } } = nextProps;
    const { hospital_id, reference_id } = params;
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    return reference_id;
  }

  goBack = (event) => {
    event.preventDefault();
    this.props.history.goBack();
  }

  autoHeight = (e) => {
    e.target.style.height = e.target.scrollHeight + "px";
  }

  componentDidMount() {

    const { match: { params } } = this.props;
    const { hospital_id, patient_id, reference_id } = params;

    const patient = {
      hospital_id,
      patient_id,
      qms_token_id: reference_id
    }

    this.setState({
      hospital_id,
      patient_id,
      patient
    }, () => {
      if (LS_SERVICE.has('hospitalConfigData')) {
        const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
        let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
        headerService$.soapConfig({ soapConfig: hospitalConfigObj.opd_layout }); // IMPORTANT TO HIT AS PER THE VIEW
        this.getPatientData();
      }
    });

    this.subscription = speechTextService.status().subscribe(data => {
      const { patient } = this.state;
      if (this.state.speech.identifier === 'drop_list') {
        let { chiefComplaint } = this.state;
        chiefComplaint.push(data.text.replace(".", ""));
        this.selectChiefComplaint(chiefComplaint, chiefComplaint);

        // let info = this.state.subjectiveData;
        // info.details[0].description = data.text;
        // this.setState({ subjectiveData: info });
        // LS_SERVICE.set("chief_complaints_" + patient.patient_id + "_" + patient.qms_token_id, data.text);
      }
      if (this.state.speech.identifier === 'hpi') {
        let info = this.state.subjectiveData;
        info.details[1].description = data.text;
        this.setState({ subjectiveData: info });
        // LS_SERVICE.set("hpi_" + patient.patient_id + "_" + patient.qms_token_id, data.text);
      }
      if (this.state.speech.identifier === 'review_of_systems') {
        let info = this.state.subjectiveData;
        info.details[2].description = data.text;
        this.setState({ subjectiveData: info });
        // LS_SERVICE.set("review_of_systems_" + patient.patient_id + "_" + patient.qms_token_id, data.text);
      }
      this.setState({
        datetime: new Date()
      });
      // document.querySelector("#" + this.state.speech.identifier).style.height = document.querySelector("#" + this.state.speech.identifier).scrollHeight + "px";
    });

    this.getVisitTestList();
    this.getChiefcomplaintList();
    // this.setState({supportingMaterial: this.state.supportingMaterial});
  }

  getVisitTestList = async _ => {
    const { patient_id, qms_token_id } = this.state.patient;
    // this.setState({testDataLoaded:false})

    const planTestAdvPromise = await Axios.get(PLAN_TEST_ADVICED_LIST({ reference_type: REFERENCE_TYPE, reference_id: qms_token_id, patient_id }))
    if (planTestAdvPromise.data.status === 'success') {
      const { constants, details, duplicateOrderTests } = planTestAdvPromise.data.data;
      this.setState({
        testAdvisedDuplicateOrders: duplicateOrderTests,
      })
    }
  }

  componentDidUpdate() {
    setTimeout(() => {
      jQueryLabels();
      FixedCta();
      let Elem = document.querySelectorAll('.subjective-field');
      Elem.forEach(function (el) {
        el.style.height = el.scrollHeight + "px";
      })
    }, 100);
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  initSpeech(info) {
    let initialText = '';
    if (info.identifier === 'drop_list') {
      initialText = this.subjectiveDataInitial[0] != undefined ? this.subjectiveDataInitial[0]['value'] : '';
    }
    if (info.identifier === 'hpi') {
      initialText = this.subjectiveDataInitial[1] != undefined ? this.subjectiveDataInitial[1]['value'] : '';
    }
    if (info.identifier === 'review_of_systems') {
      initialText = this.subjectiveDataInitial[2] != undefined ? this.subjectiveDataInitial[2]['value'] : '';
    }

    if (info.identifier === this.state.speech.identifier) {
      this.setState({
        speech: {
          identifier: info.identifier,
          state: !this.state.speech.state,
          initialText: initialText
        }
      }, () => {
        speechService.init(this.state.speech);
      });
    } else {
      this.setState({
        speech: {
          identifier: this.state.speech.identifier,
          state: false,
          initialText: ''
        }
      }, () => {
        speechService.init(this.state.speech);
        // setTimeout(() => {
        this.setState({
          speech: {
            identifier: info.identifier,
            state: true,
            initialText: initialText
          }
        }, () => {
          speechService.init(this.state.speech);
        });

        // }, 1000);
      });
    }
  }

  // updateModalStatus = (property) =>{
  //   this.setState({[property]:!this.state[property]})
  // }


  updateAssessmentData = (data) => {
    data.selected = true;
    let assessmentData = this.state.assessmentData;
    let addedAssessmentList = [];
    var index = data.i;
    if (index == -1)
      index = assessmentData.length;
    if (data.image_uploaded == -1)
      data.image = assessmentData[index].image

    else
      data.image = data.image_uploaded;
    delete data.image_uploaded;
    assessmentData[index] = data;

    addedAssessmentList = assessmentData.map((v, k) => { return v.diagnosis_id });

    this.setState({
      assessmentData, addedAssessmentList, editData: null, editIndex: -1
    }, () => {
      this.handleModalPopup(null, 'showAssessmentModal'); this.checkSelectedStatus();
    })
  }

  updateMedicineData = async (data, showTaper) => {
    data.selected = true;
    let drugsData = this.state.drugsData;
    let addedDrugsList = [];
    var index = data.i;
    if (index == -1)
      index = drugsData.length;
    drugsData[index] = data;

    addedDrugsList = drugsData.map((v, k) => {
      return v.drug_id
    });

    let previousEndDate = null;
    for (let i = (index); i >= 0; i--) {
      if (drugsData[i].end_date != null && previousEndDate == null)
        previousEndDate = drugsData[i].end_date;
    }
    var final_list_drug = [];
    for (var thisdrug of drugsData) {
      if (thisdrug.drug_id == data.drug_id) {
        if (data.correction_dose == 'Y' || data.correction_dose_applicable == 'Y') {
          thisdrug.correction_dose = 'Y';
          thisdrug.correction_dose_applicable = 'Y';
          thisdrug.correction_dose_data = data.correction_dose_data;
        }
        final_list_drug.push(thisdrug)
      }
      else {
        final_list_drug.push(thisdrug)
      }
    }

    await this.updateMedicineStartEndDate(final_list_drug, previousEndDate, index + 1, drugsData[index].drug_id);
    var start_date_array = [];
    var protocol_array = [];
    for (var thisdrug of drugsData) {
      if (thisdrug.event && thisdrug.event > 0) {
        if (thisdrug.event == 1) {
          protocol_array.push('LMP + ' + thisdrug?.lmp_event_duration + ' ' + thisdrug?.lmp_event_duration_type)
        }
        else if (thisdrug.event == 2) {
          protocol_array.push('Birth Date + ' + thisdrug?.lmp_event_duration + ' ' + thisdrug?.lmp_event_duration_type)
        }
        else if (thisdrug.event == 3) {
          protocol_array.push('System Date')
        }
        else if (thisdrug.event == 4) {
          protocol_array.push('Custom Date')
        }
        start_date_array.push(new Date(thisdrug.start_date))
      }
    }
    // var smallest_index = start_date_array.map(Number).indexOf(+new Date(Math.min.apply(null, start_date_array)))
    // var smallest_protocol = protocol_array.slice(smallest_index,smallest_index+1);
    var smallest_start_date = new Date(Math.min.apply(null, start_date_array));
    this.setState({
      // smallest_protocol: smallest_protocol,
      smallest_due_date: moment(smallest_start_date)
    })
    this.setState({
      drugsData, addedDrugsList, editData: null, editIndex: -1
    }, () => {
      if (!showTaper) this.handleModalPopup(null, 'showMedicineModal'); this.checkSelectedStatus();
    })
  }

  updateMedicineDate = async (medicine_data, start_data, this_drug_id) => {
    var updateddrug = [];
    var previousDrugID = null;
    var previousDate = null;
    for (var thisdrug of medicine_data) {
      if (thisdrug.drug_id == this_drug_id) {
        let { start_date, end_date } = this.getStartEndDate(thisdrug, (previousDrugID == thisdrug.drug_id && thisdrug.taper_row == true), previousDate, this.state.constants, start_data)
        thisdrug.start_date = start_date;
        thisdrug.end_date = end_date;
        updateddrug.push(thisdrug);
      }
      else {
        updateddrug.push(thisdrug);
      }
      previousDrugID = thisdrug.drug_id;
      previousDate = thisdrug.start_date;
    }
    this.setState({
      drugsData: updateddrug
    });
  }

  updateTestDate = async (test_data, start_data, this_test_id) => {
    var updatedtest = [];
    for (var thistest of test_data) {
      if (thistest.test_id == this_test_id) {
        thistest.start_date = start_data;
        updatedtest.push(thistest);
      }
      else {
        updatedtest.push(thistest);
      }
    }
    this.setState({
      testData: updatedtest
    });
  }

  updateMedicineStartEndDate = (drugsArray, previousDate, index, drugId) => {
    var dailyFrequency = [];
    this.state.constants.dailyFrequency.map((obj, i) => {
      dailyFrequency[obj.id] = obj;
    });

    var defaultDate = moment().format('YYYY-MM-DD');
    let restTaperDrugs = drugsArray;
    var taperStartDate = previousDate != null ? previousDate : moment(defaultDate, 'YYYY-MM-DD').add(-1, 'days').format('YYYY-MM-DD');
    for (const [key, taper] of Object.entries(restTaperDrugs)) {
      if (key < index || drugId != restTaperDrugs[key].drug_id)
        continue;
      else {
        var taper_day_interval;
        var taper_times_correction;
        var taper_endDatedays;
        var taper_dosage_per_day;
        if (taper.daily_frequency) {
          taper_day_interval = dailyFrequency[taper.daily_frequency].interval;
          taper_day_interval = taper_day_interval == "weeks" ? 7 : (taper_day_interval == 'months' ? 30 : (taper_day_interval == 'years' ? 365 : 1));
          taper_times_correction = dailyFrequency[taper.daily_frequency].total_times;
          taper_dosage_per_day = dailyFrequency[taper.daily_frequency].total_repeats;
        }
        else {
          taper_day_interval = 1;
          taper_times_correction = 1;
        }

        var number_of_days = taper.number_of_days;
        if (taper_day_interval != 1)
          number_of_days = Math.round(taper.number_of_days / taper_dosage_per_day);
        taper_endDatedays = (number_of_days * taper_day_interval * taper_times_correction) - 1;
        var newStartDate = moment(taperStartDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');
        var newEndDate = taper.drug_frequency != 3 && taper_times_correction != 0 ? moment(newStartDate, 'YYYY-MM-DD').add(taper_endDatedays, 'days').format('YYYY-MM-DD') : null;

        drugsArray[key].start_date = taper.drug_frequency != 3 && taper_times_correction != 0 ? newStartDate : null;
        drugsArray[key].end_date = newEndDate

        taperStartDate = newEndDate != null ? newEndDate : taperStartDate;
      }

    }
    this.setState({ drugsData: drugsArray })

  }


  updateTestData = (data) => {
    data.selected = true;
    let testData = this.state.testData;
    let addedTestList = [];
    var index = data.i;
    if (index == -1)
      index = testData.length;
    testData[index] = data;

    addedTestList = testData.map((v, k) => { return v.test_id });

    this.setState({
      testData, addedTestList, editData: null, editIndex: -1
    }, () => {
      this.handleModalPopup(null, 'showTestModal'); this.checkSelectedStatus();
    })
  }

  updateInstructionsData = (data) => {
    this.setState({
      instructionsData: data, instructionsCheckSelected: true
    }, () => {
      this.handleModalPopup(null, 'showInstructionModal'); this.checkSelectedStatus();
    })
  }

  getPatientData = _ => {
    const { match: { params } } = this.props;
    const { reference_id } = params;
    Axios.get(PATIENT_DATA({ reference_id }))
      .then(success => {
        const patient = {
          ...success.data.data.results,
          qms_token_id: success.data.data.results.qmsToken.qms_token_id
        }
        this.setState({
          patient
        }, () => {
          this.loadData();
        })
      })
      .catch(err => console.log(err))
  }

  loadData = () => {
    const { qms_token_id, reference_id, template_id, patient, doctor_id } = this.state;
    const { patient_id } = patient;
    const subjectivePromise = Axios.get(SUBJECTIVE_INFRMATION({ reference_type: REFERENCE_TYPE, reference_id }));
    let getPagePromise;
    let getVisitSubjectivePromise = null;
    if (this.state.copyType == 'template')
      getPagePromise = Axios.post(VIEW_TEMPLATE, qs.stringify({ id: template_id }));
    else {
      getPagePromise = Axios.post(VIEW_VISIT, qs.stringify({ referenceType: REFERENCE_TYPE, referenceId: qms_token_id }));
      getVisitSubjectivePromise = Axios.get(SUBJECTIVE_INFRMATION({ reference_type: REFERENCE_TYPE, reference_id: qms_token_id }));
    }
    const medicinesPromise = Axios.get(MEDICINES_GETALL({ patient_id: patient_id }));
    const allergiesPromise = Axios.get(ALLERGIES_GETALL({ patient_id: patient_id }));
    const instructionsPromise = Axios.get(`${INSTRUCTION_SEARCH}?doctor_id=${doctor_id}`);
    let supportingMaterialPromise;
    if (this.state.copyType == 'template') {
      supportingMaterialPromise = Axios.get(`${FETCH_SUPPORTING_MATERIAL}?formType=template-copy&templateId=${template_id}`);
    } else {
      supportingMaterialPromise = Axios.get(`${FETCH_SUPPORTING_MATERIAL}?formType=all&referenceId=${qms_token_id}`);
    }
    const vitalsPromise = Axios.get(GET_VISIT_VITALS({ patient_id: patient_id, reference_type: REFERENCE_TYPE, reference_id: reference_id }));
    const allVitalsPromise = Axios.get(VITALS_GETALL({ patient_id: patient_id }));
    const patient_obs_gynae_info = Axios.post(PATIENT_OBS_GYNAE_HX, { patient_id: patient_id, type: ['mensural', 'obstetric'] })
    const previous_visit_details = Axios.post(PREVIOUS_VISIT_DETAILS, { patient_id: patient_id })
    Axios.all([subjectivePromise, getPagePromise, medicinesPromise, allergiesPromise, instructionsPromise, vitalsPromise, allVitalsPromise, getVisitSubjectivePromise, patient_obs_gynae_info, previous_visit_details, supportingMaterialPromise])
      .then(Axios.spread(async (...responses) => {
        const responseOne = responses[0];
        const responseTwo = responses[1];
        const responseThree = responses[2];
        const responseFour = responses[3];
        const responseFive = responses[4];
        const responseSix = responses[5];
        const responseSeven = responses[6];
        const responseEight = responses[7];
        const responseNine = responses[8];
        const responseTen = responses[9];
        const responseEleven = responses[10];
        if (responseOne.data.hasOwnProperty("data") === true) {

          const { data, appointment } = responseOne.data;
          const { patient_note_doctor, patient_docments } = appointment;
          const docs = SEPARATEUPLOADEDDOCSNEW(patient_docments);

          if (data.details.length === 0) {
            data.details = [
              {
                "subjective_id": 0,
                "description": "",
                "subjective_title": "Chief Complaints"
              },
              {
                "subjective_id": 1,
                "description": "",
                "subjective_title": "HPI"
              },
              {
                "subjective_id": 2,
                "description": "",
                "subjective_title": "On Examination"
              }
            ]
          }

          let chiefComplaintList = [];
          let chiefComplaintListArr = [];
          data.details.forEach(sub => {
            if (sub.subjective_id === 0) {
              if (sub.master_description && sub.master_description.length > 0) {
                chiefComplaintList = sub.master_description.map(val => {
                  return val.master_name !== "" && val.master_name;
                })
                chiefComplaintListArr = sub.master_description.map(val => {
                  return { name: val.master_name, id: val.master_id }
                })
              }
            }
          });

          if (this.state.sameRxdate) {
            chiefComplaintList.unshift(`Follow up from the visit dated  ${this.state.sameRxdate}`);
            chiefComplaintListArr.unshift({ name: `Follow up from the visit dated  ${this.state.sameRxdate}`, id: null });
          }

          data.details[parseInt(0)].description = chiefComplaintListArr;

          this.setState({
            chiefComplaint: chiefComplaintList,
            chiefComplaintArr: chiefComplaintListArr,
            subjectiveData: data,
            patient_note_doctor: patient_note_doctor || '',
            patient_docments: docs,
          })
        }

        var already_prescribed_drugs = []
        if (responseTen?.data?.visit_details?.medicine_list?.length > 0) {
          for (var this_med of responseTen?.data?.visit_details?.medicine_list) {
            already_prescribed_drugs.push(this_med.drug_id)
          }
          this.setState({
            already_prescribed_drugs: already_prescribed_drugs
          })
        }
        var already_prescribed_tests = []
        if (responseTen?.data?.visit_details?.test_list?.length > 0) {
          for (var this_test of responseTen?.data?.visit_details?.test_list) {
            already_prescribed_tests.push(this_test.test_id)
          }
          this.setState({
            already_prescribed_tests: already_prescribed_tests
          })
        }
        if (responseTwo.data.hasOwnProperty("data") === true) {
          let responseAssessments = [];
          let responseDrugs = [];
          let responseTests = [];
          let responseInstructions = [];
          let responseTemplate;
          if (this.state.copyType == 'template') {
            responseAssessments = responseTwo.data.data.diagnosisList;
            responseDrugs = responseTwo.data.data.drugList;
            responseTests = responseTwo.data.data.testList;
            responseInstructions = responseTwo.data.data.templateInstruction;
            responseTemplate = responseTwo.data.data.template;
          }
          else {
            responseAssessments = responseTwo.data.data.diagnosis_data;
            responseDrugs = responseTwo.data.data.drugs_data;
            responseTests = responseTwo.data.data.tests_data;
            responseInstructions = responseTwo.data.data.instructions_data;
            responseTemplate = responseTwo.data.data.prescriptionDrug;
          }
          if (responseTemplate?.isEventBased == 'Y') {
            this.setState({
              obsGynae: responseNine?.data?.result || {}
            })
          }
          let assessmentTempArray = [];
          let addedAssessmentList = [];
          if (responseAssessments || responseAssessments.length > 0) {
            responseAssessments.map((data, i) => {
              var tempAssessmentObj = {
                description: this.state.copyType == 'template' ? data.description : data?.diagnosisInfo?.diagnose_description,
                diagnosis_id: this.state.copyType == 'template' ? data?.diagnosis_id : data?.diagnosisId,
                icd_code: this.state.copyType == 'template' ? data?.icd_code : data?.diagnosisInfo?.icd_code,
                id: data.id,
                image_comment: this.state.copyType == 'template' ? data.image_comment : data.VisitAssessmentImage.length > 0 ? data.VisitAssessmentImage[0].comment : null,
                // image_uploaded: this.state.copyType=='template'?data.image_uploaded:data.VisitAssessmentImage.length > 0 ? data.VisitAssessmentImage[0].imagePath : null,
                image: this.state.copyType == 'template' ? data.image_uploaded : data.VisitAssessmentImage.length > 0 ? data.VisitAssessmentImage[0].imagePath : null,
                stage: this.state.copyType == 'template' ? 0 : data.stage,
                type: this.state.copyType == 'template' ? 0 : data.type,
                status: this.state.copyType == 'template' ? 0 : data.status,
                diagnosis_notes: this.state.copyType == 'template' ? data.diagnosis_notes : data.diagnosisNotes,
                selected: false
              }
              assessmentTempArray.push(tempAssessmentObj);
              addedAssessmentList.push(tempAssessmentObj.diagnosis_id)
            });
          }
          var previousDrugID = null;
          var previousDate = null;
          let addedDrugsList = [];
          var drugsnotcustom = 0;
          var start_date_array = [];
          var protocol_array = [];
          responseDrugs.map((v, k) => {
            if (responseDrugs[k].event && responseDrugs[k].event > 0) {
              if (responseDrugs[k].event && responseDrugs[k].event != 4) {
                drugsnotcustom++;
              }
              var predefined_start_date;
              if (responseDrugs[k].event == 1 || responseDrugs[k].event == 2) {
                var days = 0;
                if (responseDrugs[k]?.lmp_event_duration_type == 'Months') {
                  days = responseDrugs[k]?.lmp_event_duration * 30
                }
                else if (responseDrugs[k]?.lmp_event_duration_type == 'Weeks') {
                  days = responseDrugs[k]?.lmp_event_duration * 7
                }
                else {
                  days = responseDrugs[k]?.lmp_event_duration
                }
                if (responseDrugs[k].event == 1) {
                  if (this.state.obsGynae?.mensural?.lmpDate && this.state.obsGynae?.mensural?.lmpDate != null) {
                    var lmp_date = new Date(this.state.obsGynae?.mensural?.lmpDate)
                    var predefined_start_date_unformatted = new Date(lmp_date.setDate(lmp_date.getDate() + days));
                    predefined_start_date = moment(predefined_start_date_unformatted).format('YYYY-MM-DD')
                  }
                  else {
                    predefined_start_date = moment().format('YYYY-MM-DD')
                  }
                }
                else {
                  if (this.state.patient?.dob && this.state.patient?.dob != null) {
                    var birth_date = new Date(this.state.patient?.dob)
                    var predefined_start_date_unformatted = new Date(birth_date.setDate(birth_date.getDate() + days));
                    predefined_start_date = moment(predefined_start_date_unformatted).format('YYYY-MM-DD')
                  }
                  else {
                    predefined_start_date = moment().format('YYYY-MM-DD')
                  }
                }
              }
              if (responseDrugs[k].event == 1) {
                protocol_array.push('LMP + ' + responseDrugs[k]?.lmp_event_duration + ' ' + responseDrugs[k]?.lmp_event_duration_type)
                start_date_array.push(new Date(predefined_start_date))
              }
              else if (responseDrugs[k].event == 2) {
                protocol_array.push('Birth Date + ' + responseDrugs[k]?.lmp_event_duration + ' ' + responseDrugs[k]?.lmp_event_duration_type)
                start_date_array.push(new Date(predefined_start_date))
              }
              else if (responseDrugs[k].event == 3) {
                protocol_array.push('System Date')
                start_date_array.push(new Date())
              }
              else if (responseDrugs[k].event == 4) {
                protocol_array.push('Custom Date')
                start_date_array.push(new Date())
              }
            }
            responseDrugs[k].start_date_protocol = predefined_start_date
            predefined_start_date = moment(predefined_start_date).diff(moment(), 'days') < 0 ? moment().format('YYYY-MM-DD') : predefined_start_date;
            let { start_date, end_date } = this.getStartEndDate(v, (previousDrugID == v.drug_id && v.taper_row == true), previousDate, responseTwo.data.data.constants, predefined_start_date)
            let dosageObj = responseTwo.data.data.constants.drugDosage.find(item => item.id == responseDrugs[k].dosage);
            responseDrugs[k].start_date = start_date
            responseDrugs[k].end_date = end_date
            responseDrugs[k].dosage_value = responseDrugs[k].dosage_value == null || responseDrugs[k].dosage_value == '' ? dosageObj?.description : responseDrugs[k].dosage_value;
            previousDrugID = v.drug_id;
            previousDate = responseDrugs[k].end_date != null ? responseDrugs[k].end_date : (v.taper_row ? previousDate : null);
            responseDrugs[k].selected = false;
            addedDrugsList.push(v.drug_id);
          });
          console.log(start_date_array)
          console.log(protocol_array)

          var smallest_index = start_date_array.map(Number).indexOf(+new Date(Math.min.apply(null, start_date_array)))
          var smallest_protocol = protocol_array.slice(smallest_index, smallest_index + 1);
          var smallest_start_date = new Date(Math.min.apply(null, start_date_array));
          this.setState({
            smallest_protocol: smallest_protocol,
            smallest_due_date: moment(smallest_start_date)
          })

          let addedTestList = [];
          responseTests.map((v, k) => {
            var predefined_start_date;
            if (responseTests[k].event && responseTests[k].event > 0) {
              if (responseTests[k].event == 1 || responseTests[k].event == 2) {
                var days = 0;
                if (responseTests[k]?.lmp_event_duration_type == 'Months') {
                  days = responseTests[k]?.lmp_event_duration * 30
                }
                else if (responseTests[k]?.lmp_event_duration_type == 'Weeks') {
                  days = responseTests[k]?.lmp_event_duration * 7
                }
                else {
                  days = responseTests[k]?.lmp_event_duration
                }
                if (responseTests[k].event == 1) {
                  if (this.state.obsGynae?.mensural?.lmpDate && this.state.obsGynae?.mensural?.lmpDate != null) {
                    var lmp_date = new Date(this.state.obsGynae?.mensural?.lmpDate)
                    var predefined_start_date_unformatted = new Date(lmp_date.setDate(lmp_date.getDate() + days));
                    predefined_start_date = moment(predefined_start_date_unformatted).format('YYYY-MM-DD')
                  }
                }
                else {
                  if (this.state.patient?.dob && this.state.patient?.dob != null) {
                    var birth_date = new Date(this.state.patient?.dob)
                    var predefined_start_date_unformatted = new Date(birth_date.setDate(birth_date.getDate() + days));
                    predefined_start_date = moment(predefined_start_date_unformatted).format('YYYY-MM-DD')
                  }
                }
              }
            }
            responseTests[k].start_date_protocol = moment(predefined_start_date).format('YYYY-MM-DD');
            predefined_start_date = moment(predefined_start_date).diff(moment(), 'days') < 0 ? moment().format('YYYY-MM-DD') : predefined_start_date;
            responseTests[k].is_diabetic = 0;
            responseTests[k].is_metformin = 0;
            responseTests[k].risk = 0;
            responseTests[k].start_date = moment(predefined_start_date).format('YYYY-MM-DD');
            responseTests[k].selected = false;
            addedTestList.push(v.test_id);
          });

          responseInstructions.map((v, k) => {
            responseInstructions[k]['id'] = this.state.copyType == 'template' ? v.instructionId : v.instruction_id;
            delete responseInstructions[k]['instruction_id'];
            delete responseInstructions[k]['instructionId'];
          });

          this.setState({
            allViewVisitData: responseTwo.data.data,
            constants: responseTwo.data.data.constants,
            assessmentData: assessmentTempArray,
            addedAssessmentList,
            drugsData: responseDrugs,
            drugsnotcustom: drugsnotcustom,
            addedDrugsList,
            testData: responseTests,
            template: responseTemplate || {},
            addedTestList,
            instructionsData: responseInstructions
          })
        }

        if (responseThree.data.hasOwnProperty("data") === true) {
          let currentMedsList = responseThree.data.data.details.current_medicines;
          var tempArr = [];
          if (currentMedsList) {
            tempArr = currentMedsList.map(function (v) { return v.drug_id });
            tempArr = tempArr.filter(function (item, pos) { return tempArr.indexOf(item) == pos; })
          }
          this.setState({
            currentMedicinesData: tempArr
          })
        }

        if (responseFour.data.hasOwnProperty("data") === true) {
          let allergiesTemp = responseFour.data.data.details.allergies;
          let patientDrugAllergies = [];
          allergiesTemp.map((v, k) => {
            if (v.allergyType == "drug")
              patientDrugAllergies.push({
                intensity: v.intensity,
                reaction: v.reaction,
                allergy_code: v.allergy_code
              })
          })
          this.setState({
            patientDrugAllergies
          })
        }

        if (responseFive.data.hasOwnProperty("data") === true) {
          this.setState({
            allInstructionsData: responseFive.data.data.details
          })

        }

        if (responseEleven.data.supportingFiles) {

          if (this.state.copyType == 'template') {
            this.setState({
              allSupportingMaterial: responseEleven.data.supportingFiles.filter(file => file.deletedAt == null),
              supportingMaterial: responseEleven.data.supportingFiles.filter(file => file.selected)
            })

            await Axios.post(UPDATE_SUPPORTING_MATERIAL, this.state.supportingMaterial).then(res => {
              let supporting = Axios.get(`${FETCH_SUPPORTING_MATERIAL}?formType=template-copy&templateId=${template_id}`);
              supporting.then(resp => {
                this.setState({
                  allSupportingMaterial: resp.data.supportingFiles.filter(file => file.deletedAt == null),
                  supportingMaterial: resp.data.supportingFiles.filter(file => file.selected)
                })
              })
            })
          } else {
            this.setState({
              allSupportingMaterial: responseEleven.data.supportingFiles,
              supportingMaterial: responseEleven.data.supportingFiles.filter(file => file.selected)
            })
            await Axios.post(UPDATE_SUPPORTING_MATERIAL, this.state.supportingMaterial).then(res => {
              let supporting = Axios.get(`${FETCH_SUPPORTING_MATERIAL}?formType=all&referenceId=${qms_token_id}`);
              supporting.then(resp => {
                this.setState({
                  allSupportingMaterial: resp.data.supportingFiles,
                  supportingMaterial: resp.data.supportingFiles.filter(file => file.selected)
                })
              })
            })
          }

        }

        if (responseSix.data.data.hasOwnProperty("details") === true) {
          this.setState({
            currentVisitVitals: responseSix.data.data.details.visitVitals,
            vitalsConstants: responseSix.data.data.constants,
            // this.state.constants.vitals_details
          })
        }

        // console.log(responseSix.data.data.constants.vital_groups)

        let seletctedSpecialityIdID = DEFAULTVITALGROUPID;
        //Finding emr_vital_group_id of emr_vital_group table using speciality id of doctor
        let selectedVitalGroup = Object.values(responseSix.data.data.constants.vital_groups).find(item => {
          let item_arr = [];
          let specialityIds = "" + item.speciality_id
          item_arr = item.speciality_id != null ? specialityIds.split(',') : item_arr;
          // if (item_arr.indexOf(LS_SERVICE.get('speciality_id').toString()) != -1)
          if (LS_SERVICE.get('speciality_id') && item_arr.indexOf(LS_SERVICE.get('speciality_id')) && item_arr.indexOf(LS_SERVICE.get('speciality_id').toString()) != -1)
            return true;
        });

        //let seletctedSpecialityId = Object.values(this.state.constants.vital_groups).find(item=>item.speciality_id==LS_SERVICE.get('speciality_id'));
        if (selectedVitalGroup != undefined) {
          seletctedSpecialityIdID = selectedVitalGroup.id
        }
        let seletctedShowingVitalGroup = Object.values(responseSix.data.data.constants.vital_groups).find(item => item.id == seletctedSpecialityIdID);

        const remainingVitals = responseSix.data.data.constants.vitals_details.filter((elem) => !seletctedShowingVitalGroup.vital_sign.find(({ vital_code }) => elem.vital_code === vital_code));


        this.setState({
          'showingVitals': seletctedShowingVitalGroup.vital_sign,
          'remainingVitals': remainingVitals
        })
        if (responseSeven != null && responseSeven.data.hasOwnProperty("data") === true) {
          this.setState({
            visitVitals: Object.values(responseSeven.data.data.details.visitVitals),
            vitalsConstants: responseSeven.data.data.constants,
            // this.state.constants.vitals_details
          }, () => { this.makeVitalsData() })
        }

        if (responseEight != null && responseEight.data.hasOwnProperty("data") === true) {
          if (responseEight.data.data.details.length > 0) {
            let HPIValue = '';
            responseEight.data.data.details.map((v, k) => {
              if (v.subjective_id == 1) HPIValue = v.description;
            });
            let currentSubjectiveData = this.state.subjectiveData;
            currentSubjectiveData.details.map((v, k) => {
              // if (v.subjective_id == 0 && this.state.sameRxdate) currentSubjectiveData.details[k].description = 'Follow up from the visit dated ' + this.state.sameRxdate + '\r\n';
              if (v.subjective_id == 1 && this.state.sameRxdate) currentSubjectiveData.details[k].description = HPIValue;
            });
            this.setState({
              subjectiveData: currentSubjectiveData,
            })
          }
        }

        this.setState({
          DataLoaded: true,
        })


        this.checkSelectedStatus();
      }))

  }

  getStartEndDate(data, isTaper, previousDate, constants, predefinedStartDate) {
    let start_date = null;
    let end_date = null;
    let day_interval = null;
    let times_correction = null;
    let dosage_per_day = null;
    let number_of_days = data.number_of_days;

    if (data.daily_frequency) {
      let frequencyObjKey = Object.keys(constants.dailyFrequency).find(key => constants.dailyFrequency[key].id === data.daily_frequency)
      frequencyObjKey = constants.dailyFrequency[parseInt(frequencyObjKey)]
      // day_interval = frequencyObjKey.interval;
      day_interval = data.number_of_days_type && data.number_of_days_type != null ? data.number_of_days_type.toLowerCase() : '';
      day_interval = day_interval == "weeks" ? 7 : (day_interval == 'months' ? 30 : (day_interval == 'years' ? 365 : 1));
      times_correction = frequencyObjKey.total_times;
      dosage_per_day = frequencyObjKey.total_repeats;
    }
    if (isTaper && previousDate)
      start_date = moment(previousDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');
    else {
      if (predefinedStartDate && predefinedStartDate != null && predefinedStartDate != undefined) {
        start_date = predefinedStartDate
      }
      else {
        start_date = moment().format('YYYY-MM-DD');
      }
    }
    if (day_interval != 1)
      number_of_days = Math.round(number_of_days / dosage_per_day);
    var endDatedays = (number_of_days * day_interval * times_correction) - 1;
    end_date = moment(start_date, 'YYYY-MM-DD').add(endDatedays, 'days').format('YYYY-MM-DD');

    if (data.drug_frequency == 3 || times_correction == 0) {
      start_date = null;
      end_date = null;
    }
    return { start_date: start_date, end_date: end_date }

  }

  makeVitalsData() {
    let vitalDetails = [];
    this.setState({
      patientHeight: null,
      patientWeight: null
    });
    let { patient, patientHeight, patientWeight } = this.state;
    let allVitalIds = [];
    this.state.vitalsConstants.vitals_details.map((v, k) => {
      allVitalIds.push(v.id);
      vitalDetails[v.id] = [];
      this.setState({ [v.id]: null });
    });
    vitalDetails['date'] = [];
    vitalDetails['source'] = [];
    vitalDetails['update_date'] = [];
    vitalDetails['source_name'] = [];
    allVitalIds.push('date');
    allVitalIds.push('source');
    allVitalIds.push('update_date');
    allVitalIds.push('source_name');

    if (Object.keys(this.state.visitVitals).length > 0) {
      let index = 0;
      this.state.visitVitals.map((temp_vitals, k) => {
        // Object.keys(temp_vitals).forEach(j => {
        allVitalIds.forEach(j => {
          if (vitalDetails[j]) vitalDetails[j][index] = temp_vitals[j] ?? '';
          if (j == 'V001' && patientHeight == null && patient.age > HOSPITALDEFAULTS.age && moment().diff(moment(temp_vitals.date[j]), 'months') < 2 && temp_vitals[j]) patientHeight = temp_vitals[j];
          if (j == 'V002' && patientWeight == null && patient.age > HOSPITALDEFAULTS.age && moment().diff(moment(temp_vitals.date[j]), 'months') < 2 && temp_vitals[j]) patientWeight = temp_vitals[j];
        });
        index++;
      });

    }
    this.setState({
      patientHeight: patientHeight,
      patientWeight: patientWeight,
      V001: patientHeight,
      V002: patientWeight,
    });
    let vitals_data = {};
    let newVitalEmptyArray = vitalDetails['date'] ? Array.from({ length: vitalDetails['date'].length }, i => '') : [];
    this.state.vitalsConstants.vitals_details.map((v, k) => {
      vitals_data[v.id] = vitalDetails[v.id] && vitalDetails[v.id].length > 0 ? vitalDetails[v.id] : newVitalEmptyArray;
    });
    vitals_data.date = vitalDetails['date'];
    vitals_data.source = vitalDetails['source'];
    vitals_data.update_date = vitalDetails['update_date'];
    vitals_data.source_name = vitalDetails['source_name'];
    this.state.visitVitalsFormatted = vitals_data;

    //vitals groups
    let vitalGroupData = [];
    const doctorSpeciality = LS_SERVICE.get('speciality_id');
    let allVital = null;
    let allVitals = [];
    //Code according to speciality, comment this code and uncomment code from static 'line 121'  if want to display adult group start
    let seletctedSpecialityIdID = DEFAULTVITALGROUPID;
    /* For dispalying fields on basis of dropdown select*/

    let selectedVitalGroup = Object.values(this.state.vitalsConstants.vital_groups).find(item => {
      let item_arr = [];
      item_arr = item.speciality_id != null ? item.speciality_id.split(',') : item_arr
      if (item_arr.indexOf(doctorSpeciality.toString()) != -1)
        return true;
    });

    if (selectedVitalGroup == undefined) {
      let defaultVitalGroup = Object.values(this.state.vitalsConstants.vital_groups).find(item => item.id == seletctedSpecialityIdID);
      selectedVitalGroup = defaultVitalGroup;
    }
    var vitalSignIds = [];
    for (const [key, vitalSign] of Object.entries(selectedVitalGroup.vital_sign)) {
      vitalSignIds.push(vitalSign.vital_id);
    }
    selectedVitalGroup.vitalSignIds = vitalSignIds.toString().replace(/(^,)|(,$)/g, "");
    vitalGroupData.push(selectedVitalGroup);

    allVital = vitalGroupData;
    allVitals.push(vitalGroupData);
    //Code according to speciality, comment this code and uncomment code from static 'line 121' if want to display adult group end

    // for (const [key, vitalGroup] of Object.entries(this.state.vitalsConstants.vital_groups)) {
    //   if (vitalGroup.speciality_id == doctorSpeciality) {
    //     var vitalSignIds = [];
    //     for (const [key, vitalSign] of Object.entries(vitalGroup.vital_sign)) {
    //       vitalSignIds.push(vitalSign.vital_id);
    //     }
    //     vitalGroup.vitalSignIds = vitalSignIds.toString().replace(/(^,)|(,$)/g, "");
    //     vitalGroupData.push(vitalGroup);
    //   }
    //   if (vitalGroup.id == 1 || vitalGroup.id == 2) {
    //     var vitalSignIds2 = [];
    //     for (const [key, vitalSign] of Object.entries(vitalGroup.vital_sign)) {
    //       vitalSignIds2.push(vitalSign.vital_id);
    //     }
    //     vitalGroup.vitalSignIds = vitalSignIds2.toString().replace(/(^,)|(,$)/g, "");
    //     allVital = vitalGroup;
    //     allVitals.push(vitalGroup);
    //   }
    // }
    // if (vitalGroupData.length == 0) {
    //   // vitalGroupData.push(allVital);
    //   vitalGroupData = allVitals;
    // }

    this.setState({
      selectedVitalGroups: vitalGroupData,
      vitalsType: vitalGroupData[0].id,
      displayVitals: vitalGroupData[0].vitalSignIds.split(','),
      vitalsInput: vitalGroupData[0].vital_sign,
      visitVitalsFormatted: vitals_data,
      vitals_data_formatted: true
    })
    // this.makeVitalsHTML();
    // return vitals_data;
  }

  checkSelectedStatus = () => {
    // if ((this.subjectiveData[0].value === '') || (this.subjectiveData[1].value === '')) {
    //   notification.success({
    //     message: 'Please complete subjective',
    //     placement: 'topRight'
    //   })
    // }

    const { assessmentData, drugsData, testData, instructionsCheckSelected, supportingCheckSelected } = this.state;

    let assessmentSelectedCount = 0;
    assessmentData.map((v, k) => {
      if (v.selected) assessmentSelectedCount++;
    })
    let drugsSelectedCount = 0;
    drugsData.map((v, k) => {
      if (v.selected) drugsSelectedCount++;
    })
    let testSelectedCount = 0;
    testData.map((v, k) => {
      if (v.selected) testSelectedCount++;
    })

    if (assessmentData.length == assessmentSelectedCount && drugsData.length == drugsSelectedCount && testData.length == testSelectedCount && instructionsCheckSelected && supportingCheckSelected)
      this.setState({ disableConfirmBtn: false, isSelectAllChecked: true })
    else
      this.setState({ disableConfirmBtn: true, isSelectAllChecked: false })

  }

  handleSelectCheck = (index, array) => {
    let dataArray = this.state[array];
    dataArray[index].selected = !dataArray[index].selected;
    this.setState({ [array]: dataArray }, () => {
      if (array == 'drugsData' && dataArray[index].selected)
        this.handleDrugAllergy(index);
      this.checkSelectedStatus();
    })
  }
  handleInstructionsSelectCheck = () => {
    this.setState({ instructionsCheckSelected: !this.state.instructionsCheckSelected }, () => this.checkSelectedStatus())
  }

  handleSupportingSelectCheck = () => {
    this.setState({ supportingCheckSelected: !this.state.supportingCheckSelected }, () => this.checkSelectedStatus())
  }


  polyPharmacyWarningShown = () => {
    this.setState({
      polyPharmacyWarningShown: true,
    })
  }

  clearEdit = () => {
    this.setState({
      editData: null,
      editIndex: -1
    })
  }

  // For Modal popup
  handleModalPopup = (e, popupName, data = null, actionType = '', objId = null, patientDocs) => {
    if (e !== null)
      e.preventDefault();
    this.setState({
      [popupName]: !this.state[popupName]
    },
      () => {
        const { viewDocumentModal } = this.state;

        if (viewDocumentModal) {
          this.carouselRef.current.goTo(patientDocs);
        }

        this.setState({
          actionText: "Add", editData: null, editIndex: -1
        }, () => {
          const { showVitalsModal, showAssessmentModal, showMedicineModal, showInstructionModal, showTestModal, deleteConfirmModal, showDrugAllergyModal, showDrugPolyPharmacyModal, showSupportingModal } = this.state;
          if (!showVitalsModal) {
            this.state.vitalsConstants.vitals_details.map((v, k) => {
              this.setState({ [v.id]: null });

            });

            if (this.vitalFormRef.current !== null) {
              this.vitalFormRef.current.resetFields();
            }
          } else {

            let seletctedSpecialityIdID = DEFAULTVITALGROUPID;
            /* For dispalying fields on basis of dropdown select*/
            let defaultVitalGroup = Object.values(this.state.vitalsConstants.vital_groups).find(item => item.id == seletctedSpecialityIdID);
            let selectedVitalGroup = Object.values(this.state.vitalsConstants.vital_groups).find(item => {
              let item_arr = [];
              item_arr = item.speciality_id != null ? item.speciality_id.split(',') : item_arr
              // if (item_arr.indexOf(LS_SERVICE.get('speciality_id').toString()) != -1)
              if (LS_SERVICE.get('speciality_id') && item_arr.indexOf(LS_SERVICE.get('speciality_id')) && item_arr.indexOf(LS_SERVICE.get('speciality_id').toString()) != -1)
                return true;
            });

            if (selectedVitalGroup == undefined) {
              selectedVitalGroup = defaultVitalGroup;
            }

            if (typeof selectedVitalGroup != 'undefined' && selectedVitalGroup != '') {
              seletctedSpecialityIdID = selectedVitalGroup.id
              this.setState({
                seletctedSpecialityIdID: seletctedSpecialityIdID
              })
            }
            // let seletctedShowingVitalGroup1 = Object.values(this.state.constants.vital_groups).find(item => item.id == seletctedSpecialityIdID);
            //  console.log(seletctedShowingVitalGroup1.id);
            let showingVitals = selectedVitalGroup.vital_sign;

            let displayShowingVitals1 = [];
            let displayVitals = [];
            showingVitals.map((v, k) => {
              displayShowingVitals1.push(v.id);
              displayVitals.push(v.vital_id);
            });

            displayVitals = displayVitals.toString();

            this.setState({
              displayShowingVitals: displayShowingVitals1,
              displayVitals: displayVitals,
              vitalsType: selectedVitalGroup.id,
              vitalsInput: showingVitals,
            })

            // orderVitalsService.init(displayShowingVitals1);
            let { patientHeight, patientWeight, patient } = this.state;

            if (patient.age > HOSPITALDEFAULTS.age) {
              if (this.vitalFormRef.current !== null) {

                this.vitalFormRef.current.setFieldsValue({
                  V001: patientHeight,
                  V002: patientWeight
                });
                this.handleBmiCalculate();
              }
            }
            AntSelectLabels();
          }

          if (showAssessmentModal) {

            if (actionType === 'editAssessment') {
              this.setState({
                actionText: "Edit",
                editData: this.state.assessmentData[objId],
                editIndex: objId
              })
              AntSelectLabels();
            }

          }
          if (showMedicineModal) {
            if (actionType === 'editMedicine') {
              this.setState({ actionText: "Edit" })
            }

            if (data !== null) {
              let editData = this.state.drugsData[objId];
              editData.previousEndDate = null;
              for (let index = (objId - 1); index >= 0; index--) {
                if (this.state.drugsData[index].end_date != null && editData.previousEndDate == null)
                  editData.previousEndDate = this.state.drugsData[index].end_date;
                if (editData.start_date) {
                  editData.end_date = moment(editData.start_date, 'YYYY-MM-DD').add(editData.number_of_days - 1, 'days').format('YYYY-MM-DD');
                }

              }
              this.setState({
                actionText: "Edit",
                editData: editData,
                editIndex: objId
              })
            }
          }

          if (showInstructionModal) {
            // const { allInstructionsData } = this.state;
            // this.setState({
            //   isNewInstruction: false,
            //   instructionOption: allInstructionsData
            // });

          }


          if (deleteConfirmModal) {
            // this.setState({ actionType, objId });
            // For Assessment
            if (actionType === 'deleteAssessment') {
              this.setState({ deleteActionType: actionType, deleteObjId: objId, deleteMessage: 'Are you sure you want to delete assessment?' });
            }

            // For Medicines
            if (actionType === 'deleteMedicine') {
              this.setState({ deleteActionType: actionType, deleteObjId: objId, deleteMessage: 'Are you sure you want to delete medicine?' });
            }

            // For Tests
            if (actionType === 'deleteTest') {
              this.setState({ deleteActionType: actionType, deleteObjId: objId, deleteMessage: 'Are you sure you want to delete test?' });
            }

          }

          if (showDrugAllergyModal) {
            this.setState({ allergiesMessage: data.message, deleteObjId: data.type == 'all' ? data.deleteObj : objId })
          }

          if (showDrugPolyPharmacyModal) {
            this.setState({ polyPharmacyMessage: data.message, deleteObjId: data.type == 'all' ? null : objId })
          }


        })
      })

  }
  /*
    resetMedicineModal = () => {
      this.setState({updateMedicineModal:false,medicineEditData:null})
    }
    resetUpdateMedicineModal = (val) => {
      this.setState({updateMedicineModal:val})
    }*/

  handleVitalModalClear = _ => {
    this.state.vitalsConstants.vitals_details.map(function (v, k) {
      this.setState({ [v.id]: null });
    }, this);
    this.vitalFormRef.current.resetFields();
    // let { patientHeight, patientWeight, patient } = this.state;
    // if (patient.age > HOSPITALDEFAULTS.age) {
    //   if (this.vitalFormRef.current !== null) {
    //     this.vitalFormRef.current.setFieldsValue({
    //       V001: patientHeight,
    //       V002: patientWeight
    //     });
    //     this.setState({
    //       V001: patientHeight,
    //       V002: patientWeight
    //     }, () => this.handleBmiCalculate())
    //   }
    // }
    AntClearLabels();
  }

  handleVitalModalButtonDisabled = _ => {
    if (this.state.constants && this.state.vitalsConstants.vitals_details) {
      var vitalFieldsCount = this.state.vitalsConstants.vitals_details.length;
      var emptyCount = 0;
      //pick vital group from constants.vital_group
      let seletctedVitalGroup = Object.values(this.state.vitalsConstants.vital_groups).find(item => item.id == this.state.vitalsType);

      if (typeof seletctedVitalGroup != 'undefined') {
        let applicableVitals = seletctedVitalGroup.vital_sign;
        applicableVitals.map((v, k) => {
          if (parseFloat(this.state[v.id]) == 0) emptyCount++;
        });
      }

      if (emptyCount > 0)
        return true;

      if (this.state.V005 != null && this.state.V005 != '' && (this.state.V006 == '' || this.state.V006 == null))
        return true;

      if (this.state.V006 != null && this.state.V006 != '' && (this.state.V005 == '' || this.state.V005 == null))
        return true;

      if (this.state.vitalNotInRange.length > 0)
        return true;

      return false;
    }
    else
      return false;
  }

  handleVitalSelect = (val, props) => {
    this.setState({ V001: '', V002: '' });
    let seletctedShowingVitalGroup = Object.values(this.state.vitalsConstants.vital_groups).find(item => item.id == val);

    let showingVitals = seletctedShowingVitalGroup.vital_sign;
    let displayShowingVitals = [];
    let displayShowingVitalsValidations = [];
    showingVitals.map((v, k) => {

      if (v.id == 'V001') {
        this.setState({ V001: this.state.patientHeight })
      }
      if (v.id == 'V002') {
        this.setState({ V002: this.state.patientWeight })
      }

      if (this.state.vitalNotInRange.includes(v.id)) {
        displayShowingVitalsValidations.push(v.id);
      }
      displayShowingVitals.push(v.id);

    });

    this.setState({ vitalNotInRange: displayShowingVitalsValidations })

    let displayVitals = props.datavitals.split(',');



    this.setState({
      vitalsType: val,
      displayVitals: displayVitals,
      displayShowingVitals: displayShowingVitals
    })

    AntSelectLabels();
  }

  handleVitalAddSubmit = async _ => {

    if (this.handleVitalModalButtonDisabled()) return;

    let vitalsData = this.vitalFormRef.current.getFieldsValue(true);

    let postdata = {};
    for (let element of this.state.displayShowingVitals) {
      if (vitalsData[element] != undefined) {
        postdata[element] = vitalsData[element];
      }
    }
    postdata.V006 = this.state.V006 || '';
    postdata.V003 = this.state.V003 || '';
    postdata.V017 = this.state.V017 || '';
    if (postdata.V014 == '' && typeof postdata.V014 != 'undefined' && postdata.V015 == '' && typeof postdata.V015 != 'undefined') {
      postdata.V003 = ''
    }

    vitalsData = postdata;

    const { patient, spin_loading } = this.state;
    const { patient_id, qms_token_id } = patient;

    var finalVitalsData = {}
    for (var [key, value] of Object.entries(vitalsData)) {
      if (value == null) {
        finalVitalsData[key] = '';
      }
      else {
        finalVitalsData[key] = value;
      }
    }

    const PARAMS = {
      vitals_data: finalVitalsData,
      reference_type: REFERENCE_TYPE,
      reference_id: qms_token_id,
      patient_id
    }

    this.setState({
      spin_loading: !spin_loading
    })

    const vitalAddPromise = await Axios.post(OBJECTIVE_VITAL_STORE, qs.stringify(PARAMS))

    if (vitalAddPromise.status) {
      const { reference_id, patient } = this.state;
      const vitalsPromise = await Axios.get(GET_VISIT_VITALS({ patient_id: patient.patient_id, reference_type: REFERENCE_TYPE, reference_id: reference_id }));
      if (vitalsPromise.status) {
        if (vitalsPromise.data.data.hasOwnProperty("details") === true) {
          this.setState({
            currentVisitVitals: vitalsPromise.data.data.details.visitVitals,
          })
        }
        this.handleModalPopup(null, 'showVitalsModal');
        this.setState({
          spin_loading: false
        })
      }


    }
  }


  handleOnChangeNumbers = (event, min, max, decimal_value) => {
    let { name, value } = event.currentTarget;
    let vitalNotInRange = this.state.vitalNotInRange;
    value = value.replace(decimal_value == 0 ? /\D/ : /[^0-9.]/g, "");
    if (decimal_value != 0 && value.includes('.')) {
      let fraction = value.split('.');
      let valueInt = parseInt(fraction[0]);
      fraction = "" + fraction[1];
      if (fraction.length != 0 && fraction.length > decimal_value) {
        fraction = fraction.substring(0, decimal_value);
        fraction = fraction && fraction != '' ? fraction : 0;
        value = valueInt + '.' + fraction;
      }
    }
    var index = vitalNotInRange.indexOf(name);
    if (parseFloat(value) < min || parseFloat(value) > max) {
      if (index == -1)
        vitalNotInRange.push(name);
    }
    else {
      if (index != -1)
        vitalNotInRange.splice(index, 1);
    }
    this.setState({
      [name]: value,
      vitalNotInRange
    }, () => {
      this.vitalFormRef.current.setFieldsValue({
        [name]: value
      })
      this.handleBmiCalculate();
    })
  }

  handleBmiCalculate = _ => {
    const { V001, V002 } = this.state;

    if (V001 > 0 && V002 > 0) {
      let val = BMI_CALCULATE(V001, V002);
      let bsa = BSA_CALCULATE(V001, V002);
      this.setState({
        V003: val,
        V017: bsa
      }, () => { AntSelectLabels() })

      this.vitalFormRef.current.setFieldsValue({
        V003: val,
        V017: bsa
      })

      AntSelectLabels();
    } else {
      this.setState({
        V003: ''
      }, () => { AntSelectLabels() })

      this.vitalFormRef.current.setFieldsValue({
        V003: ''
      })

      AntSelectLabels();
    }
  }
  // ############################## Tests Data #################################

  /*testOnFocus = (e) => {
    this.setState({
      testOnFocus: true
    })

  }*/

  handleOnItemDelete = (e, actionType, objId) => {
    e.preventDefault();

    var stateVariable = null;
    var stateAddedVariable = null;
    var propKey = null;
    // For Assessment
    if (actionType === 'deleteAssessment') {
      stateVariable = 'assessmentData';
      stateAddedVariable = 'addedAssessmentList';
      propKey = 'diagnosis_id';
    }
    // For Medicine
    if (actionType === 'deleteMedicine') {
      stateVariable = 'drugsData';
      stateAddedVariable = 'addedDrugsList';
      propKey = 'drug_id';
    }
    // For Test
    if (actionType === 'deleteTest') {
      stateVariable = 'testData';
      stateAddedVariable = 'addedTestList';
      propKey = 'test_id';
    }

    let dataArray = this.state[stateVariable];
    let drugId;
    let previousEndDate;
    if (actionType === 'deleteMedicine') {
      drugId = dataArray[objId].drug_id;
      previousEndDate = null;
      for (let index = (objId - 1); index >= 0; index--) {
        if (dataArray[index].end_date != null && previousEndDate == null)
          previousEndDate = dataArray[index].end_date;
      }
    }

    dataArray.splice(objId, 1);
    let addedArray = [];
    var start_date_array = [];
    var protocol_array = [];
    dataArray.map((v, k) => {
      if (actionType === 'deleteMedicine') {
        if (dataArray[k].event && dataArray[k].event > 0) {
          if (dataArray[k].event == 1) {
            protocol_array.push('LMP + ' + dataArray[k]?.lmp_event_duration + ' ' + dataArray[k]?.lmp_event_duration_type)
          }
          else if (dataArray[k].event == 2) {
            protocol_array.push('Birth Date + ' + dataArray[k]?.lmp_event_duration + ' ' + dataArray[k]?.lmp_event_duration_type)
          }
          else if (dataArray[k].event == 3) {
            protocol_array.push('System Date')
          }
          else if (dataArray[k].event == 4) {
            protocol_array.push('Custom Date')
          }
          start_date_array.push(new Date(dataArray[k].start_date))
        }
      }
      addedArray.push(v[propKey])
    })
    if (actionType === 'deleteMedicine') {
      // var smallest_index = start_date_array.map(Number).indexOf(+new Date(Math.min.apply(null, start_date_array)))
      // var smallest_protocol = protocol_array.slice(smallest_index,smallest_index+1);
      var smallest_start_date = start_date_array ? new Date(Math.min.apply(null, start_date_array)) : undefined;
      this.setState({
        // smallest_protocol: smallest_protocol,
        smallest_due_date: smallest_start_date ? moment(smallest_start_date) : null
      })
    }
    if (actionType === 'deleteMedicine')
      this.updateMedicineStartEndDate(dataArray, previousEndDate, objId, drugId);

    this.setState({
      deleteConfirmModal: false,
      [stateVariable]: dataArray,
      [stateAddedVariable]: addedArray
    }, () => this.checkSelectedStatus());
  }

  /* addNewInstruction = (e) => {
     count--;
     let newInstruction = this.addInstructionRef.current.value;
     const addNewArr = [];
     const addNewArrInList = [];
     addNewArr.push({ 'instruction_id': count, 'instruction': newInstruction });
     addNewArrInList.push({ 'id': count, 'instruction': newInstruction, 'selected': true })
     let { allViewVisitData } = this.state;
     const { allInstructionsData } = this.state;
     allViewVisitData.instructions_data.push(...addNewArr);
     allInstructionsData.push(...addNewArrInList);
 
     this.setState({
       allViewVisitData,
       allInstructionsData,
       isNewInstruction: false
     });
     this.InstructionsOnFilter(e);
     this.addInstructionRef.current.value = '';
 
   }*/

  handleDrugAllergy = (i) => {
    var drugGeneric = this.state.drugsData[i].drug_generic;
    var drugAllergic = false;
    var drugAllergicText = '';
    var drugAllergicIntensity = '';
    const { patientDrugAllergies } = this.state;
    const allergyIntensity = this.state.constants.allergyIntensity;
    if (drugGeneric && drugGeneric.length > 0) {
      drugGeneric.map((v, k) => {
        patientDrugAllergies.map((allergy, k) => {
          if (allergy.allergy_code == v.generic_id.code) {
            drugAllergic = true;
            drugAllergicText = v.generic_id.generic_name;
            drugAllergicIntensity = allergy.intensity ? allergyIntensity[parseInt(allergy.intensity)] : allergyIntensity[0];
          }
        })
      });
    }
    if (drugAllergic) {
      this.handleModalPopup(null, 'showDrugAllergyModal', { message: 'The patient has ' + drugAllergicIntensity.name + ' severity allergy to ' + drugAllergicText + '. Are you sure you want to continue?' }, i);
    }
    else if (this.state.currentMedicinesData.length > HOSPITALDEFAULTS.maximum_medicines_allowed && !this.state.polyPharmacyWarningShown) {
      this.handleModalPopup(null, 'showDrugPolyPharmacyModal', { message: 'The patient is already on ' + this.state.currentMedicinesData.length + ' medications. Are you sure you want to prescribe more?' }, i);
      // this.setState({polyPharmacyWarningShown:true})
    }
    else {
      // cimsAPI($ggpi);
    }
  }

  handleSelectAllPolyAllergy = () => {
    const { drugsData, currentAllergiesData, currentMedicinesData } = this.state;
    var drugAllergic = false;
    var drugAllergicText = '';
    var drugAllergicIntensity = '';
    const { patientDrugAllergies } = this.state;
    const allergyIntensity = this.state.constants.allergyIntensity;
    var allergyDrugs = [];
    var allergiesMessage = '';
    allergiesMessage += `<p className="mb-0 font-weight-bold">The patient has:</p> <ul className='custom-list list-success'>`;
    drugsData.map((v, k) => {
      var drugGeneric = v.drug_generic;
      if (drugGeneric && drugGeneric.length > 0) {
        drugGeneric.map((v, k) => {
          patientDrugAllergies.map((allergy, k) => {
            if (allergy.allergy_code == v.generic_id.code) {
              allergyDrugs.push(v.drug_id);
              drugAllergic = true;
              allergiesMessage += `<li>` + allergyIntensity[parseInt(allergy.intensity)].name + ` severity allergy to ` + v.generic_id.generic_name + `</li>`
            }
          })
        });
      }
    })
    allergiesMessage += '</ul><p className="mb-0">Are you sure you want to continue?</p>';

    if (drugAllergic) {
      this.handleModalPopup(null, 'showDrugAllergyModal', { message: allergiesMessage, type: 'all', deleteObj: allergyDrugs });
    }
    else if (this.state.currentMedicinesData.length > HOSPITALDEFAULTS.maximum_medicines_allowed && !this.state.polyPharmacyWarningShown) {
      this.handleModalPopup(null, 'showDrugPolyPharmacyModal', { message: 'The patient is already on ' + this.state.currentMedicinesData.length + ' medications. Are you sure you want to prescribe more?', type: 'all' });
      // this.setState({polyPharmacyWarningShown:true})
    }
    else {
      // cimsAPI($ggpi);
    }
  }

  handleSelectAllClick = (e) => {

    this.setState({ isSelectAllChecked: !this.state.isSelectAllChecked }, () => {
      let { assessmentData, drugsData, testData, instructionsCheckSelected, supportingCheckSelected, isSelectAllChecked } = this.state;

      assessmentData.map((v, k) => {
        assessmentData[k].selected = isSelectAllChecked;
      })

      drugsData.map((v, k) => {
        drugsData[k].selected = isSelectAllChecked;
      })

      testData.map((v, k) => {
        testData[k].selected = isSelectAllChecked;
      })

      instructionsCheckSelected = isSelectAllChecked;

      supportingCheckSelected = isSelectAllChecked;
      this.setState({
        assessmentData, drugsData, testData, instructionsCheckSelected, supportingCheckSelected
      }, () => { this.checkSelectedStatus() })

      if (isSelectAllChecked)
        this.handleSelectAllPolyAllergy()
    })

  }


  handleAllergyOverrule = (e, index) => {
    this.handlePolyPharmacyMessage(index);
    this.setState({
      showDrugAllergyModal: false,
    })
  }

  handlePolyPharmacyMessage = (index) => {
    if (this.state.currentMedicinesData.length > HOSPITALDEFAULTS.maximum_medicines_allowed && !this.state.polyPharmacyWarningShown) {
      this.handleModalPopup(null, 'showDrugPolyPharmacyModal', { message: 'The patient is already on ' + this.state.currentMedicinesData.length + ' medications. Are you sure you want to prescribe more?' }, index);

    }
  }

  /*handleOnSelectEachItem(e, IDs, type, Obj, ObjId) {
    const { CheckSelect, currentAllergiesData } = this.state;
    CheckSelect.map((x, i) => {
      if (x.attr === IDs) {
        CheckSelect[i] = { type: type, attr: IDs, value: x.value === '0' ? '1' : '0', Obj, ObjId: ObjId };
      }
      this.setState({ CheckSelect })
    });

    // if (type === 'assessment') {
    //   let { allViewVisitData } = this.state;

    //   let assessData = allViewVisitData.diagnosis_data.find(dd => type + '_' + dd.id === IDs)

    //   if (assessData !== undefined) {
    //     const data = [...allViewVisitData.diagnosis_data];
    //     allViewVisitData.diagnosis_data = [];
    //     this.setState({
    //       allViewVisitData,
    //       disableConfirmBtn: true
    //     }, () => {
    //       const { allViewVisitData } = this.state;
    //       allViewVisitData.diagnosis_data = [...data]
    //       this.setState({
    //         allViewVisitData
    //       })
    //     })
    //   }
    // }

    this.setState({
      disableConfirmBtn: true
    })

    if (CheckSelect.find(x => x.value === '1')) {
      this.setState({
        disableConfirmBtn: true
      })
    } else {
      this.setState({
        isSelectAllChecked: false,
        disableConfirmBtn: false
      })
    }

    if (CheckSelect.filter(x => x.value === '1').length < CheckSelect.length) {
      this.setState({
        isSelectAllChecked: false,
      })
    } else {
      this.setState({
        isSelectAllChecked: true,
      })
    }


    if (type === 'drug') {
      this.handlePolyPharmacyMessage();
    }

    if (type === "drug" && Obj !== null) {
      currentAllergiesData.details.allergies.find((x) => {
        if (x.allergy_code === Obj.code) {
          let intensity = currentAllergiesData.constants.allergy_intensity[x.intensity].name
          let allergyName = x.allergy_name;
          this.setState({
            allergiesMessage: "The patient has " + intensity + " severity allergy to " + allergyName + ". Are you sure you want to continue?",
            allergiesAlertModal: true
          })
        }
      });
    }
  }*/


  handleChangeAllergyDrug = (e) => {
    let dataArray = this.state.drugsData;
    if (typeof this.state.deleteObjId.length != 'undefined' && this.state.deleteObjId.length > 0) {
      dataArray.map((v, k) => {
        if (this.state.deleteObjId.includes(v.drug_id))
          dataArray.splice(k, 1);
      })
    }
    else {
      dataArray.splice(this.state.deleteObjId, 1);
    }
    this.setState({
      drugsData: dataArray, deleteObjId: null
    }, () => { this.handleModalPopup(null, 'showDrugAllergyModal'); this.checkSelectedStatus() });

  }

  handlePolyPharmacyNo = (e) => {
    // debugger;
    let drugsData = this.state.drugsData;
    if (this.state.deleteObjId != null && typeof this.state.deleteObjId.length != 'undefined') {

      drugsData.splice(this.state.deleteObjId, 1);
    }
    else {
      drugsData.map((v, k) => {
        drugsData[k].selected = false;
      })
    }
    this.setState({
      drugsData, deleteObjId: null
    }, () => { this.handleModalPopup(null, 'showDrugPolyPharmacyModal'); this.checkSelectedStatus() });
  }

  handlePolyPharmacyYes = (e) => {
    // debugger;
    this.setState({
      polyPharmacyWarningShown: true,
      polyPharmacyModal: false
    }, () => { this.handleModalPopup(null, 'showDrugPolyPharmacyModal'); this.checkSelectedStatus() });


  }

  onChangeSubjectives = (e) => {
    let { subjectiveData } = this.state;
    var index = e.target.attributes.dataindex.value;
    subjectiveData.details[parseInt(index)].description = e.target.value;
    this.setState({ subjectiveData });

    // let val = e.target.value;
    // let dataid = e.target.getAttribute('dataid')
    // if (parseInt(dataid) === 0) {
    //   this.subjectiveDataInitial[0] = { id: dataid, value: val }
    //   if (this.subjectiveDataInitial[0].value === '') {
    //     this.setState({
    //       isSelectAllChecked: false,
    //       disableConfirmBtn: false
    //     })
    //   }
    // };
    // if (parseInt(dataid) === 1) {
    //   this.subjectiveDataInitial[1] = { id: dataid, value: val }
    //   if (this.subjectiveDataInitial[1].value === '') {
    //     this.setState({
    //       isSelectAllChecked: false,
    //       disableConfirmBtn: false
    //     })
    //   }
    // };
    // if (parseInt(dataid) === 2) {
    //   this.subjectiveDataInitial[2] = { id: dataid, value: val }
    // };
  }

  setSubjectiveValues(index, sameRxdate, subjectiveData, dataid) {
    let value = subjectiveData.details[index] && subjectiveData.details[index].description !== null ? subjectiveData.details[index].description : '';

    if (parseInt(dataid) === index) {
      this.subjectiveDataInitial[index] = { id: dataid, value: value };
    }
    return this.subjectiveDataInitial[index] != undefined ? this.subjectiveDataInitial[index]['value'] : '';
  }


  /*subjectiveDataInitialFnc() {
    const { subjectiveData } = this.state;
    // if(Object.keys(subjectiveData.details).length !== 0){
    this.subjectiveData = Object.values(subjectiveData.subjectives).map((subjectivObj) => {
      {
        return ({ id: subjectivObj.id, value: this.refs[subjectivObj.input_name].value })
      }
    })

    // }

  }*/



  /*assessmentDataInitialFnc() {
    this.assessments_data = [];
    const { templateView, CheckSelect } = this.state;
    let selectedId = CheckSelect.filter(data => {
      if (data.value && data.type === "assessment") {
        return true
      }
      return false
    })
    let arr = templateView.filter(item => {
      for (var key in selectedId) {
        if (parseInt(item.id) === parseInt(selectedId[key].ObjId))
          return true;
      }
      return false
    })
    arr.forEach((data, i) => {
      this.assessments_data.push({
        diagnosis_id: data.diagnosis_id,
        text: data.description,
        code: data.icd_code,
        type: data.type,
        stage: data.stage,
        status: data.status,
        image: data.image_uploaded,
        image_comment: data.image_comment,
        diagnosis_notes: data.diagnosisNotes
      })

    })

  }
  drugDataInitialFnc() {
    const { allViewVisitData, CheckSelect } = this.state;
    let selectedId1 = CheckSelect.filter(data1 => {
      if (data1.type === "drug" && data1.value) {
        return true
      }
      return false
    })
    this.drugs_data = allViewVisitData.drugs_data.filter(item => {
      for (var key in selectedId1) {
        if (parseInt(item.drug_id) === parseInt(selectedId1[key].ObjId))
          return true;
      }
      return false
    })
  }
  TestDataInitialFnc() {
    const { allViewVisitData, CheckSelect } = this.state;
    let selectedId2 = CheckSelect.filter(data1 => {
      if (data1.type === "test" && data1.value) {
        return true
      }
      return false
    })
    this.tests_data = allViewVisitData.tests_data.filter(item => {
      for (var key in selectedId2) {
        if (parseInt(item.id) === parseInt(selectedId2[key].ObjId))
          return true;
      }
      return false
    })
  }
  instructionsInitialFnc() {
    let { allViewVisitData, CheckSelect, submitInstrData } = this.state;
    let selectedId3 = CheckSelect.filter(data2 => {
      if (data2.type === "instructions" && data2.value) {
        return true
      }
      return false
    })
    if(typeof submitInstrData=='undefined')
      submitInstrData=this.state.allInstructionsData;
    if (selectedId3.length) {
      // this.instructions_data = allViewVisitData.instructions_data
      this.instructions_data = submitInstrData
    }
    else {
      this.instructions_data = null
    }
  }*/
  IsApplyCareProtoCaol = async (e) => {
    let drugIds;
    let addedAssessmentList;
    
    let {template_id,copyType,drugsData,assessmentData,referenceId,StoreId,FacilityId,enterpriseId,patientId} = this.state;

    if (copyType == 'visit' || copyType == 'template' ) {
     
      if(drugsData && drugsData.length > 0){
        var drugId = [];
        drugsData.map((v, j) => {
          drugId.push(
            v.drug_id
          );
        })
        drugIds = drugId.join('-');
      }else{
        drugIds     = drugIds == undefined?0:0;
      }
      if(assessmentData && assessmentData.length > 0){
        var assessmentDataID = [];
        assessmentData.map((d, i) => {
          assessmentDataID.push(
            d.diagnosis_id
          );
        })
        addedAssessmentList = assessmentDataID.join('-')
      }else{
        addedAssessmentList   = addedAssessmentList == undefined?0:0;
      }
    }else{
      drugIds               = drugIds == undefined?0:0;
      addedAssessmentList   = addedAssessmentList == undefined?0:0;
    }

  
    let referenceType       = 0;
    let orederSetId         = 0;
    let medicineScreen      = 'careplanform';

    let isHospitalAllowed = CIMS_ALLOW_HOSPITALS.includes(this.state.hospitalId);

    if (CIMSALLOW && isHospitalAllowed) {
    await Axios.get(CIMS_CAREPLAN_INTERACTION_EXISTS({ drugIds, referenceType, referenceId, orederSetId, patientId, enterpriseId, FacilityId, StoreId, template_id, medicineScreen, addedAssessmentList }))
      .then(success => {
        if (success.data.interaction) {
          this.checkCimsInteraction = true;
          this.setState({drug_id_Interaction_Modalurl:drugIds, careProtoColId: template_id, referenceType: referenceType, referenceId: referenceId, orederSetId: orederSetId, enterpriseId: enterpriseId, FacilityId: FacilityId, StoreId: StoreId, patientId: patientId,addedAssessmentList:addedAssessmentList, isModalInteractionVisible: this.checkCimsInteraction });
        }
      })
      .catch(err => console.log(err))
    }else{
      this.checkCimsInteraction = false
    }

  }

  handleInteractionCancel = () => {
    this.setState({ isModalInteractionVisible: false });
  };

  handleInteractionOk = () => {
    this.setState({ isModalInteractionVisible: false },
      () => { this.handleOnConfirmSubmit('', 'No') });
  };

  handleOnConfirmSubmit = async (e, type = null) => {
    if (this.state.visible != 'false' && type == null) {
      e.preventDefault()
    }
    if (type == null) {
      await this.IsApplyCareProtoCaol()
    }

    if (this.checkCimsInteraction == true && type == null) {
      return;
    }
    // e.preventDefault()
    const { hospital_id, patient_id, patient, spin_loading, reference_id, subjectiveData } = this.state;
    const { qms_token_id } = patient;
    // this.subjectiveDataInitialFnc();
    // this.assessmentDataInitialFnc();
    // this.drugDataInitialFnc();
    // this.TestDataInitialFnc();
    // this.instructionsInitialFnc();

    // const field_arr = []
    // Object.values(subjectiveData.subjectives).forEach(sf => {
    //   if (sf.required) field_arr.push(sf.input_name)
    // })

    // const field_validation = new Array(field_arr.length).fill(false);
    // field_arr.forEach((fa, idx) => {
    //   if(idx !== 0){
    //     field_validation[idx] = (subjectiveData.details[idx].description == null || subjectiveData.details[idx].description.trim() == '')
    //   }
    // })

    // if (field_validation.some(fv => fv == true)) {
    //   notification.error({
    //     message: 'Please fill subjective details',
    //     placement: 'topRight'
    //   })
    //   return;
    // }
    if (this.state.subjectiveData.details[0].description.length === 0) {
      notification.error({
        message: 'Please fill subjective details',
        placement: 'topRight'
      })
      return;
    }

    var subjectiveDetails = [];
    this.state.subjectiveData.details.map((v, k) => {
      subjectiveDetails.push({
        id: v.subjective_id,
        value: v.description,
      });
    })

    const materialObj = this.state.allSupportingMaterial.filter(file => file.selected).map(filtered => ({ id: filtered.id, file_name: filtered.fileName, file_url: filtered.fileUrl }));

    const PARAMS = {
      referenceType: REFERENCE_TYPE,
      referenceId: reference_id,
      copyType: this.state.copyType,
      subjective_data: subjectiveDetails,
      assessments_data: this.state.assessmentData,
      drugs_data: this.state.drugsData,
      tests_data: this.state.testData,
      instruction: this.state.instructionsData,
      supportingMaterial: materialObj,
      template_id: this.state.template_id,
      hospital_id: LS_SERVICE.get("slot_hospital_id"),
      speciality_id: LS_SERVICE.get("speciality_id"),
      is_event_based: this.state.template?.isEventBased == 'Y' ? true : false,
    }

    this.setState({
      spin_loading: !spin_loading
    }, () => {
      Axios.post(COPY_VISIT_APPLY, qs.stringify(PARAMS)).then(success => {
        if (success.data.status) {
          this.props.history.push({
            pathname: `/patient/todays-visit/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${reference_id}`
          });
          this.setState({
            spin_loading: !spin_loading
          })
        }
      }).catch(err => console.log(err))
    })
  }

  /*handleTestsData = data => {
    let { allViewVisitData } = this.state;

    const {
      instructions,
      requisition_comments,
      test_id,
      test_name,
      type,
      urgency,
      view_id
    } = data;

    const DATA = {
      id: -1,
      instructions,
      is_diabetic: null,
      is_metformin: null,
      requisition_comments,
      risk: null,
      test_id,
      test_name,
      type,
      urgency,
      view_id
    }

    allViewVisitData.tests_data = [...allViewVisitData.tests_data, DATA];

    const { CheckSelect } = this.state;
    CheckSelect.push({
      'type': 'test',
      'attr': 'test_' + allViewVisitData.tests_data[allViewVisitData.tests_data.length - 1].id,
      'value': '1',
      'Obj': null,
      'ObjId': allViewVisitData.tests_data[allViewVisitData.tests_data.length - 1].id
    });
    this.setState({
      allViewVisitData,
      resetTestForm: true,
      CheckSelect
    }, () => {
      this.handleModalPopup(null, 'addTestsModal');
    });

  }*/

  /* handleMedicineData = data => {
     if(data.length!=0){
       let { allViewVisitData, isMedicineEdit } = this.state;
 
       const {
         daily_frequency,
         days_in_week,
         days_in_week_text,
         dosage,
         dosage_form,
         dosage_form_text,
         drug_fixed_qty,
         drug_form,
         drug_frequency,
         drug_id,
         drug_name,
         fav,
         instruction,
         number_of_days,
         quantity,
         route,
         schedule_name,
         schedules,
         i,
         strength,
         taper_row,
         urgency,
         start_date,
         end_date,
         drug_generic,
         medicine_remarks
       } = data;
 
       const DATA = {
         daily_frequency,
         days_in_week,
         days_in_week_text,
         dosage,
         dosage_form,
         dosage_form_text,
         drug_form,
         drug_frequency,
         drug_id,
         drug_name,
         end_date,
         // fixed_quantity: drug_fixed_qty,
         drug_fixed_qty,
         instruction,
         number_of_days,
         route,
         schedule_name,
         schedules,
         start_date,
         strength,
         taper_row,
         urgency,
         quantity,
         fav,
         drug_generic,
         medicine_remarks
       }
       let index=i;
       if (!isMedicineEdit || typeof i=='undefined')
         index=allViewVisitData.drugs_data.length;
       DATA.i=index;
       allViewVisitData.drugs_data[index] = DATA;
 
       //   console.log(allViewVisitData.drugs_data);
       const { CheckSelect } = this.state;
       CheckSelect.push({
         'type': 'drug',
         'attr': "drug_" + ((allViewVisitData.drugs_data.length) - 1) + "_" + allViewVisitData.drugs_data[allViewVisitData.drugs_data.length - 1].drug_id,
         'value': '1',
         'Obj': null,
         'ObjId': allViewVisitData.drugs_data[allViewVisitData.drugs_data.length - 1].drug_id
       });
 
       this.setState({
         allViewVisitData,
         resetMedicalForm: true,
         isMedicineEdit: false,
         updateMedicineModal:false,
         showMedicineModal:false,
         CheckSelect
         // medicineEditData: null
       }, () => {
         // this.handleOnSelectEachItem(null, 'drug_' + i + '_' + drug_id, 'drug', data.drug_generic[0].generic_id);
         // this.handleModalPopup(null, 'showMedicineModal');
       });
     }
 
   }*/

  handleStatusSwitch = async (e, i, data) => {
    const { id, status } = data;
    let assessmentData = this.state.assessmentData;

    assessmentData[i].status = e ? 0 : 1;
    this.setState({ assessmentData });

    /*const switchPromise = await Axios.get(ASSESSMENT_STATUS_UPDATE({ id, status: !status ? 1 : 0 }))

    if (switchPromise) {
      this.loadData();

      const { data } = switchPromise;

      notification.success({
        message: data.message,
        placement: 'topRight'
      })
    }*/
  }

  //Subjective Feild Focus Handling
  handleOnFocus = (e) => {
    this.setState((prevState) => ({
      speech: {
        ...prevState.speech,
        state: false
      }
    }), () => {
      speechService.init(this.state.speech);
    })
  }
  //Subjective Feild Focus Handling

  selectChiefComplaint = (value, props) => {
    let chiefComplaintArr = [];
    if (props && props.length > 0) {
      chiefComplaintArr = props.map((data, index) => (data.value == value[index] && data.key !== undefined) ? { id: data.key, name: data.value } : { id: null, name: value[index] });
    }

    let { subjectiveData } = this.state;
    subjectiveData.details[parseInt(0)].description = chiefComplaintArr;
    this.setState({ subjectiveData });

    this.setState({
      chiefComplaint: value
    });
  }

  getChiefcomplaintList() {
    Axios.post(CHIEFCOMPLAINT_LIST)
      .then(success => {
        this.setState({
          chiefComplaintList: success.data.data
        })
      })
      .catch(err => console.log(err))
  }

  optionChiefComplaintLoad() {
    if (this.state.chiefComplaintList && this.state.chiefComplaintList.length > 0) {
      return this.state.chiefComplaintList.map((obj, index) => {
        return <Option key={index} value={obj.complaint_text} data={obj}>{obj.complaint_text}</Option>
      })
    }
  }

  getMaterialData = (materialData) => {
    this.setState({ allSupportingMaterial: materialData });
  }

  saveSupportingMaterial = async _ => {
    const { match: { params }, location: { state } } = this.props;
    let { qms_token_id } = params;


    this.setState({ supportingMaterial: this.state.allSupportingMaterial.filter(file => file.selected) });
    this.handleModalPopup(null, 'showSupportingModal');
  }

  ccTags(data) {
    return (
      <div className="ant-select-selection-overflow-item" style={{ opacity: 1 }}>
        <span className="ant-select-selection-item" title={data.label}>
          <Tooltip title="Double click to edit">
            <span className="ant-select-selection-item-content" onDoubleClick={() => {
              this.setState({ ccEditText: data.label, ccEditOriginalText: data.label });
            }}>{data.label}</span>
          </Tooltip>
          <span className="ant-select-selection-item-remove" unselectable="on" aria-hidden="true" style={{ userSelect: "none" }} onClick={() => data.onClose()}>
            <span role="img" aria-label="close" className="anticon anticon-close">
              <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg>
            </span>
          </span>
        </span>
      </div>
    );
  }

  updateCcChipInfo(e) {
    let { ccEditText, ccEditOriginalText, chiefComplaint, patient } = this.state;
    chiefComplaint.map((element, i) => {
      if (element == ccEditOriginalText) {
        chiefComplaint[i] = ccEditText;
      }
    });

    LS_SERVICE.set("chief_complaint_" + patient.patient_id + "_" + patient.qms_token_id, chiefComplaint);
    this.setState({
      chiefComplaint: chiefComplaint,
      ccEditText: '',
      ccEditOriginalText: ''
    });
    e.preventDefault();
  }

  updateSelect(data) {
    this.setState({
      isSelectAllChecked: data,
      disableConfirmBtn:!data
    })
  }

  render() {

    const idOfHospital = this.state.hospital_id;
    const { sameRxdate, subjectiveData, allViewVisitData, currentVisitVitals, patient, CheckSelect, patient_note_doctor, patient_docments, ccEditText, ccEditOriginalText } = this.state;
    return (
      <>

        {/* <!-- Page sidebar--> */}
        {patient != undefined && patient.patient_id != undefined && patient.patient_id != null ? <AsideLeft patient_id={patient.patient_id} reference_id={patient.qms_token_id} /> : null}

        {/* <MyHcSpeechRecognition /> */}

        <div className="content-wrapper">
          <div className="container-fluid">
            {this.state.DataLoaded === true ? (
              <>
                {
                  patient_note_doctor?.length > 0
                    || patient_docments?.length > 0
                    ? (
                      <div className="row">
                        <div className="col-12 mb-3" id="patient_notes_docs">
                          <div className="card bg-grey rounded-0">
                            <div className="card-body">

                              {patient_note_doctor.length > 0 ? (
                                <>
                                  <h6 className="text-uppercase text-dark" id="notes_for_doctor_head">Notes For Doctor</h6>
                                  <p className="text-dark" id="notes_for_doctor_note">{patient_note_doctor}</p>
                                </>) : null}

                              {
                                patient_docments?.length > 0 ? (
                                  <>
                                    <h6 className="text-uppercase text-dark" id="docs_for_doctor_head">Documents uploaded by the patient</h6>
                                    <figure className="mb-0" id="docs_for_doctor_docs">
                                      {
                                        patient_docments?.map((file, index) => (
                                          <a href="#" className="show-rx-modal" key={index} onClick={e => this.handleModalPopup(e, 'viewDocumentModal', index, 'img')}>
                                            {/* <img className="mr-3" alt='Doc IImg' src={file.type === 'img' ? icJpg : icPdf} /> */}
                                            <i className={`mr-2 icon-${file.type === 'img' ? 'jpg' : 'pdf'}`}></i>
                                          </a>
                                        ))
                                      }
                                    </figure>
                                  </>
                                ) : null
                              }

                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null
                }
                <div className="row mt-2" id="main-page-container">
                  <div className="col-12">
                    <form id="template-select-form" className="emr emrfrm subjective-form" method="POST" action="" acceptCharset="UTF-8" encType="multipart/form-data" autoComplete="off">
                      <div className="row">
                        <div className="col-12 mt-2">
                          <div className="float-left">
                            <h1 className="page-title">Subjective</h1>
                          </div>
                          <div className="float-right">
                            <button className="btn btn-outline-secondary px-4 small text-uppercase" onClick={this.goBack}>BACK</button>
                          </div>
                        </div>
                        <div className="col-12 mt-2 subjective-data">
                          {
                            typeof subjectiveData !== 'undefined' && this.state.DataLoaded ?
                              Object.values(subjectiveData.subjectives).map((subjectivObj, index) =>
                                <>
                                  {/* {subjectiveData.details[index].description !==""? */}
                                  <div className="form-group micwrap normal mb-4" key={index}>


                                    <label htmlFor="Chief Complaints" className="control-label mb-0">{subjectivObj.display_text} <span className="text-danger">{subjectivObj.required === true ? '*' : ''}</span></label>
                                    {/* {this.state.chief_complaint != undefined ? this.state.chief_complaint : '----'} */}

                                    {(subjectivObj.input_name === 'chief_complaint') ?
                                      <>
                                        <Link
                                          onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: 'drop_list' }) }}
                                          className={(this.state.speech.identifier === 'drop_list' && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link>

                                        <Modal width={600} title="Edit Chief Complaints" closable={false} footer={null} visible={(ccEditText != '') ? true : false}>
                                          <div className="row">
                                            <div className="col-12">
                                              <textarea style={{ minHeight: "150px" }} onInput={(e) => this.autoHeight(e)} autoComplete="off" className="form-control" name="edit_cc" type="text" autoFocus value={this.state.ccEditText} onChange={(e) => this.setState({ ccEditText: e.target.value })} />
                                            </div>
                                            <div className="col-12 mt-4">
                                              <button class="btn btn-primary px-4 pull-right" type="button" onClick={(evt) => this.updateCcChipInfo(evt)}>Update</button>
                                              <button class="btn btn-outline-primary px-4 pull-right mr-2" type="button" onClick={(evt) => { this.setState({ ccEditText: '', ccEditOriginalText: '' }) }}>Cancel</button>
                                            </div>
                                          </div>
                                        </Modal>

                                        <Select
                                          showSearch
                                          id={subjectivObj.input_name}
                                          name={subjectivObj.input_name}
                                          style={{ border: 'none' }}
                                          className="form-control subjective-field custom-cheif break-word"
                                          onChange={(val, props) => this.selectChiefComplaint(val, props)}
                                          mode="tags"
                                          tagRender={(evt) => this.ccTags(evt)}
                                          defaultValue={this.state.chiefComplaint}
                                          value={this.state.chiefComplaint}
                                        // allowClear
                                        >
                                          {this.optionChiefComplaintLoad()}
                                        </Select>
                                      </>
                                      :
                                      <>
                                        <Link
                                          onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: subjectivObj.input_name }) }}
                                          className={(this.state.speech.identifier === subjectivObj.input_name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link>
                                        <textarea onChange={this.onChangeSubjectives} dataid={subjectivObj.id} dataindex={index} className="form-control subjective-field" ref={subjectivObj.input_name}
                                          style={{ minHeight: (index === 0 && sameRxdate) ? '70px' : '' }}
                                          name={subjectivObj.input_name} row="1" onInput={(e) => this.autoHeight(e)}
                                          value={this.setSubjectiveValues(index, sameRxdate, subjectiveData, subjectivObj.id)}
                                          onFocus={this.handleOnFocus}
                                        />
                                      </>
                                    }
                                  </div>
                                  {/* :""} */}
                                </>
                              ) : null
                          }
                        </div>
                      </div>

                      {/* <!-- Vital --> */}
                      <>
                        {currentVisitVitals !== undefined && Object.keys(currentVisitVitals).length > 0 ?
                          <div className="row mt-4 mb-5">
                            <h2 className="page-title col-12 mb-3">Vitals <small className="text-muted">({moment(currentVisitVitals[0].date).format('DD MMM, YYYY | hh:mm A')})</small></h2>
                            {this.state.showingVitals.map(vital => {
                              if (vital.id != 'V005' && vital.id != 'V006')
                                return (<div className="col text-dark border-right">{vital.display_name} <br />({vital.display_value})<span className="text-muted d-block mt-2">{currentVisitVitals[0] && currentVisitVitals[0][vital.id] !== "" ? currentVisitVitals[0][vital.id] : '-'}</span></div>)
                              if (vital.id == 'V005')
                                return (<div className="col text-dark border-right">Blood Pressure <br />(mmHg)<span className="text-muted d-block mt-2">{currentVisitVitals[0]?.V006 !== "" ? currentVisitVitals[0]?.V006 : "-"}/{currentVisitVitals[0]?.V005 !== "" ? currentVisitVitals[0]?.V005 : "-"}</span></div>)
                            })}
                            {this.state.remainingVitals.map(vital => {
                              if (vital.id != 'V005' && vital.id != 'V006')
                                return (<div className="col text-dark border-right">{vital.display_name} <br />({vital.display_value})<span className="text-muted d-block mt-2">{currentVisitVitals[0] && currentVisitVitals[0][vital.id] !== "" ? currentVisitVitals[0][vital.id] : '-'}</span></div>)
                              if (vital.id == 'V005')
                                return (<div className="col text-dark border-right">Blood Pressure <br />(mmHg)<span className="text-muted d-block mt-2">{currentVisitVitals[0]?.V006 !== "" ? currentVisitVitals[0]?.V006 : "-"}/{currentVisitVitals[0]?.V005 !== "" ? currentVisitVitals[0]?.V005 : "-"}</span></div>)
                            })}
                            {/*<div className="col text-dark border-right">Height <br />(cm)<span className="text-muted d-block mt-2">{typeof currentVisitVitals[0].V001 !== 'undefined' && currentVisitVitals[0].V001 !== '' ? currentVisitVitals[0].V001 : '-'}</span></div>
                                  <div className="col text-dark border-right">Weight <br />(kg)<span className="text-muted d-block mt-2">{typeof currentVisitVitals[0].V002 !== 'undefined' && currentVisitVitals[0].V002 !== '' ? currentVisitVitals[0].V002 : '-'}</span></div>
                                  <div className="col text-dark border-right">BMI <br />(kg/m2)<span className="text-muted d-block mt-2">{typeof currentVisitVitals[0].V003 !== 'undefined' && currentVisitVitals[0].V003 !== '' ? currentVisitVitals[0].V003 : "-"}</span></div>
                                  <div className="col text-dark border-right">Temperature <br />(F)<span className="text-muted d-block mt-2">{typeof currentVisitVitals[0].V004 !== 'undefined' && currentVisitVitals[0].V004 !== '' ? currentVisitVitals[0].V004 : "-"}</span></div>
                                  <div className="col text-dark border-right">Blood Pressure <br />(mmHg) <span className="text-muted d-block mt-2">{typeof currentVisitVitals[0].V006 !== 'undefined' && currentVisitVitals[0].V006 !== '' ? currentVisitVitals[0].V006 : '-'}/{typeof currentVisitVitals[0].V005 !== 'undefined' && currentVisitVitals[0].V005 !== '' ? currentVisitVitals[0].V005 : '-'}</span></div>
                                  <div className="col text-dark border-right">SPO2 <br />(%)<span className="text-muted d-block mt-2">{typeof currentVisitVitals[0].V007 !== 'undefined' && currentVisitVitals[0].V007 !== '' ? currentVisitVitals[0].V007 : '-'}</span></div>
                                  <div className="col text-dark border-right">Heart Rate <br />(bpm)<span className="text-muted d-block mt-2">{typeof currentVisitVitals[0].V008 !== 'undefined' && currentVisitVitals[0].V008 !== '' ? currentVisitVitals[0].V008 : '-'}</span></div>
                                  <div className="col text-dark border-right">Pulse Rate <br />(bpm)<span className="text-muted d-block mt-2">{typeof currentVisitVitals[0].V009 !== 'undefined' && currentVisitVitals[0].V009 !== '' ? currentVisitVitals[0].V009 : '-'}</span></div>
                                  <div className="col text-dark border-right">Blood Sugar Fasting <br />(mg/dL)<span className="text-muted d-block mt-2">{typeof currentVisitVitals[0].V010 !== 'undefined' && currentVisitVitals[0].V010 !== '' ? currentVisitVitals[0].V010 : "-"}</span></div>
                                  <div className="col text-dark border-right">Blood Sugar Post Prandial <br />(mg/dL)<span className="text-muted d-block mt-2">{typeof currentVisitVitals[0].V011 !== 'undefined' && currentVisitVitals[0].V011 !== '' ? currentVisitVitals[0].V011 : "-"}</span></div>*/}
                          </div>
                          : <div className="row my-4">
                            <div className="col-12 mb-3">
                              <div className="float-left"><h1 className="page-title">Vitals</h1></div>
                              <div className="float-right"><a href="#" className="btn btn-outline-primary small text-uppercase" onClick={e => this.handleModalPopup(e, 'showVitalsModal')}>+ Vitals</a></div>
                            </div>
                          </div>}
                      </>

                      <div className="row mt-3">
                        <div className="col-12 mb-3">
                          <div className="float-left">
                            <h1 className="page-title">Assessment</h1>
                          </div>
                          <div className="float-right">
                            <a href="#" className="btn btn-outline-primary small text-uppercase" onClick={e => this.handleModalPopup(e, 'showAssessmentModal')}>+ Assessment</a>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <div className="table-responsive">
                            <AssessmentTable assessmentData={this.state.assessmentData} viewType={'copy-to-visit'} statusSwitch={this.handleStatusSwitch} showAction={true} showSelectCheck={true} constants={this.state.constants} updateSelection={this.handleSelectCheck} handlePopUp={this.handleModalPopup} />
                          </div>
                        </div>
                        <br />
                      </div>

                      {
                        this.state.template?.isEventBased && this.state.template?.isEventBased == 'Y' && this.state.obsGynae?.mensural?.lmpDate && this.state.obsGynae?.mensural?.lmpDate != null
                          ?
                          <div className="row mt-4">
                            <>
                              <div className="col-3 emr-form emr-medicine-form template-medicine-form">
                                <div className="form-group hasdata">

                                  <DatePicker
                                    id="protocol_start_date"
                                    name="protocol_start_date"
                                    format="DD MMM, YYYY"
                                    showNow={true}
                                    placeholder={""}
                                    allowClear
                                    defaultPickerValue={this.state.smallest_due_date}
                                    disabled={this.state.drugsnotcustom && this.state.drugsnotcustom > 0 ? true : false}
                                    showTime={{ defaultValue: moment() }}
                                    defaultValue={this.state.smallest_due_date}
                                    value={moment(this.state.smallest_due_date).diff(moment(), 'days') < 0 ? moment() : this.state.smallest_due_date}
                                    className="form-control datetimepicker"
                                    onOk={this.setDateTimeHandler}
                                  />
                                  <label htmlFor="key_finding_date_time">Protocol Start Date</label>
                                </div>
                              </div>
                              <div className="col-4 emr-form emr-medicine-form template-medicine-form">
                                <input
                                  type="text"
                                  className="form-control"
                                  disabled={true}
                                  value={"Protocol: " + this.state.smallest_protocol}
                                />
                              </div>
                            </>
                            {
                              this.state.obsGynae?.obstetric?.basic?.pregnant == 1
                                ?
                                <>
                                  {
                                    (
                                      this.state.obsGynae?.obstetric?.basic?.gestationalAgeUsgAsOnToday &&
                                      this.state.obsGynae?.obstetric?.basic?.gestationalAgeUsgAsOnToday != null
                                    )
                                      ||
                                      (
                                        this.state.obsGynae?.obstetric?.basic?.gestationalAgeLmpAsOnToday &&
                                        this.state.obsGynae?.obstetric?.basic?.gestationalAgeLmpAsOnToday != null
                                      )
                                      ?
                                      <div className="col-4 emr-form emr-medicine-form template-medicine-form">
                                        <input
                                          type="text"
                                          className="form-control"
                                          disabled={true}
                                          value={
                                            (
                                              this.state.obsGynae?.obstetric?.basic?.gestationalAgeUsgAsOnToday &&
                                              this.state.obsGynae?.obstetric?.basic?.gestationalAgeUsgAsOnToday != null
                                            )
                                              ?
                                              'Gestational age (USG): ' + this.state.obsGynae?.obstetric?.basic?.gestationalAgeUsgAsOnToday
                                              :
                                              'Gestational age (LMP): ' + this.state.obsGynae?.obstetric?.basic?.gestationalAgeLmpAsOnToday
                                          }
                                        />
                                      </div>
                                      :
                                      null
                                  }
                                </>
                                :
                                null
                            }
                          </div>
                          :
                          <div className="row mt-4"></div>
                      }

                      <div className={this.state.template?.isEventBased && this.state.template?.isEventBased == 'Y' ? "row" : "row mt-3"}>
                        <div className="col-12 mb-3 emr-form emr-medicine-form template-medicine-form">
                          <div className="float-left">
                            <h3 className="page-title mt-2">Medicines</h3>
                          </div>
                          <div className="float-right">
                            {
                              LS_SERVICE.get('isObsGynaeDoctor') == 1 && LS_SERVICE.get('isObsGynaePatient') == true && this.state.template?.isEventBased && this.state.template?.isEventBased == 'Y' && this.state.obsGynae?.mensural?.lmpDate && this.state.obsGynae?.mensural?.lmpDate != null
                                ?
                                <>
                                  <Badge className="mr-2 legends-badge" color={"rgb(247, 234, 234)"} text={"Missed"} />
                                  <Badge className="mr-2 legends-badge" color={"rgb(221, 219, 219)"} text={"Previously Prescribed"} />
                                  <Badge className="mr-2 legends-badge" color={"rgb(220, 237, 244)"} text={"Due Now"} />
                                  <Badge className="mr-2 legends-badge" color={"rgb(255 255 255)"} text={"Due Later"} />
                                </>
                                :
                                null
                            }
                            <a href="#" className="btn btn-outline-primary small" onClick={e => this.handleModalPopup(e, 'showMedicineModal')}>+ MEDICINE</a>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <MedicineTable alreadyPrescribedDrugs={this.state.already_prescribed_drugs} updateData={this.updateMedicineDate} patientInfo={this.state.patient} patientObsGynaeInfo={this.state.obsGynae} eventBased={this.state.template?.isEventBased && this.state.template?.isEventBased == 'Y' && this.state.obsGynae?.mensural?.lmpDate && this.state.obsGynae?.mensural?.lmpDate != null ? true : false} drugsData={this.state.drugsData} viewType={'copy-to-visit'} showAction={true} showSelectCheck={true} constants={this.state.constants} updateSelection={this.handleSelectCheck} handlePopUp={this.handleModalPopup} />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12 mb-3">
                          <div className="float-left">
                            <h3 className="page-title mt-2">Tests</h3>
                          </div>
                          <div className="float-right">
                            <a href="#" className="btn btn-outline-primary small" onClick={e => { this.handleModalPopup(e, 'showTestModal') }}>+ TEST</a>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 mb-4">
                          <TestTable pateintInfo={this.state.patient} alreadyPrescribedTests={this.state.already_prescribed_tests} updateData={this.updateTestDate} eventBased={this.state.template?.isEventBased && this.state.template?.isEventBased == 'Y' && this.state.obsGynae?.mensural?.lmpDate && this.state.obsGynae?.mensural?.lmpDate != null ? true : false} testData={this.state.testData} viewType={'copy-to-visit'} showAction={true} showSelectCheck={true} constants={this.state.constants} updateSelection={this.handleSelectCheck} handlePopUp={this.handleModalPopup} />
                        </div>

                      </div>

                      <div className="row">
                        <div className="col-12">
                          <div className="float-left">
                            <h3 className="page-title mt-2">Instructions</h3>
                          </div>
                          <div className="text-right">
                            <a href="#" className="btn btn-outline-primary small" onClick={e => this.handleModalPopup(e, 'showInstructionModal')}>+ INSTRUCTIONS</a>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-10">
                          <ul className="custom-list list-success">
                            <InstructionTable instructionsData={this.state.instructionsData} viewType={'full'} constants={this.state.constants} />
                          </ul>
                        </div>
                        <div className="col-2 text-right">
                          <span className="d-inline mr-4">
                            <i data-checkSelect="instructions" data-type={'instructions'} onClick={this.handleInstructionsSelectCheck}
                              className={this.state.instructionsCheckSelected ? "btn-true-sm accept" : "btn-true-sm"}></i>
                          </span>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <div className="float-left">
                            <h3 className="page-title mt-2">Supporting Material</h3>
                          </div>
                          <div className="text-right">
                            <a href="#" className="btn btn-outline-primary small" onClick={e => this.handleModalPopup(e, 'showSupportingModal')}>+ Supporting Material</a>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-10">
                          <ul className="custom-list list-success">
                            <SupportingMaterialTable
                              supportingMaterialData={this.state.supportingMaterial}
                              viewType={'full'} hospitalId={this.state.hospital_id}
                              constants={this.state.constants} />
                          </ul>
                        </div>
                        <div className="col-2 text-right">
                          <span className="d-inline mr-4">
                            <i data-checkSelect="supportingMaterial" data-type={'supportingMaterial'} onClick={this.handleSupportingSelectCheck}
                              className={this.state.supportingCheckSelected ? "btn-true-sm accept" : "btn-true-sm"}></i>
                          </span>
                        </div>
                      </div>


                      <div className="row mt-2">
                        <div className="col-12">
                          <div className="float-right">
                            <button type="button" className="btn" onClick={(e) => this.handleSelectAllClick(e)}>
                              Select All&nbsp;&nbsp;&nbsp;<span className="select-check select-all-check">
                                <img src={this.state.isSelectAllChecked ? selecAllImg2 : selecAllImg}
                                  className="img-fluid" alt="check unselected" /></span>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="mt-5">&nbsp;</div>
                      <div className="fixed-cta">
                        <button className="btn btn-primary px-5 text-uppercase" disabled={this.state.disableConfirmBtn}
                          onClick={(e) => this.handleOnConfirmSubmit(e)}> CONFIRM</button>
                      </div>
                    </form>
                  </div>
                </div>
              </>
            ) : (
              <div className="row">
                <div className="col-12 text-center mt-2">{LOADER_RED}</div>
              </div>

            )
            }
          </div>
        </div>

        {/* ################################## For Modal ####################################  */}

        {/* || VITAL MODAL */}
        <Modal
          title={`Add Vitals`}
          visible={this.state.showVitalsModal}
          onCancel={(e) => this.handleModalPopup(e, 'showVitalsModal')}
          style={{ top: 40 }}
          footer={[
            <div className="text-center">
              <Button form="vitalForm" type="outline-secondary" onClick={this.handleVitalModalClear}
                htmlType="button" className="btn btn-outline-secondary cursor-pointer">
                CLEAR
              </Button>
              <Button form="vitalForm" type="primary" htmlType="submit" className="btn btn-primary text-uppercase ml-2"
                disabled={this.handleVitalModalButtonDisabled() || this.state.spin_loading}>
                CONFIRM{this.state.spin_loading ? LOADER : ''}
              </Button>
            </div>
          ]}
          width="910px"
          bodyStyle={{ paddingBottom: '10px' }}
          className="emrfrm"
        >

          <Form
            id="vitalForm"
            className="add-vitals"
            ref={this.vitalFormRef}
            validateMessages={HANDLEVALIDANTMESSAGES}
            onFinish={this.handleVitalAddSubmit}
            layout="vertical"
          >
            <div className="row">
              <div className="col-4">

                <Form.Item
                  name="vitalType"
                >
                  <span className="d-none">{this.state.vitalsType}</span>
                  <div className="form-group mb-1 hasdata">
                    <Select name="vitalsType" id="vitalsType" value={this.state.vitalsType} defaultValue={this.state.vitalsType} onChange={this.handleVitalSelect}>
                      {this.state.selectedVitalGroups.map((vitalGroup, index) => (<Option key={index} value={vitalGroup.id} datavitals={vitalGroup.vitalSignIds}>{vitalGroup.long_description}</Option>)
                      )}
                    </Select>
                    <label htmlFor="vitalsType">Vital Group</label>
                  </div>
                </Form.Item>

              </div>
            </div>
            <div className="row">
              {this.state.vitalsInput ? this.state.vitalsInput.map((vital, idx) => {
                const tabIndx = idx + 1;
                if (!this.state.displayShowingVitals.includes(vital.id)) return (null)
                if (vital.id == 'V006') return (null)
                if (vital.id == 'V017') return (null)
                else if (vital.id == 'V005')
                  return (<div className="col-4 antInputItem multi-col" style={!this.state.displayVitals.includes("5") ? { display: 'none' } : {}}>
                    <Form.Item name="bp" label="Blood Pressure" rules={[{
                      required: vital.required,
                      type: 'number',
                      min: vital.min,
                      max: vital.max,
                      transform: function (value) {
                        return value === "" || value === undefined ? value : +value
                      }
                    }]}>
                      <div className="multi-input-col-inner">
                        <div className="ant-input-wrapper ant-input-group">
                          <div className="ant-input">
                            <Input name="V006" data-vitalid="6" tabIndex="5" autoComplete="off" maxLength={vital.maxlength} min={vital.min} max={vital.max}
                              value={this.state.V006} onChange={(e) => { this.handleOnChangeNumbers(e, vital.min, vital.max, vital.decimal_value) }} />
                            <Input name="V005" data-vitalid="5" tabIndex="6" autoComplete="off" maxLength={vital.maxlength} min={vital.min} max={vital.max}
                              className="multi" value={this.state.V005} onChange={(e) => { this.handleOnChangeNumbers(e, vital.min, vital.max, vital.decimal_value) }} />
                          </div>

                          <span className="ant-input-group-addon">mmHg</span>
                        </div>
                      </div>
                    </Form.Item>
                  </div>)
                else
                  return (<>
                    <div className="col-4 antInputItem" style={!this.state.displayVitals.includes("" + vital.vital_id) ? { display: 'none' } : {}}>
                      <Form.Item
                        name={vital.id}
                        label={vital.display_name}
                        rules={[{
                          required: vital.required,
                          type: 'number',
                          min: vital.min,
                          max: vital.max,
                          transform: function (value) {
                            return value === "" || value === undefined || value === null ? '' : +value;
                          }
                        }]}>

                        <Input addonAfter={vital.display_value} name={vital.id} autoComplete="off" maxLength={vital.maxlength} data-vitalid={vital.vital_id} tabIndex={tabIndx}
                          value={this.state[vital.id]} onChange={(e) => { this.handleOnChangeNumbers(e, vital.min, vital.max, vital.decimal_value) }} disabled={vital.id == 'V003'} />
                      </Form.Item>

                    </div></>
                  )
              }) : (null)}
            </div>
          </Form>

        </Modal>



        {/* For Modal Assessment  */}
        {this.state.showAssessmentModal ?
          <Modal
            title={this.state.actionText + " Assessment"}
            visible={this.state.showAssessmentModal}
            onCancel={(e) => this.handleModalPopup(e, 'showAssessmentModal')}
            footer={false}
            width="850px"
          // bodyStyle={{ padding: "10px 20px" }}
          >
            {this.state.showAssessmentModal ? <AssessmentForm formType={"copy-to-visit"} actionText={this.state.actionText} updateData={this.updateAssessmentData} autofocus={false} editData={this.state.editData} editIndex={this.state.editIndex} addedAssessmentList={this.state.addedAssessmentList} handleTemplateRadioSubmit={null} clearEdit={this.clearEdit} /> : null}


          </Modal>
          : null
        }

        {/* For Modal Medicine  */}
        {this.state.showMedicineModal ?
          <Modal
            title={this.state.actionText + " Medicine"}
            visible={this.state.showMedicineModal}
            onCancel={(e) => this.handleModalPopup(e, 'showMedicineModal')}
            footer={false}
            width="1500px"
          // bodyStyle={{ padding: "10px 20px" }}
          >
            {this.state.showMedicineModal ?
              <MedicineForm formType={"copy-to-visit"} actionText={this.state.actionText} updateData={this.updateMedicineData} autofocus={false} editData={this.state.editData}
                editIndex={this.state.editIndex} addedDrugsList={this.state.addedDrugsList} constants={this.state.constants} patient={this.state.patient} clearEdit={this.clearEdit}
                polyPharmacyWarningShown={this.state.polyPharmacyWarningShown} updatepolyPharmacyWarningShown={this.polyPharmacyWarningShown}
              />

              : null}

          </Modal>
          : null
        }

        {/* For Modal Test  */}
        {this.state.showTestModal ?
          <Modal
            title={this.state.actionText + " Test"}
            visible={this.state.showTestModal}
            onCancel={(e) => this.handleModalPopup(e, 'showTestModal')}
            footer={false}
            width="850px"
          // bodyStyle={{ padding: "10px 20px" }}
          >
            {this.state.showTestModal ? <TestForm formType={"copy-to-visit"} testAdvisedDuplicateOrders={this.state.testAdvisedDuplicateOrders} actionText={this.state.actionText} updateData={this.updateTestData} autofocus={false} editData={this.state.editData} editIndex={this.state.editIndex} addedTestList={this.state.addedTestList} constants={this.state.constants} clearEdit={this.clearEdit} hospital_id={this.state.hospital_id} /> : null}


          </Modal>
          : null
        }


        {/* For Modal Instruction  */}
        {this.state.showInstructionModal ?
          <Modal
            title={this.state.actionText + " Instructions"}
            visible={this.state.showInstructionModal}
            onCancel={(e) => this.handleModalPopup(e, 'showInstructionModal')}
            footer={false}
          // bodyStyle={{ padding: "10px 20px" }}
          >
            {this.state.showInstructionModal ? <InstructionsForm actionText={this.state.actionText} updateData={this.updateInstructionsData} addedInstructionsData={this.state.instructionsData} handleModalPopup={this.handleModalPopup} /> : null}

          </Modal>
          : null
        }

        {/* For Modal Supporting Material */}
        {this.state.showSupportingModal ?
          <Modal
            title={false}
            visible={this.state.showSupportingModal}
            onCancel={(e) => this.handleModalPopup(e, 'showSupportingModal')}
            width="850px"
            footer={false}
          // bodyStyle={{ padding: "10px 20px" }}
          >
            <form className="template-form" autoComplete="off" encType="multipart/form-data">
              {this.state.showSupportingModal ?
                <SupportingMaterial
                  actionText={this.state.actionText}
                  referenceId={this.state?.reference_id}
                  templateId={this.state.template_id}
                  formType={this.state.copyType == 'template' ? 'template' : 'all'}
                  isEdit='edit'
                  hospitalId={this.state.hospital_id}
                  setMaterialData={(data) => this.getMaterialData(data)}
                  supportingMaterial={this.state.supportingMaterial}
                  allSupportingMaterial={this.state.allSupportingMaterial}
                /> : null}
              <div className="modal-footer pb-0">
                <div className="col-12 text-center instructions_cta">
                  <button type="button" className="btn btn-outline-secondary px-4 mr-2" onClick={(e) => this.handleModalPopup(e, 'showSupportingModal')}>CANCEL</button>
                  <div className="btn btn-primary px-4 text-uppercase confirm-instructions"
                    onClick={this.saveSupportingMaterial}>SAVE</div>
                </div>
              </div>
            </form>
          </Modal>
          : null
        }

        {/* For Modal Delete Confirm  */}
        <Modal
          title={false}
          visible={this.state.deleteConfirmModal}
          onCancel={(e) => this.handleModalPopup(e, 'deleteConfirmModal')}
          footer={false}
          closable={false}
        >
          <div className="row mb-3">
            <h6 className="col-12 text-center">{this.state.deleteMessage}</h6>
          </div>
          <div className="modal-footer justify-content-center emrfrm pb-0">
            <button type="button" className="btn btn-outline-secondary px-5  mr-2" onClick={(e) => this.handleModalPopup(e, 'deleteConfirmModal')}>CANCEL</button>
            <button type="button" className="btn btn-primary px-5" onClick={(e) => this.handleOnItemDelete(e, this.state.deleteActionType, this.state.deleteObjId)}>DELETE</button>
          </div>

        </Modal>

        {/* For Modal Allergies Alert  */}
        <Modal
          title={false}
          visible={this.state.showDrugAllergyModal}
          onCancel={(e) => this.handleModalPopup(e, 'showDrugAllergyModal')}
          footer={false}
          closable={false}
        >
          <div className="row mb-3">
            <div className="col-12" dangerouslySetInnerHTML={{ __html: this.state.allergiesMessage }} />
          </div>
          <div className="modal-footer justify-content-center emrfrm pb-0">
            <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handleAllergyOverrule(e, this.state.deleteObjId)}>Overrule</button>
            <button type="button" className="btn btn-primary px-4 text-uppercase" onClick={(e) => this.handleChangeAllergyDrug(e)}>Change the drug</button>
          </div>
        </Modal>

        {/* For Modal Poly Pharmacy Alert  */}
        <Modal
          title="Poly Pharmacy Alert"
          visible={this.state.showDrugPolyPharmacyModal}
          onCancel={(e) => this.handleModalPopup(e, 'showDrugPolyPharmacyModal')}
          footer={false}
          closable={false}
        >
          <div className="row mb-3">
            <div className="col-12" dangerouslySetInnerHTML={{ __html: this.state.polyPharmacyMessage }} />
          </div>
          <div className="modal-footer justify-content-center emrfrm pb-0">
            <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handlePolyPharmacyNo(e)}>No</button>
            <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.handlePolyPharmacyYes(e)}>Yes</button>
          </div>
        </Modal>

        {/** Document Viewer */}
        <Modal
          title={patient_docments?.length === 1 ? "Document" : "Documents"}
          visible={this.state.viewDocumentModal}
          footer={false}
          width={750}
          onCancel={e => this.handleModalPopup(e, 'viewDocumentModal')}
          style={{ top: 20 }}
        >
          <div className="pdf_slider">
            {
              patient_docments?.length > 0 ? (
                <Carousel arrows dots={false} ref={this.carouselRef} >
                  {
                    patient_docments?.map((file, index) => (
                      file.type === 'img' ?
                        <figure><img className="mr-3" src={file.src} alt='uploaded Doc' /></figure>
                        :
                        <embed className="mr-3" src={file.src + "#toolbar=0"} />
                    ))
                  }

                </Carousel>
              ) : null
            }
          </div>
        </Modal>
        <Modal className="myDrugsInteraction_modal" id="myDrugsInteraction"
            title="Drug Interaction"
            visible={this.state.isModalInteractionVisible}
            onOk={() => this.handleInteractionOk()}
            onCancel={() => this.handleInteractionCancel()}
            footer={false}
            style={{ width: 1000 }}
            zIndex={9999}
          >
            <iframe id="drug_interaction_widget_iframe2" title="interaction" className="cimsiframe" src={`${CIMS_INTERACTION}?drugIds=${this.state.drug_id_Interaction_Modalurl}&referenceId=${this.state.reference_id}&referenceType=${this.state.referenceType}&enterpriseId=${this.state.enterpriseId}&FacilityId=${this.state.FacilityId}&StoreId=${this.state.StoreId}&patientId=${this.state.patientId}&orederSetId=${0}&careProtoColId=${this.state.careProtoColId}&medicineScreen=${'careplanform'}&addedAssessmentList=${this.state.addedAssessmentList}`}></iframe>
            <div className="modal-footer justify-content-center emrfrm pb-0">
              <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handleInteractionCancel(e)}>DON’T PROCEED</button>
              <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.handleInteractionOk(e)}>PROCEED TO ORDER</button>
            </div>
          </Modal>
        {
          this.state.drugsData.length > 0 && this.state.isSelectAllChecked && !this.state.showDrugPolyPharmacyModal &&  !this.state.showDrugAllergyModal?
            <MedicineAlertPopup updateSelectState={this.updateSelect} patient={this.state.patient} templateListmedicine={this.state.drugsData} /> : null
        }
      </>
    )
  }
}


export default CopyToVisit;
