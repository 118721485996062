import React, { Component, Fragment } from 'react';
import { Select, Modal, Form, Button, Radio, Tooltip, Dropdown, Input, notification, Checkbox, AutoComplete, message } from 'antd';
import { MEDICINES_GETALL, SEARCH_DRUGS, DRUGS_CONSTANT, DRUG, PRESCRIPTION_STORE, DRUG_FAVOURITE, CIMS_INFO, CIMS_INTERACTION_EXISTS, CIMS_INTERACTION, REFERENCE_TYPE, PRESCRIPTION_DATA, DELETE_MEDICINE, MYHC_APPTYPE, WOODLANDS, HOSPITALDEFAULTS, LOADER_BLACK, LOADER, HOMECARE_CALL_RECORD_RATING, CIMSALLOW, CIMS_ALLOW_HOSPITALS } from '../../utils/constant';
import 'antd/dist/antd.css';
import LS_SERVICE from '../../utils/localStorage';
import axios from 'axios';
import qs from 'qs';
import debounce from 'lodash/debounce';
import moment, { duration } from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './medicine.scss';
import calendarDatePickerIco from "../../assets/images/ic-calendar.png";
import { jQueryLabels, AntSelectLabels, AntClearLabels, FixedCta, ClearAntList, ClearSelectSelectionItem } from '../../utils/jQueryLabels';
import MedicineGridHelper from '../../utils/MedicineGridHelper';
import { medicineDataEmptyDisableButton } from '../../utils/rxjs-sharing';




const { Option } = Select;
class MedicineTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      viewType: 'full', //template, full
      constants: [],
      drugsData: [],
      eventBased: false,
      patientObsGynaeInfo: {},
      patientInfo: {},
      alreadyPrescribedDrugs: [],
      //constants
      drugForm: {},
      drugDosage: {},
      drugRoute: {},
      drugDaysInWeek: {},
      drugDailyFrequency: {},
      drugSchedules: {},
      drugUrgency: {},
      drugInstructions: {},
      deleteMedicineModal: false,
      deleteAllMedicineOnce: this.props.deleteAllMedicineOnce ? this.props.deleteAllMedicineOnce : false,
      isMedicineGrid: false,
      allMedcineList: {},
      availableDrugRoutes: [],
      drugOptions: [],
      grid_med: {},
      editable_grid: null,
      drug_daily_frequency_repeats: null,
      saveMedicineData: false,
      inputNumberType: '',
      isEditGrid: this.props.editGrid || undefined,
      isModalInfoVisible:false,
      drug_id:0,
      store_id: 0,
      facility_id:  0,
      enterprise_id: 0,
      hospitalId: parseInt(LS_SERVICE.get('slot_hospital_id'))
    }
    this.deleteAllmedicines = this.deleteAllmedicines.bind(this);
    this.handleModalPopup = this.handleModalPopup.bind(this);
    this.handleDeletePopSubmit = this.handleDeletePopSubmit.bind(this);
    //this.handleOnChange = this.handleOnChange.bind(this);
    this.getRouteDropdown = this.getRouteDropdown.bind(this);
    this.getDosageLoadDropdown = this.getDosageLoadDropdown.bind(this);
    // this.onBlurDrugDosage = debounce(this.onBlurDrugDosage, 900);
  }

  optionFormDrugLoad() {
    if (!this.state.constants.drugForm || this.state.constants.drugForm.length === 0) {
      return (<Option value={0}><span>No Result Found</span></Option>)
    }
    return this.state.constants.drugForm.map((obj, i) => {
      this.state.drugForm[obj.id] = obj.description;
      return (<Option key={i} value={obj.id} title={obj.description}>{obj.description}</Option>
      )
    }, this)
  }

  optionDosageLoad() {

    // return this.state.constants.drugDosage.map((obj, i) => {
    //   this.state.drugDosage[obj.id] = obj.description;
    // }, this)
    if (this.state.constants.drugDosage && this.state.constants.drugDosage.length > 0) {
      return this.state.constants.drugDosage.map((obj, i) => {
        this.state.drugDosage[obj.id] = obj.description;
      }, this)
    } else {
      return null;
    }

  }

  optionRouteLoad() {
    if (this.state.drugRoute && this.state.drugRoute.length > 0) {
      return this.state.constants.route.map((obj, i) => {
        this.state.drugRoute[obj.id] = obj.description;
      }, this)
    } else {
      return null;
    }

  }

  optionDaysLoad() {
    return this.state.constants.daysInWeek.map((obj, i) => {
      this.state.drugDaysInWeek[obj.id] = obj.description;
    }, this)
  }

  optionDailyFrequencyLoad() {
    return this.state.constants.dailyFrequency.map((obj, i) => {
      this.state.drugDailyFrequency[obj.id] = obj.description;
    }, this)
  }

  optionSchedulesLoad() {
    this.schedulesName = [];
    return this.state.constants.drugSchedules.map((obj, i) => {
      this.state.drugSchedules[obj.id] = obj.description;
      this.schedulesName.push(obj.description)
    }, this)
  }

  optionPriorityLoad() {
    return this.state.constants.drugUrgency.map((obj, i) => {
      this.state.drugUrgency[obj.id] = obj.description;
    }, this)
  }

  optionInstructionLoad() {
    return this.state.constants.drugInstructions.map((obj, i) => {
      this.state.drugInstructions[obj.id] = obj.description;
    }, this)
  }


  componentDidMount() {
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get('token');
  }

  componentDidUpdate(prevProps, prevState) {
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    return {
      drugsData: nextProps.drugsData,
      viewType: nextProps.viewType,
      eventBased: nextProps.eventBased,
      patientObsGynaeInfo: nextProps.patientObsGynaeInfo,
      patientInfo: nextProps.patientInfo,
      alreadyPrescribedDrugs: nextProps.alreadyPrescribedDrugs,
      constants: nextProps.constants
    }
  }

  handleEditDelete = async (e, modal, data, action, idx) => {

    if (this.props.isPracticing === false) {
      this.props.practiceModal('isPracticingModal');
      return;
    }
    let showtapper = await this.checkDataIsTapper(idx);
    this.props.handlePopUp(e, modal, data, action, idx, showtapper);
  }

  updateStartDate = (start_data, this_drug_id) => {
    var medicine_data = []
    start_data = moment(start_data).format('YYYY-MM-DD');
    for (var drugs of this.state.drugsData) {
      if (drugs.drug_id == this_drug_id) {
        medicine_data.push(drugs)
      }
      else {
        medicine_data.push(drugs)
      }
    }
    this.props.updateData(medicine_data, start_data, this_drug_id)
  }

  deleteAllmedicines() {
    this.setState({
      deleteMedicineModal: true
    })
  }

  handleModalPopup() {
    this.setState({
      deleteMedicineModal: false
    })
  }

  handleDeletePopSubmit() {
    let all_prescription_id = []
    this.state.drugsData.map(obj => {
      all_prescription_id.push(obj.prescription_drug_id)
    })
    this.props.handleDeleteAll(all_prescription_id);
    this.setState({
      deleteMedicineModal: false
    })
  }

  handleOnChange = async (value, optionInfo) => {

    const { allMedcineList } = this.state;
    let medData = allMedcineList;
    let addMedsTrue = allMedcineList.filter((med) => med.prescription_drug_id == optionInfo.medData.prescription_drug_id);
    let drug_id = optionInfo.medData.drug_id;
    // this.setState({ grid_med: optionInfo.medData });
    if (addMedsTrue.length == 0) {
      medData.push(optionInfo.medData);
      await this.setState({
        allMedcineList: medData
      })

      await axios.post(DRUG, qs.stringify({
        id: drug_id
      })).then(success => {
        const response = success.data.data.result;
        let availableDrugRoutesTemp = response.drug_route_for_form;
        let availableDrugRoutes = [];
        if (availableDrugRoutesTemp && availableDrugRoutesTemp.length == 0 || availableDrugRoutesTemp == null) {
          availableDrugRoutes = this.state.constants.route;
        }
        else {
          availableDrugRoutesTemp.map((v, k) => {
            availableDrugRoutes.push({ id: parseInt(v.routeId), description: v.long })
          })
        }
        // this.setState({ availableDrugRoutes: availableDrugRoutes });
        // let availableDrugRoutes = this.state.availableDrugRoutes;
        var routeForForms = this.state.constants.drugRouteForForm.filter((item, j) => item.formId == response.form_id?.id) || [];
        let allDrugRoute = this.state.constants.route;
        if (availableDrugRoutes.length == 0 || availableDrugRoutes == null) {
          availableDrugRoutes.length = []
          for (var rff of routeForForms) {
            availableDrugRoutes.push({
              id: rff.routeId, description: rff.route_descriptions
            })
          }
        }
        let drugOptions = availableDrugRoutes.length == 0 || availableDrugRoutes == null ? allDrugRoute : availableDrugRoutes
        this.setState({
          drugOptions
        })
      }).catch(err => console.log(err))
    }
  }

  async checkDataIsTapper(index) {
    let data_is = this.state.drugsData;
    let index_is = parseInt(index);
    let index_length = parseInt(data_is?.length);
    let finaldata = [...data_is]?.sort((a, b) => a.drug_id - b.drug_id);
    let on_samll = false;
    let on_big = false;
    let show_taper = false;
    if (index_is != 0) {
      if (finaldata[index_is - 1].drug_id == finaldata[index_is].drug_id) {
        on_samll = true
      }
    }
    if (index_is + 1 != index_length) {
      if (finaldata[index_is + 1].drug_id == finaldata[index_is].drug_id) {
        on_big = true
      }
    }
    if (on_samll || on_big) {
      show_taper = true;
    }
    console.log("show_taper", show_taper)
    return show_taper;
  }

  getRouteDropdown(data) {
    const { allMedcineList } = this.state;
    if (!allMedcineList?.hasOwnProperty(data.drug_id)) {
      return;
    }
    const { constants, drugOptions, grid_med } = this.state;
    let drug_id = data.drug_id;

    let availableDrugRoutesTemp = allMedcineList?.[data.drug_id].drug_route_for_form;
    let availableDrugRoutes = [];
    if (availableDrugRoutesTemp && availableDrugRoutesTemp.length == 0 || availableDrugRoutesTemp == null) {
      availableDrugRoutes = this.state.constants.route;
    }
    else {
      availableDrugRoutesTemp.map((v, k) => {
        availableDrugRoutes.push({ id: parseInt(v.routeId), description: v.long })
      })
    }
    var routeForForms = constants.drugRouteForForm.filter((item, j) => item.formId == this.state.allMedcineList?.med?.[data.drug_id].form_id?.id) || [];
    let allDrugRoute = constants.route;
    if (availableDrugRoutes.length == 0 || availableDrugRoutes == null) {
      availableDrugRoutes.length = []
      for (var rff of routeForForms) {
        availableDrugRoutes.push({
          id: rff.routeId, description: rff.route_descriptions
        })
      }
    }
    let drugOptionsRoute = availableDrugRoutes.length == 0 || availableDrugRoutes == null ? allDrugRoute : availableDrugRoutes;
    let oraldefaultValue = this.state.constants.route.filter((data1, i) => data1.id == data.route ? data1.description : null);
    if (drugOptionsRoute.length > 0) {
      return (<Select defaultOpen={true} showSearch placement="bottomLeft" className={oraldefaultValue[0]?.description != undefined || '' ? "drug-input drug-Route w-100" : "drug-input drug-Route custom-pink w-100"}
        filterOption={(input, option) => {
          return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
        }} style={{ width: 80 }} defaultValue={oraldefaultValue[0]?.description && oraldefaultValue[0]?.description != '' ? oraldefaultValue[0]?.description : null} onChange={(value, optionInfo) => this.onChangeDrugRoute(value, optionInfo)}>
        {drugOptionsRoute.map((data1, i) => {
          return <Option medData={data} value={data1.id} title={data1.description} disabled={data1.description == oraldefaultValue[0]?.description}>{data1.description}</Option>
        })}
      </Select>
      )
    }
  }

  onChangeDrugRoute = (val, optionInfo) => {
    const { drugsData } = this.state;
    let moedifiedPrescriptionDrugId = optionInfo.medData.prescription_drug_id;
    let data = drugsData.filter((data, i) => data.prescription_drug_id == moedifiedPrescriptionDrugId ? data.route = val : null);
    this.saveMedicineData(data);
  }

  saveMedicineData = async (data, type = null) => {
    if (data?.length == 0) {
      return;
    }
    data[0].selected = true;
    const PARAMS = {
      reference_type: REFERENCE_TYPE,
      reference_id: LS_SERVICE.get('call_patient_qms_token_id'),
      patient_id: LS_SERVICE.get('call_patient_id'),
      medicine_data: data,
    };

    await axios.post(PRESCRIPTION_STORE, qs.stringify(PARAMS))
      .then(success => {
        debugger;
        notification.success({
          message: ' Medicine data updated successfully',
          placement: 'topRight'
        });
        if (type == null) {
          this.setState({ editable_grid: null })
          this.setState({ inputNumberType: '' })
        }
        // if(LS_SERVICE.get('soap_type')=='single'){
        this.props.updateMedicineData();
        // }
      }).catch(err => {
        this.setState({ inputNumberType: '' })
        notification.error({
          message: 'Failed to update medicine data',
          placement: 'topRight'
        });
        console.log(err);
      })
  }


  getDailyFrequencyDropdown(data, frequecyDefaultValue) {
    let { allMedcineList, constants } = this.state;

    if (!allMedcineList.hasOwnProperty(data.drug_id)) {
      return;
    }

    if (constants.dailyFrequency.length > 0) {
      return (<Select showSearch defaultOpen={true} className="w-100"
        placement="bottomLeft" filterOption={(input, option) => {
          return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
        }} style={{ width: 100 }} defaultValue={frequecyDefaultValue} onChange={(value, optionInfo) => this.onSelectDailyFrequencyChange(value, optionInfo)}>
        {constants.dailyFrequency.map((data1, i) => {
          return <Option key={i} medData={data} value={data1.id} disabled={frequecyDefaultValue == data1.description} dataSchedule={data1?.schedule} dataid={data1} title={data1.description}>{data1.description}</Option>
        })}
      </Select>
      )
    }
  }



  onSelectDailyFrequencyChange = (val, props) => {
    const { drugsData } = this.state;

    if (props.dataid == undefined) {
      return;
    }
    var day_interval = props.dataid.interval;
    day_interval = day_interval == "weeks" ? 7 : (day_interval == 'months' ? 30 : (day_interval == 'years' ? 365 : 1));
    var times_correction = props.dataid.total_times;
    var dosage_per_day = props.dataid.total_repeats;
    let start_date;
    // var start_date = this.state.start_date;
    // if (!start_date && start_date != undefined) {
    //   if (this.state.editData != null && this.state.editData.start_date != null)
    //     start_date = this.state.editData.start_date
    //   else if (this.state.editData != null && this.state.editData.start_date == null && this.state.editData.previousEndDate != null && this.state.editData.taper_row)
    //     start_date = moment(this.state.editData.previousEndDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');
    //   else
    //     start_date = moment().format('YYYY-MM-DD');
    // }
    if (times_correction == 0) {
      start_date = moment().format('YYYY-MM-DD');
      // if (!this.state.isModalTaperDoseVisible)
      //   this.setState({ disableNumberOfDays: false })
      // else
      //   this.setState({ disableNumberOfDaysTaper: true })
    }
    else {
      if (start_date == null) {
        start_date = moment().format('YYYY-MM-DD');
      }

      // if (!this.state.isModalTaperDoseVisible)
      //   this.setState({ disableNumberOfDays: false })
      // else
      //   this.setState({ disableNumberOfDaysTaper: false })
    }
    let moedifiedPrescriptionDrugId = props.medData.prescription_drug_id;

    let data = drugsData.filter((data, i) => {
      if (data.prescription_drug_id == moedifiedPrescriptionDrugId) {
        data.start_date = start_date;
        data.schedules = props.dataSchedule.map(function (obj) { return obj.id });
        data.schedule_name = props.dataSchedule.map(function (obj) { return obj.description }).toString().replace(/,/g, '-');
        data.daily_frequency = props.dataid.id;
        data.number_of_days_type = day_interval === 1 ? 'Days' : day_interval === 7 ? 'Weeks' : 'Months';
        return data;
      }
    });
    this.setState({
      drug_daily_frequency_repeats: day_interval != 1 ? 1 : parseInt(dosage_per_day),
    })
    // this.setState({
    //   drug_start_date: start_date,
    //   drug_schedules: props.dataSchedule.map(function (obj) { return obj.id }),
    //   drug_schedule_name: props.dataSchedule.map(function (obj) { return obj.description }).toString().replace(/,/g, '-'),
    //   drug_daily_frequency: props.dataid.id,
    //   
    //   drug_duration_time: day_interval === 1 ? 'Days' : day_interval === 7 ? 'Weeks' : 'Months'
    // }, () => { this.updateQuantity(); this.updateEndDate(); this.checkDrugSaveBtn(); })

    // AntSelectLabels();
    this.updateEndDate(data)

  }

  updateEndDate = async (data, type = null) => {
    let drug_number_of_days, drug_daily_frequency, drug_start_date, drug_duration_time, drug_duration_time_taper;
    drug_start_date = data[0].start_date;
    var day_interval;
    drug_number_of_days = data[0].number_of_days;
    drug_daily_frequency = data[0].daily_frequency;
    day_interval = (data[0].number_of_days_type && data[0].number_of_days_type != null) ? data[0].number_of_days_type.toLowerCase() : '';


    if (drug_number_of_days && drug_daily_frequency && drug_start_date) {
      var number_of_days = parseInt(drug_number_of_days);
      var start_date = drug_start_date;
      var end_date = null;
      let frequencyObjKey = Object.keys(this.state.constants.dailyFrequency).find(key => this.state.constants.dailyFrequency[key].id === drug_daily_frequency)
      frequencyObjKey = this.state.constants.dailyFrequency[parseInt(frequencyObjKey)]
      day_interval = day_interval == "weeks" ? 7 : (day_interval == 'months' ? 30 : (day_interval == 'years' ? 365 : 1));
      var times_correction = frequencyObjKey.total_times;
      var dosage_per_day = frequencyObjKey.total_repeats;
      if (typeof number_of_days != 'undefined' && !isNaN(number_of_days) && typeof start_date != 'undefined' && start_date != '' && times_correction != 0) {
        if (day_interval != 1)
          // number_of_days = Math.round(number_of_days / dosage_per_day);
          //  number_of_days = number_of_days * day_interval * times_correction;
          number_of_days = number_of_days * day_interval;
        end_date = moment(start_date, 'YYYY-MM-DD').add(number_of_days - 1, 'days').format('YYYY-MM-DD');
      }
      if (times_correction == 0) {
        end_date = moment(start_date, 'YYYY-MM-DD').add(number_of_days - 1, 'days').format('YYYY-MM-DD');
        data[0].start_date = start_date;
        data[0].end_date = end_date;
        // await this.setState({ drug_start_date: start_date, drug_end_date: end_date }, () => { this.checkDrugSaveBtn() });
      } else {
        data[0].start_date = start_date;
        data[0].end_date = end_date;
        // await this.setState({ drug_end_date: end_date }, () => { this.checkDrugSaveBtn() });
      }
    }

    else if (drug_start_date == null) {
      data[0].end_date = null;
    }
    this.saveMedicineData(data, type);

  }


  getDailyschedulesDropdown(data, schduledDefaultValue) {
    let { allMedcineList, constants } = this.state;

    if (data.schedules !== undefined && data.schedules !== null && data.schedules != "") {
      data.schedules = data.schedules.map(data => parseInt(data))
    }
    let drug_schedules = (data.active == 'Y' && data.schedules !== null) ? data.schedules : (data.schedules && data.schedules !== null) ? data.schedules : [];
    if (!allMedcineList.hasOwnProperty(data.drug_id)) {
      return;
    }
    if (constants.drugSchedules.length > 0) {
      return (<Select allowClear placement="bottomLeft" defaultValue={drug_schedules} className="w-100" mode="multiple"
        filterOption={(input, option) => {
          return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
        }}
        onChange={(value, optionInfo) => this.selectSchedulesChange(value, optionInfo)}>
        {constants.drugSchedules.map((obj, i) => {
          return <Option key={i} medData={data} value={obj.id} title={obj.description}>{obj.description}</Option>
        })}
      </Select>
      )
    }
  }

  selectSchedulesChange = (val, props) => {

    this.setState({ saveMedicineData: this.state.drug_daily_frequency_repeats === val.length ? true : false })
    const { drugsData } = this.state;
    let moedifiedPrescriptionDrugId = props[0]?.medData?.prescription_drug_id;
    let data = drugsData.filter((data, i) => { return data.prescription_drug_id == moedifiedPrescriptionDrugId })
    this.checkDrugSave(data, val, props);

  }

  checkEmptyValue(val) {
    return typeof val == 'undefined' || val == null || val == '' ? true : false;
  }

  checkEmptyMultiSelectValue(val) {
    return typeof val == 'undefined' || val == null || val.length == 0 ? true : false;
  }


  checkDrugSave = async (data, val, props, type) => {
    let medicineData = data[0];
    let disableBtn = false;
    if (typeof medicineData != 'undefined') {
      let drugId = medicineData?.drug_id;
      let drugForm = medicineData?.drug_form;

      let strength = medicineData?.strength;
      let dosage = medicineData?.dosage;
      let route = medicineData?.route;
      let drugFrequency = medicineData?.drug_frequency;
      let drugDaysInWeek = medicineData?.days_in_week;
      let dailyFrequency = medicineData?.daily_frequency;
      let schedule = medicineData?.schedules;
      let urgency = medicineData?.urgency;
      let event = medicineData?.event;
      let lmp_event_duration = medicineData?.drug_lmp_event_duration;
      let lmp_event_duration_type = medicineData?.drug_lmp_event_duration_type;
      let numberOfDays = medicineData?.number_of_days;
      let startDate = medicineData?.drug_start_date;
      let endDate = medicineData?.drug_end_date;
      let medicineFormValidate = (medicineData?.active === 'N') ? false : true;
      let number_of_days_type = medicineData?.number_of_days_type;
      if (type == 'Duration') {
        val = schedule;
        number_of_days_type = props.children;
      }
      // if (!this.state.addTaperDose && this.state.isModalTaperDoseVisible) {
      //   dosage = this.state.drug_dosage_taper;
      //   route = this.state.drug_route_taper;
      //   strength = this.state.drug_strength_taper;
      //   numberOfDays = this.state.drug_number_of_days_taper;
      //   drugFrequency = this.state.drug_frequency_taper;
      //   drugDaysInWeek = this.state.drug_days_in_week_taper;
      //   dailyFrequency = this.state.drug_daily_frequency_taper;
      //   schedule = this.state.drug_schedules_taper;
      // }


      //In case of empty value

      if (this.checkEmptyValue(drugId)) disableBtn = true;
      // if (this.checkEmptyValue(drugForm)) disableBtn = true;
      // if (this.checkEmptyValue(strength)) disableBtn = true;
      // if (this.checkEmptyValue(route)) disableBtn = true;
      // if (this.checkEmptyValue(drugFrequency)) disableBtn = true;




      // if (this.checkEmptyValue(dosage)) disableBtn = true;
      // if (this.state.dose_for_schedule_applicable == 'Y') {
      //   if (this.state.drug_schedules.includes(1)) {
      //     if (this.checkEmptyValue(this.state.dosageDropDownMorning)) disableBtn = true;
      //     if (this.checkEmptyValue(this.state.drug_instruction_morning)) disableBtn = true;
      //   }
      //   if (this.state.drug_schedules.includes(2)) {
      //     if (this.checkEmptyValue(this.state.dosageDropDownAfternoon)) disableBtn = true;
      //     if (this.checkEmptyValue(this.state.drug_instruction_afternoon)) disableBtn = true;
      //   }
      //   if (this.state.drug_schedules.includes(3)) {
      //     if (this.checkEmptyValue(this.state.dosageDropDownEvening)) disableBtn = true;
      //     if (this.checkEmptyValue(this.state.drug_instruction_evening)) disableBtn = true;
      //   }
      //   if (this.state.drug_schedules.includes(4)) {
      //     if (this.checkEmptyValue(this.state.dosageDropDownNight)) disableBtn = true;
      //     if (this.checkEmptyValue(this.state.drug_instruction_night)) disableBtn = true;
      //   }
      // }

      // if (this.state.eventBased == true) {
      //   if (this.checkEmptyValue(event)) disableBtn = true;
      //   if (event == 1 || event == 2) {
      //     if (this.checkEmptyValue(lmp_event_duration)) disableBtn = true;
      //     if (this.checkEmptyValue(lmp_event_duration_type)) disableBtn = true;
      //   }
      // }


      if (drugFrequency != 3) {
        let day_interval = null;
        let times_correction = null;
        let dosage_per_day = null;
        if (this.checkEmptyValue(dailyFrequency)) disableBtn = true;
        else {
          let frequencyObjKey = Object.keys(this.state.constants.dailyFrequency).find(key => this.state.constants.dailyFrequency[key].id === dailyFrequency)
          frequencyObjKey = this.state.constants.dailyFrequency[parseInt(frequencyObjKey)];
          // day_interval = frequencyObjKey.interval;
          day_interval = (number_of_days_type && number_of_days_type != null) ? number_of_days_type.toLowerCase() : '';
          day_interval = day_interval == "weeks" ? 7 : (day_interval == 'months' ? 30 : (day_interval == 'years' ? 365 : 1));
          // if (this.state.isModalTaperDoseVisible) {
          //   day_interval = this.state.drug_duration_time_taper && this.state.drug_duration_time_taper != null ? this.state.drug_duration_time_taper.toLowerCase() : '';
          //   day_interval = day_interval == "weeks" ? 7 : (day_interval == 'months' ? 30 : (day_interval == 'years' ? 365 : 1));
          // }
          dosage_per_day = frequencyObjKey.total_repeats
          times_correction = frequencyObjKey.total_times;
          if (val && val.length > 0) {
            if ((dosage_per_day <= 4 && (dailyFrequency != 9 && dailyFrequency != 8)) && val && val.length != dosage_per_day)
              disableBtn = true;
            else if ((dosage_per_day <= 4 && (dailyFrequency == 9 || dailyFrequency == 8)) && val && val.length != 1)
              disableBtn = true;
            else if (dosage_per_day > 4 && val && val.length != 4)
              disableBtn = true;
          }
          if ((dailyFrequency == 9 || dailyFrequency == 11) && (day_interval < 7)) {
            disableBtn = true;
          }
          else if ((dailyFrequency == 8 || dailyFrequency == 10) && (day_interval < 30)) {
            disableBtn = true;
          }
        }

        if (drugFrequency == 2 && this.checkEmptyMultiSelectValue(drugDaysInWeek)) disableBtn = true;
        // if (this.checkEmptyMultiSelectValue(schedule)) disableBtn = true;
        if (drugDaysInWeek?.length > 0) disableBtn = false;
        if (this.checkEmptyValue(urgency)) disableBtn = true;
        // if (this.checkEmptyValue(numberOfDays)) disableBtn = true;

        // if (this.props.formType != 'template' && times_correction != null && times_correction != 0) {
        //   if (this.checkEmptyValue(startDate)) disableBtn = true;
        //   // if (this.checkEmptyValue(endDate)) disableBtn = true;
        // }
      }

      await this.setState({ saveMedicineData: disableBtn })

      if ((drugFrequency == 1 || drugFrequency == 2) && !medicineFormValidate && numberOfDays != null) {
        this.setState({ saveMedicineData: medicineFormValidate })
      }

      if ((drugFrequency == 1 || drugFrequency == 2) && !medicineFormValidate && (numberOfDays == null || numberOfDays == "")) {
        this.setState({ saveMedicineData: true })
      }

      if (drugFrequency == 3 && !medicineFormValidate) {
        this.setState({ saveMedicineData: medicineFormValidate })
      }

      if (!medicineFormValidate) {
        this.setState({ saveMedicineData: false })
      }

    }
    if (type == 'Duration') {
      return this.state.saveMedicineData;
    }
    const { drugsData, grid_med } = this.state;
    let moedifiedPrescriptionDrugId = grid_med.prescription_drug_id;
    if (this.state.saveMedicineData || val.length == 0) {
      notification.error({
        message: 'Please select specific frequency',
        placement: 'topRight'
      });

    } else {
      let data = drugsData.filter((data, i) => {
        if (data.prescription_drug_id == moedifiedPrescriptionDrugId) {
          // data.start_date=start_date;
          data.schedules = val;
          data.schedule_name = props.length != 0 ? props.map(function (obj) { return obj.title }).toString().replace(/,/g, '-') : '';
          // data.daily_frequency=props.dataid.id;
          // data.number_of_days_type= day_interval === 1 ? 'Days' : day_interval === 7 ? 'Weeks' : 'Months';
          return data;
        }
      });
      this.saveMedicineData(data);
    }


    // let disableScheduleForDoseBtn = false;
    // if (this.state.drug_schedules.includes(1)) {
    //   if (this.checkEmptyValue(this.state.dosageDropDownMorning)) disableScheduleForDoseBtn = true;
    //   if (this.checkEmptyValue(this.state.drug_instruction_morning)) disableScheduleForDoseBtn = true;
    // }
    // if (this.state.drug_schedules.includes(2)) {
    //   if (this.checkEmptyValue(this.state.dosageDropDownAfternoon)) disableScheduleForDoseBtn = true;
    //   if (this.checkEmptyValue(this.state.drug_instruction_afternoon)) disableScheduleForDoseBtn = true;
    // }
    // if (this.state.drug_schedules.includes(3)) {
    //   if (this.checkEmptyValue(this.state.dosageDropDownEvening)) disableScheduleForDoseBtn = true;
    //   if (this.checkEmptyValue(this.state.drug_instruction_evening)) disableScheduleForDoseBtn = true;
    // }
    // if (this.state.drug_schedules.includes(4)) {
    //   if (this.checkEmptyValue(this.state.dosageDropDownNight)) disableScheduleForDoseBtn = true;
    //   if (this.checkEmptyValue(this.state.drug_instruction_night)) disableScheduleForDoseBtn = true;
    // }
    // this.setState({ AddDoseForScheduleButton: disableScheduleForDoseBtn })

    // var overlap_status = this.checkRangeOverlap(this.state.correction_dose_validate) || false

    // this.setState({ AddCorrectionDoseButton: overlap_status })

    // if ((drugFrequency == 1 || drugFrequency == 2) && !this.state.medicineFormValidate && this.state.drug_number_of_days != null) {
    //   this.setState({ AddMedicineButton: this.state.medicineFormValidate })
    // }

    // if ((drugFrequency == 1 || drugFrequency == 2) && !this.state.medicineFormValidate && (this.state.drug_number_of_days == null || this.state.drug_number_of_days == "")) {
    //   this.setState({ AddMedicineButton: true })
    // }

    // if (drugFrequency == 3 && !this.state.medicineFormValidate) {
    //   this.setState({ AddMedicineButton: this.state.medicineFormValidate })
    // }

    // if (!this.state.medicineFormValidate) {
    //   this.setState({ AddMedicineButton: false })
    // }

  }

  getDosageLoadDropdownSchduled(data, sch) {
    let { allMedcineList, constants } = this.state;
    if (!allMedcineList.hasOwnProperty(data.drug_id)) {
      return;
    }
    let dosageVal;
    let dosageFormText;
    if (sch == 1) {
      dosageVal = data.dosage_value_morning;
      dosageFormText = data.dosage_form_text;
    } else if (sch == 2) {
      dosageVal = data.dosage_value_afternoon;
      dosageFormText = data.dosage_form_text;
    } else if (sch == 3) {
      dosageVal = data.dosage_value_evening;
      dosageFormText = data.dosage_form_text;
    } else if (sch == 4) {
      dosageVal = data.dosage_value_night;
      dosageFormText = data.dosage_form_text;
    }
    return (<React.Fragment><><div><p> <Select defaultOpen={true} showSearch placement="bottomLeft" className='w-100'
      defaultValue={dosageVal} onChange={(value, optionInfo) => this.handleDosageSchduled(value, optionInfo, sch)}>
      {this.state.constants.drugDosage.map((obj, i) => {
        return <Option key={i} medData={data} disabled={dosageVal == obj.description} value={obj.description} title={obj.description}>{obj.description}</Option>
      })}
    </Select> </p><p>{dosageFormText}</p></div></>
    </React.Fragment>)
  }


  handleDosageSchduled(val, optionInfo, sch) {
    const { drugsData, drugDosage } = this.state;
    let dosageSyntax = sch == 1 ? 'dosage_value_morning' : sch == 2 ? 'dosage_value_afternoon' : sch == 3 ? 'dosage_value_evening' : 'dosage_value_night';
    var dosage = Object.keys(drugDosage).find(key => this.state.drugDosage[key] == val);
    let moedifiedPrescriptionDrugId = optionInfo.medData.prescription_drug_id;

    let data = drugsData.filter((data, i) => data.prescription_drug_id == moedifiedPrescriptionDrugId ? (data[dosageSyntax] = parseFloat(val), data.dosage = dosage) : null);
    data[0].i = 2;
    data[0].dose_for_schedule_applicable = 'Y';
    this.saveMedicineData(data);
  }


  getDosageLoadDropdown(data) {
    let { allMedcineList, constants } = this.state;
    if (!allMedcineList.hasOwnProperty(data.drug_id)) {
      return;
    }

    let dosageVal;
    let dosageFormText;

    if (data.dosage && data.dosage < 17) {
      this.state.constants.drugDosage.map(
        function (data1, n) {
          if (data.dosage_form_text === null) {
            data.dosage_form_text = '';
          }
          if (parseInt(data1.id) == parseInt(data.dosage)) {
            dosageVal = data1.description;
            dosageFormText = data.dosage_form_text;
          } else {
            return null;
          }
        })
    } else {
      if (data.dosage_value != undefined) {
        dosageVal = data.dosage_value;
        dosageFormText = data.dosage_form_text;
      } else {
        dosageFormText = data.dosage_form_text;
      }
    }
    return (<React.Fragment><><div><p>
      <Tooltip placement="topLeft" title={dosageVal == undefined ? "Dosage is mandatory" : null}> <AutoComplete defaultOpen
        onBlur={(event) => this.onBlurDrugDosage(event, data)} filterOption={(inputValue, option) => typeof option.value === 'string' && option.value.indexOf(inputValue) !== -1}
        showSearch placement="bottomLeft" placeholder="Dosage" className={dosageVal == undefined || '' ? "drug-input drug-Dosage custom-pink" : "drug-input drug-Dosage"}
        style={{ width: 80 }} defaultValue={dosageVal} onSelect={(value, optionInfo) => this.handleOnDosageValue(value, optionInfo)}>
        {this.state.constants.drugDosage.map((obj, i) => {
          // this.state.drugDosage[obj.id] = "" + obj.description;
          return <Option key={i} medData={data} value={obj.description} disabled={dosageVal == obj.description} title={obj.description}>{obj.description}</Option>
        })}
      </AutoComplete></Tooltip>
    </p><p>{dosageFormText}</p></div></>
    </React.Fragment>)
  }

  onBlurDrugDosage = (event, data) => {
    var val = event.target.value;
    let CheckIsAvailable = this.state.constants.drugDosage.filter((obj) => val == obj.description)
    if (CheckIsAvailable.length > 0 || val == '') {
      return
    }
    // var drugDosageValue = this.getDosageUpdate(event)
    if (val === "" || /^[1-9]\d*(?:\.\d{0,2})?$/.test(val)) {
      var dosage = Object.keys(this.state.drugDosage).find(key => this.state.drugDosage[key] == val);
      var DRUGSConstants = this.state.constants;
      var drugDosage = this.state.drugDosage;
      var highestDosageId = Math.max(...Object.keys(drugDosage).map(Number))

      var drugDosageValue = null;
      if (typeof dosage == 'undefined' && val != null && val != '') {
        this.state.constants.drugDosage.map((obj, i) => {
          highestDosageId = highestDosageId == null || highestDosageId < obj.id ? obj.id : highestDosageId;
        })
        drugDosage[parseInt(highestDosageId) + 1] = "" + val;
        DRUGSConstants.drugDosage.push({ id: parseInt(highestDosageId) + 1, description: val });
        drugDosageValue = parseInt(highestDosageId) + 1;
        this.setState({ drugDosage, DRUGSConstants });
      }
      else {
        drugDosageValue = dosage
      }
      let optionInfo = {};
      optionInfo.medData = data;
      this.handleOnDosageValue(val, optionInfo)

    } else {
      notification.error({
        message: 'Please type specific dosage'
      })
    }
    // return drugDosageValue;
  }


  // onSearchDrugDosage = (val) => {
  //   if (val === "" || /^[1-9]\d*(?:\.\d{0,2})?$/.test(val)) {
  //     this.setState({ dosageDropDown: val })
  //   }

  // }

  handleOnDosageValue = (val, optionInfo) => {
    const { drugsData, drugDosage } = this.state;
    var dosage = Object.keys(drugDosage).find(key => this.state.drugDosage[key] == val);
    let moedifiedPrescriptionDrugId = optionInfo.medData.prescription_drug_id;
    let data = drugsData.filter((data, i) => data.prescription_drug_id == moedifiedPrescriptionDrugId ? (data.dosage_value = val, data.dosage = dosage) : null);

    this.saveMedicineData(data);

  }

  getInstructionDropdownSchduled(data, sch) {
    let { allMedcineList, constants } = this.state;

    if (!allMedcineList.hasOwnProperty(data.drug_id)) {
      return
    }
    let Instruction;
    let InstructionOptionVal;
    if (sch == 1) {
      Instruction = constants.drugMorningInstructions.filter((data1, i) => data1.id == data.instruction_morning ? data1.description : null);
      InstructionOptionVal = constants.drugMorningInstructions;
    } else if (sch == 2) {
      Instruction = constants.drugAfternoonInstructions.filter((data1, i) => data1.id == data.instruction_afternoon ? data1.description : null);
      InstructionOptionVal = constants.drugAfternoonInstructions;
    } else if (sch == 3) {
      Instruction = constants.drugEveningInstructions.filter((data1, i) => data1.id == data.instruction_evening ? data1.description : null);
      InstructionOptionVal = constants.drugEveningInstructions;
    } else if (sch == 4) {
      Instruction = constants.drugNightInstructions.filter((data1, i) => data1.id == data.instruction_night ? data1.description : null);
      InstructionOptionVal = constants.drugNightInstructions;
    } else {
      Instruction = null;
      InstructionOptionVal = null;
    }
    if (constants.drugSchedules.length > 0) {
      return (<Select showSearch={false} defaultOpen={true} className="w-100"
        placement="bottomLeft" 
        defaultValue={Instruction[0]?.description} onChange={(value, optionInfo) => this.changeInstrutionSchduled(value, optionInfo, sch)}>
        {InstructionOptionVal.map((obj, i) => {
          return <Option key={i} medData={data} disabled={Instruction[0]?.description == obj.description} value={obj.id} title={obj.description}>{obj.description}</Option>
        })}
      </Select>
      )
    }
  }

  changeInstrutionSchduled(val, optionInfo, sch) {
    const { drugsData } = this.state;
    let instructionSyntax = sch == 1 ? 'instruction_morning' : sch == 2 ? 'instruction_afternoon' : sch == 3 ? 'instruction_evening' : 'instruction_night';
    let moedifiedPrescriptionDrugId = optionInfo.medData.prescription_drug_id;
    let data = drugsData.filter((data, i) => data.prescription_drug_id == moedifiedPrescriptionDrugId ? data[instructionSyntax] = val : null);
    // data[0].i = 2;
    data[0].dose_for_schedule_applicable = 'Y';
    this.saveMedicineData(data);

  }

  getInstructionDropdown(data) {
    let { allMedcineList, constants } = this.state;

    if (!allMedcineList.hasOwnProperty(data.drug_id)) {
      return;
    }

    let instructionDefault = constants.drugInstructions.filter((data1, i) => data1.id == data.instruction ? data1.description : null)
    if (constants.drugSchedules.length > 0) {
      return (<Select defaultOpen={true} showSearch={false} placement="bottomLeft" className='w-100'
        defaultValue={instructionDefault[0]?.description} onChange={(value, optionInfo) => this.handleOnChangeInstruction(value, optionInfo)}>
        {constants.drugInstructions.map((obj, i) => {
          return <Option key={i} medData={data} disabled={instructionDefault[0]?.description == obj.description} value={obj.id} title={obj.description}>{obj.description}</Option>
        })}
      </Select>
      )
    }
  }


  handleOnChangeInstruction = (val, optionInfo) => {
    const { drugsData } = this.state;
    let moedifiedPrescriptionDrugId = optionInfo.medData.prescription_drug_id;
    let data = drugsData.filter((data, i) => data.prescription_drug_id == moedifiedPrescriptionDrugId ? data.instruction = val.toString() : null);
    this.saveMedicineData(data);

  }

  getDurationDropdown(data) {

    let { allMedcineList, constants } = this.state;

    if (!allMedcineList.hasOwnProperty(data.drug_id)) {
      return;
    }
    let numberofDays = data.number_of_days;
    let NumberOfDaysType = data.number_of_days_type;
    return (<React.Fragment><><div><p> <Input onBlur={(event) => this.onBlurNumberOfDays(event, data)} defaultValue={numberofDays} maxLength={3} placeholder="No of Days" rules={[{ type: 'number' }]} onChange={(e, data) => { this.onChangeNumberOfDays(e, data) }} /> </p>
      <p>
        <Select defaultValue={NumberOfDaysType} id="drug_duration_time" name="drug_duration_time" placeholder="Duration" onSelect={(value, optionInfo) => this.selectDurationChange(value, optionInfo, numberofDays)}
        // className="input-group-addons med_addon_height"
        // value={this.state.drug_duration_time} 
        >
          {this.optionDurationTypeLoad(data)}
        </Select>
      </p>
    </div></></React.Fragment>)
  }

  onBlurNumberOfDays(event, data) {
    let val = event.target.val;
    const { inputNumberType } = this.state
    if (inputNumberType == '' && val == '') {
      notification.error({
        message: "Please type correct number of days"
      })
      return;
    }
    data.number_of_days = inputNumberType == '' ? val : inputNumberType;
    let MedicineData = [];
    MedicineData.push(data)
    this.updateEndDate(MedicineData, 'numberofDay')

    // this.saveMedicineData(MedicineData, 'numberofDay');
  }

  optionDurationTypeLoad(data) {
    return (
      <>
        <Option medData={data} key={1} value="Days" title="Days">Days</Option>
        <Option medData={data} key={2} value="Weeks" title="Weeks">Weeks</Option>
        <Option medData={data} key={3} value="Months" title="Months">Months</Option>
        <Option medData={data} key={4} value="Years" title="Years">Years</Option>
      </>
    )
  }


  onChangeNumberOfDays = (val, data) => {
    if (/^0/.test(val.target.value)) {
      this.setState({ inputNumberType: '' })
      return;
    } else {
      this.setState({ inputNumberType: !isNaN(val.target.value) ? val.target.value : '' })


      //  , () => {
      //     this.updateEndDate();
      //     this.updateQuantity();
      //     this.checkDrugSaveBtn();
      //   });

      //   if (val.target.value === "") {
      //     this.setState({ drug_end_date: null })
      //   }
    }
  }

  async selectDurationChange(value, props, numberofDays) {
    const { drugsData, inputNumberType } = this.state;
    let moedifiedPrescriptionDrugId = props.medData?.prescription_drug_id;
    let data = drugsData.filter((data, i) => { return data.prescription_drug_id == moedifiedPrescriptionDrugId })
    var drugDuration;
    data[0].number_of_days = inputNumberType != '' && inputNumberType != undefined ? inputNumberType : numberofDays;
    await this.checkDrugSave(data, value, props, 'Duration').then((response) => {
      drugDuration = response;
    })
    if (drugDuration == true) {
      notification.error({
        message: 'Please select specific duration '
      })
      return
    }
    data[0].number_of_days_type = value;
    this.updateEndDate(data)

  }

  onClickMedData = async (data, i, e, grid, sch) => {

    if (this.props.editGrid == undefined) {
      return;
    }

    let edittable = ['schedule', 'frequency', 'duration'];
    if (data.drug_frequency == 3 && edittable.includes(grid)) {
      // notification.error({
      //   message: 'You cannot edit medicine having frequecy type prn',
      //   placement: 'topRight'
      // });
      return
    }
    let edittableCaseofSchdule = ['schedule', 'frequency']
    if (data.drug_frequency == 2 && edittableCaseofSchdule.includes(grid)) {
      // notification.error({
      //   message: 'You cannot change frequency you have to edit medicine',
      //   placement: 'topRight'
      // });
      return
    }

    let { allMedcineList, editable_grid } = this.state;
    if (typeof sch != 'undefined') {
      await this.setState({ editable_grid: grid + '_' + i + '_' + sch })
    } else {
      await this.setState({ editable_grid: grid + '_' + i })
    }

    let grid_med = data;
    this.setState({ grid_med: grid_med });
    if (typeof allMedcineList[data.drug_id] != 'undefined') {
      return;
    }

    await MedicineGridHelper.drugInfo(data).then(response => {
      allMedcineList[data.drug_id] = response;
      this.setState({
        drugOptions: response,
        allMedcineList: allMedcineList
      });
    }).catch(err => console.log(err));

  }

  checkMedicineIsEdidtable(data, grid_type) {
    let classNameAdded = false;
    if (data.drug_frequency == 2 || data.drug_frequency == 3) {
      classNameAdded = true
    }
    return classNameAdded;
  }

  handleViewInfo = async (e,drugId) => {
    this.setState({
      drug_id: drugId,
      isModalInfoVisible:true
    })
  }

  handleInfoOk = () => { this.setState({ isModalInfoVisible: false }); };

  handleInfoCancel = (e) => { this.setState({ isModalInfoVisible: false }) };


  render() {
    let { editable_grid, isEditGrid } = this.state;
    this.optionFormDrugLoad();
    this.optionDosageLoad();
    this.optionRouteLoad();
    this.optionDaysLoad();
    this.optionDailyFrequencyLoad();
    this.optionSchedulesLoad();
    this.optionPriorityLoad();
    this.optionInstructionLoad();
    let medicineRows = {};
    if (this.state.drugsData && this.state.drugsData.length > 0) {
      for (const medInfo of this.state.drugsData) {
        let medKey = medInfo['drug_id'];
        if (medInfo['dose_for_schedule'] == 'Y' && medInfo['schedules']?.length > 1) {
          medicineRows[medKey] = medicineRows[medKey] !== undefined ? (medicineRows[medKey] + medInfo['schedules'].length) : medInfo['schedules'].length;
        }
        else {
          medicineRows[medKey] = medicineRows[medKey] !== undefined ? (medicineRows[medKey] + 1) : 1;
        }
        if (medInfo['correction_dose'] == 'Y' && medInfo['correction_dose_details']?.length <= 0) {
          var final_cd = []
          for (var cd of medInfo['correction_dose_data']) {
            final_cd.push([cd.range_min, cd.range_max, cd.range_unit])
          }
          medInfo['correction_dose_details'] = final_cd
        }
      }
    }

    let iteratedDrugs = [];
    let mederowCount = 1;
    let medicine_remarks = '';
    let correction_dose = 'N';
    let correction_dose_data;
    let final_medicines_data = [];
    let medi_table = this.state.drugsData;
    final_medicines_data = [...medi_table]?.sort((a, b) => a.drug_id - b.drug_id);

    return (
      <Fragment>
        {this.state.drugsData && this.state.drugsData.length > 0 ?
          <>
            <div className="table-responsive emrfrm">
              <table className="table table-bordered custom-plan-header mb-0 mt-1">
                <thead>
                  <tr>
                    <th width="18%">MEDICINE NAME</th>
                    {/* {this.state.viewType != 'copy-from-prescription' ? (
                        <th>STRENGTH</th>
                    ) : null} */}
                    <th width="8%">ROUTE</th>
                    <th width="12%">FREQUENCY</th>
                    <th width="14%">SCHEDULE</th>
                    <th width="8%">DOSAGE</th>
                    <th width="13%">INSTRUCTIONS</th>
                    <th width="120px">Duration</th>
                    {(((this.state.viewType == 'template' || this.state.viewType == 'list-visit') && this.state.eventBased == true) || (this.state.viewType != 'template' && this.state.viewType != 'list-visit')) ? <th width="180px">DUE FROM</th> : null}
                    {this.props.showAction ? <th width="120"><div className="d-flex align-items-center"><span className='pr-2'>Action</span>  {this.state.deleteAllMedicineOnce == true ? <span><Tooltip placement="topLeft" title="Delete All"><i onClick={this.deleteAllmedicines} className='icon_delete icon_delete_all cursor-pointer mt-1'></i></Tooltip></span> : null}</div>
                    </th> : null}
                  </tr>
                </thead>
                <tbody>
                  {final_medicines_data.map((data, i) => {

                    let classNameAdded = this.checkMedicineIsEdidtable(data);
                    var deleteIndex = this.state.viewType == 'visit-full' ? data.id : i;
                    let medexists;
                    var protocol_start_date = moment(data.start_date).format('DD MMM, YYYY');
                    let schduledDefaultValue = data.schedule_name != null && data.schedule_name ? data.schedule_name : '-';
                    var drugId = data.drug_id;
                    let showBackgroundRoute = data.route != null ? this.state.constants.route.find((data1, i) => data1.id == data.route ? data1.description : null) : null;
                    let dosageBackground = null;
                    if (data.dosage && data.dosage < 17) {
                      this.state.constants.drugDosage.map(
                        function (data2, n) {
                          if (data.dosage_form_text === null) {
                            // data.dosage_form_text = '';
                          }
                          if (parseInt(data2.id) == parseInt(data.dosage)) {
                            dosageBackground = data2.description;
                          } else {
                            return null;
                          }
                        })
                    } else {
                      if (data.dosage_value != undefined) {
                        dosageBackground = data.dosage_value;
                      }
                    }
                    if (!iteratedDrugs.includes(data.drug_id)) {
                      medexists = false;
                      mederowCount = 1;
                      medicine_remarks = data.medicine_remarks
                      correction_dose = data.correction_dose
                      correction_dose_data = data.correction_dose_data
                      iteratedDrugs.push(data.drug_id);
                    } else {
                      medexists = true;
                      if (medicine_remarks === '') {
                        medicine_remarks = data.medicine_remarks;
                        correction_dose = data.correction_dose
                        correction_dose_data = data.correction_dose_data
                      }
                    }
                    if (data.schedules === null) {
                      data.schedule_name = null
                    }
                    var custom_bgcolor;
                    var show_original_due = false;
                    if (this.state.alreadyPrescribedDrugs?.includes(data.drug_id)) {
                      custom_bgcolor = '#dddbdb'
                    }
                    else if (moment(data.start_date_protocol).diff(moment(), 'days') < 0) {
                      custom_bgcolor = '#f7eaea'
                      show_original_due = true;
                    }
                    else if (moment(data.start_date).diff(moment(), 'days') == 0) {
                      custom_bgcolor = '#dcedf4'
                    }

                    const drugRowSpan = medicineRows[data.drug_id] !== undefined && medicineRows[data.drug_id] > 1 ? medicineRows[data.drug_id] : 1;
                    let drugFreqText = data.drug_frequency ? Object.keys(this.state.constants.drugFrequency).find(key => this.state.constants.drugFrequency[key].id === data.drug_frequency) : null;
                    drugFreqText = drugFreqText != null ? this.state.constants.drugFrequency[drugFreqText].description : '';
                    let htmlData = [];
                    let frequecyDefaultValue = data.days_in_week_text && data.drug_frequency && parseInt(data.drug_frequency) == 2 ? data.days_in_week_text : (data.drug_frequency == 1 ? this.state.drugDailyFrequency[data.daily_frequency] ? this.state.drugDailyFrequency[data.daily_frequency] : '-' : drugFreqText);
                    if (data.dose_for_schedule == 'Y' && data?.schedules?.length > 1) {
                      var counter = 1;
                      data.drug_duration_time = (data.daily_frequency == 8 || data.daily_frequency == 10) ? 'Months' : (data.daily_frequency == 9 || data.daily_frequency == 11) ? 'Weeks' : 'Days';
                      data?.schedules.map((sch, schIndex) => {
                        if (counter == 1) {
                          htmlData.push(
                            <>
                              <tr key={i}>
                                {drugRowSpan > 1 && !medexists ? <td rowSpan={drugRowSpan} className={this.state.viewType === 'copy-to-visit' && !data.selected ? (medicine_remarks !== '' && medicine_remarks !== null ? 'text-danger text-dark remarks-included  remark-border' : 'text-danger text-dark') : (medicine_remarks !== '' && medicine_remarks !== null ? 'text-dark remarks-included remark-border' : 'text-dark tablet-border')} style={{ fontWeight: 500 }}>{data.drug_name}</td> : null}
                                {drugRowSpan === 1 && !medexists ? <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? (medicine_remarks !== '' && medicine_remarks !== null ? 'text-danger text-dark remarks-included  remark-border' : 'text-danger text-dark') : (medicine_remarks !== '' && medicine_remarks !== null ? 'text-dark remarks-included remark-border' : 'text-dark tablet-border')} style={{ fontWeight: 500 }}>{data.drug_name}</td> : null}
                                {/* {this.state.viewType != 'copy-from-prescription' ? (
                                  <td rowSpan={data?.schedules?.length} className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.strength}</td>
                                ) : null} */}
                                <td rowSpan={data?.schedules?.length} className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null} onClick={e => this.onClickMedData(data, i, e, 'route')}>{(editable_grid == 'route_' + i.toString()) ? this.getRouteDropdown(data) : <React.Fragment>
                                  <span className={this.props.editGrid == undefined ? null : `custom-dp ${showBackgroundRoute == null && data.medicine_type=='F'? 'custom-empty-pink' : null}`}>
                                    {data.route != null ? this.state.constants.route.map((data1, i) => data1.id == data.route ? data1.description : null) : '-'}
                                  </span>
                                </React.Fragment>}
                                </td>
                                <td rowSpan={data?.schedules?.length} className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null} >
                                  {data.days_in_week_text && data.drug_frequency && parseInt(data.drug_frequency) == 2 ? data.days_in_week_text : (data.drug_frequency == 1 ? this.state.drugDailyFrequency[data.daily_frequency] : drugFreqText == "PRN" ? "PRN/SOS": drugFreqText)}</td>

                                <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>
                                  <React.Fragment> {sch == 1 ? 'Morning' : null}
                                    {sch == 2 ? 'Afternoon' : null}
                                    {sch == 3 ? 'Evening' : null}
                                    {sch == 4 ? 'Night' : null}</React.Fragment>
                                </td>
                                <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null} onClick={e => this.onClickMedData(data, i, e, 'dosage', schIndex)}>{(editable_grid == 'dosage_' + i.toString() + '_' + schIndex.toString()) ? this.getDosageLoadDropdownSchduled(data, schIndex + 1) : <React.Fragment>
                                  <span className={isEditGrid == undefined ? null : 'custom-tb'}>
                                    {sch == 1 ? data.dosage_value_morning + ' ' + data.dosage_form_text : null}
                                    {sch == 2 ? data.dosage_value_afternoon + ' ' + data.dosage_form_text : null}
                                    {sch == 3 ? data.dosage_value_evening + ' ' + data.dosage_form_text : null}
                                    {sch == 4 ? data.dosage_value_night + ' ' + data.dosage_form_text : null}
                                  </span>
                                </React.Fragment>}
                                </td>
                                <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null} onClick={e => this.onClickMedData(data, i, e, 'instruction', schIndex)}>
                                  {(editable_grid == 'instruction_' + i.toString() + '_' + schIndex.toString()) ? this.getInstructionDropdownSchduled(data, schIndex + 1) : <React.Fragment>
                                    <span className={isEditGrid == undefined ? null : 'custom-dp'}>
                                      {sch == 1 ? this.state.constants.drugMorningInstructions.map((data1, i) => data1.id == data.instruction_morning ? data1.description : null) : null}
                                      {sch == 2 ? this.state.constants.drugAfternoonInstructions.map((data1, i) => data1.id == data.instruction_afternoon ? data1.description : null) : null}
                                      {sch == 3 ? this.state.constants.drugEveningInstructions.map((data1, i) => data1.id == data.instruction_evening ? data1.description : null) : null}
                                      {sch == 4 ? this.state.constants.drugNightInstructions.map((data1, i) => data1.id == data.instruction_night ? data1.description : null) : null}
                                    </span>
                                  </React.Fragment>}
                                </td>
                                <td rowSpan={data?.schedules?.length} className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null} onClick={e => this.onClickMedData(data, i, e, 'duration', schIndex)}>{(editable_grid == 'duration_' + i.toString() + '_' + schIndex.toString()) ? this.getDurationDropdown(data) : <React.Fragment>
                                  <span className={isEditGrid == undefined || (classNameAdded && data.drug_frequency == 3) ? null : 'custom-tb'}>
                                    {data.number_of_days ? data.number_of_days + " " + data?.number_of_days_type : (parseInt(data?.drug_frequency) != 3 ? (parseInt(data?.daily_frequency) == 4 ? '-' : 'Till review') : '-')}
                                  </span>
                                </React.Fragment>}
                                </td>

                                {(this.state.viewType == 'template' || this.state.viewType == 'list-visit') && this.state.eventBased == true ?
                                  <td rowSpan={data?.schedules?.length} className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.event !== null ? this.state.constants.drugEvent.map((data1, i) => data1.id === data.event ? data1.description : null) : ''} {data.event == 1 || data.event == 2 ? data.lmp_event_duration : null} {data.event == 1 || data.event == 2 ? data.lmp_event_duration_type : null}</td>
                                  : null}
                                {
                                  this.state.viewType != 'template' && this.state.viewType != 'list-visit'
                                    ?
                                    <td rowSpan={data?.schedules?.length} className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>
                                      <div>
                                        {
                                          data?.event == 4
                                            ?
                                            <>
                                              {
                                                moment(data.start_date).format('D MMM, YYYY') == 'Invalid date'
                                                  ?
                                                  '-'
                                                  :
                                                  <>
                                                    <div className="form-group hasdata">
                                                      <DatePicker
                                                        id="protocol_start_date"
                                                        name="protocol_start_date"
                                                        data-drug={data.drug_id}
                                                        minDate={moment().toDate()}
                                                        defaultPickerValue={moment(protocol_start_date).format('D MMM, YYYY')}
                                                        defaultValue={moment(protocol_start_date).format('D MMM, YYYY')}
                                                        value={moment(protocol_start_date).format('D MMM, YYYY')}
                                                        className="form-control datetimepicker"
                                                        onChange={(e) => { this.updateStartDate(e, data.drug_id) }}
                                                      />
                                                      <label htmlFor="key_finding_date_time">Custom Date</label>
                                                    </div>
                                                  </>
                                              }
                                            </>
                                            :
                                            <>
                                              {
                                                moment(data.start_date).format('D MMM, YYYY') == 'Invalid date'
                                                  ?
                                                  '-'
                                                  :
                                                  <>
                                                    {moment(data.start_date).format('D MMM, YYYY')}
                                                    <span style={{ fontSize: "12px" }}>
                                                      {
                                                        show_original_due == true
                                                          ?
                                                          <>
                                                            <br />
                                                            (Due from {moment(data.start_date_protocol).format('D MMM, YYYY')})
                                                          </>
                                                          :
                                                          null
                                                      }
                                                    </span>
                                                  </>
                                              }
                                            </>
                                        }
                                      </div>
                                      <div className={data.urgency == 1 ? "text-secondary" : null}>
                                        {data.urgency !== null ? this.state.constants.drugUrgency.map((data1, i) => data1.id === data.urgency ? data1.description : null) : ''}
                                      </div>
                                    </td>
                                    :
                                    null
                                }

                                {/* <td rowSpan={data?.schedules?.length} className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.start_date ? data.start_date : '-'}</td> */}
                                {this.props.showAction ?
                                  <td rowSpan={data?.schedules?.length} >
                                    {
                                      this.state.viewType != 'template' && this.state.viewType != 'soap-full' ?
                                        <a className="nostyle-link mr-2" onClick={e => this.handleEditDelete(e, 'showMedicineModal', data, 'editMedicine', i)}><i className="icon-edit"></i></a>
                                        :
                                        <a className="nostyle-link mr-2" onClick={e => this.props.handleEdit(data, i)}><i className="icon-edit"></i></a>
                                    }
                                    <a className="nostyle-link mr-2" onClick={(e) => this.handleEditDelete(e, 'deleteConfirmModal', '', 'deleteMedicine', deleteIndex)}><i className="icon_delete"></i></a>
                                    {CIMSALLOW && CIMS_ALLOW_HOSPITALS.includes(this.state.hospitalId)
                                     && data.drug_cims && data.drug_cims!=null 
                                     ? (
                                      <a className="nostyle-link ml-1 mr-2" onClick={(e) => this.handleViewInfo(e, drugId)}><i className='icon_info' style={{width:'17px',height:'17px'}}></i></a>
                                    ) : null}
                                    {this.props.showSelectCheck ?
                                      <span className="select-diagnosis select-check">
                                        <i data-checkSelect={i} data-type={'drugs'} onClick={(e) => this.props.updateSelection(i, 'drugsData')}
                                          className={data.selected ? "btn-true-sm accept" : "btn-true-sm"}></i>
                                      </span>
                                      : null}
                                  </td>
                                  : null}
                              </tr>
                              {drugRowSpan === mederowCount && medicine_remarks !== '' && medicine_remarks !== null ?
                                <tr>
                                  <td colspan="11" className="remarks-column">
                                    <span className='bold-500'>Remarks :&nbsp;</span><span>{medicine_remarks}</span>
                                  </td>
                                </tr>
                                : null}
                              {drugRowSpan === mederowCount && correction_dose == 'Y' ?
                                <>
                                  <tr>
                                    <td colspan="11" className="py-2">
                                      <span className="text-primary">Correction Dose for {data.drug_name}</span>
                                      <table className="table" style={{ width: "auto" }}>
                                        <thead>
                                          <tr>
                                            <th width="200">Blood Sugar (mg/dL)</th>
                                            {data?.correction_dose_data?.[0] ? <th width="120">{data?.correction_dose_data?.[0]?.range_min} - {data?.correction_dose_data?.[0]?.range_max}</th> : null}
                                            {data?.correction_dose_data?.[1] ? <th width="120">{data?.correction_dose_data?.[1]?.range_min} - {data?.correction_dose_data?.[1]?.range_max}</th> : null}
                                            {data?.correction_dose_data?.[2] ? <th width="120">{data?.correction_dose_data?.[2]?.range_min} - {data?.correction_dose_data?.[2]?.range_max}</th> : null}
                                            {data?.correction_dose_data?.[3] ? <th width="120">{data?.correction_dose_data?.[3]?.range_min} - {data?.correction_dose_data?.[3]?.range_max}</th> : null}
                                            {data?.correction_dose_data?.[4] ? <th width="120">{data?.correction_dose_data?.[4]?.range_min} - {data?.correction_dose_data?.[4]?.range_max}</th> : null}
                                            {data?.correction_dose_data?.[5] ? <th width="120">{data?.correction_dose_data?.[5]?.range_min} - {data?.correction_dose_data?.[5]?.range_max}</th> : null}
                                            {data?.correction_dose_data?.[6] ? <th width="120">{data?.correction_dose_data?.[6]?.range_min} - {data?.correction_dose_data?.[6]?.range_max}</th> : null}
                                            {data?.correction_dose_data?.[7] ? <th width="120">{data?.correction_dose_data?.[7]?.range_min} - {data?.correction_dose_data?.[7]?.range_max}</th> : null}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>Insulin (Units)</td>
                                            {data?.correction_dose_data?.[0] ? <td>{data?.correction_dose_data?.[0]?.range_unit}</td> : null}
                                            {data?.correction_dose_data?.[1] ? <td>{data?.correction_dose_data?.[1]?.range_unit}</td> : null}
                                            {data?.correction_dose_data?.[2] ? <td>{data?.correction_dose_data?.[2]?.range_unit}</td> : null}
                                            {data?.correction_dose_data?.[3] ? <td>{data?.correction_dose_data?.[3]?.range_unit}</td> : null}
                                            {data?.correction_dose_data?.[4] ? <td>{data?.correction_dose_data?.[4]?.range_unit}</td> : null}
                                            {data?.correction_dose_data?.[5] ? <td>{data?.correction_dose_data?.[5]?.range_unit}</td> : null}
                                            {data?.correction_dose_data?.[6] ? <td>{data?.correction_dose_data?.[6]?.range_unit}</td> : null}
                                            {data?.correction_dose_data?.[7] ? <td>{data?.correction_dose_data?.[7]?.range_unit}</td> : null}
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </>
                                : null}
                            </>
                          )
                          mederowCount = mederowCount + 1;
                        }
                        else {
                          htmlData.push(
                            <>
                              <tr key={i}>
                                <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>

                                  <React.Fragment> {sch == 1 ? 'Morning' : null}
                                    {sch == 2 ? 'Afternoon' : null}
                                    {sch == 3 ? 'Evening' : null}
                                    {sch == 4 ? 'Night' : null}
                                  </React.Fragment>

                                </td>
                                <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null} onClick={e => this.onClickMedData(data, i, e, 'dosage', schIndex)}>{(editable_grid == 'dosage_' + i.toString() + '_' + schIndex.toString()) ? this.getDosageLoadDropdownSchduled(data, schIndex + 1) : <React.Fragment>
                                  <span className={isEditGrid == undefined ? null : 'custom-tb'}>
                                    {sch == 1 ? data.dosage_value_morning + ' ' + data.dosage_form_text : null}
                                    {sch == 2 ? data.dosage_value_afternoon + ' ' + data.dosage_form_text : null}
                                    {sch == 3 ? data.dosage_value_evening + ' ' + data.dosage_form_text : null}
                                    {sch == 4 ? data.dosage_value_night + ' ' + data.dosage_form_text : null}
                                  </span>
                                </React.Fragment>}
                                </td>
                                <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null} onClick={e => this.onClickMedData(data, i, e, 'instruction', schIndex)}>
                                  {(editable_grid == 'instruction_' + i.toString() + '_' + schIndex.toString()) ? this.getInstructionDropdownSchduled(data, schIndex + 1) : <React.Fragment>
                                    <span className={isEditGrid == undefined ? null : 'custom-dp'}>
                                      {sch == 1 ? this.state.constants.drugMorningInstructions.map((data1, i) => data1.id == data.instruction_morning ? data1.description : null) : null}
                                      {sch == 2 ? this.state.constants.drugAfternoonInstructions.map((data1, i) => data1.id == data.instruction_afternoon ? data1.description : null) : null}
                                      {sch == 3 ? this.state.constants.drugEveningInstructions.map((data1, i) => data1.id == data.instruction_evening ? data1.description : null) : null}
                                      {sch == 4 ? this.state.constants.drugNightInstructions.map((data1, i) => data1.id == data.instruction_night ? data1.description : null) : null}
                                    </span>
                                  </React.Fragment>}
                                </td>

                              </tr>
                              {drugRowSpan === mederowCount && medicine_remarks !== '' && medicine_remarks !== null ?
                                <tr>
                                  <td colspan="11" className="remarks-column">
                                    <span className='bold-500'>Remarks :&nbsp;</span><span>{medicine_remarks}</span>
                                  </td>
                                </tr>
                                : null}
                              {drugRowSpan === mederowCount && correction_dose == 'Y' ?
                                <>
                                  <tr>
                                    <td colspan="11" className="py-2">
                                      <span className="text-primary">Correction Dose for {data.drug_name}</span>
                                      <table className="table" style={{ width: "auto" }}>
                                        <thead>
                                          <tr>
                                            <th width="200">Blood Sugar (mg/dL)</th>
                                            {data?.correction_dose_data?.[0] ? <th width="120">{data?.correction_dose_data?.[0]?.range_min} - {data?.correction_dose_data?.[0]?.range_max}</th> : null}
                                            {data?.correction_dose_data?.[1] ? <th width="120">{data?.correction_dose_data?.[1]?.range_min} - {data?.correction_dose_data?.[1]?.range_max}</th> : null}
                                            {data?.correction_dose_data?.[2] ? <th width="120">{data?.correction_dose_data?.[2]?.range_min} - {data?.correction_dose_data?.[2]?.range_max}</th> : null}
                                            {data?.correction_dose_data?.[3] ? <th width="120">{data?.correction_dose_data?.[3]?.range_min} - {data?.correction_dose_data?.[3]?.range_max}</th> : null}
                                            {data?.correction_dose_data?.[4] ? <th width="120">{data?.correction_dose_data?.[4]?.range_min} - {data?.correction_dose_data?.[4]?.range_max}</th> : null}
                                            {data?.correction_dose_data?.[5] ? <th width="120">{data?.correction_dose_data?.[5]?.range_min} - {data?.correction_dose_data?.[5]?.range_max}</th> : null}
                                            {data?.correction_dose_data?.[6] ? <th width="120">{data?.correction_dose_data?.[6]?.range_min} - {data?.correction_dose_data?.[6]?.range_max}</th> : null}
                                            {data?.correction_dose_data?.[7] ? <th width="120">{data?.correction_dose_data?.[7]?.range_min} - {data?.correction_dose_data?.[7]?.range_max}</th> : null}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>Insulin (Units)</td>
                                            {data?.correction_dose_data?.[0] ? <td>{data?.correction_dose_data?.[0]?.range_unit}</td> : null}
                                            {data?.correction_dose_data?.[1] ? <td>{data?.correction_dose_data?.[1]?.range_unit}</td> : null}
                                            {data?.correction_dose_data?.[2] ? <td>{data?.correction_dose_data?.[2]?.range_unit}</td> : null}
                                            {data?.correction_dose_data?.[3] ? <td>{data?.correction_dose_data?.[3]?.range_unit}</td> : null}
                                            {data?.correction_dose_data?.[4] ? <td>{data?.correction_dose_data?.[4]?.range_unit}</td> : null}
                                            {data?.correction_dose_data?.[5] ? <td>{data?.correction_dose_data?.[5]?.range_unit}</td> : null}
                                            {data?.correction_dose_data?.[6] ? <td>{data?.correction_dose_data?.[6]?.range_unit}</td> : null}
                                            {data?.correction_dose_data?.[7] ? <td>{data?.correction_dose_data?.[7]?.range_unit}</td> : null}
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </>
                                : null}
                            </>
                          )
                          mederowCount = mederowCount + 1;
                        }
                        counter++;
                      })
                    }
                    else {
                      data.drug_duration_time = (data.daily_frequency == 8 || data.daily_frequency == 10) ? 'Months' : (data.daily_frequency == 9 || data.daily_frequency == 11) ? 'Weeks' : 'Days';
                      htmlData.push(
                        <>
                          <tr key={i} style={{ backgroundColor: this.state.viewType == 'copy-to-visit' && this.state.eventBased == true && this.state.patientInfo.gender == 'Female' && LS_SERVICE.get('isObsGynaeDoctor') == 1 && LS_SERVICE.get('isObsGynaePatient') == true && custom_bgcolor ? custom_bgcolor : null }}>
                            {drugRowSpan > 1 && !medexists ? <td rowSpan={drugRowSpan} className={this.state.viewType === 'copy-to-visit' && !data.selected ? (medicine_remarks !== '' && medicine_remarks !== null ? 'text-danger text-dark remarks-included remark-border' : 'text-danger text-dark') : (medicine_remarks !== '' && medicine_remarks !== null ? 'text-dark remarks-included remark-border ' : 'text-dark tablet-border')} style={{ fontWeight: 500 }}>{data.drug_name}</td> : null}
                            {drugRowSpan === 1 && !medexists ? <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? (medicine_remarks !== '' && medicine_remarks !== null ? 'text-danger text-dark remarks-included remark-border' : 'text-danger text-dark') : (medicine_remarks !== '' && medicine_remarks !== null ? 'text-dark remarks-included  remark-border ' : 'text-dark tablet-border')} style={{ fontWeight: 500 }}>{data.drug_name}</td> : null}
                            {/* {this.state.viewType != 'copy-from-prescription' ? (
                              <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.strength?data.strength:'-'}</td>
                            ) : null} */}

                            <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null} onClick={e => this.onClickMedData(data, i, e, 'route')}>
                              {(editable_grid == 'route_' + i.toString()) ? this.getRouteDropdown(data) : <React.Fragment>
                                <span className={this.props.editGrid == undefined ? null : `custom-dp ${showBackgroundRoute == null &&  data.medicine_type=='F' ? 'custom-empty-pink' : null}`}>
                                  {data.route != null ? this.state.constants.route.map((data1, i) => data1.id == data.route ? data1.description : null) : '-'}
                                </span>
                              </React.Fragment>}
                            </td>

                            <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null} onClick={e => this.onClickMedData(data, i, e, 'frequency')}>
                              {(editable_grid == 'frequency_' + i.toString()) ? this.getDailyFrequencyDropdown(data, frequecyDefaultValue) : <React.Fragment> <span className={isEditGrid == undefined || classNameAdded ? null : 'custom-dp'}>
                                {data.days_in_week_text && data.drug_frequency && parseInt(data.drug_frequency) == 2 ? data.days_in_week_text : (data.drug_frequency == 1 ? this.state.drugDailyFrequency[data.daily_frequency] ? this.state.drugDailyFrequency[data.daily_frequency] : '-' : drugFreqText == "PRN" ? "PRN/SOS": drugFreqText)}
                              </span>
                              </React.Fragment>}
                            </td>
                            <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null} onClick={e => this.onClickMedData(data, i, e, 'schedule')}> {(editable_grid == 'schedule_' + i.toString()) ? this.getDailyschedulesDropdown(data, schduledDefaultValue) : <React.Fragment> <span className={isEditGrid == undefined || classNameAdded ? null : 'custom-dp'}>
                              {data.schedule_name != null && data.schedule_name ? data.schedule_name : '-'}
                            </span>
                            </React.Fragment>}
                            </td>
                            <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null} onClick={e => this.onClickMedData(data, i, e, 'dosage')}>{(editable_grid == 'dosage_' + i.toString()) ? this.getDosageLoadDropdown(data,) : <React.Fragment> <span className={isEditGrid == undefined ? null : `custom-tb ${dosageBackground == null && data.medicine_type =='F' ? 'custom-empty-pink' : null}`}>

                              {
                                data.dosage && data.dosage < 17
                                  ?
                                  this.state.constants.drugDosage.map(
                                    function (data1, n) {
                                      if (data.dosage_form_text === null) {
                                        data.dosage_form_text = '';
                                      }
                                      if (parseInt(data1.id) == parseInt(data.dosage)) {
                                        return data1.description + ' ' + data.dosage_form_text;
                                      } else {
                                        return null;
                                      }
                                    })
                                  :
                                  (data.dosage_value != undefined) ? data.dosage_value + ' ' + (data.dosage_form_text) ?? data.dosage_form_text : '-'}</span></React.Fragment>}</td>
                            <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null} onClick={e => this.onClickMedData(data, i, e, 'instruction')}>{(editable_grid == 'instruction_' + i.toString()) ? this.getInstructionDropdown(data) : <React.Fragment>
                              <span className={isEditGrid == undefined ? null : 'custom-dp'}>
                                {this.state.constants.drugInstructions.map((data1, i) => data1.id == data.instruction ? data1.description : null)}
                              </span>
                            </React.Fragment>}
                            </td>
                            <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null} onClick={e => this.onClickMedData(data, i, e, 'duration')}>{(editable_grid == 'duration_' + i.toString()) ? this.getDurationDropdown(data) : <React.Fragment>
                              <span className={isEditGrid == undefined || (classNameAdded && data.drug_frequency == 3) ? null : 'custom-tb'}>
                                {data.number_of_days ? data.number_of_days + " " + data?.number_of_days_type : (parseInt(data?.drug_frequency) != 3 ? (parseInt(data?.daily_frequency) == 4 ? '-' : 'Till review') : '-')}
                              </span>
                            </React.Fragment>}</td>

                            {(this.state.viewType == 'template' || this.state.viewType == 'list-visit') && this.state.eventBased == true ?
                              <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.event !== null ? this.state.constants.drugEvent.map((data1, i) => data1.id === data.event ? data1.description : null) : ''} {data.event == 1 || data.event == 2 ? data.lmp_event_duration : null} {data.event == 1 || data.event == 2 ? data.lmp_event_duration_type : null}</td>
                              : null}
                            {
                              this.state.viewType != 'template' && this.state.viewType != 'list-visit'
                                ?
                                <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>
                                  <div>
                                    {
                                      data?.event == 4
                                        ?
                                        <>
                                          {
                                            moment(data.start_date).format('D MMM, YYYY') == 'Invalid date'
                                              ?
                                              '-'
                                              :
                                              <>
                                                <div className="form-group hasdata">
                                                  <DatePicker
                                                    id="protocol_start_date"
                                                    name="protocol_start_date"
                                                    data-drug={data.drug_id}
                                                    minDate={moment().toDate()}
                                                    defaultPickerValue={moment(protocol_start_date).format('D MMM, YYYY')}
                                                    defaultValue={moment(protocol_start_date).format('D MMM, YYYY')}
                                                    value={moment(protocol_start_date).format('D MMM, YYYY')}
                                                    className="form-control datetimepicker"
                                                    onChange={(e) => { this.updateStartDate(e, data.drug_id) }}
                                                  />
                                                  <label htmlFor="key_finding_date_time">Custom Date</label>
                                                </div>
                                              </>
                                          }
                                        </>
                                        :
                                        <>
                                          {
                                            moment(data.start_date).format('D MMM, YYYY') == 'Invalid date'
                                              ?
                                              '-'
                                              :
                                              <>
                                                {moment(data.start_date).format('D MMM, YYYY')}
                                                <span style={{ fontSize: "12px" }}>
                                                  {
                                                    show_original_due == true
                                                      ?
                                                      <>
                                                        <br />
                                                        (Due from {moment(data.start_date_protocol).format('D MMM, YYYY')})
                                                      </>
                                                      :
                                                      null
                                                  }
                                                </span>
                                              </>
                                          }
                                        </>
                                    }
                                  </div>
                                  <div className={data.urgency == 1 ? "text-secondary" : null}>
                                    {data.urgency !== null ? this.state.constants.drugUrgency.map((data1, i) => data1.id === data.urgency ? data1.description : null) : ''}
                                  </div>
                                </td>
                                :
                                null
                            }
                            {this.props.showAction ?
                              <td>
                                {
                                  this.state.viewType != 'template' && this.state.viewType != 'soap-full' ?
                                    <a className="nostyle-link mr-2" onClick={e => this.handleEditDelete(e, 'showMedicineModal', data, 'editMedicine', i)}><i className="icon-edit"></i></a>
                                    :
                                    <a className="nostyle-link mr-2" onClick={e => this.props.handleEdit(data, i)}><i className="icon-edit"></i></a>
                                }
                                <a className="nostyle-link mr-2" onClick={(e) => this.handleEditDelete(e, 'deleteConfirmModal', '', 'deleteMedicine', deleteIndex)}><i className="icon_delete"></i></a>
                                {CIMSALLOW && CIMS_ALLOW_HOSPITALS.includes(this.state.hospitalId) 
                                && data.drug_cims && data.drug_cims!=null 
                                ? (
                                      <a className="nostyle-link ml-1 mr-2" onClick={(e) => this.handleViewInfo(e, drugId)}><i className='icon_info' style={{width:'17px',height:'17px'}}></i></a>
                                    ) : null}
                                {this.props.showSelectCheck ?
                                  <span className="select-diagnosis select-check">
                                    <i data-checkSelect={i} data-type={'drugs'} onClick={(e) => this.props.updateSelection(i, 'drugsData')}
                                      className={data.selected ? "btn-true-sm accept" : "btn-true-sm"}></i>
                                  </span>
                                  : null}
                              </td>
                              : null}
                          </tr>
                          {drugRowSpan === mederowCount && medicine_remarks !== '' && medicine_remarks !== null ?
                            <tr>
                              <td colspan="11" className="remarks-column">
                                <span className='bold-500'>Remarks :&nbsp;</span><span>{medicine_remarks}</span>
                              </td>
                            </tr>
                            : null}
                          {drugRowSpan === mederowCount && correction_dose == 'Y' ?
                            <>
                              <tr>
                                <td colspan="11" className="py-2">
                                  <span className="text-primary">Correction Dose for {data.drug_name}</span>
                                  <table className="table" style={{ width: "auto" }}>
                                    <thead>
                                      <tr>
                                        <th width="200">Blood Sugar (mg/dL)</th>
                                        {data?.correction_dose_data?.[0] ? <th width="120">{data?.correction_dose_data?.[0]?.range_min} - {data?.correction_dose_data?.[0]?.range_max}</th> : null}
                                        {data?.correction_dose_data?.[1] ? <th width="120">{data?.correction_dose_data?.[1]?.range_min} - {data?.correction_dose_data?.[1]?.range_max}</th> : null}
                                        {data?.correction_dose_data?.[2] ? <th width="120">{data?.correction_dose_data?.[2]?.range_min} - {data?.correction_dose_data?.[2]?.range_max}</th> : null}
                                        {data?.correction_dose_data?.[3] ? <th width="120">{data?.correction_dose_data?.[3]?.range_min} - {data?.correction_dose_data?.[3]?.range_max}</th> : null}
                                        {data?.correction_dose_data?.[4] ? <th width="120">{data?.correction_dose_data?.[4]?.range_min} - {data?.correction_dose_data?.[4]?.range_max}</th> : null}
                                        {data?.correction_dose_data?.[5] ? <th width="120">{data?.correction_dose_data?.[5]?.range_min} - {data?.correction_dose_data?.[5]?.range_max}</th> : null}
                                        {data?.correction_dose_data?.[6] ? <th width="120">{data?.correction_dose_data?.[6]?.range_min} - {data?.correction_dose_data?.[6]?.range_max}</th> : null}
                                        {data?.correction_dose_data?.[7] ? <th width="120">{data?.correction_dose_data?.[7]?.range_min} - {data?.correction_dose_data?.[7]?.range_max}</th> : null}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>Insulin (Units)</td>
                                        {data?.correction_dose_data?.[0] ? <td>{data?.correction_dose_data?.[0]?.range_unit}</td> : null}
                                        {data?.correction_dose_data?.[1] ? <td>{data?.correction_dose_data?.[1]?.range_unit}</td> : null}
                                        {data?.correction_dose_data?.[2] ? <td>{data?.correction_dose_data?.[2]?.range_unit}</td> : null}
                                        {data?.correction_dose_data?.[3] ? <td>{data?.correction_dose_data?.[3]?.range_unit}</td> : null}
                                        {data?.correction_dose_data?.[4] ? <td>{data?.correction_dose_data?.[4]?.range_unit}</td> : null}
                                        {data?.correction_dose_data?.[5] ? <td>{data?.correction_dose_data?.[5]?.range_unit}</td> : null}
                                        {data?.correction_dose_data?.[6] ? <td>{data?.correction_dose_data?.[6]?.range_unit}</td> : null}
                                        {data?.correction_dose_data?.[7] ? <td>{data?.correction_dose_data?.[7]?.range_unit}</td> : null}
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </>
                            : null}
                        </>
                      )
                      mederowCount = mederowCount + 1;
                    }
                    delete data.drug_duration_time;
                    return htmlData;
                  })}
                </tbody>
              </table>
            </div>
          </>
          : (null)
        }

        <Modal
          visible={this.state.deleteMedicineModal}
          onCancel={this.handleModalPopup}
          style={{ top: 40 }}
          title="Are you sure you want to delete all medicines?"
          closable={false}
          footer={[
            <div className="text-center">
              <button
                className="btn btn-outline-secondary px-5 cancelOrderSet"
                onClick={this.handleModalPopup}
              >
                CANCEL
              </button>
              <button
                className="btn btn-primary px-5 deleteOrderSet ml-1"
                disabled={this.state.spin_loading}
                id='deleteButton'
                onClick={this.handleDeletePopSubmit}
              >
                DELETE{this.state.spin_loading ? LOADER : ""}
              </button>
            </div>,
          ]}
        ></Modal>

        <Modal className="myDrugInfo_modal" id="myDrugInfo"
          title="Drug Information"
          visible={this.state.isModalInfoVisible}
          onOk={() => this.handleInfoOk()}
          onCancel={() => this.handleInfoCancel()}
          footer={false}
        >
          <iframe id="drug_interaction_widget_iframe1" title="information" className="cimsiframe" src={`${CIMS_INFO}/${this.state.drug_id}/${this.state.store_id}/${this.state.facility_id}/${this.state.enterprise_id}`}></iframe>
        </Modal>

      </Fragment>
    );
  }
}

export default MedicineTable;
